import React from 'react';
import { Form } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import Select from 'react-select';

const ConfigurationComponent = ({
    towerIndex,
    configIndex,
    configuration,
    handleConfigurationChange,
    removeConfiguration,
    mstConfig
}) => {
   
    const optionsData = mstConfig.map(item => ({
        value: item.id,
        label: item.name
    }));
    return (
        <tr>
            <td>{configIndex + 1}</td>
            <td>
                <Form.Control
                    type="text"
                    placeholder="Name"
                    name="configurations.name"
                    onChange={(e) => handleConfigurationChange(e, towerIndex, configIndex)}
                />
            </td>
            <td>
                <Form.Control
                    type="number"
                    placeholder="Agreement Price"
                    name="configurations.agreement_price"
                    onChange={(e) => handleConfigurationChange(e, towerIndex, configIndex)}
                />
            </td>
            <td>
                <Form.Control
                    type="number"
                    placeholder="All Inclusive Price"
                    name="configurations.all_inclusive_price"
                    onChange={(e) => handleConfigurationChange(e, towerIndex, configIndex)}
                />
            </td>
            <td>
                {/* <Form.Control
                        type="text"
                        placeholder="Unit Configuration Name"
                        name="configurations.unit_configuration_name"
                        onChange={(e) => handleConfigurationChange(e, towerIndex, configIndex)}
                    /> */}
                <Select
                    options={optionsData}
                    // value={selectedOptions}
                    onChange={(selectedOption) => handleConfigurationChange({
                        target: {
                            name: "configurations.unit_configuration_name",
                            value: selectedOption ? selectedOption.value : ''
                        }
                    }, towerIndex, configIndex)}
                    name="configurations.unit_configuration_name"
                />
            </td>
            <td>
                <Form.Control
                    type="number"
                    placeholder="No of Bedrooms"
                    name="configurations.no_of_bedrooms"
                    onChange={(e) => handleConfigurationChange(e, towerIndex, configIndex)}
                />
            </td>
            <td>
                <Form.Control
                    type="number"
                    placeholder="No of Bathrooms"
                    name="configurations.no_of_bathrooms"
                    onChange={(e) => handleConfigurationChange(e, towerIndex, configIndex)}
                />
            </td>
            <td>
                <Form.Control
                    type="number"
                    placeholder="Carpet Area"
                    name="configurations.carpet_area"
                    onChange={(e) => handleConfigurationChange(e, towerIndex, configIndex)}
                />
            </td>
            <td>
                <Form.Control
                    type="number"
                    placeholder="Saleable Carpet Area"
                    name="configurations.saleable_carpet_area"
                    onChange={(e) => handleConfigurationChange(e, towerIndex, configIndex)}
                />
            </td>
            <td>
                <Form.Control
                    type="text"
                    placeholder="Unit Facing Direction"
                    name="configurations.unit_facing_direction"
                    onChange={(e) => handleConfigurationChange(e, towerIndex, configIndex)}
                />
            </td>
            <td>
                <Form.Control
                    type="number"
                    placeholder="No Of Apartment"
                    name="configurations.no_of_apartment"
                    onChange={(e) => handleConfigurationChange(e, towerIndex, configIndex)}
                />
            </td>
            <td>
                <Form.Control
                    type="number"
                    placeholder="No Of Booked Apartment"
                    name="configurations.no_of_booked_apartment"
                    onChange={(e) => handleConfigurationChange(e, towerIndex, configIndex)}
                />
            </td>
            <td>
                {/* <Button variant="danger" onClick={() => removeConfiguration(towerIndex, configIndex)}>Remove</Button> */}
                <button className="btn btn-outline-danger rounded-circle add-config text-danger" onClick={() => removeConfiguration(towerIndex, configIndex)}><Icon icon="ic:sharp-remove" style={{ fontSize: '24px' }} /></button>
            </td>
        </tr>
    );
};

export default ConfigurationComponent;
