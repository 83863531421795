import React, { useEffect, useState } from 'react';
import { appAbsPath, executeLaravelAPI, userToken, showToastMessage, parsedUserData } from '../../Admin/Utils';
import { Link } from 'react-router-dom';


const WishListBtn = ({projectId, loggeduserdetails, color, width, height, isWishlistActives}) => {
    const [isActive, setIsActive] = useState(false);
    const [isLogin, setLoginTrue] = useState(false);
    const [isWishList, isWishlistActive] = useState(false);
    // console.log(isWishlistActives);
 
    const handleShortListedClick = async (projectId) => {
        try {
            const result = await executeLaravelAPI(`add-wishlist?wishlistid=${projectId}`, '', 'POST', userToken);
            if (result.status === 200) {
                showToastMessage('Shorlisted Project has been added successfully', 'top-right', 'success');
                if (!isActive) {
                    setIsActive(true);
                }
                isWishlistActive(true);
            } else {
                showToastMessage('Shorlisted Project has not been added.', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error fetched project data:', error);
        }
    };
    const handleShortListedRemove = async (projectId) => {
        try {
            const result = await executeLaravelAPI(`remove-wishlist?wishlistid=${projectId}`, '', 'POST', userToken);
            if (result.status === 200) {
                showToastMessage('Shorlisted Project has been removed successfully', 'top-right', 'error');
                setIsActive(false);
                isWishlistActive(false);
            } else {
                showToastMessage('Shorlisted Project has not been removed.', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error fetched project data:', error);
        }
    };
    const handleNotLogin = () => {
        // setLoginTrue(true);
        document.getElementById('overlay').style.display='block';
        document.getElementById('login_title').textContent="Add this project to your wishlist";
    }

    return (
        loggeduserdetails !== null ? (
            <div className={`irfswish position-absolute c-pointer zindex-10 top-0 end-0 m-3 my-0 pt-3 ${isWishList && isActive || isWishlistActives ? 'shortlisted' : ''}`} id={projectId} onClick={isWishList || isWishlistActives ? () => handleShortListedRemove(projectId) : () => handleShortListedClick(projectId)}>
                <svg id="Component_21_117" data-name="Component 21 – 117" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 31 31">
                    <g id="Ellipse_112" data-name="Ellipse 112" fill="none" stroke={color} strokeWidth="1">
                    <circle cx="15.5" cy="15.5" r="15.5" stroke="none"/>
                    <circle cx="15.5" cy="15.5" r="15" fill="none"/>
                    </g>
                    <path id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" d="M13.682,3.938h-.034A3.858,3.858,0,0,0,10.427,5.7,3.858,3.858,0,0,0,7.206,3.938H7.172a3.833,3.833,0,0,0-3.8,3.831A8.253,8.253,0,0,0,5,12.268,28.4,28.4,0,0,0,10.427,17.5a28.4,28.4,0,0,0,5.432-5.232,8.253,8.253,0,0,0,1.621-4.5A3.833,3.833,0,0,0,13.682,3.938Z" transform="translate(5.344 4.781)" fill={color}/>
                </svg>
            </div>
            ) : (
                // console.log("User not logged in"),
                <a onClick={() => handleNotLogin()} className='irfswish position-absolute c-pointer zindex-10 top-0 end-0 m-3 my-0 pt-3'>
                    <svg id="Component_21_117" data-name="Component 21 – 117" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 31 31">
                        <g id="Ellipse_112" data-name="Ellipse 112" fill="none" stroke={color} strokeWidth="1">
                        <circle cx="15.5" cy="15.5" r="15.5" stroke="none"/>
                        <circle cx="15.5" cy="15.5" r="15" fill="none"/>
                        </g>
                        <path id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" d="M13.682,3.938h-.034A3.858,3.858,0,0,0,10.427,5.7,3.858,3.858,0,0,0,7.206,3.938H7.172a3.833,3.833,0,0,0-3.8,3.831A8.253,8.253,0,0,0,5,12.268,28.4,28.4,0,0,0,10.427,17.5a28.4,28.4,0,0,0,5.432-5.232,8.253,8.253,0,0,0,1.621-4.5A3.833,3.833,0,0,0,13.682,3.938Z" transform="translate(5.344 4.781)" fill={color}/>
                    </svg>                     
                </a>
            )
    );
};

export default WishListBtn;
