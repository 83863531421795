import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { userToken, executeLaravelAPI} from '../../Utils';
// import PageHeader from '../Layouts/PageHeader';
// import ModalForm from './ModalForm';
import ReactPaginate from 'react-paginate';
import { Icon } from '@iconify/react';
import ProjectForm from '../ProjectForm';
import ProjectEditForm from './ProjectEditForm';
import addNewImg from '../../../../Assets/img/add-new.svg';
import deleteBtnImg from '../../../../Assets/img/delete-btn.svg';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';


const itemsPerPageOptions = [20, 50, 100, 500, 1000];
// const OutsideClickHandler = ({ children, onOutsideClick }) => {
//     const wrapperRef = useRef(null);
  
//     useEffect(() => {
//       const handleOutsideClick = (event) => {
//         // Check if the click is outside the div
//         if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
//           // Call the callback function provided
//           onOutsideClick();
//         }
//       };
  
//       // Attach the event listener to the document body
//       document.addEventListener('mousedown', handleOutsideClick);
  
//       // Cleanup the event listener on component unmount
//       return () => {
//         document.removeEventListener('mousedown', handleOutsideClick);
//       };
//     }, [onOutsideClick]);
  
//     return <div ref={wrapperRef}>{children}</div>;
//   };

const UpdatedProjectList = ({ show, handleClose }) => {
    const pageDetails={'pagetitle':'Projects','pageslug':'projects'};

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
    const [paginatedData, setPaginatedData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [projects, setResponseData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm] = useState('');
    const [totalItems, setTotalItems] = useState(0); 
    const [details, setDetails] = useState([]);
    const [showResult, showSearchResult] = useState(false);
    const [showDefaultResult, showDefaultSearchResult] = useState(false);
    const [priceRange, setPriceRange] = useState([500000, 50000000]);
    const [buttonIcon, setButtonIcon] = useState({});
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [showOnLocationList, clickOnLocationList] = useState(false);
    const [sortCriteria, setSortCriteria] = useState('Relevance');

    const location = useLocation();
    

    useEffect(() => {
        document.title = pageDetails.pagetitle;
        getProjects();
    },[currentPage, itemsPerPage]);

    const getProjects = async (paramsData) => {
        try {
            setLoading(true);
            console.log("---------------------------------")
            // console.log(paramsData);
            if(paramsData.orderby == "" || paramsData.orderby == undefined || paramsData.orderby == null || paramsData.orderby == "Relevance"){
                paramsData.orderby = "priceLowToHigh"
            }
            // paramsData.orderby = "updatedat";
            const result=await executeLaravelAPI('projects', paramsData, 'GET', userToken);
            setPaginatedData(result.data.data);
            setTotalItems(result.data.total);
            console.log(paginatedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    // const getProjects = async (pageNum) => {
    //     try {
    //         setLoading(true);
    //         const params = {
    //             page: pageNum || currentPage + 1,   
    //             per_page: itemsPerPage,
    //             orderby: "updatedat",
    //         };
    //         const result=await executeLaravelAPI('projects', params, 'GET', userToken);
    //         setPaginatedData(result.data.data);
    //         setTotalItems(result.data.total);
    //         console.log(paginatedData);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    
    const handleOpenModal = () => {
        setShowModal(true);
    };
    
    // const handleCloseModal = () => {
    //     setShowModal(false);
    // };
    // const [isFormVisible, setIsFormVisible] = useState(true);
    const hideForm = () => {
        setShowModal(false);
    };
    const hideEditForm = () => {
        setShowModal(false);
    };
    /* Long Amount to convert short Amount like 30.5L or 1.2 Cr.  */
    const formatNumber = (num) => {
        try {
            if (num) {
              if (!isNaN(num)) {
                if (num < 100000) {
                  return num.toString(); // No formatting needed for numbers less than 100,000
                } else if (num < 10000000) {
                  return (num / 100000).toFixed(2) + ' L';
                } else {
                  return (num / 10000000).toFixed(2) + ' Cr';
                }
              }
            }
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        
          return '0';
      
    };
    // const squareFeet = (num) => {
    //     const sqft = num ? parseFloat(num) * 43560 : '';
    //     return sqft.toFixed(2);
    // };

    // Function to handle input change  
    const longToShort = (num) => {  
        // const date = num ? new Date(parseInt(num)).toLocaleDateString('en-GB', {           
        //     day: '2-digit',           
        //     month: '2-digit',           
        //     year: 'numeric',         
        // }): ''; 
        // return date;  
        // const timestamp = "2024-01-15T14:09:58.000000Z";
        const date = new Date(num);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`; 
    };

     /* Search Function */
    //  const handleSearchChange = (e) => {
    //     setSearchTerm(e.target.value);
    //     setCurrentPage(0); // Reset to the first page when searching
    // };

    {/* Project Status Function */}
    const getBadge = (status) => {
        switch (status) {
          case 'completed':
            return 'success'
          case 'On-Going Project':
            return 'secondary'
          case 'ongoing':
            return 'secondary'
          case 'prelaunch':
            return 'warning'
          case 'blocked':
            return 'danger'
          case 'New Project':
            return 'danger'
          default:
            return 'warning'
        }
    }
    const toggleDetails = (index, projectId) => {
        const position = details.indexOf(index);
        let newDetails = details.slice();
        if (position !== -1) {
          newDetails.splice(position, 1);
        } else {
          newDetails = [...details, index];
        }
        setDetails(newDetails);
        setSelectedProjectId(projectId);
        setButtonIcon((prevIcon) => ({
            ...prevIcon,
            [index]: !prevIcon[index],
        }));
    };

    /* Pagination Function */
    const handlePageClick = ({ selected }) => {
        // alert(selected);
        setCurrentPage(selected);
        getProjects((selected+1));
        setLocality(selected+1);
        setProject(selected+1);
    };

    const handleItemsPerPageChange = (e) => {
        const selectedValue = parseInt(e.target.value, 10);
        setItemsPerPage(selectedValue);
        setCurrentPage(0); // Reset to the first page when changing items per page
    };
    useEffect(() => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const filteredProjects = paginatedData.filter((project) => {
            const matchName = project.name.toLowerCase().includes(searchTerm.toLowerCase());
            const matchStatus = project.project_status.toLowerCase().includes(searchTerm.toLowerCase());
            const matchRegionName = project.region_name.toLowerCase().includes(searchTerm.toLowerCase());      
             // Add similar checks for other parameters (Nearby, Configuration, Price, etc.)
          
          return matchName || matchRegionName || matchStatus;
        });
    
        const newPaginatedData = filteredProjects.slice(startIndex, endIndex);
        setPaginatedData(newPaginatedData);
      }, [currentPage, itemsPerPage, projects, searchTerm]);


      const getAllParams = (location) => {
        const searchParams = new URLSearchParams(location.search);
        const allParams = {};
        for (const [key, value] of searchParams.entries()) {
            allParams[key] = value;
        }
        allParams.page = currentPage + 1;
        allParams.per_page = itemsPerPage;
        return allParams;
    }

    
    const handleSelectChange = async (e) => {
        try {
            const filterValue = e.target.value;
            const result = await executeLaravelAPI('filterprojects', filterValue, 'POST', userToken);
            setResponseData(result.data);
            // setSelectedValue(filterValue);
        } catch (error) {
            console.error('Error fetched project data:', error);
        }
    };
   
    // Search filter
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchDevResults, setDevSearchResults] = useState([]);
    const uniqueLocalitiesRegion = new Set();
    const uniqueLocalitiesArea = new Set();
    // const uniqueLocalities = new Set();uniqueLocalitiesRegion

    //Out side click hide div
    // const [isDivVisible, setDivVisibility] = useState(true);
    // const hideDiv = () => {
    //     setDivVisibility(false);
    // };

    const handleFocus = (e) => {
        showSearchResult(true);
        // setDivVisibility(true);
        if(e.target.value === '' || !searchQuery || searchQuery.trim() === ''){
            showDefaultSearchResult(true);
        }else{
            showDefaultSearchResult(false);
        }
    };
   
    const handleInputChange =  async (e) => {
         setSearchQuery(e.target.value);
        try {
            setLoading(true);
            const params = {   
                name:  e.target.value ,
                locality_name:  e.target.value ,
                searchtype: `name`,
                // searchtype: `locality_name`,
                page: 1,   
                per_page: 8,
            };
            
            const resultData= await executeLaravelAPI('projects', params, 'GET', userToken);
            console.log(resultData);
            setSearchResults(resultData.data.data);

            console.log("Developer Search ="+e.target.value.length);
            if(e.target.value.length > 2){
                const paramsDev = {   
                    name:  e.target.value ,
                    searchtype: `developers`,
                };
                const resultDevData= await executeLaravelAPI('developers', paramsDev, 'GET', userToken);
                setDevSearchResults(resultDevData.data);
                console.log(resultDevData.data);
            }
            // setPaginatedData(result.data);
            // setTotalItems(result.data.total);
            
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
        showSearchResult(true);
        if(e.target.value.length === 0){
            showDefaultSearchResult(true);
        }else{
            showDefaultSearchResult(false);
        }
    };

    const handleSearch = async () => {
        try {
            setLoading(true);
            const params = {
                // name: searchQuery,
                // locality_name: searchQuery ,    
                locality_name:  searchQuery ,
                // region:  searchQuery ,
            };
            const resultData= await executeLaravelAPI('projects', params, 'GET', userToken);
            // console.log(params);
            setSearchResults(resultData.data.data);
            // setPaginatedData(result.data);
            // setTotalItems(result.data.total);
            
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    
    //Click on locality name
    const setLocality = async (locality, pageNum) => {
        try {
            setLoading(true);
            if (!selectedLocations.includes(locality)) {
                // Update the array with the new location
                const updatedLocations = [...selectedLocations, locality];
                setSelectedLocations([...selectedLocations, locality]);
            
                const params = {
                    locality_name:  updatedLocations,
                    page: pageNum || currentPage + 1,   
                    per_page: itemsPerPage,
                    searchtype:'locality_name'
                };
                // console.log(locality);
                const resultData= await executeLaravelAPI('projects', params, 'GET', userToken);
                // console.log(params);
                // setSearchResults(resultData.data);
                setPaginatedData(resultData.data.data);
                setTotalItems(resultData.data.total);
            }
            
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
        showSearchResult(false);
    };
    const removeLocation = (location) => {
        // const location = e.target.parentElement.innerText;
        const newLocations = selectedLocations.filter((item) => item !== location);
        setSelectedLocations(newLocations);
        setLocality(location);
    };
    const hideLocationList = () => {
        clickOnLocationList(true);
        showSearchResult(true);
    };

    //Click on locality name
    const setProject = async (projectName, pageNum) => {
        try {
            setLoading(true);
            setSelectedLocations({});
            const params = {
                name: projectName,
                page: pageNum || currentPage + 1,   
                per_page: itemsPerPage,
            };

            const resultData= await executeLaravelAPI('projects', params, 'GET', userToken);
            // console.log(params);
            // setSearchResults(resultData.data);
            setPaginatedData(resultData.data.data);
            setTotalItems(resultData.data.total);
            
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
        showSearchResult(false);
    };

    const hideSearchWrap = () => {
        showSearchResult(false);
        clickOnLocationList(true);
    };
    

    // Create a mapping of localities to projects
    const localityProjectsMap = {};
    searchResults.forEach(project => {
        const locality = project.region;
        if (!localityProjectsMap[locality]) {
        localityProjectsMap[locality] = [];
        }
        localityProjectsMap[locality].push(project);
    });
    searchResults.forEach(project => {
        uniqueLocalitiesRegion.add(project.region.toLowerCase()); // Store lowercase to ensure case-insensitivity
        uniqueLocalitiesArea.add(project.locality_name.toLowerCase()); // Store lowercase to ensure case-insensitivity
    });


    const handleSliderChange = (newRange) => {
        setPriceRange(newRange);
        // alert(newRange)
    };
    
    const handleFilter = (newRange) => {
        const params = getAllParams(location);
        params.minprice = newRange[0];
        params.maxprice = newRange[1];
        console.log("Price min:- "+newRange[0]);
        console.log("Price max:- "+newRange[1]);
        getProjects(params);
    };  
    //Sorting project --Drop Down
    function getSortProjectFilter(sortCriteria) {
        const params = getAllParams(location);
        params.orderby = sortCriteria;
        setSortCriteria(sortCriteria);
        getProjects(params);
    }


   

    const handleTowerChange =  async (event) => {
        const selectedOption = event.target.options[event.target.selectedIndex];
        const towerId = selectedOption.getAttribute('data-tower-id');
        const projectId = event.target.getAttribute('data-project-id');
        let configData = [];
        if(projectId != ""){
            const result = await executeLaravelAPI(`project/${projectId}`, '', 'GET', userToken);
            const dataProjectDetails = result.data;
            // console.log(dataProjectDetails.towers);
            if (result.data && result.data.towers) {
            dataProjectDetails.towers.forEach(tower => {
                if(tower.id == towerId){
                    tower.configurations.forEach(config => {
                        configData.push(config)
                    })
                }
            })}
        }

         // get Bhk 
        const configBhkHTML = event.target.closest('td').nextElementSibling;
        let configName = new Set();
        
        configData.forEach((config, index) => {
           configName.add(config.name);
        });
        configBhkHTML.innerHTML="";
        configName.forEach((config, index) => {
            configBhkHTML.append(config );
            configBhkHTML.append(document.createElement("br"));
        })

        // get carpet 
        const configCarpetHTML = configBhkHTML.nextElementSibling
        configCarpetHTML.innerHTML="";
        configData.forEach((config, index) => {
            configCarpetHTML.append(parseFloat(config.carpet).toFixed(1) );
            configCarpetHTML.append(document.createElement("br"));
         });
        
         
    };


    
    /*Table Css */
    // const configurationsSize = {
    //     minWidth: '170px',
    // };
    // const correctStyle = {
    //     minWidth: '120px',
    // };
    return (
        <>
        {/* <PageHeader pageDetails={pageDetails}/> */}
        <section className="content">
            {/* <div className="row">
                <div className="col-md-12">
                    <select value={selectedValue} onChange={handleSelectChange}>
                        <option value="">--Select--</option>
                        <option value="ongoing">Ongoing</option>
                        <option value="completed" selected>Completed</option>
                    </select>
                </div>
            </div> */}
            <div className="row">
                <div className="col-md-12">
                    <div className="box">
                        {/* <div className="box-header with-border">
                            <h3 className="box-title">Projects</h3>
                        </div> */}
                        <div className='row'>
                            <div className='col-lg-12'>
                                {/* <input type="search" placeholder="Search..." value={searchTerm} onChange={handleSearchChange} style={{background: "url('https://api.iconify.design/ion/search-outline.svg') no-repeat 96% center / 24px"}} /> */}
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='position-relative'>
                                            <div className='position-realtive'>
                                                <input
                                                    type="search"
                                                    className='form-control search-input shadow-none border-none'
                                                    placeholder="Search for locality, project, or builder"
                                                    value={searchQuery}
                                                    onChange={handleInputChange}
                                                    onFocus={handleFocus}
                                                    style={{background: "url('https://api.iconify.design/ion/search-outline.svg') no-repeat 10px center / 24px"}}
                                                />
                                                <button className='theme-btn position-absolute top-0 end-0 search-btn' onClick={handleSearch} disabled={loading}>
                                                    Search
                                                </button>
                                                {!showResult && (
                                                    selectedLocations.length > 0 && (
                                                        <><ul className='selected-location' onClick={hideLocationList}>
                                                        {selectedLocations.map((location, index) => (
                                                            <li key={index}>
                                                                {location}
                                                                <a href="() => false" onClick={() => removeLocation(location)} className='position-absolute fs-2 top-0 text-black text-decoration-none' style={{right:'15px'}}><Icon icon="iconamoon:close-thin" style={{color: "#fff"}} /></a>
                                                            </li>
                                                        ))}
                                                        </ul></>
                                                    )
                                                )}
                                            </div>
                                            {loading && <p>Loading...</p>}
                                            {/* {isDivVisible && (
                                                <OutsideClickHandler onOutsideClick={hideDiv}> */}
                                            <div className={`search-wrap scroll-style position-absolute top-25 start-0 bg-white border pb-4 ${showResult ? ( 'd-block' ) : (' d-none')}`}>
                                                <a href="() => false" onClick={hideSearchWrap} className='position-absolute fs-2 top-0 text-black text-decoration-none' style={{right:'15px'}}>&times;</a>
                                                <div className='row mt-4'>
                                                {showOnLocationList && (
                                                    selectedLocations.length > 0 && (
                                                        <><ul className='selected-location dropdownLocation-list' onClick={hideLocationList}>
                                                        {selectedLocations.map((location, index) => (
                                                            <li key={index}>
                                                                {location}
                                                                <a href="() => false" onClick={() => removeLocation(location)} className='position-absolute fs-2 top-0 text-black text-decoration-none' style={{right:'15px'}}><Icon icon="iconamoon:close-thin" style={{color: "#fff"}} /></a>
                                                            </li>
                                                        ))}
                                                        </ul></>
                                                    )
                                                )}
                                                    <div className='col-12'>
                                                        <div className='title-wrap'><span>Locality:</span></div>
                                                            <div>
                                                                <ul className='mb-0 result-list'>
                                                                    {Array.from(uniqueLocalitiesRegion).map(locality => (
                                                                        !showDefaultResult ? (
                                                                        <li key={locality.id}><a href="() => false" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality(locality)}>{locality}</a></li>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    ))}
                                                                    {showDefaultResult ? 
                                                                    ( 
                                                                        <><li><a href="() => false" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality("wakad")}>Wakad</a></li> 
                                                                        <li><a href="() => false" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality("baner")}>Baner</a></li> 
                                                                        <li><a href="() => false" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality("kothrud")}>Kothrud</a></li> 
                                                                        <li><a href="() => false" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality("hadapsar")}>Hadapsar</a></li>
                                                                        <li><a href="() => false" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality("bavdhan")}>Bavdhan</a></li>
                                                                        <li><a href="() => false" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality("punewale")}>Punewale</a></li>
                                                                        <li><a href="() => false" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality("kharadi")}>Kharadi</a></li>
                                                                        </>
                                                                        
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                    </div>
                                                    <div className='col-12'>
                                                    {!showDefaultResult ? (
                                                        <><div className='title-wrap'><span>Developers:</span></div>
                                                            <div>
                                                                <ul className='mb-0 result-list'>
                                                                {Array.from(searchDevResults).slice(0, 7).map(developer => (
                                                                    <li key={developer.id}><a href="() => false" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality(developer.name)}>{developer.name}</a></li>
                                                                ))}
                                                                </ul>
                                                            </div></>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                    <div className='col-12'>
                                                        {!showDefaultResult ? (
                                                        <><div className='title-wrap'><span>Projects:</span></div>
                                                            <div>
                                                                <ul className='mb-0 result-list'>
                                                                    {searchResults.map(project => (
                                                                    <li key={project.id}><a href="() => false" className='text-secondary text-decoration-none text-capitalize' onClick={() => setProject(project.name)}>{project.name}</a></li>
                                                                    ))}
                                                                </ul>
                                                            </div></>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                    
                                                    
                                                    {/* <div className='col-12'>
                                                        <ul className='mb-0 row'>
                                                            <li className='col-12'>
                                                                {searchResults.length === 0 && !loading && <p>No results found.</p>}
                                                            </li>
                                                        </ul>
                                                    </div> */}

                                                </div>
                                            </div>
                                            {/* </OutsideClickHandler>
                                            )} */}
                                        </div>
                                    </div>
                                    <div className='col-lg-6 mt-3 pe-0 position-relative'>
                                        {/* <label>Price Range:</label> */}
                                        <Slider
                                            range
                                            min={500000}
                                            max={500000000}
                                            value={priceRange}
                                            onChange={handleSliderChange}
                                            onChangeComplete={handleFilter}
                                        />
                                        <p className='d-flex justify-content-between'>
                                            <span>{`₹${formatNumber(priceRange[0])}`}</span>
                                            <span>{`₹${formatNumber(priceRange[1])}`}</span>
                                        </p>
                                        {/* <div className={`search-wrap  top-25 start-0 bg-white border p-3 `}>
                                             <a href="() => false" onClick={hideSearchWrap} className='position-absolute fs-2 top-0 text-black text-decoration-none' style={{right:'15px'}}>&times;</a> 
                                            <ul>
                                                {filteredData.map(item => (
                                                <li key={item.id}>{item.name} - ₹{formatNumber(item.minimum_total_price)} - ₹{formatNumber(item.maximum_total_price)}</li>
                                                ))}
                                            </ul>
                                        </div> */}
                                    </div>
                                    <div className='col-lg-6'>
                                        {/* <Button variant="success" className='me-3 editBtn'>
                                            Edit
                                        </Button> */}
                                        <div className='col-lg-12 col-12 pe-0 mt-4'>
                                            {/* <div className='col-6 text-end pe-0'></div> */}
                                            <div className='col-12 pe-0 d-flex justify-content-end'>
                                            <span className='me-2'>Sort by: </span>
                                            <select className="form-select form-select-sm" style={{width:"200px"}}
                                                value={sortCriteria} onChange={(e) => getSortProjectFilter(e.target.value)}
                                                >
                                                    <option value="Relevance">Relevance</option>  
                                                    <option value="priceLowToHigh">Price: Low to High</option>  
                                                    <option value="priceHighToLow">Price: High to Low</option>  
                                                    <option value="sqftLowToHigh">Sq. Ft.: Low to High</option>  
                                                    <option value="sqftHighToLow">Sq. Ft.: High to Low</option>  
                                                    <option value="recentlyLaunched">Recently Launched</option>  
                                                    <option value="possessionDate">Possession Date (Recent First)</option>
                                                    {/* <option value="updatedat">Latest Updated</option> */}
                                                    <option value="orderByNameAsc">Name: A-Z</option>
                                                    <option value="orderByNameDesc">Name: Z-A</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-12 col-12 pe-0 d-flex justify-content-end'>
                                            <a href="() => false" className='mt-4 me-3' onClick={handleOpenModal}>
                                                <img src={addNewImg} />
                                            </a>
                                            <a href="() => false" className='px-0 mt-4'>
                                                <img src={deleteBtnImg} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div show={show} onHide={handleClose} className={`animated-box mt-2 ${showModal ? ( 'd-block' ) : ('d-none')}`}>
                            <ProjectForm hideForm={hideForm}/>
                        </div>
                        
                        <div className="box-body table-container mt-4 hr-scroll-style">
                            <table className="table table-resposive table-hover dashboard-table shadow-sm mb-0">
                                <thead>
                                    <tr>
                                        <th width="40px" style={{'minWidth': '40px'}} className='sticky-column'><input type='checkbox' /></th>
                                        <th width="200px" style={{'minWidth': '200px'}} className='sticky-column column-snd'> Project Name</th>
                                        <th>Location</th>
                                        <th>Tower</th>
                                        <th title='Configurations'>Configuration</th>
                                        <th width="120px">Carpet Area</th>
                                        <th width="190px">Price</th>
                                        {/* <th></th> */}
                                        <th>Project Status</th>                                    
                                        <th>Possession Date</th>
                                        <th>Updated Date</th>
                                        <th className='sticky-column'>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {loading ? (
                                    <></>
                                       
                                    ) : (
                                        
                                        paginatedData.map((project, index) => (
                                            <><tr key={project._id}>
                                                <td className='sticky-column'><input type='checkbox' /></td>
                                                <td className='sticky-column column-snd'>{project.name}</td>
                                                <td>{project.locality_name}</td>
                                                <td>
                                                <div className="row form-group">
                                                        <div className="col-sm-12">
                                                            <select name="tower" className="form-select"  onChange={(event) => handleTowerChange( event)}                    data-project-id={project.id} disabled={project.towers.length === 1}  data-tower-id="">
                                                            {project.towers.map((tower, index) => ( 
                                                                <option data-tower-id={tower.id} value={tower.building_name}>{tower.building_name}</option>
                                                            ))}
                                                                
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {/* <a href="() => false"  className='editTowerBtn d-block p-2 text-secondary text-decoration-none rounded border-2 border-secondary' style={{"background-color": "#ffffff",'borderStyle': "dashed", 'transition': 'background-color 0.5s ease'}}>
                                                    Tower A
                                                    </a> */}
                                                </td>
                                                
                                                <td>
                                                    {/* Display config data */}
                                                    {/* {configData ? (
                                                    // Render config data here
                                                    <pre>{JSON.stringify(configData, null, 2)}</pre>
                                                    ) : (
                                                    // Show a message or loading indicator while fetching config data
                                                    'Loading config data...'
                                                    )} */}
                                                    {/* {project.configurations} */}
                                                    {
                                                        project && project.towers && project.towers[0] && project.towers[0].configurations ? (
                                                            [...new Set(project.towers[0].configurations.map(config => config.name))].map(uniqueName => (
                                                            <span key={uniqueName}>{uniqueName} <br /></span>
                                                            ))
                                                        ) : null
                                                        }
                                                  
                                                </td>
                                                <td>
                                                    {/* {squareFeet(project.project_size)} sq. ft. */}
                                                    {/* {project.project_size} sq. ft. */}
                                                    {/* 400 sq. ft.<br />711 sq. ft.<br />870 sq. ft. */}
                                                    {
                                                        project && project.towers && project.towers[0] && project.towers[0].configurations ? (
                                                            [...new Set(project.towers[0].configurations.map(config => config.carpet))].map(uniqueCarpet => (
                                                            <span key={uniqueCarpet}>{parseFloat(uniqueCarpet).toFixed(1)} <br /></span>
                                                            ))
                                                        ) : null
                                                        }
                                                </td>
                                                <td>
                                                    Min: ₹{formatNumber(project.minimum_total_price)} <br /> Max: ₹{formatNumber(project.maximum_total_price)}</td>
                                                    {/* <td>
                                                    {project.rera_registration_no.split(',').join(', ').replace(/"/g, '').replace("[", '').replace("]", '')}
                                                    </td> */}
                                                <td className="text-capitalize">
                                                    <span className={`badge rounded-pill bg-${getBadge(project.project_status)}`}>{project.project_status}</span>
                                                </td>
                                                <td>
                                                    {project.possession_date_readable}
                                                </td>
                                                <td>
                                                    {longToShort(project.updated_at)}
                                                </td>
                                                <td className='sticky-column'>
                                                    <a href="() => false" onClick={() => toggleDetails(index, project.id)} data-project-id={project.id} data-index={index + 1}>
                                                        { buttonIcon[(index)] ? <Icon icon='ion:close-outline' style={{ fontSize: '30px', color: '#192951' }} /> : <Icon icon='fe:edit' style={{ fontSize: '24px', color: '#192951' }} />}
                                                    </a>
                                                </td>
                                                </tr>{details.includes(index) && (<tr data-id={index + 1}>
                                                    <td colSpan={10}>
                                                        <ProjectEditForm selectedProjectId={selectedProjectId} hideEditForm={hideEditForm}/>
                                                    </td>
                                             </tr>)}</>
                                        ))
                                    )}
                                </tbody>
                                <tfoot></tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-5 align-items-center'>
                <div className='col-lg-4'>
                    {/* Display "Showing x-y of z items" */}
                    <p className='graylight mb-0'>Showing {currentPage * itemsPerPage + 1}-{Math.min((currentPage + 1) * itemsPerPage, totalItems)} of {totalItems} items</p>
                </div>
                <div className='col-lg-8 d-flex justify-content-end align-items-center'>
                    {/* Render pagination component */}
                    <div className='me-3'>
                        <span className='graylight'>Results per page </span>
                        <select className='showPerPage' value={itemsPerPage} onChange={handleItemsPerPageChange}>
                            {itemsPerPageOptions.map((option) => (
                                <option key={option.id} value={option}>
                                {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <ReactPaginate
                        pageCount={Math.ceil(totalItems / itemsPerPage)}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-end mb-0'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        activeClassName={'active'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        disableInitialCallback={true}     
                    />              
                </div>
            </div>
           
            
        </section>
        {/*  */}
        {/* <Modal show={showModal} onHide={handleCloseModal} className="modal-xl">
            
            <Modal.Header closeButton  className="px-5">
                <Modal.Title>Add New</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5">
                <ProjectForm/> 
            </Modal.Body>
         
         </Modal> */}

        </>
    )
};

const Loader = () => {
    return <tr><td colSpan="7">Loading...</td></tr>;
};
export default UpdatedProjectList;