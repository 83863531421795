import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Button, Form, Card } from 'react-bootstrap';
import { appAbsPath, showToastMessage, buttonLoadingClass, executeLaravelAPI, uploadAssets, userToken, s3url, validateName } from '../../Utils';
import SubmitButton from '../../shared/SubmitButton';

const ShortUpdateEdit = () => {
    const { id } = useParams();
    const buttonClass = useRef('theme-btn btn btn-success');
    const [buttonClassLoader, setButtonClass] = useState(buttonClass.current);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [locationFiles, setLocationFiles] = useState([]);
    const [shortUpdate, setShortUpdate] = useState({
        title: '',
        images: [],
        content: '',
        status: 1,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await executeLaravelAPI(`shortupdate/${id}`, '', 'GET', userToken);
                if (response.status === 200) {
                    setShortUpdate(response.data);
                    setLoading(false);
                } else {
                    setError('Something went wrong');
                    setLoading(false);
                }
            } catch (error) {
                setError('Something went wrong');
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);

    const handleLocationFileChange = (event) => {
        // Convert FileList to an array and update state
        const filesArray = Array.from(event.target.files);
        const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp', 'image/gif'];
        
        const invalidFiles = filesArray.filter(file => !validTypes.includes(file.type));
        
        if (invalidFiles.length > 0) {
            showToastMessage("Invalid file type selected. Please select a valid image file.", 'top-right', 'error');
            return;
        }
        setLocationFiles(filesArray);
    };

     const handleInputChange = (e) => {
        const { name, value } = e.target;
        setShortUpdate((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleRemoveImage = (index) => {
        const updatedImages = [...shortUpdate.images];
        updatedImages.splice(index, 1);
        setShortUpdate({ ...shortUpdate, images: updatedImages });
    };

    const handleSave = async (e) => {
        e.preventDefault();
        let error = {};

        error.title = validateName(shortUpdate.title);
        error.content = validateName(shortUpdate.content);

        if (error.title === 'this field is required') {
            showToastMessage("Title required", 'top-right', 'error');
            return;
        }

        if (error.content === 'this field is required') {
            showToastMessage("Content required", 'top-right', 'error');
            return;
        }

        setButtonClass(buttonLoadingClass);
        setLoading(true);

        let data = new FormData();
        data.append('title', shortUpdate.title);
        data.append('content', shortUpdate.content);
        data.append('status', shortUpdate.status);

      

        try {
            const response = await executeLaravelAPI(`update-shortupdate/${id}`, shortUpdate, 'PUT', userToken);
            if (response.status === 200) {
                // Append file if it exists
                if (locationFiles && locationFiles.length) {
                    const formData = new FormData();
                    locationFiles.forEach(file => {
                        formData.append('images[]', file);
                    });
                    const imageupdate = await uploadAssets(`shortupdateassets/${response.data.id}`, formData, userToken);
                }

                showToastMessage("Short update updated successfully", 'top-right', 'success');
                setButtonClass('theme-btn btn btn-success');
                setLoading(false);
            } else {
                showToastMessage("Something went wrong", 'top-right', 'error');
                setButtonClass('theme-btn btn btn-success');
                setLoading(false);
            }
        } catch (error) {
            showToastMessage("Something went wrong", 'top-right', 'error');
            setButtonClass('theme-btn btn btn-success');
            setLoading(false);
        }
    };

    return (
        <div className="short-update-edit">
            <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                <h2 className="mb-0">Edit Short Update</h2>
                <NavLink to="/portaladmin/shortupdates" className="btn btn-primary">Back</NavLink>
            </div>
            <div className="p-3">
                <Form onSubmit={handleSave}>
                    <Card>
                        <Card.Body>
                            <Form.Group className="mb-3">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" name="title" value={shortUpdate.title} onChange={handleInputChange} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Content</Form.Label>
                                <Form.Control as="textarea" name="content" value={shortUpdate.content} onChange={handleInputChange} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Images</Form.Label>
                                <Form.Control type="file" multiple onChange={handleLocationFileChange} />
                                <div className="mt-2">
                                    {shortUpdate.images && shortUpdate.images.map((image, index) => (
                                        <div key={index} className="d-flex align-items-center mb-2">
                                            <img src={`${s3url}${image}`} alt={`image-${index}`} className="img-fluid me-1" style={{ maxWidth: '100px' }} />
                                            <Button variant="danger" onClick={() => handleRemoveImage(index)}>Remove</Button>
                                        </div>
                                    ))}
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Status</Form.Label>
                                <Form.Select name="status" value={shortUpdate.status} onChange={handleInputChange}>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mt-3 float-end">
                                <SubmitButton type="submit" className={buttonClassLoader} label="Save" />
                            </Form.Group>
                        
                    </Card.Body>
                </Card>
                {/* <SubmitButton buttonClass={buttonClassLoader} loading={loading} /> */}
                </Form>
            </div>
        </div>
    );
};

export default ShortUpdateEdit;
