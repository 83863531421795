
import React, { useState,createContext } from 'react';

export const SearchVisibilityContext = createContext();

export const SearchVisibilityProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [downloadCouponBtn,setDownloadCouponBtn] = useState(false)
  const [couponData,setCouponData] = useState({})
  const [searchOptions, setSearchOptions] = useState([]);

  return (
    <SearchVisibilityContext.Provider value={{ isVisible, setIsVisible,searchOptions, setSearchOptions, downloadCouponBtn,setDownloadCouponBtn , couponData,setCouponData }}>
      {children}
    </SearchVisibilityContext.Provider>
  );
};