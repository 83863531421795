import React, { useEffect, useState } from 'react';
import { executeLaravelFrontAPI, s3url } from './../../Admin/Utils';
import defaultImg from '../../../images/default-floor-plan.png';
import { Icon } from '@iconify/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import $ from 'jquery';


const ProjectDetailUnitFloorPlan = ({ projectSlug }) => {

    const [project, setProject] = useState({});
    const [unitPlan, setUnitPlan] = useState({});
    const [floorPlan, setFloorPlan] = useState({});
    const [selectedPlan, setSelectedPlan] = useState('unitplan');


    useEffect(() => {
        if (projectSlug) {
            getProjectDetails();
        }
    }, [projectSlug]);

    const getProjectDetails = async () => {
        try {
            const result = await executeLaravelFrontAPI(`project/${projectSlug}`, '', 'GET');
            console.log('result:', result.data);
            const projectDetails = result.data;
            // setProject(projectDetails);
            setUnitPlan(projectDetails.unitplan);
            setFloorPlan(projectDetails.floorplan);

        } catch (error) {
            console.error('Error fetching project details:', error);
        }
    };

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        speed: 800,
        centerMode: false,
        autoplay: false,
        autoplaySpeed: 3000,
        prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className='prev-arrow' />,
        nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className='next-arrow' />
    };

    const handleConfigurationClick = (configuration, event) => {
        setSelectedPlan(configuration);
        $('.config-item').removeClass('is--active');
        $(event.target).addClass('is--active');


    }

    return (
        <div className="-detail floorplan-unitplan-wrapper">
            <div className="layout-desc bg-white">
                <div className="configurations-listing tab__list overflow-auto">
                    <div className={`text-uppercase tab__item me-2 me-md-3 config-item is--active`} onClick={(event) => handleConfigurationClick('unitplan', event)}>
                        Unit Plan
                    </div>
                    <div className={`text-uppercase tab__item me-2 me-md-3 config-item `} onClick={(event) => handleConfigurationClick('floorplan', event)}>
                        Floor Plan
                    </div>
                </div>


                {selectedPlan === 'unitplan' ? (
                    <div className="selected-carpet-details">

                        {/* <p className="text-center">UnitPlan</p> */}
                        {Array.isArray(unitPlan) && unitPlan.length >= 1 ? (

                            <Slider {...settings}>
                                {Array.isArray(unitPlan) && unitPlan.map((asset, index) => (
                                    <div key={index} data-index-id={index} className="d-flex flex-column align-items-center">

                                        <img
                                            src={`${s3url}/configuration-assets${asset.url}`}
                                            alt={asset.name}
                                            className="img-fluid "
                                        />
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <div className="d-flex flex-column align-items-center mt-5">
                                {/* <img 
                                src={defaultImg}
                                alt="Default Floor Plan" 
                                className="img-fluid" 
                                /> */}
                                <p className='mt-2'><Icon icon="fa6-regular:face-sad-tear" className='me-2 align-middle' color={"#667486"} /> Unit Plan that you’ve requested is currently unavailable</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="selected-carpet-details">
                        {/* <p>FloorPlan</p> */}
                        {Array.isArray(floorPlan) && floorPlan.length >= 1 ? (
                            <Slider {...settings}>
                                {Array.isArray(floorPlan) && floorPlan.map((asset, index) => (
                                    <div key={index} data-index-id={index} className="d-flex flex-column align-items-center">
                                        <img
                                            src={`${s3url}/configuration-assets${asset.url}`}
                                            alt={asset.name}
                                            className="img-fluid "
                                        />
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <div className="d-flex flex-column align-items-center mt-5">
                                {/* <img 
                                src={defaultImg}
                                alt="Default Floor Plan" 
                                className="img-fluid" 
                                /> */}
                                <p className='mt-2'><Icon icon="fa6-regular:face-sad-tear" className='me-2 align-middle' color={"#667486"} /> Floor plan that you’ve requested is currently unavailable</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );


}

export default ProjectDetailUnitFloorPlan;