import React from 'react';
import { FloatingLabel, Form, Button } from 'react-bootstrap';
import ConfigurationComponent from './ConfigurationComponent'; // Assuming you have this component
import { Icon } from '@iconify/react';

const TowerComponent = ({ index, tower, handleTowerChange, handleConfigurationChange,  addConfiguration, removeTower, removeConfiguration,unitConfData }) => {
    return (
        <>
            <tr >
                <td>{index + 1}</td>
                <td>

                    <Form.Control type="text" placeholder="Building Name" name="towers.building_name"  onChange={(e) => handleTowerChange(e, index)} />

                </td>
                <td>

                    <Form.Control type="date" placeholder="proposed_completion_date" name="towers.proposed_completion_date"  onChange={(e) => handleTowerChange(e, index)} />

                </td>
                <td>

                    <Form.Control type="number" placeholder="No Of Basements" name="towers.no_of_basements"  onChange={(e) => handleTowerChange(e, index)} />

                </td>
                <td>

                    <Form.Control type="number" placeholder="No Plinth" name="towers.no_plinth"  onChange={(e) => handleTowerChange(e, index)} />

                </td>
                <td>
                    <Form.Control type="number" placeholder="No Podium" name="towers.no_podium"  onChange={(e) => handleTowerChange(e, index)} />
                </td>
                <td>
                    <Form.Control type="number" placeholder="No Sanctioned Floors" name="towers.no_sanctioned_floors"  onChange={(e) => handleTowerChange(e, index)} />
                </td>
                <td>
                    <Form.Control type="number" placeholder="No Stilts" name="towers.no_stilts"  onChange={(e) => handleTowerChange(e, index)} />
                </td>
                <td>
                    <Form.Control type="number" placeholder="No Open Parkings" name="towers.no_open_parkings"  onChange={(e) => handleTowerChange(e, index)} />
                </td>
                <td>
                    <Form.Control type="number" placeholder="No Closed Parkings" name="towers.no_closed_parkings"  onChange={(e) => handleTowerChange(e, index)} />
                </td>
                <td>
                    <button  className="btn btn-outline-danger rounded-circle add-config text-danger" onClick={() => removeTower(index)}><Icon icon="ic:sharp-remove" style={{ fontSize: '24px' }} /></button>
                </td>
            </tr>



            <tr>
                
                <td className='configurationtd' colSpan={11}>
                <p className='mt-2'>Configurations</p>
                    <table className='table table-responsive-lg mt-2 p-0 mb-4 configuration-table'>
                        <tbody>
                            <tr className='table-light'>
                                <td>No</td>
                                <td>Name</td>
                                <td>Agreement Price</td>
                                <td>All Inclusive Price</td>
                                <td>Unit Configuration Name</td>
                                <td>No of Bedrooms</td>
                                <td>No of Bathrooms</td>
                                <td>Carpet <small>in Sq.Ft</small></td>
                                <td>Saleable Carpet <small>in Sq.Ft</small></td>
                                <td>Unit Facing Direction</td>
                                <td>No Of Apartment</td>
                                <td>No Of Booked Apartment</td>


                                {/* <td>Final Price</td> */}
                                <td><button type='button' className='btn btn-outline-success rounded-circle add-config' onClick={() => addConfiguration(index)}>
                                    <Icon icon="ic:sharp-add" style={{ fontSize: '24px' }} />
                                </button></td>
                            </tr>
                        </tbody>
                        {tower.configurations.map((config, configIndex) => (
                            <ConfigurationComponent
                                key={configIndex}
                                towerIndex={index}
                                configIndex={configIndex}
                                configuration={config}
                                handleConfigurationChange={handleConfigurationChange}  // Assuming you have a similar method for configurations
                                removeConfiguration={removeConfiguration}
                                mstConfig = {unitConfData}
                            />
                        ))}
                        
                    </table>
                </td>
            </tr >
        </>
    );
};

export default TowerComponent;
