
import BannerUnit from '../../../../Assets/img/irfs-bannerunit.svg';

const Banner = () => {
    return(<>
        <section className='py-5 irfsbanner'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <img src={BannerUnit} className="img-fluid d-none d-sm-block"/>
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default Banner;