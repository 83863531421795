import React, { useEffect, useState, useRef, useContext, Suspense } from 'react';
import { appAbsPath, executeLaravelFrontAPI, s3url, getSortBHKConf, createGa4Event, formatNumber, getMinMaxPrice, parsedUserData, lazyLoadImages } from './../Admin/Utils';
import Header from './Layouts/Header';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
// import { useSearchVisibility } from './Layouts/SearchVisibilityContext';
import { SearchVisibilityContext } from './Layouts/SearchVisibilityContext';
import { Icon } from '@iconify/react';

// import WishListBtn from './Pages/WishListBtn';
import BlogIcon from '../../Assets/img/blogicon.png';
import BlogBook from '../../Assets/img/book.png';
import BlogRobot from '../../Assets/img/blog-robot.png';
import BlogHouse from '../../Assets/img/house.png';
import BlogShaniwar from '../../Assets/img/blog-shaniwar.png';
import Checklist from '../../Assets/img/checklist.png';
import Affordable from '../../Assets/img/affordble.png';
import Fire from '../../Assets/img/fire.png';
import Flowers from '../../Assets/img/flowers.png';
import Sky1 from '../../Assets/img/sky1.png';
import Sky2 from '../../Assets/img/sky2.png';
import Stars from '../../Assets/img/stars.png';
import Box from '../../Assets/img/box.png';
import Hot from '../../Assets/img/hot.png';
import BannerBuild from '../../Assets/img/banner-build.png';
// import ReraImg from '../../Assets/img/rera.svg';
import ArrowD from '../../Assets/img/arrow.svg';
import Comparesvg from '../../Assets/img/compare.svg';
// import defaultGallery from '../../images/default-corporate-image.jpg';

//Home Content Box Import
import ContentBox from './Pages/Home/HomeContentBox';


import BwLogo from '../../Assets/img/bwwhite.png';
import Shaniwaar from '../../Assets/img/shaniwarwada-home.png';
import Blueright from '../../Assets/img/blue-right.png';
import Ceoimg from '../../Assets/img/ceoimg.png';
import Buildabstract from '../../Assets/img/about-building.svg';
import SearchInput from "./Layouts/SearchInput";
import SearchPopup from "./Layouts/SearchPopup";
import { Link, useLocation } from 'react-router-dom';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProjectCard from './Layouts/ProjectCard';
import { Button } from 'react-bootstrap';
const ExploreProjectsLocalities = React.lazy(() => import('./Pages/Home/ExploreProjectsLocalities'));



// import ExploreProjectsLocalities from './Pages/Home/ExploreProjectsLocalities';
// import {setProjectSearchLocation,setProjectSearchDeveloper, setProjectSearchConfiguration} from './../Admin/UserActivatyTracker';

const Home = ({ isWishlistActive }) => {
    const pageDetails = { 'pagetitle': 'Beyondwalls', 'pageslug': '/' };
    const [showResult, showSearchResult] = useState(false);
    const [blogsData, setblogsData] = useState([]);
    const sectionRef = useRef(null);
    const [showDefaultResult, showDefaultSearchResult] = useState(false);
    const [projects, setResponseData] = useState([]);
    const location = useLocation();
    const isLandingPage = location.pathname === '/';
    const currentUrl = location.pathname;

    // let blogimg1  = BlogIcon;
    // let blogimg2  = BlogBook;
    // let blogimg3  = BlogGirl;
    // let blogimg4  = BlogRobot;
    // let blogimg5  = BlogHouse;
    // let blogimg6  = BlogShaniwar;

    const blogImages = [BlogIcon, BlogBook, BlogRobot]; // Add more image URLs as needed
    const blogImages2 = [BlogHouse, BlogShaniwar, Checklist]; // Add more image URLs as needed
    const [showLink, setShowLink] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            lazyLoadImages();
            setShowLink(true);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
    // const [currCity, setCurrCity] = useState(sessionStorage.getItem('city') || 'pune');

    useEffect(() => {
        axios.get('https://corporate.beyondwalls.com/?portalblogs=1')
            .then((response) => setblogsData(response.data.data))
            .catch((error) => console.error('Error fetching data:', error));
    }, []);

    const scrollToSection = () => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        return () => {
            if (location.pathname === '/' || location.pathname.includes('/project/')) {
                localStorage.removeItem('selectedValues');
            }
        };
    }, [location.pathname]);

    // Search filter
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const uniqueLocalitiesRegion = new Set();
    const uniqueLocalitiesArea = new Set();

    // Out side click hide div
    const searchInputRef = useRef(null);
    const suggestionContainerRef = useRef(null);

    const handleOutsideClick = (e) => {
        if (
            suggestionContainerRef.current &&
            !suggestionContainerRef.current.contains(e.target) &&
            searchInputRef.current &&
            !searchInputRef.current.contains(e.target)
        ) {
            showSearchResult(false);
            // console.log("Outside click");
        }
    };



    useEffect(() => {
        getProjects();
        document.addEventListener('mousedown', handleOutsideClick);
        // const storedCity = sessionStorage.getItem('city');
        // if (storedCity) {
        // setCurrCity(storedCity);
        // }
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []); // Run the effect only once during component mount
    //End Out side click hide div

    //City change
    const handleCityChange = (e) => {
        // const selectedCity = e.target.value;
        // setCurrCity(selectedCity);
        // sessionStorage.setItem('city', selectedCity);

    };
    const handleFocus = (e) => {
        showSearchResult(true);
        // setDivVisibility(true);
        if (e.target.value === '' || !searchQuery || searchQuery.trim() === '') {
            showDefaultSearchResult(true);
        } else {
            showDefaultSearchResult(false);
        }
    };
    // Create a mapping of localities to projects
    const localityProjectsMap = {};
    // searchLocationResults.forEach(searchLocationResults => {
    //     //console.log(searchLocationResults.location);
    //     const locality = searchLocationResults.location.toLowerCase();
    //     if (!localityProjectsMap[locality]) {
    //         localityProjectsMap[locality] = [];
    //     }
    //     localityProjectsMap[locality].push(searchLocationResults.location);
    // });
    //console.log("LocationLength:->>  "+searchLocationResults.length);
    searchResults.forEach(project => {
        uniqueLocalitiesRegion.add(project.region); // Store lowercase to ensure case-insensitivity
        uniqueLocalitiesArea.add(project.locality_name); // Store lowercase to ensure case-insensitivity
    });
    // const handleInputChange =  async (e) => {
    //      setSearchQuery(e.target.value);
    //      handleFocus(e);
    //     try {
    //         const params = {name:  e.target.value};
    //         if(e.target.value.length >= 3){
    //             setTimeout( async () => {
    //                 const resultConfigurationData = await executeLaravelFrontAPI ('mstconfigurationsinsearch', params, 'GET');
    //                 setConfigurationSearchResults(resultConfigurationData.data.data);

    //                 const resultLocalityData = await executeLaravelFrontAPI ('localityinsearch', params, 'GET');
    //                 setLocationSearchResults(resultLocalityData.data.data);

    //                 const resultData = await executeLaravelFrontAPI ('projectsinsearch', params, 'GET');
    //                 setSearchResults(resultData.data.data);

    //                 const resultDevData = await executeLaravelFrontAPI ('developersinsearch', params, 'GET');
    //                 setDevSearchResults(resultDevData.data.data);
    //             },1500);
    //         } 

    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     } finally {

    //     }
    //     showSearchResult(true);
    //     if(e.target.value.length === 0){
    //         showDefaultSearchResult(true);
    //     }else{
    //         showDefaultSearchResult(false);
    //     }
    // };

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: false,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className='prev-arrow' />,
        nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className='next-arrow' />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const Lookingsettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 4, // Display 3 items at a time
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: false,
        rows: 2,
        slidesPerRow: 1,
        prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className='prev-arrow' />,
        nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className='next-arrow' />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    rows: 1,
                    slidesPerRow: 1,
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const testmonisettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: true,
        prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className='prev-arrow' />,
        nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className='next-arrow' />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    rows: 1,
                    slidesPerRow: 1,
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    // const [dataBlog, setDataBlog] = useState(null);
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //           const response = await fetch('https://corporate.beyondwalls.com/?portalblogs=1', {
    //             method: 'GET',
    //             headers: {

    //             }
    //           });
    //           if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //           }
    //           const result = await response.json().data;
    //           setDataBlog(result);
    //         } catch (error) {
    //           console.error('Error fetching data:', error);
    //         }
    //       };

    //     fetchData();
    //   }, []);
    //   console.log(dataBlog);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 992);
        };

        // Add event listener to track window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getProjects = async () => {
        const paramsData = {};
        paramsData.is_mandate = true;
        paramsData.per_page = 15;

        let result = {};
        try {
            result = await executeLaravelFrontAPI('projects', paramsData, 'GET');
            setResponseData(result.data.data);
            // setTotalItems(result.data.total);
            // console.log(result.data.data);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
        }
    }
    const { setIsVisible } = useContext(SearchVisibilityContext);


    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };


    useEffect(() => {
        const onScroll = () => {
            if (currentUrl === '/') {
                const bannerBottom = document.getElementById('home-banner').getBoundingClientRect().bottom;
                if (bannerBottom < 0) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }

            }
        };

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [setIsVisible, currentUrl]);

    return (
        <>
            <Helmet>
                <title>BeyondWalls: Buy Properties in India, Real Estate, Property Site India</title>
                <meta name="description" content="Browse residential properties for sale in India at BeyondWalls - New Projects, Ready to Move in Flats/Apartments. Choose your dream home from our 100% Verified Listings." />
                <link rel="canonical" href="https://www.beyondwalls.com" />
                <meta property="og:url" content="https://www.beyondwalls.com/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="BeyondWalls: Buy Properties in India, Real Estate, Property Site India" />
                <meta property="og:description" content="BeyondWalls- Browse residential properties for sale in India- New Projects, Ready to Move in Flats/Apartments. 100% Verified Listings" />
                <meta property="og:image" content="https://portal.beyondwalls.com/static/media/logo.fe506d7579e2f8d20d3fcb8856ca8b0c.svg"></meta>

                <meta name="twitter:card" content="summary_large_image"></meta>
                <meta property="twitter:domain" content="beyondwalls.com"></meta>
                <meta property="twitter:url" content="https://www.beyondwalls.com/"></meta>
                <meta name="twitter:title" content="BeyondWalls: Buy Properties in India, Real Estate, Property Site India"></meta>
                <meta name="twitter:description" content="BeyondWalls- Browse residential properties for sale in India- New Projects, Ready to Move in Flats/Apartments. 100% Verified Listings"></meta>
                <meta name="twitter:image" content="https://portal.beyondwalls.com/static/media/logo.fe506d7579e2f8d20d3fcb8856ca8b0c.svg"></meta>

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Corporation",
                        "name": "BeyondWalls",
                        "legalName": "BeyondWalls",
                        "url": "https://www.beyondwalls.com/",
                        "logo": "https://portal.beyondwalls.com/static/media/logo.fe506d7579e2f8d20d3fcb8856ca8b0c.svg",
                        "foundingDate": "2021",
                        "founders": [
                            {
                                "@type": "Person",
                                "name": " Mr. Vikram Kotnis"
                            }
                        ],
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "4th floor, Sr.No:9, H.NO:1/2, near hotel Ramada Plaza, Above Maruti Suzuki sumankirti cars Pvt Ltd. Mahalunge",
                            "addressLocality": "Pune",
                            "addressRegion": "Maharashtra ",
                            "postalCode": " 411045",
                            "addressCountry": "India"

                        },
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "contactType": "customer support",
                            "email": "contact.us@beyondwalls.com"
                        },
                        "sameAs": [
                            "https://www.facebook.com/beyondwallsofficial",
                            "https://goo.gl/maps/dYUNLxxbBhzwYoqS8",
                            "https://twitter.com/BeyondWallsCo",
                            "https://www.instagram.com/beyondwallsofficial/",
                            "https://www.youtube.com/channel/UCRudeqskEYlCpTmo7X8HttQ",
                            "https://www.linkedin.com/company/beyondwallsofficial/?viewAsMember=true"
                        ]
                    })}
                </script>

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "WebPage",
                        "name": "BeyondWalls: Buy Properties in India, Real Estate, Property Site India",
                        "speakable":
                        {
                            "@type": "SpeakableSpecification",
                            "xPath": [
                                "/html/head/title",
                                "/html/head/meta[@name='description']/@content"
                            ]
                        },
                        "url": "https://www.beyondwalls.com/"
                    })}
                </script>


                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "WebSite",
                        "name": "BeyondWalls: Buy Properties in India, Real Estate, Property Site India",
                        "url": "https://www.beyondwalls.com/",
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://www.beyondwalls.com/search?q={search_term_string}",
                            "query-input": "required name=search_term_string"
                        }
                    })}
                </script>


                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "BeyondWalls: Buy Properties in India, Real Estate, Property Site India",
                        "image": "https://portal.beyondwalls.com/static/media/logo.fe506d7579e2f8d20d3fcb8856ca8b0c.svg",
                        "url": "https://www.beyondwalls.com/",
                        "telephone": "020 67082729",
                        "priceRange": "NA",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "4th floor, Sr.No:9, H.NO:1/2, near hotel Ramada Plaza, Above Maruti Suzuki sumankirti cars Pvt Ltd. Mahalunge",
                            "addressLocality": "Pune",
                            "postalCode": "411045",
                            "addressCountry": "IN"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": 18.58272232153281,
                            "longitude": 73.75996146930895
                        },
                        "openingHoursSpecification": {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday"
                            ],
                            "opens": "10:00",
                            "closes": "07:00"
                        },
                        "sameAs": [
                            "https://www.facebook.com/beyondwallsofficial",
                            "https://twitter.com/BeyondWallsCo",
                            "https://www.instagram.com/beyondwallsofficial/",
                            "https://www.youtube.com/channel/UCRudeqskEYlCpTmo7X8HttQ",
                            "https://www.linkedin.com/company/beyondwallsofficial/?viewAsMember=true",
                            "https://goo.gl/maps/dYUNLxxbBhzwYoqS8"
                        ]
                    })}
                </script>


                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "http://schema.org/",
                        "@type": "Place",
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "18.58272232153281",
                            "longitude": "73.75996146930895"
                        }
                    })}
                </script>



                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "SearchResultsPage",
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.beyondwalls.com/"
                        },
                        "headline": "BeyondWalls: Buy Properties in India, Real Estate, Property Site India",
                        "description": "BeyondWalls- Browse residential properties for sale in India- New Projects, Ready to Move in Flats/Apartments. 100% Verified Listings",
                        "image": "https://www.beyondwalls.com/wp-content/themes/beyondwallsconsumer/images/proptech-bg.png",
                        "author": {
                            "@type": "Organization",
                            "name": "BeyondWalls: Buy Properties in India, Real Estate, Property Site India",
                            "url": "https://www.beyondwalls.com/"
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "BeyondWalls: Buy Properties in India, Real Estate, Property Site India",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://portal.beyondwalls.com/static/media/logo.fe506d7579e2f8d20d3fcb8856ca8b0c.svg"
                            }
                        }
                    })}
                </script>
            </Helmet>
            <Header />
            <section id='home-banner' className="position-relative bg-white hero-section text-center d-flex flex-column justify-content-center align-items-center">
                <div className="container hero-main">
                    <div className='hero-cont'>
                        <div className='row h100'>
                            <div className="hero-main-1 col-xl-6 col-12">
                                <div className="hero-heading mt-0 pt-0 pt-md-1">
                                    <div className='bwlogo'>
                                        <img src={BwLogo} loading='lazy' alt="hero-image" className="img-fluid d-block" />
                                    </div>
                                    <h1 className="d-flex flex-column align-items-lg-start align-items-center urbanist-font text-center text-lg-start">
                                        Real Estate <br />Simplified
                                    </h1>
                                </div>
                                <div className="hero-searchmain position-relative col-lg-8 col-12 align-self-center" ref={searchInputRef}>
                                    <div className="text-start mt-5 position-relative">
                                        <div className="search-component">
                                            {isMobile ? (
                                                <SearchPopup buttonShow={true} iconShow={false} />
                                            ) : (
                                                <SearchInput />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-6 col-12'>
                                <div className="hero-main-2 mt-3 mt-md-0 pb-0">
                                    {/*<a href={`${appAbsPath}/projects/map-view`} onClick={()=>{createGa4Event("hp_map_view", 'Map View', `Map View` )}}><img src={homeBanner} alt="hero-image" className="img-fluid col-9" /></a>
                                    <a href={`${appAbsPath}/projects/map-view`} className='map-btn' onClick={()=>{createGa4Event("hp_map_view", 'Map View', `Map View` )}}><img src={mapBtn} alt="map-btn" /></a>*/}
                                    <img src={BannerBuild} loading='lazy' alt="hero-image" className="img-fluid heroimg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={scrollToSection} className='arrowd d-none d-md-block'><img src={ArrowD} loading='lazy' alt="hero-image" className="img-fluid d-block" /></div>
            </section>
            <section className="home-features bg-white" ref={sectionRef}>
                <div className='container project-container'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h2 className='sec-title hometitle text-md-start text-center pt-md-0 fw-bold'>Top Projects in Pune</h2>
                        <div className="ceobtn">
                            <a href={`${appAbsPath}/projects`} onClick={() => { createGa4Event("hp_explore_now", 'Explore Now', `Explore Now`) }}>View More</a>
                        </div>
                    </div>
                    <div className="top-projects-slider position-relative">
                        <Slider {...settings}>
                            {projects.map((project, index) => (
                                <ProjectCard
                                    key={index}
                                    project={project}
                                    showWishlist="yes"
                                />
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>

            <section className='bg-white local-sec'>
                <div className='container'>
                    <div className='row localrow'>
                        <div className='col-lg-5 col-12 pdltcol d-flex flex-column justify-content-center'>
                            <h2 className='sec-title fw-bold text-md-start'>Explore Localities In Pune</h2>
                            <a href="/location/wakad" className="local-project-btn d-block mb-4">Explore More</a>
                        </div>
                        <div className='col-lg-7 col-12 position-relative'>
                            <div>
                                <img data-src={Shaniwaar} alt="map-btn" className='img-fluid floatimg lazy' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <section className="bg-white py-5">
                <div className="container">
                    <div className="row">
                        <iframe className="ceovid" src="https://www.youtube.com/embed/YKXbxh6IidY" title="Introducing BeyondWalls Property Search Portal Powered by AI | By Vikram Kotnis | MD, BeyondWalls" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
            </section> */}

            {/* <section className='py-5 bg-white'>
                <div className='container'>
                    <div className='row pt-5'>
                        <div className='customai'>
                            <div className='row align-items-center'>
                                <div className='col-xl-8 col-12 ps-5'>
                                <p className="subhead">Will be used in the future</p>
                                    <h2 className='sec-head text-white'>Get your custom A.I based
                                    Recommendation</h2>
                                    <div className="ceobtn">
                                        <a href="" target="_blank" className="blog-link">Explore Now</a>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-12'>
                                    <div className='housebox position-relative'>
                                        <div>
                                            <img src={Robot} className="img-fluid robotimg"/>
                                        </div>
                                        <div>
                                            <img src={Chip} className="img-fluid chipimg"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <Suspense fallback={<div>Loading...</div>}>
                <ExploreProjectsLocalities irfs={false}/>
            </Suspense>

            <section className='bg-white compare-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='comparesec'>
                            <div>
                                <img src={Comparesvg} className="img-fluid compareimg" />
                            </div>
                            <div>
                                <h2 className='compare-head'>Compare Your Top <br></br>Property Choices</h2>
                                <a href="/compare" className="local-project-btn d-block mb-4">Explore More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='bg-white py-5'>
                <div className='container project-local'>
                    <div className='row'>
                        <ContentBox
                            appAbsPath={appAbsPath}
                            createGa4Event={createGa4Event}
                            Box={Box}
                            Blueright={Blueright}
                            Flowers={Flowers}
                            Stars={Stars}
                            Hot={Hot}
                            Fire={Fire}
                            Affordable={Affordable}
                            Sky1={Sky1}
                            Sky2={Sky2}
                            showPara={true}
                            showTopContent={true}
                            showForwardIcon={true}
                            allowLazyLoad={true}
                        />
                    </div>
                </div>
            </section>

            <section className='bg-white ceosection'>
                <div className='container ceocontainer position-relative'>
                    <div className='row align-items-center'>
                        <div className='col-md-7 col-12'>
                            <div className='ceobox'>
                                <h2 className="sec-title fw-bold text-md-start text-center">About Beyondwalls</h2>
                                <p className='sec-desc text-md-start text-center'>BeyondWalls is an AI-powered property search platform that offers a comprehensive property consultancy service designed to simplify homebuying for you! This is your one-stop homebuying portal that brings you the right comparison of properties, for the right locations, to help you make the right decision.</p>
                                <a href="https://corporate.beyondwalls.com/" target='_blank' className="local-project-btn d-block mb-4">Explore More</a>
                            </div>
                        </div>
                        <div className='col-md-5 col-12'>
                            <div className='ceoimgbox'>
                                <div className='ceocirc'>&nbsp;</div>
                                <img data-src={Ceoimg} alt="CEO" className='img-fluid lazy' />
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <img data-src={Buildabstract} alt="CEO" className='img-fluid buildabstract lazy' />
                    </div>
                </div>
            </section>

            {/*<section className="bg-white ceosec pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-12">
                            <div className="ceobox">
                                <div>
                                    <img src={Ceo} alt="CEO" className='img-fluid ceoimg' />
                                </div>
                                <div className="ceo-cont">
                                    <h2 className="sec-title text-md-start text-center pt-md-0 fw-bold">Real Estate Simplified!</h2>
                                    <div className="ceobtn">
                                        <a href={`${appAbsPath}/projects`} className="blog-link theme-btn-reverse" onClick={()=>{createGa4Event("hp_explore_now", 'Explore Now', `Explore Now` )}}>Explore Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-12">
                            <div className="ceodesc p-5">
                                <h2 className="sec-title text-md-start text-center pt-md-0 pt-5 fw-bold">Why Choose BeyondWalls?</h2>
                                <p className="sec-desc text-md-start text-center">BeyondWalls Portal is an AI-powered property search platform It is a comprehensive property consultancy service designed to simplify homebuying for you!  This is your one-stop homebuying portal that brings you the right comparison of properties, for the right locations, to help you make the right decision.   We're your trusted partners in the journey of homebuying bringing you 15 years of expertise in the real estate industry, coupled with an extensive database backed by cutting-edge AI technology. Our aim is to provide you with verified, authentic and filtered data personalized as per your preferences. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/}

            {/*<section className="home-features bg-white pt-5">
                <div className='container'>
                    <h2 className='sec-title fw-bold text-md-start text-center'>What Are You Looking For?</h2>
                    <div className='looking-slider-wrap mt-4'>
                        <Slider {...Lookingsettings}>
                        <Link to={`${appAbsPath}/projects?is_ready_to_move=true`} onClick={()=>{createGa4Event('hp_looking_for' , 'hp_looking_for', `Ready to Move In`)}} className='text-decoration-none'>
                            <div className='looking-card-inner'>
                                <div className='looking-for-card'>
                                    <div className='looking-for-img'>
                                        <img src={readyToMove} alt="Ready to Move In" className='img-fluid col-12' />
                                        <p>Check out the top ready-to-move-in properties.</p>
                                    </div>
                                    <div className='looking-for-details'>
                                        <h3 className='text-md-start text-center'>Ready to Move In</h3>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to={`${appAbsPath}/projects?is_affordable=true`} onClick={()=>{createGa4Event('hp_looking_for' , 'hp_looking_for', `Affordable Homes`)}} className='text-decoration-none'>
                            <div className='looking-card-inner'>
                                <div className='looking-for-card'>
                                    <div className='looking-for-img'>
                                        <img src={budgetHome} alt="Affordable Homes" className='img-fluid col-12' />
                                        <p>Choose your ideal home within your budget.</p>
                                    </div>
                                    <div className='looking-for-details'>
                                        <h3 className='text-md-start text-center'>Affordable Homes</h3>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        <Link to={`${appAbsPath}/projects?is_best_of_amenities=true`} onClick={()=>{createGa4Event('hp_looking_for' , 'hp_looking_for', `Best Of The Amenities`)}} className='text-decoration-none'>
                            <div className='looking-card-inner'>
                                <div className='looking-for-card'>
                                    <div className='looking-for-img'>
                                        <img src={bestAmenities} alt="Best Of The Amenities" className='img-fluid col-12' />
                                        <p>Select a home based on your preferred luxuries.</p>
                                    </div>
                                    <div className='looking-for-details'>
                                        <h3 className='text-md-start text-center'>Best Of The Amenities</h3>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to={`${appAbsPath}/projects?is_new_in_town=true`} onClick={()=>{createGa4Event('hp_looking_for' , 'hp_looking_for', `New In Town`)}} className='text-decoration-none'>
                            <div className='looking-card-inner'>
                                <div className='looking-for-card'>
                                    <div className='looking-for-img'>
                                        <img src={newInTown} alt="New In Town" className='img-fluid col-12' />
                                        <p>Explore the latest launches in your city.</p>
                                    </div>
                                    <div className='looking-for-details'>
                                        <h3 className='text-md-start text-center'>New In Town</h3>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        <Link to={`${appAbsPath}/projects?is_great_view=true`} onClick={()=>{createGa4Event('hp_looking_for' , 'hp_looking_for', `What A View`)}} className='text-decoration-none'>
                            <div className='looking-card-inner'>
                                <div className='looking-for-card'>
                                    <div className='looking-for-img'>
                                        <img src={whatAView} alt="What A View" className='img-fluid col-12' />
                                        <p>Enjoy peaceful living with stunning scenic views.</p>
                                    </div>
                                    <div className='looking-for-details'>
                                        <h3 className='text-md-start text-center'>What A View</h3>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        <Link to={`${appAbsPath}/projects?is_hot_selling=true`} onClick={()=>{createGa4Event('hp_looking_for' , 'hp_looking_for', `Hot Selling`)}} className='text-decoration-none'>
                            <div className='looking-card-inner'>
                                <div className='looking-for-card'>
                                    <div className='looking-for-img'>
                                        <img src={hotSelling} alt="Hot Selling" className='img-fluid col-12' />
                                        <p>Discover the best-selling properties in your area.</p>
                                    </div>
                                    <div className='looking-for-details'>
                                        <h3 className='text-md-start text-center'>Hot Selling</h3>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        <Link to={`${appAbsPath}/projects?is_legends_of_city=true`} onClick={()=>{createGa4Event('hp_looking_for' , 'hp_looking_for', `Legends Of Pune`)}} className='text-decoration-none'>
                            <div className='looking-card-inner'>
                                <div className='looking-for-card'>
                                    <div className='looking-for-img'>
                                        <img src={legendsPune} alt="Legends Of Pune" className='img-fluid col-12' />
                                        <p>Browse properties by leading real estate developers.</p>
                                    </div>
                                    <div className='looking-for-details'>
                                        <h3 className='text-md-start text-center'>Legends Of Pune</h3>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        <Link to={`${appAbsPath}/projects?is_live_in_sky=true`} onClick={()=>{createGa4Event('hp_looking_for' , 'hp_looking_for', `Live In The Sky`)}} className='text-decoration-none'>
                            <div className='looking-card-inner'>
                                <div className='looking-for-card'>
                                    <div className='looking-for-img'>
                                        <img src={liveInSky} alt="Live In The Sky" className='img-fluid col-12' />
                                        <p>Discover premium high-rise residences in prime locations.</p>
                                    </div>
                                    <div className='looking-for-details'>
                                        <h3 className='text-md-start text-center'>Live In The Sky</h3>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </Slider>
                    </div>
                </div>
            </section>*/}
            {/*<section className="home-features bg-white pt-5">
                <div className='container'>
                    <h2 className='sec-title fw-bold text-md-start text-center'>Blogs</h2>
                    <div className='blog-wrap mt-3 mt-md-5 pb-5'>
                        {blogsData.slice(0, 3).map((blogsData) => (
                        <div className='blog-card' key={blogsData.id}>
                            <div className={`d-flex ${blogsData.index % 2 !== 0 ? '' : 'flex-row-reverse'}`}>
                                <div className='blog-img col-4'>
                                    <img src={blogsData.image.url} alt="blog-image" className='img-fluid' />
                                </div>
                                <div className='blog-details col-8'>
                                    <h3 className='blog-title'>{blogsData.title}</h3>
                                    <p className='blog-desc'>{blogsData.content}</p>
                                    <a href='#' className='blog-link'>Read More</a>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </section>*/}


            <section className='bg-white py-5'>
                <div className='container'>
                    <div className='row'>
                        <h2 className='sec-title fw-bold text-md-start text-center'>Blogs</h2>
                    </div>
                    <div className='row'>
                        <div className='col-xl-6 col-12'>
                            <div className='blog-grid'>
                                {blogsData.slice(0, 3).map((blogsData, index) => (
                                    <div className='blog-box'>
                                        <div>
                                            <h3 className='blog-title abtitle'>{blogsData.title}</h3>
                                            <a href={`${appAbsPath}/blog/${blogsData.slug}`} target="_blank" className='blog-link'>Explore More</a>
                                        </div>
                                        <div>
                                            <div className='blogicon'>
                                                <img data-src={blogImages[index]} className='img-fluid lazy' />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='col-xl-6 col-12'>
                            <div className='blog-grid2'>
                                {blogsData.slice(3, 6).map((blogsData, index) => (
                                    <div className='blog-box'>
                                        <div>
                                            <h3 className='blog-title abtitle'>{blogsData.title}</h3>
                                            <a href={`${appAbsPath}/blog/${blogsData.slug}`} target="_blank" className='blog-link'>Explore More</a>
                                        </div>
                                        <div>
                                            <div className='blogicon'>
                                                <img data-src={blogImages2[index]} className='img-fluid lazy' />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            {/*<section className='bg-white py-5'>
                <div className='container'>
                    <div className='row'>
                        <h2 className='sec-title fw-bold text-md-start text-center'>Blogs</h2>
                    </div>
                    <div className='row'>
                        <div className='col-xl-6 col-12'>
                            <div className='blog-grid'>
                                {}
                                <div className='blog-box'>
                                    <div>
                                        <h3 className='blog-title'>Email Marketing Strategies for Real Estate Businesses</h3>
                                        <a href='https://www.beyondwalls.com/blog/blog-real-estate-email-marketing-strategies' target="_blank" className='blog-link'>Explore More</a>
                                    </div>
                                    <div>
                                        <div className='blogicon'>
                                            <img src={BlogIcon} className='img-fluid'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='blog-box'>
                                    <div>
                                        <h3 className='blog-title'>SEO for Real Estate: The Complete Guide to Improve Search Visibility</h3>
                                        <a href='https://www.beyondwalls.com/blog/blog-real-estate-seo' target="_blank" className='blog-link'>Explore More</a>
                                    </div>
                                    <div>
                                        <div className='blogicon'>
                                            <img src={Checklist} className='img-fluid'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='blog-box'>
                                    <div>
                                        <h3 className='blog-title'>Innovative Virtual Tour Marketing Strategies for Real Estate</h3>
                                        <a href='https://www.beyondwalls.com/blog/real-estate-virtual-tour-marketing-strategies' target="_blank" className='blog-link'>Explore More</a>
                                    </div>
                                    <div>
                                        <div className='blogicon'>
                                            <img src={BlogHouse} className='img-fluid'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6 col-12'>
                            <div className='blog-grid2'>
                                <div className='blog-box'>
                                    <div>
                                        <h3 className='blog-title'>Analyse the Locations With High Livability Quotient?</h3>
                                        <a href='https://corporate.beyondwalls.com/blog/property-market-value-intricacies-in-india' target="_blank" className='blog-link'>Explore More</a>
                                    </div>
                                    <div>
                                        <div className='blogicon'>
                                            <img src={BlogRobot} className='img-fluid'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='blog-box'>
                                    <div>
                                        <h3 className='blog-title'>Analyse the Locations With High Livability Quotient?</h3>
                                        <a href='https://corporate.beyondwalls.com/blog/property-market-value-intricacies-in-india' target="_blank" className='blog-link'>Explore More</a>
                                    </div>
                                    <div>
                                        <div className='blogicon'>
                                            <img src={BlogShaniwar} className='img-fluid'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='blog-box'>
                                    <div>
                                        <h3 className='blog-title'>Analyse the Locations With High Livability Quotient?</h3>
                                        <a href='https://www.beyondwalls.com/blog/real-estate-video-marketing-guide' target="_blank" className='blog-link'>Explore More</a>
                                    </div>
                                    <div>
                                        <div className='blogicon'>
                                            <img src={BlogBook} className='img-fluid'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/}

            {/* <section className='bg-white pt-5'>
                <h2 className='sec-title fw-bold text-center'>Testimonials</h2>
                <div className='testimoni-slider'>
                    <Slider {...testmonisettings}>
                        <div className='test-slide'>
                            <img src={Testimoni} className='img-fluid'/>
                        </div>
                        <div className='test-slide'>
                            <img src={Testimoni} className='img-fluid'/>
                        </div>
                        <div className='test-slide'>
                            <img src={Testimoni} className='img-fluid'/>
                        </div>
                    </Slider>
                </div>
            </section>*/}

        </>
    )
}
export default Home;
