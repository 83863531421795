

import React, { useEffect, useState, lazy, useRef, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Carousel, Accordion } from 'react-bootstrap';
import { appAbsPath, executeLaravelFrontAPI, s3url, userData, userToken, executeLaravelAPI, showToastMessage, formatNumber, getSortBHKConf, getMinMaxCarpets, toCovertYear, parsedUserData, numberWithCommas, isMobileDevice, convertShortDate, dateDiffInMonths, getMinMaxPrice, createGa4Event, capitalize, checkFileExtension } from '../../../Admin/Utils';
import ProjectDetailsLayout from '../ProjectDetailsLayout';
import { Icon, buildIcon } from '@iconify/react';
import Loader from '../../../Loader';
import defaultGallery from '../../../../images/default-corporate-image.jpg';
import bwLogo from '../../../../Assets/img/bw-logo.svg';
import scoreBoard from '../../../../Assets/img/score-board.png';
import buildImg from '../../../../Assets/img/building-img.png';
import ExploreMore from '../../../../Assets/img/explore-more.png';
import wishlistBanner from '../../../../Assets/img/wishlist-banner.png';
import InterestedImg from '../../../../Assets/img/interested.png';
import rating1 from '../../../../Assets/img/rating-1.svg';
import rating2 from '../../../../Assets/img/rating-2.svg';
import rating3 from '../../../../Assets/img/rating-3.svg';
import rating4 from '../../../../Assets/img/rating-4.svg';
import rating5 from '../../../../Assets/img/rating-5.svg';
import Vector1 from '../../../../Assets/img/vector1.png';
import Vector2 from '../../../../Assets/img/vector2.png';
// import highlighesImg from '../../../../Assets/img/highlighesImg.png';
import highlighesImg from '../../../../Assets/img/highlight.png';
import RelatedProjects from '../RecomendedProjects';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import MapContainerLeaflet from './MapContainerLeaflet';
import WishListBtn from '../WishListBtn';
import CircularProgress from '../CircularProgress';
// import { Link } from 'react-router-dom';
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import EMICalculator from '../EMICalculator';
import ProjectRating from '../ProjectRating';
import ProjectRatingSet from '../ProjectRatingSet';
import FormEnquiry from '../FormEnquiry';
import EsriLeafletMap from '../EsriLeafletMapDetailsPage';
// import ScorePopup from './ScorePopup';
import { setUserProjectView, setUserDownloadBrochure } from '../../../Admin/UserActivatyTracker';
import { Helmet } from 'react-helmet-async';
import { CompareProjectContext } from '../../Layouts/CompareProjectContext';

const isMobile = isMobileDevice();

const ConfigurationDetails = ({ configuration, minCarpet, maxCarpet, minPrice, maxPrice, quoted_per_sqft_price, project }) => {
    const [emiPopup, setEmiPopup] = useState(false);
    const { isProjectId, setProjectId } = useContext(CompareProjectContext);
    const { isDeveloperId, setDeveloperId } = useContext(CompareProjectContext);

    const handleEMIClick = () => {
        setEmiPopup(!emiPopup);
    };
    const handleClickEnquiryForm = () => {
        setProjectId(project.id);
        setDeveloperId(project.developer.id);
        document.getElementById('overlayEnquiry').style.display = 'block';
    }


    return (
        <><div className="mt-2 ">
            <p className="text-mob-comparision fs-6 plp-carpet-area">Carpet area: <span
                className="text-listing fw-bold text-decoration-underline">
                {minCarpet.toFixed(0) === maxCarpet.toFixed(0) ?
                    numberWithCommas(minCarpet.toFixed(0))
                    :
                    numberWithCommas(minCarpet.toFixed(0)) + " - " + numberWithCommas(maxCarpet.toFixed(0))
                } <span className='fw-normal'>sq.ft.</span></span>
            </p>
        </div>
            <div className={`tab-pane fade show active`}>
                <div className="plp-price-cards mt-1 parent test">
                    <div className="card border-primary text-dark">
                        <div className="card-header p-2 pt-1 pb-1 fw-bold border-bottom-0">Avg Price/ Sq.ft
                            <OverlayTrigger delay={{ hide: 150, show: 200 }} overlay={(props) => (
                                <Tooltip {...props} > Avg Price/ Sq.ft</Tooltip>
                            )} placement={isMobile ? "top" : "right"}>
                                <Icon icon="akar-icons:info-fill" className='position-absolute' style={{ "color": "#43A66A", "right": "8px", "top": "6px" }} />
                            </OverlayTrigger>
                        </div>
                        <div className="card-body bg-white p-2 pt-1 pb-1">
                            <span className="card-title fw-bold">
                                {isNaN(minPrice) || isNaN(maxPrice) || minPrice === 0 || maxPrice === 0 ? (
                                    <span className="text-primary" style={{ "cursor": "pointer" }} onClick={() => handleClickEnquiryForm()}>Price on Request</span>
                                ) : (
                                    // parseInt(minPrice) === 0 ?
                                    // `₹ ${formatNumber((maxPrice / maxCarpet).toFixed(0))}` : `₹ ${formatNumber((minPrice / minCarpet).toFixed(0))}`
                                    //)
                                    `₹\u00A0${numberWithCommas(formatNumber(quoted_per_sqft_price).toFixed(0))}`
                                )}
                            </span>

                        </div>
                    </div>
                    <div className="card border-primary text-dark">
                        <div className="card-header p-2 pt-1 pb-1 fw-bold border-bottom-0">Total Price
                            <OverlayTrigger delay={{ hide: 150, show: 200 }} overlay={(props1) => (
                                <Tooltip {...props1}> Total Price = Agreement Price + SDR + GST*.
                                    (*The actual total price may vary depending on other charges)</Tooltip>
                            )} placement={isMobile ? "top" : "right"}>
                                <Icon icon="akar-icons:info-fill" className='position-absolute' style={{ "color": "#43A66A", "right": "8px", "top": "6px" }} />
                            </OverlayTrigger>
                        </div>
                        <div className="card-body bg-white p-2 pt-1 pb-1">
                            <span className="card-title fw-bold">
                                {isNaN(minPrice) || isNaN(maxPrice) || minPrice === 0 || maxPrice === 0 ? (
                                    <span className="text-primary" style={{ "cursor": "pointer" }} onClick={() => handleClickEnquiryForm()}>Price on Request</span>
                                ) : (
                                    minPrice === maxPrice ? (
                                        `₹\u00A0${formatNumber(minPrice)}`
                                    ) : (
                                        // (minPrice !== '0' ?
                                        // `₹\u00A0${formatNumber(maxPrice)}` : `₹\u00A0${formatNumber(minPrice)} - ₹${formatNumber(maxPrice)}`
                                        // )
                                        `₹\u00A0${formatNumber(minPrice)} - ₹\u00A0${formatNumber(maxPrice)}`
                                    )
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="card border-primary text-dark position-none">
                        <div className="card-header p-2 pt-1 pb-1 fw-bold border-bottom-0  position-relative">EMI Starts At
                            {/* <OverlayTrigger delay={{ hide: 450, show: 300 }} overlay={(props2) => ( 
                            <Tooltip {...props2}> Total Price = Ag Price + SDR + GST*.
                            (*The actual total price may vary depending on other charges)</Tooltip> 
                        )} placement="right">   
                            <Icon icon="akar-icons:info-fill" className='position-absolute'  style={{"color": "#43A66A", "right":"8px", "top":"6px"}} />
                        </OverlayTrigger> */}
                        </div>
                        <div className="card-body bg-white p-2 pt-1 pb-1">
                            <span className="card-title fw-bold ">
                                {isNaN(minPrice) || isNaN(maxPrice) || minPrice === 0 || maxPrice === 0 ? (
                                    <span className="text-primary" style={{ "cursor": "pointer" }} onClick={() => handleClickEnquiryForm()}>Price on Request</span>
                                ) : (
                                    <><div className='emiCalcPopup1' onClick={() => handleEMIClick()}>
                                        <a href='javascript:;' className='text-decoration-none'>
                                            <EMICalculator loanPrice={maxPrice} showEMI={emiPopup} />
                                        </a>
                                    </div>
                                        {/* <EMICalculator loanPrice={maxPrice} showEMI={emiPopup} /> */}
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </div></>
    );
};
const PDP = ({ loggeduserdetails }) => {
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const { projectslug } = useParams();
    const [project, showProjectData] = useState({});
    const [relatedProject, setRelatedProject] = useState([]);
    const [selectedConfig, setSelectedConfig] = useState(null);
    const [selectedTab, setSelectedTab] = useState(null);
    const [userdetails, setUserResponseData] = useState(null);
    const [isWishList, isWishlistActive] = useState(false);
    const [projectId, setProjectId] = useState();
    const [developerId, setDeveloperId] = useState();
    const [projectName, setprojectName] = useState();
    const [showAll, setShowAll] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [places, setPlaces] = useState([]);

    const [initialMouse, setInitialMouse] = useState(0);
    const [slideMovementTotal, setSlideMovementTotal] = useState(0);
    const [mouseIsDown, setMouseIsDown] = useState(false);
    const [sliderLeft, setSliderLeft] = useState(-10);
    const [slideTextOpacity, setSlideTextOpacity] = useState(1);
    const [isUnlocked, setIsUnlocked] = useState(false);
    const sliderRef = useRef(null);
    const [rating, setRating] = useState(0);
    const [ratingReview, setRatingReview] = useState(rating);
    const [ratingReviewForm, setRatingReviewForm] = useState(true);
    const [inputValue, setInputValue] = useState('wakad');
    const [reviewText, setReviewText] = useState('');
    const [recommendChecked, setRecommendChecked] = useState(false);
    const [isSubmitedReview, setIsSubmitedReview] = useState(false);
    const [selectedParameters, setSelectedParameters] = useState([]);

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);


    const [sortedCombinedData, setSortedCombinedData] = useState([]);
    const [hasReloaded, setHasReloaded] = useState(false);

    // const [photoIndex, setPhotoIndex] = useState(0)


    const layoutPlanRef = useRef(null);
    const propertyDetailsRef = useRef(null);
    const highlightsRef = useRef(null);
    const amenitiesRef = useRef(null);
    const specificationRef = useRef(null);
    const locationRef = useRef(null);
    const faqRef = useRef(null);
    const developerRef = useRef(null);
    const projOverviewHeadRef = useRef(null);
    const [activeSection, setActiveSection] = useState(null);
    useEffect(() => {
        window.scrollTo(0, 0);
        const projectOffsetTop = projOverviewHeadRef.current.offsetTop; //stored here as on scroll value is getting
        const handleScroll = () => {
            const yOffset = window.pageYOffset + 80; // Add 100px offset
            // console.log("offset--"+yOffset);
            // console.log("project overview--"+projectOffsetTop);
            const sections = [
                { name: 'layout', ref: layoutPlanRef },
                { name: 'propertyDetails', ref: propertyDetailsRef },
                { name: 'highlights', ref: highlightsRef },
                { name: 'amenities', ref: amenitiesRef },
                { name: 'specifications', ref: specificationRef },
                { name: 'location', ref: locationRef },
                { name: 'developers', ref: developerRef }
                // Add other sections...
            ];
            // console.log("project offset top--"+projectOffsetTop);
            if (yOffset > projectOffsetTop) {
                projOverviewHeadRef.current.classList.add('sticky');
            } else {
                projOverviewHeadRef.current.classList.remove('sticky');
            }
            for (let section of sections) {
                // console.log(section.ref.current, yOffset)
                if (section.ref.current && yOffset >= section.ref.current.offsetTop - 80) {
                    setActiveSection(section.name);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        return () => {
            if (location.pathname === '/' || location.pathname.includes('/project/')) {
                localStorage.removeItem('selectedValues');
            }
        };
    }, [location.pathname]);
    useEffect(() => {
        // Get the active button
        const activeButton = document.querySelector('.menu-scroll-container .active');
        if (activeButton) {
            // Scroll the container to center the active button
            activeButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, []);

    const scrollToSection = (ref) => {
        const yOffset = ref.current.offsetTop - 150; // Subtract 100px from the top
        window.scrollTo({ top: yOffset, behavior: 'smooth' });
    };
    useEffect(() => {
        // Fetch project data when component mounts
        const getProjects = async () => {
            try {
                const result = await executeLaravelFrontAPI(`project/${projectslug}`, '', 'GET');
                const dataProjectDetails = result.data;

                if (result.success == false) {
                    window.location.href = '/404';
                } else {
                    setUserProjectView(dataProjectDetails.id);
                    showProjectData(dataProjectDetails); // Set the editedItem state
                    setProjectId(dataProjectDetails.id);
                    setDeveloperId(dataProjectDetails.developer.id);
                    setprojectName(dataProjectDetails.name)
                    isWishlistActive(false);
                    if (dataProjectDetails && dataProjectDetails?.towers.length > 0 && dataProjectDetails?.towers[0]?.configurations.length > 0) {
                        setSelectedTab(dataProjectDetails.towers[0].configurations[0].name.replace(/\s/g, ' ').toUpperCase());
                    }
                }

                // set Cookies in userProjectView
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (projectslug) {
            getProjects();

        }

    }, [projectslug]);
    useEffect(() => {
        if (project) {
            const uniqueConfigs = getUniqueConfigurations();
            setSelectedConfig(uniqueConfigs[0]);
        }
    }, [project, selectedTab]);

    useEffect(() => {
        if (loggeduserdetails) {
            if (Object.keys(loggeduserdetails).length === 0) {
                userData().then((result) => {
                    setUserResponseData(result);
                    //getWishListProjects();
                })
            }
        }
        // getRelatedProjects();
    }, []);
    useEffect(() => {
        if (project) {
            setInputValue(project?.address?.location === undefined ? project?.address?.locality_name : project?.address?.location);
            setRatingReviewForm(true)
        }
    }, [project]);

    // useEffect to initialize the sortedCombinedData
    useEffect(() => {
        if (project && project.specifications) {
            const sortedData = sortAndCombine(project.specifications);
            setSortedCombinedData(sortedData);
        }
    }, [project && project.specifications]); // Added validation for project and project.specifications


    // console.log(project.id);
    // console.log(project.developer.id);

    const handleConfigClick = (configName) => {
        setSelectedConfig(configName);
    };

    const getMinMaxCarpet = (configurationName) => {
        let minCarpet = Infinity;
        let maxCarpet = -Infinity;

        project.towers.forEach((tower) => {
            tower.configurations.forEach((config) => {
                if (config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase() === configurationName) {
                    const carpet = parseFloat(config.carpet);
                    minCarpet = Math.min(minCarpet, carpet);
                    maxCarpet = Math.max(maxCarpet, carpet);
                }
            });
        });

        return { minCarpet, maxCarpet };
    };
    const getMinMaxPrices = (configurationName) => {
        let minPrice = Infinity;
        let maxPrice = -Infinity;
        project.towers.forEach((tower) => {
            tower.configurations.forEach((config) => {
                if (config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase() === configurationName) {
                    const price = parseInt(config.final_price);
                    minPrice = Math.min(minPrice, price);
                    maxPrice = Math.max(maxPrice, price);
                }
            });
        });

        return { minPrice, maxPrice };
    };

    const getUniqueConfigurations = () => {
        const configurationsSet = new Set();
        project?.towers?.forEach((tower) => {
            tower?.configurations?.forEach((config) => {
                const processedName = config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase();
                configurationsSet.add(processedName);
            });
        });
        return Array.from(configurationsSet).sort();
    };
    if (!project) {
        return <Loader />;
    }
    // console.log(JSON.parse(project?.gallery));

    const uniqueConfigs = getUniqueConfigurations();
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Display 3 items at a time
        slidesToScroll: 5
    };

    function fixNumberTwoDecimalFromString(str) {
        const formatToTwoDecimals = match => parseFloat(match).toFixed(2);
        // Replace numbers in the string using the format function
        const updatedStr = str.replace(/-?\d+(\.\d+)?/g, formatToTwoDecimals);
        return updatedStr;// "The cost is 100.26 dollars and 20.79 euros"
    }
    // const inputString = project.project_size_readable;
    // console.log(inputString);
    // let numericValue = null, numericValue2 = null;
    // if (typeof inputString === 'string') {
    //     const matchResult = inputString.match(/[\d.]+/);
    //     if (matchResult) {
    //         numericValue = parseFloat(matchResult[0]).toFixed(2);
    //         numericValue2 = parseFloat(matchResult[1]);
    //         // console.log(numericValue2);
    //     } else {
    //         // console.log("No numeric value found in the string");
    //     }
    // } else {
    //     console.log("Input is not a string");
    // console.log(project.address.latitude);

    let position = [];
    if (project?.address?.latitude === undefined || project?.address?.longitude === undefined) {
        position = [18.324234234, 73.123123];
    } else {
        position = [project.address.latitude, project.address.longitude];
    }



    const handleMouseDown = (event) => {
        setMouseIsDown(true);
        const clientX = event.clientX || event.touches[0].pageX;
        setInitialMouse(clientX);
        const buttonBackgroundWidth = document.getElementById('button-background').offsetWidth;
        setSlideMovementTotal(buttonBackgroundWidth - sliderRef.current.offsetWidth + 10);
        if (parsedUserData === null) {
            handleNotLogin("Wishlist");
        } else {
            handleShortListedClick(project.id);
        }
    };

    const handleMouseUp = () => {
        if (!mouseIsDown) return;
        setMouseIsDown(false);
        const relativeMouse = sliderLeft + 10;

        if (relativeMouse < slideMovementTotal) {
            setSlideTextOpacity(1);
            setSliderLeft(-10);
            return;
        }

        setIsUnlocked(true);
        if (parsedUserData === null) {
            handleNotLogin("Wishlist");
        } else {
            handleShortListedClick(project.id);
        }

        sliderRef.current.removeEventListener('click', handleSliderClick);
        setTimeout(() => {
            sliderRef.current.addEventListener('click', handleSliderClick);
        }, 0);
    };

    const handleMouseMove = (event) => {
        if (!mouseIsDown) return;
        const currentMouse = event.clientX || event.touches[0].pageX;
        const relativeMouse = currentMouse - initialMouse;
        const slidePercent = 1 - (relativeMouse / slideMovementTotal);

        setSlideTextOpacity(slidePercent);

        if (relativeMouse <= 0) {
            setSliderLeft(-10);
            return;
        }
        if (relativeMouse >= slideMovementTotal) {
            setSliderLeft(slideMovementTotal);
            return;
        }
        setSliderLeft(relativeMouse - 10);
    };

    const handleSliderClick = () => {
        if (!isUnlocked) return;
        setIsUnlocked(false);
        sliderRef.current.removeEventListener('click', handleSliderClick);
    };

    const handleShortListedClick = async (projectId) => {
        try {
            const result = await executeLaravelAPI(`add-wishlist?wishlistid=${projectId}`, '', 'POST', userToken);
            if (result.status === 200) {
                showToastMessage('Shorlisted Project has been added successfully', 'top-right', 'success');
                if (!isActive) {
                    setIsActive(true);
                }
                isWishlistActive(true);
            } else {
                showToastMessage('Shorlisted Project has not been added.', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error fetched project data:', error);
        }
    };

    // const getRelatedProjects = async () => {
    //     const paramsData = {};
    //     // console.log(inputValue)
    //     paramsData.locality_name = inputValue;
    //     paramsData.searchtype = "locality_name";
    //     paramsData.per_page = 10;

    //     // console.log(paramsData);
    //     let result = {};
    //     try {
    //         result = await executeLaravelFrontAPI('projects', paramsData, 'GET');
    //         setRelatedProject(result.data.data);

    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     } finally {
    //     }
    // }
    // const settingsForRelatedProject = {
    //     dots: false,
    //     arrows: true,
    //     infinite: true,
    //     speed: 800,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className='prev-arrow' />,
    //     nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className='next-arrow' />,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 arrows: true,
    //                 slidesToShow: 3,
    //                 slidesToScroll: 1,
    //             }
    //         },
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1
    //             }
    //         },
    //         {
    //             breakpoint: 480,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1
    //             }
    //         }
    //     ]
    // };
    const handleNotLogin = (btnClick) => {
        document.getElementById('overlay').style.display = 'block';
        if (btnClick === "Download Brochure") {
            document.getElementById('login_title').textContent = "Your brochure is ready, login to download";
        }
        if (btnClick === "Last Transacations") {
            document.getElementById('login_title').textContent = "Stay updated with the latest transactions";
        }
        if (btnClick === "Wishlist") {
            document.getElementById('login_title').textContent = "Add this project to your wishlist";
        }
    }
    const handlerelatedProj = () => {
        window.scrollTo(0, 0);
    }
    const headleSubmitReview = () => {
        setRatingReview(rating);
        setRatingReviewForm(false)
    }

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        if (checked) {
            setSelectedParameters([...selectedParameters, id]);
        } else {
            setSelectedParameters(selectedParameters.filter(param => param !== id));
        }
    };

    const handleReviewTextChange = (e) => {
        setReviewText(e.target.value);
    };
    const handleRecommendChange = (e) => {
        setRecommendChecked(e.target.checked);
    };
    const handleSubmitReview = async () => {
        // console.log(loggeduserdetails);
        // console.log(loggeduserdetails.id);
        const formData = {
            project_id: project.id,
            user_id: loggeduserdetails.id,
            rating: rating,
            parameter: selectedParameters.join(),
            review: reviewText,
            recommend: recommendChecked === true ? 1 : 0
        };
        // console.log(formData);
        try {
            const result = await executeLaravelAPI(`projectrating`, formData, 'POST', userToken);

            if (result.status === 200 && result.success === true) {
                showToastMessage('Project Review has been added successfully', 'top-right', 'success');
                if (!isSubmitedReview) {
                    setIsSubmitedReview(true);
                }
            }

            else if (result.status === 200 && result.success === false) {
                showToastMessage('Project Review has already been added', 'top-right', 'error');
            }
            else {
                showToastMessage('Project Review has not been added.', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error fetched project data:', error);
        }
    }
    const brochureURL = project.brochure && project.brochure.length > 0 ? project.brochure[0].url : '';
    // const images = project?.gallery?.map(image => `${s3url}/project-assets${image.url}`) ?? [];

    const images = Array.isArray(project?.gallery) ? project.gallery.map(image => `${s3url}/project-assets${image.url}`) : [];



    const openLightbox = (index) => {
        setTimeout(() => {
            setPhotoIndex(index);
            setLightboxOpen(true);
        }, 10);
    };

    // console.log(project.specifications);

    // Function to sort and combine data
    const sortAndCombine = (data) => {
        if (!data || !Array.isArray(data)) {
            return [];
        }

        const combinedData = {};

        data.forEach((item) => {
            const { specification_type, description } = item;
            if (combinedData[specification_type]) {
                combinedData[specification_type].description += `\n${description}`;
            } else {
                combinedData[specification_type] = { ...item };
            }
        });

        const result = Object.values(combinedData);
        result.sort((a, b) => a.specification_type.localeCompare(b.specification_type));

        return result;
    };




    return (
        <>
            <Helmet>
                <title>{project.name ? project.name : ''} in {project?.address?.location ? project.address.location : ''}, {project?.address?.city ? capitalize(project.address.city) : ''} : Reviews, Prices & Floor Plans - BeyondWalls</title>
                <meta name="description" content={project.name ? `${project.name} by ${project.developer?.name ? project.developer.name : ''} offers ${uniqueConfigs.length > 0 ? uniqueConfigs.join(', ') + ' ' : ''}Flats for sale in ${project?.address?.location ? project.address.location : ''}, ${project?.address?.city ? capitalize(project.address.city) : ''}. To check price, floor plan, master plan, amenities, agreement Value, photos, visit BeyondWalls!` : ''} />
                <link rel="canonical" href={`https://www.beyondwalls.com/project/${project.slug ? project.slug : ''}`} />


            </Helmet>
            <div className='pdp-class'>
            <main className="project-detail-main pdp-main pb-5 pt-5 mt-5">
                <section className="project-main pt-3">
                    <div className="px-3 px-md-5 px-sm-5">
                        <div className="tabcontent rounded-8 plp-card mb-3 float-start float-md-none col-12">
                            <img src={Vector2} className='img-fluid vector2'/>
                            <div className="plp-card-main pdp-details-card">
                                <div className="position-relative ms-0 mb-3 mb-md-0 ms-md-0 project-right-content mt-3 mt-md-0">
                                    <img src={Vector1} className='img-fluid vector1'/>
                                    <div className="position-relative">
                                        <h1 className="plpcard-name m-0 noto-sans">{project.name}</h1>
                                        <p className='mb-0 developer-name text-theme'>IRFS Exclusive</p>
                                        <h2 className='discount'>10% Off</h2>
                                        <button className="btn cupon-btn btn-sm">Download Coupon</button>
                                    </div>
                                </div>
                                <div className={`image-side position-relative col-6 ${project.gallery && project.gallery.length <= 3 ? 'full-img' : ''}`}>
                                    {project && project.gallery && Array.isArray(project.gallery) && project.gallery.length > 0 ? (
                                        project.gallery.length === 1 ? (
                                            <div className='lazyload-placeholder project-img' style={{ backgroundImage: `url(${s3url}/project-assets${project.gallery[0].url})` }}></div>
                                        ) : (
                                            <Carousel data-bs-theme="light">
                                                {project.gallery.map((image, index) => (
                                                    checkFileExtension(image.url) && (
                                                        <Carousel.Item key={index}>
                                                            <img src={`${s3url}/project-assets${image.url}`} />
                                                        </Carousel.Item>
                                                    )
                                                ))}
                                            </Carousel>
                                        )
                                    ) : (
                                        <>
                                            <div className='lazyload-placeholder project-img' style={{ paddingTop: '100%', backgroundImage: `url(${defaultGallery})` }}></div>
                                        </>
                                    )}
                                    <WishListBtn projectId={project.id} isWishlistActive={isWishlistActive} loggeduserdetails={parsedUserData} color={"#fff"} width={"30px"} height={"30px"} handleNotLogin />
                                </div>
                            </div>
                        </div>
                        <section className="projet-details-main py-sm-5 pb-3">
                            <div className="">
                                <div className="row">
                                    <div className="col-xl-9 col-12">
                                        <div className="proj-overview-head py-2" ref={projOverviewHeadRef}>
                                            <div className={`menu-scroll-container d-flex justify-content-between align-items-center border-bottom-plp layout-mobile-slider`}>
                                                <button className={`btn rounded-0 pb-2 ${activeSection === 'layout' ? 'active' : ''}`} id="toggle-button-2" onClick={() => scrollToSection(layoutPlanRef)}>Layout Plan</button>

                                                <button className={`btn rounded-0 pb-2 ${activeSection === 'propertyDetails' ? 'active' : ''}`} id="toggle-button-1" onClick={() => scrollToSection(propertyDetailsRef)}>Property Details</button>
                                                {project.usp && JSON.parse(project.usp).length > 0 && (
                                                    JSON.parse(project.usp)?.map((usp, index) => (
                                                        (index < 1 ? (
                                                            <button className={`btn rounded-0 pb-2 ${activeSection === 'highlights' ? 'active' : ''}`} id="toggle-button-4" onClick={() => scrollToSection(highlightsRef)}>Highlights</button>
                                                        ) : (
                                                            <></>
                                                        ))
                                                    ))
                                                )}
                                                {project?.amenities?.length > 2 && (
                                                    <button className={`btn rounded-0 pb-2 ${activeSection === 'amenities' ? 'active' : ''}`} id="toggle-button-1" onClick={() => scrollToSection(amenitiesRef)}>Amenities</button>
                                                )}
                                                <button className={`btn rounded-0 pb-2 ${activeSection === 'specifications' ? 'active' : ''}`} id="toggle-button-1" onClick={() => scrollToSection(specificationRef)}>Specifications</button>
                                                <button className={`btn rounded-0 pb-2 ${activeSection === 'location' ? 'active' : ''}`} id="toggle-button-3" onClick={() => scrollToSection(locationRef)}>Location</button>
                                                {/* {project.faqs && JSON.parse(project.faqs).length > 0 && (
                                                    JSON.parse(project.faqs)?.map((faq, index) => (
                                                        faq.title && faq.description && (
                                                            (index < 1 ? (
                                                                <button className={`btn rounded-0 pb-2 ${activeSection === 'developers' ? 'active' : ''}`} id="toggle-button-6" onClick={() => scrollToSection(faqRef)}>FAQ</button>
                                                            ) : (
                                                                <></>
                                                            ))
                                                        )
                                                    ))
                                                )} */}
                                                {project.developer?.established_time_stamp !== null && project.developer?.established_time_stamp !== undefined && project.developer?.established_time_stamp !== NaN && project?.developer?.total_ongoing_projects + project?.developer?.total_completed_projects !== 0 &&
                                                    <button className={`btn rounded-0 pb-2 ${activeSection === 'developers' ? 'active' : ''}`} id="toggle-button-3" onClick={() => scrollToSection(developerRef)}>About Developer</button>
                                                }
                                            </div>
                                        </div>

                                        <div className="d-flex flex-column gap-3 pt-4">

                                            <div id="element-5" ref={layoutPlanRef}>
                                                <div className="pdplist-background ">
                                                    <p className="fs-5 text-plp fw-bold m-0">Layout Plan</p>
                                                </div>
                                                <ProjectDetailsLayout projectSlug={project.slug} />
                                            </div>
                                            <div id='element-8' className='mt-5'>
                                                <div className='wishlist-banner'>
                                                    <div className='d-flex align-items-bottom justify-content-around'>
                                                        <div className='d-flex align-items-center wishlist-inner'>
                                                            <img src={InterestedImg} />
                                                            <div className='inner mt-3 ms-5'>
                                                                <h3 className='text-white ms-2'>Interested In This Property?</h3>

                                                                <div id="button-background">
                                                                    <span className="slide-text" style={{ opacity: slideTextOpacity }}>Click To Shortlist!</span>
                                                                    <div
                                                                        id="slider"
                                                                        onMouseDown={handleMouseDown}
                                                                        onTouchStart={handleMouseDown}
                                                                        onMouseUp={handleMouseUp}
                                                                        onTouchEnd={handleMouseUp}
                                                                        // onMouseMove={handleMouseMove}
                                                                        // onTouchMove={handleMouseMove}
                                                                        style={{ left: `${sliderLeft}px` }}
                                                                        ref={sliderRef}
                                                                    >
                                                                        <Icon icon={`${isUnlocked ? 'gravity-ui:check' : 'ep:arrow-right-bold'}`} id="locker" width={"26px"} color='#00208B' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="element-2" ref={propertyDetailsRef}>
                                                <div className="pdplist-background">
                                                    <p className="fs-5 text-plp fw-bold m-0">Property Details</p>
                                                </div>
                                                <div className="d-flex flex-wrap bg-white property-desc" style={{ rowGap: "25px" }}>
                                                    {project.total_buildings && (
                                                        <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                            <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                <span className="text-property">Units</span>
                                                                <span className="mt-2 d-block text-secondary fw-500"><span className="property-detail-bedrooms">{/*{project.total_buildings} Buildings - */} {project.total_units} units</span></span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {project.project_size_readable && (
                                                        <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                            <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                <span className="text-property">Project Area</span>

                                                                <span className="mt-2 d-block text-secondary fw-500"><span className="property-detail-bathrooms">{fixNumberTwoDecimalFromString(project.project_size)} acres</span></span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                        <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                            <span className="text-property">Carpet area</span>

                                                            <span className="mt-2 d-block text-secondary fw-500"><span className="property-detail-carpet"></span>{(getMinMaxCarpets(project).minCarpet).toFixed(0)} - {(getMinMaxCarpets(project).maxCarpet).toFixed(0)} sq.ft.</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                        <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                            <span className="text-property">Configurations</span>

                                                            <span className="mt-2 d-block text-secondary fw-500">
                                                                <span className="property-detail-carpet">
                                                                    {`${uniqueConfigs.length > 0 ? uniqueConfigs.join(', ') + ' ' : ''}`}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    {project?.developer?.name && (
                                                        <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                            <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                <span className="text-property">Developer</span>

                                                                <span className="mt-2 d-block text-secondary fw-500">{project?.developer?.name}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {/* <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                    <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                        <span className="text-property">Project</span>

                                                        <span className="mt-2 d-block text-secondary fw-500"></span>
                                                    </div>
                                                </div> */}
                                                    {/* <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                    <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                        <span className="text-property">Facing</span>

                                                        <span className="mt-2 d-block text-secondary fw-500">East</span>
                                                    </div>
                                                </div> */}

                                                    {project.project_status != '' ? (
                                                        <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                            <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                <span className="text-property">Status</span>

                                                                <span className="mt-2 d-block text-secondary fw-500">
                                                                    {/* {dateDiffInMonths(project.completion_timestamp, new Date()) <= 3 ? 'Ready To Move' : project.project_status == '' ? dateDiffInMonths(project.completion_timestamp, new Date()) > 3 && dateDiffInMonths(project.completion_timestamp, new Date()) < 24 ? 'Under Construction' : 'New Project' : project.project_status} */}
                                                                    {project.project_status ? project.project_status : 'Under Construction'}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                            <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                <span className="text-property">Status</span>

                                                                <span className="mt-2 d-block text-secondary fw-500"> {dateDiffInMonths(project.completion_timestamp, new Date()) <= 3 ? 'Ready To Move' : project.project_status == '' ? dateDiffInMonths(project.completion_timestamp, new Date()) > 3 && dateDiffInMonths(project.completion_timestamp, new Date()) < 24 ? 'Under Construction' : 'New Project' : project.project_status}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {project.possession_date_readable && (
                                                        <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                            <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                <span className="text-property">Possession by</span>

                                                                <span className="mt-2 d-block text-secondary fw-500">{convertShortDate(project.possession_date_readable)}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {project.project_type && (
                                                        <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                            <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                <span className="text-property">Property type</span>

                                                                <span className="mt-2 d-block text-secondary fw-500">{project.project_type}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {(project?.rera_registration_no) && (
                                                        <div className="col-6 col-md-4 d-flex align-items-center justify-content-sm-center">
                                                            <div className="col-xl-9 col-md-10 col-11 pb-2 border-bottom h-100">
                                                                <span className="text-property">RERA ID</span>

                                                                <span className="mt-2 d-block text-secondary fw-500">{(project?.rera_registration_no)?.split(',').join(', ').replace(/"/g, '').replace("[", '').replace("]", '')}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                            </div>
                                            {project.usp && JSON.parse(project.usp).length > 0 && (
                                                JSON.parse(project.usp).map((usp, index) => (
                                                    (index < 1 ? (
                                                        <div id={"element-4"} key={index} ref={highlightsRef}>
                                                            <div className="pdplist-background ">
                                                                <p className="fs-5 text-plp fw-bold m-0">Highlights</p>
                                                            </div>
                                                            <div className="d-flex flex-column flex-md-row bg-white highlight-desc">
                                                                <div className='highligh-img d-none d-md-block'>
                                                                    <div className='inner-wrap'>
                                                                        <img src={highlighesImg} className='img-fluid' />
                                                                    </div>
                                                                </div>
                                                                <div className="">

                                                                    <ol className="highlight-ul row-first d-flex flex-column gap-1 flex-md-row mb-1">
                                                                        {project.usp &&
                                                                            JSON.parse(project.usp)?.map((usp, index) => (
                                                                                index < 2 &&
                                                                                <li key={index} className="text-property fs-6">
                                                                                    <p className="m-0">{usp}</p>
                                                                                </li>
                                                                            ))}
                                                                    </ol>
                                                                    <ol className="highlight-ul row-second  d-flex gap-2 flex-column flex-md-row">
                                                                        {project.usp &&
                                                                            JSON.parse(project.usp)?.map((usp, index) => (
                                                                                index >= 2 && usp != '' &&
                                                                                <li key={index} className="text-property fs-6">
                                                                                    <p className="m-0">{usp}</p>
                                                                                </li>
                                                                            ))}
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                    )
                                                ))
                                            )}
                                            {project?.amenities?.length > 2 && (
                                                <div className='' ref={amenitiesRef}>
                                                    <Accordion defaultActiveKey="0" className='mainSectionAccordion' >
                                                        <Accordion.Item eventKey={0}>
                                                            <Accordion.Header>
                                                                <div className="pdplist-background col-12">
                                                                    <p className="fs-5 text-plp fw-bold m-0">Amenities</p>
                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className="bg-white amenities-desc" style={{ rowGap: "15px" }}>
                                                                    <ul className='row ps-5' style={{ 'listStyle': 'disc' }}>
                                                                        {project.amenities
                                                                            .sort((a, b) => a.name.split(' ').length - b.name.split(' ').length)
                                                                            .map((amenity, index) => (
                                                                                <li key={index} className="col-6 col-md-3 pe-4 ps-0 pt-3" style={{ 'listStyle': 'disc' }}>
                                                                                    <span className="mt-2 d-block text-plp" title={amenity.name.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, c => c.toUpperCase())}>
                                                                                        {amenity.name.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, c => c.toUpperCase())}
                                                                                    </span>
                                                                                </li>
                                                                            ))}
                                                                    </ul>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </div>
                                            )}

                                            {project.specifications && project.specifications !== '[]' && (
                                                <div ref={specificationRef}>
                                                    <Accordion defaultActiveKey="0" className='mainSectionAccordion'>
                                                        <Accordion.Item eventKey={0}>
                                                            <Accordion.Header>
                                                                <div className="pdplist-background col-12" >
                                                                    <p className="fs-5 text-plp fw-bold m-0">Specifications</p>
                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className="d-flex flex-column bg-white specification-desc">
                                                                    <div className="d-flex flex-column gap-3 specification-wrap">
                                                                        <Accordion defaultActiveKey="0">
                                                                            {sortedCombinedData.map((item, index) => (
                                                                                <Accordion.Item key={index} eventKey={index}>
                                                                                    <Accordion.Header>
                                                                                        <span className='text-capitalize'>{item.specification_type.replace(/_/g, ' ').toLowerCase().replace(' specs', '')}</span>
                                                                                    </Accordion.Header>
                                                                                    <Accordion.Body>
                                                                                        <ul className='specification-listing'>
                                                                                            {item.description.split('\n').map((line, lineIndex) => (
                                                                                                <li key={lineIndex}>{line}</li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </Accordion.Body>
                                                                                </Accordion.Item>
                                                                            ))}
                                                                        </Accordion>
                                                                    </div>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </div>
                                            )}
                                            {project?.address?.latitude !== undefined && project?.address?.longitude !== undefined && (
                                                <div id="element-3" ref={locationRef}>
                                                    <div className="pdplist-background">
                                                        <p className="fs-5 text-plp fw-bold m-0">Project Location & Connectivity</p>
                                                    </div>
                                                    <div className='col-12 position-relative mapwrap'>
                                                        {/* <MapContainerLeaflet latitude={position[0]} longitude={position[1]}
                                                            projectname={project.name}
                                                            address1={project.address.address1}
                                                            address2={project.address.address2}
                                                            address3={project.address.address3}
                                                            city={project.address.city}
                                                            height={isMobile ? '250px' : '450px'}
                                                            image={project?.gallery && project.gallery[0] ? `${s3url}/project-assets${project.gallery[0].url}` : defaultGallery}
                                                        /> */}
                                                        <EsriLeafletMap latitude={position[0]} longitude={position[1]} height={'450px'} project={project} />
                                                        {/* <h4 style={{ "position": "absolute", "top": "10px", "left": "10px" }}>{project.name}</h4> */}
                                                    </div>

                                                </div>
                                            )}
                                            {/* {project.faqs && JSON.parse(project.faqs).length > 0 && (
                                                <div id="element-6" ref={faqRef}>
                                                    <div className="pdplist-background">
                                                        <p className="fs-5 text-plp fw-bold m-0">FAQ</p>
                                                    </div>
                                                    <div className="d-flex flex-column bg-white faq-desc">
                                                        <div className="d-flex flex-column gap-3">
                                                            <ol className="highlight-ul d-flex flex-column gap-3">
                                                                {JSON.parse(project.faqs).map((faq, index) => (
                                                                    <li key={index} className="text-property fs-6">
                                                                        <p className="m-0 mb-2 text-secondary fs-6">{faq.question}</p>
                                                                        <p>{faq.answer}</p>
                                                                    </li>
                                                                ))}
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            )} */}
                                            {project.developer?.established_time_stamp !== null && project.developer?.established_time_stamp !== undefined && project.developer?.established_time_stamp !== NaN && project?.developer?.total_ongoing_projects + project?.developer?.total_completed_projects !== 0 &&
                                                <><div id="element-7" ref={developerRef}>
                                                    <div className="pdplist-background">
                                                        <p className="fs-5 text-plp fw-bold m-0">Developer Legacy</p>
                                                    </div>
                                                    <div className='col-12 position-relative'>
                                                        <div className='d-md-flex flex-wrap gap-0 gap-md-5 bg-white developer-desc justify-content-center justify-content-md-between'>
                                                            <div className='developer-logo pe-0 pe-md-5 border-md-end text-md-left text-center'>
                                                                <img src='' alt='' />
                                                                <p>{project?.developer?.name}</p>
                                                            </div>
                                                            <div className='d-md-flex flex-md-row justify-content-md-around text-center gap-0 gap-md-5 flex-md-fill'>
                                                                <div className='developer-stats col-xs-6 mb-4'>
                                                                    <p>{
                                                                        project.developer?.established_time_stamp === null || project.developer?.established_time_stamp === undefined || project.developer?.established_time_stamp === NaN ? 'NA' :
                                                                            toCovertYear(project.developer?.established_time_stamp) == 0 ? 1 : toCovertYear(project.developer?.established_time_stamp)
                                                                    }+</p>
                                                                    <span>Years of experience</span>
                                                                </div>
                                                                <div className='developer-stats col-xs-6 mb-4'>
                                                                    <p>{project?.developer?.total_ongoing_projects + project?.developer?.total_completed_projects}+</p>
                                                                    <span>Total projects </span>
                                                                </div>
                                                                {/* <div className='developer-stats col-xs-6 mb-4'>
                                                                <p>15+</p>
                                                                <span>Sq.Ft delivered</span>
                                                            </div>
                                                            <div className='developer-stats col-xs-6 mb-4'>
                                                                <p>15+</p>
                                                                <span>Customers served</span>
                                                            </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div></>
                                            }
                                            {parsedUserData !== null ? (
                                                <div id="element-9">
                                                    <div className='project-ratting'>
                                                        <div className='d-flex flex-wrap gap-5 bg-white ratting-desc align-items-center'>
                                                            <div className='project-img pe-5'>
                                                                <img
                                                                    src={project?.gallery && project.gallery ? `${s3url}/project-assets${project.gallery[0].url}` : defaultGallery}
                                                                    className="img-fluid"
                                                                    alt={project.name}
                                                                />
                                                                <p className='text-center fw-bold mt-3 mb-0'>{project?.name}</p>
                                                            </div>
                                                            {ratingReviewForm ? (
                                                                <div className='d-flex flex-row justify-content-around text-center gap-5 flex-fill'>
                                                                    <div className='ratting-info text-center'>
                                                                        <h4>Your Opinion matter to us!</h4>
                                                                        <p className='mb-0'>Rate this property</p>
                                                                        <div className='d-flex justify-content-center'>
                                                                            <ProjectRating className="justify-content-center" rating={rating} setRating={setRating} edit={true} />
                                                                            {/* {rating !== null && <p>Your rating: {rating}</p> */}

                                                                        </div>
                                                                        <a onClick={() => headleSubmitReview()} className='mt-3 btn btn-theme rounded-sm fw-bold px-4'>Next</a>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className='d-flex flex-fill fill-review-form flex-column'>
                                                                    <div className='d-flex ratting-info align-items-center justify-content-start'>
                                                                        <h6 className='mb-0 me-3 review-form-title'>What impressed you the most? </h6>
                                                                        <ProjectRatingSet className="" rating={rating} setRating={setRating} size={"30"} edit={true} />
                                                                        {(rating >= 0 && rating < 2) && <img src={rating1} className='ms-2 total-rating-emogi' />}
                                                                        {(rating >= 2 && rating < 3) && <img src={rating2} className='ms-2 total-rating-emogi' />}
                                                                        {(rating >= 3 && rating < 4) && <img src={rating3} className='ms-2 total-rating-emogi' />}
                                                                        {(rating >= 4 && rating < 5) && <img src={rating4} className='ms-2 total-rating-emogi' />}
                                                                        {rating === 5 && <img src={rating5} className='ms-2 total-rating-emogi' />}
                                                                        <p className='mb-0 ms-2'>
                                                                            {(rating >= 0 && rating < 2) && <span>Poor</span>}
                                                                            {(rating >= 2 && rating < 3) && <span>Unsatisfactory</span>}
                                                                            {(rating >= 3 && rating < 4) && <span>Satisfactory</span>}
                                                                            {(rating >= 4 && rating < 5) && <span>Good</span>}
                                                                            {rating === 5 && <span>Excellent</span>}
                                                                        </p>
                                                                    </div>
                                                                    <div className='project-parameter-list'>
                                                                        <div className='d-flex flex-wrap gap-2 mt-3'>
                                                                            <p><input type='checkbox' name='projectParameter' id="location"
                                                                                onChange={handleCheckboxChange} /><label htmlFor='location'>Location</label></p>
                                                                            <p><input type='checkbox' name='projectParameter' id="pricing"
                                                                                onChange={handleCheckboxChange} /><label htmlFor='pricing'>Pricing</label></p>
                                                                            <p><input type='checkbox' name='projectParameter' id="amenities"
                                                                                onChange={handleCheckboxChange} /><label htmlFor='amenities'>Amenities</label></p>
                                                                            <p><input type='checkbox' name='projectParameter' id="brand"
                                                                                onChange={handleCheckboxChange} /><label htmlFor='brand'>Brand</label></p>
                                                                            <p><input type='checkbox' name='projectParameter' id="layout"
                                                                                onChange={handleCheckboxChange} /><label htmlFor='layout'>Layout</label></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='write-review mt-2'>
                                                                        <h6 className='mb-0 me-3 review-form-title text0left'>Write your review</h6>
                                                                        <div className='d-flex gap-3 align-items-end'>
                                                                            <textarea className='form-control' placeholder='Type your review here…' value={reviewText}
                                                                                onChange={handleReviewTextChange}></textarea>
                                                                            <a className='mt-3 px-4' onClick={handleSubmitReview}>Submit</a>
                                                                        </div>
                                                                    </div>
                                                                    <div className='recommend-friend'>
                                                                        <div className='d-flex gap-2'>
                                                                            <input type='checkbox' name="recommend" id="recommend" checked={recommendChecked}
                                                                                onChange={handleRecommendChange} />
                                                                            <label htmlFor='recommend'>I would recommend this property to my friends!</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (<></>)
                                            }
                                        </div>
                                    </div>


                                    <div className="col-3 d-none d-xl-block ">
                                        {project.total_score >= 1 && (
                                            <div className="right-card-one">
                                                <div className='right-card-one-inner position-relative'>
                                                    <div className='inner-content'>
                                                        <img src={bwLogo} />
                                                        <h3>Property <br />Scorecard!</h3>
                                                        {/* <div>
                                                        <a onClick={() => setOpen(true)} className='btn btn-theme rounded-pill fw-700'>Know more</a>
                                                        {open ? <ScorePopup text="Hello there!" closePopup={() => setOpen(false)} /> : null}
                                                    </div> */}
                                                    </div>
                                                    <div className='scorecard position-absolute'>
                                                        <img src={scoreBoard} className='' />
                                                        <p>Your BW Score Is:</p>
                                                        <div className='scoreValue'>
                                                            <CircularProgress percentage={project?.total_score} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {brochureURL && (
                                            <div className='brochure-wrap mt-4'>
                                                <div className='brochure-inner'>
                                                    <div className='d-flex flex-row user-info align-self-center align-items-center p-3'>
                                                        <span className='nameTitle'>
                                                            {parsedUserData && parsedUserData.name ? (
                                                                <span className=''>
                                                                    {parsedUserData.name
                                                                        .split(" ")
                                                                        .map(word => word.charAt(0))
                                                                        .join("")
                                                                        .toUpperCase() // Optionally, convert to uppercase
                                                                    }
                                                                </span>
                                                            ) : (
                                                                <span className=''>
                                                                    G
                                                                </span>

                                                            )}
                                                        </span>
                                                        <div className='user-name-info align-self-center flex-fill ps-3'>
                                                            <p className='mb-0'>Dear <span>{parsedUserData !== null ? parsedUserData.name : 'Guest'}</span></p>
                                                            <span>Your Brochure Is Ready!</span>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-center align-self-center align-items-center py-3 download-btn-info'>
                                                        <span className='happy-face'><Icon icon="system-uicons:face-happy" width="22px" height="22px" color='#fff' /></span>
                                                        <p className='align-self-center mb-0 mx-2'>Take The Next Step</p>
                                                        {parsedUserData !== null ? (
                                                            <a href={`${s3url}/project-assets${brochureURL}`} onClick={() => { setUserDownloadBrochure(project.id) }} target='_blank' className='btn btn-theme rounded-pill fw-700'>
                                                                <span>Download</span>
                                                                <Icon icon="ep:arrow-right-bold" width="1em" height="1em" color='#fff' />
                                                            </a>
                                                        ) : (
                                                            <a onClick={() => [handleNotLogin("Download Brochure"), setUserDownloadBrochure(project.id)]} className='btn btn-theme rounded-pill fw-700'>
                                                                <span>Download </span>
                                                                <Icon icon="ep:arrow-right-bold" width="1em" height="1em" color='#fff' />
                                                            </a>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className='releated-dev-wrap mt-4'>
                                            <div className='releated-dev-inner'>
                                                <h3>Explore Other Top Developers In</h3>
                                                <a href={`${appAbsPath}/projects?minprice=0&searchtype=locality_name&locality_name=${project?.address?.location}`} className='btn btn-theme rounded-pill fw-700'>{project?.address?.location}</a>
                                                <img src={ExploreMore} />
                                            </div>
                                        </div>

                                        <FormEnquiry projectId={projectId} developerId={developerId} projectName={projectName} />




                                    </div>
                                </div>
                            </div>
                        </section>
                        <RelatedProjects project={project} // locationName={project?.address?.location}       
                        slidesToShow={[4,3,2,2,1]}    
                        />

                        {/* <section className='similer-project'>
                            <h2 className='fw-bold mb-3'>You may also like</h2>

                            <div className='row'>
                                <Slider {...settingsForRelatedProject}>
                                    {relatedProject.map((relatedProject, index) => (
                                        <div className='' key={index}>
                                            <Link to={`${appAbsPath}/project/${relatedProject.slug}`} className='text-decoration-none' onClick={() => handlerelatedProj()}>
                                                <div className='card mx-2'>
                                                    <img src={relatedProject.gallery[0] && relatedProject.gallery[0].url !== undefined ? `${s3url}/project-assets${relatedProject.gallery[0].url}` : defaultGallery} className="img-fluid" alt={relatedProject.name} />
                                                    <div className='card-body d-flex align-items-center justify-content-between pb-0'>
                                                        <h5 className='mb-0 text-truncate' title={relatedProject.name}>{relatedProject.name}</h5>
                                                        <p className='mb-0'>{getMinMaxPrice(relatedProject) && getMinMaxPrice(relatedProject).minPrice !== null && formatNumber((getMinMaxPrice(relatedProject).minPrice).toFixed(2)) !== 0
                                                            ? <span>{`₹\u00A0${formatNumber((getMinMaxPrice(relatedProject).minPrice).toFixed(2))} - ${getMinMaxPrice(relatedProject) && getMinMaxPrice(relatedProject).maxPrice !== null ? formatNumber((getMinMaxPrice(relatedProject).maxPrice).toFixed(2)) : ''}`}</span>
                                                            : null
                                                        }</p>
                                                    </div>
                                                    <p className='ps-3 text-truncate'>By {relatedProject.developer.name}</p>
                                                    <div className='card-footer border-0'>
                                                        <p>{relatedProject.locality_name} | {getSortBHKConf(relatedProject)}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </section> */}
                    </div>
                </section>
            </main>
                        </div>
        </>
    );
};

export default PDP;
