// import React from 'react';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { TrackGoogleAnalyticsEvent } from './GoogleAnalytics';

export const appUrl = process.env.REACT_APP_URL;
export const laravelApiUrl = process.env.REACT_APP_LARAVEL_API_URL;
export const laravelFrontApiUrl = process.env.REACT_APP_LARAVEL_FRONT_API_URL;
export const appAbsPath = process.env.REACT_APP_ABSPATH;
export const srcUrl = process.env.REACT_APP_SRCURL;
export const appSrcUrl = process.env.REACT_APP_SRC;
export const s3url = process.env.REACT_APP_IMAGEUPLOADENV === 'spaces' ? process.env.REACT_APP_S3URL : process.env.REACT_APP_URL + '/backend/public/uploads';
export const userToken = localStorage.getItem('bwToken');
export const userSrd = localStorage.getItem('srd');
// export const selectedcity = sessionStorage.getItem('city') || 'pune';
export const buttonLoadingClass = 'btn__dots--loading';

export const add = (a, b) => a + b;
export const subtract = (a, b) => a - b;
export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
export const fetchedFromPortal = { rera: "Rera", Acre99: "Acre99", dwello: "Dwello", custom: "Custom" };

// const TRACKING_ID = "G-N707E6NXV1"; // Live


export const clog = (data, stop = 0, msg = '') => {
    if (msg) { console.log(msg); }
    console.log(data);
    if (stop === 1) { return false; }
}

export const setCookie = (name, value) => {
    Cookies.set(name, value, {
        sameSite: 'strict',
        httpOnly: true,
        secure: true,
    });
};
export const parsedUserData = Cookies.get('userData') ? JSON.parse(Cookies.get('userData')) : null;

export const getCookie = (name) => {
    return Cookies.get(name);
};
//export const userToken = getCookie('bwToken');

export const deleteCookie = (name) => {
    setCookie(name, '', { expires: -1 });
};

export const priceFormat = (price) => {
    const formattedPrice = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(price);
    return formattedPrice;
};

export const cleanedPrice = (price) => {
    const cleanedValue = price.replace(/₹|,/g, '');
    return cleanedValue;
}
export const progressBar = async (setLoading, setProgress) => {
    setLoading(true);
    // Simulating a long-running functionality
    for (let i = 0; i <= 100; i++) {
        setProgress(i);
        await new Promise(resolve => setTimeout(resolve, 1));
    }
    setLoading(false);
};

//Get Logged User Information
export const userData = async () => {
    if (userToken) {
        const loggedUserData = executeLaravelAPI('profile', '', 'GET', userToken);
        return loggedUserData;
    }
    return '';
}

//Show Message once operation is done
export const showToastMessage = (message = '', position = 'top-right', type = 'info') => {
    // Available types: 'info', 'success', 'warning', 'error'
    toast[type](message, {
        position: position,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
};

// Laravel API Execution
export const executeLaravelFrontAPI = async (apiEndPoint, data = '', apiMethod = 'POST') => {

    const updatedJsonData = { ...data };

    // updatedJsonData.city = selectedcity;
    // alert(selectedcity)
    const apiUrl = laravelFrontApiUrl + '/' + apiEndPoint;
    let urlWithParams = apiUrl;
    if (apiMethod === 'GET') {
        urlWithParams = new URL(apiUrl);
        Object.keys(updatedJsonData).forEach(key => urlWithParams.searchParams.append(key, updatedJsonData[key]));
    }
    const requestOptions = {
        method: apiMethod,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    // Check if authToken is available and add it to headers
    // if (authToken) {
    delete requestOptions.headers['Content-Type'];
    // requestOptions.headers['Authorization'] = `Bearer ${authToken}`;
    //}
    // Check if data is available
    if (updatedJsonData && apiMethod !== 'GET') {
        requestOptions.body = JSON.stringify(updatedJsonData);
        // console.log(requestOptions.body);
    }
    const response = await fetch
        (
            urlWithParams,
            requestOptions
        );
    // console.log(response);

    if (response.status === 200) {
        return await response.json();
    } else {
        return '';
    }
};

// Laravel API Execution
export const executeLaravelAPI = async (apiEndPoint, data = '', apiMethod = 'POST', authToken = '') => {
    try {
        const apiUrl = laravelApiUrl + '/' + apiEndPoint;
        let urlWithParams = apiUrl;
        if (apiMethod === 'GET') {
            urlWithParams = new URL(apiUrl);
            Object.keys(data).forEach(key => urlWithParams.searchParams.append(key, data[key]));
        }
        const requestOptions = {
            method: apiMethod,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        // Check if authToken is available and add it to headers
        if (authToken) {
            delete requestOptions.headers['Content-Type'];
            requestOptions.headers['Authorization'] = `Bearer ${authToken}`;
        }
        //console.log(fileUpload);
        // Check if data is available
        if (data && apiMethod !== 'GET') {
            requestOptions.body = JSON.stringify(data);
        }

        const response = await fetch
            (
                urlWithParams,
                requestOptions
            );
        //console.log(requestOptions);
        // console.log(response);
        if (response.status === 200) {
            return await response.json();
        } else {
            return '';
        }
    } catch (error) {
        // Handle any other errors that may occur during the API call
        console.error('API Error 2:', error.message);
        throw error; // Rethrow the error to allow the caller to handle it if needed
    } finally {
        //button.classList.remove('btn__dots--loading', 'clicked');
    }
};

//Convert epoch to human readable date time
export const uploadAssets = async (apiEndPoint, data = '', authToken = '') => {
    const apiUrl = laravelApiUrl + '/' + apiEndPoint;
    const response = await fetch(apiUrl, {
        method: 'POST',
        body: data,
        headers: {
            'Authorization': `Bearer ${authToken}`
        }
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        return '';
    }
};

//Convert epoch to human readable date time
export const convertEpochToReadableTime = (epoch) => {
    const date = new Date(epoch * 1000); // Convert seconds to milliseconds
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
    return date.toLocaleDateString('en-US', options);
};

//Format No
export const formatNumber = (formatNumber) => {
    try {
        const minprice = isNaN(formatNumber) || formatNumber === null || formatNumber === undefined ? 0 : formatNumber;
        const minPriceValue = Number(minprice);
        // if (Array.isArray(jsonArray) && jsonArray.length > 0 && jsonArray[0].price !== undefined) {
        //   const minPriceValue = jsonArray[0].price;
        // console.log(jsonArray[0].price);
        //   if (!isNaN(minPriceValue)) {
        if (minPriceValue < 100000) {
            return minPriceValue; // No formatting needed for numbers less than 100,000
        } else if (minPriceValue < 10000000) {
            return (minPriceValue / 100000).toFixed(2) + ' L';
        } else {
            return (minPriceValue / 10000000).toFixed(2) + ' Cr';
        }
        //   }
        // }
    } catch (error) {
        console.error('Error parsing JSON:', error);
    }

    return '0';
};
//Format No
export const formatNumberThousands = (formatNumber) => {
    try {
        const minprice = isNaN(formatNumber) ? 0 : formatNumber;
        const minPriceValue = JSON.parse(minprice);
        // if (Array.isArray(jsonArray) && jsonArray.length > 0 && jsonArray[0].price !== undefined) {
        //   const minPriceValue = jsonArray[0].price;
        // console.log(jsonArray[0].price);
        //   if (!isNaN(minPriceValue)) {
        if (minPriceValue < 100000) {
            return (minPriceValue / 1000).toFixed(2) + ' K';
            //   return minPriceValue; // No formatting needed for numbers less than 100,000
        } else if (minPriceValue < 10000000) {
            return (minPriceValue / 100000).toFixed(2) + ' L';
        } else {
            return (minPriceValue / 10000000).toFixed(2) + ' Cr';
        }
        //   }
        // }
    } catch (error) {
        console.error('Error parsing JSON:', error);
    }

    return '0';
};

// date difference in months form current date

export const dateDiffInMonths = (date1, date2) => {
    const dt1 = new Date(date1);
    const dt2 = new Date(date2);

    let years = dt1.getFullYear() - dt2.getFullYear();
    let months = dt1.getMonth() - dt2.getMonth();
    let days = dt1.getDate() - dt2.getDate();

    // Adjust months if the days difference is negative
    if (days < 0) {
        months--;
    }

    let diff = years * 12 + months;
    return Math.abs(diff);
};

//Lazy Load Images
export const lazyLoadImages = () => {
    const images = document.querySelectorAll('img[data-src]');
    const lazyImg = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const img = entry.target;
                const src = img.getAttribute('data-src');
                if (src) {
                    img.setAttribute('src', src);
                    img.removeAttribute('data-src');
                    observer.unobserve(img);
                }
            }
        });
    };
    const imgObserver = new IntersectionObserver(lazyImg, {
        root: null,
        threshold: 0,
    })
    images.forEach(image => {
        imgObserver.observe(image);
    });
}

export const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
        {...props}
        className={
            "slick-prev slick-arrow" +
            (currentSlide === 0 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
        type="button"
    >
        Previous
    </button>
);
export const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
        {...props}
        className={
            "slick-next slick-arrow" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
        type="button"
    >
        Next
    </button>
);

export const getSortBHKConf = (project) => {

    // replaced towers with configurations
    // if (project && Array.isArray(project.configurations)) {
    //     // Flatten and filter unique configurations
    //     const parseData = project.configurations;
    //     // const uniqueConfigs = parseData.flatMap(tower =>
    //     //         tower.configurations ? tower.configurations.map(config => config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase()) : []
    //     //     )
    //     //     .filter((value, index, self) => self.indexOf(value) === index)
    //     //     .sort();
    //     const uniqueConfigs = parseData.map(config => config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase()).filter((value, index, self) => self.indexOf(value) === index).sort();
    //         // console.log(uniqueConfigs);

    //     // Remove duplicate "BHK" entries and format configurations
    //     const formattedConfigs = uniqueConfigs.map(config => config.replace(/\s*(BHK)?$/, ''))
    //         .filter((value, index, self) => self.indexOf(value) === index);

    //     // Generate the output string with "and" separator for the last element
    //     const output = formattedConfigs.length > 1
    //         ? formattedConfigs.slice(0, -1).join(', ') + ', ' + formattedConfigs[formattedConfigs.length - 1] + ' BHK'
    //         : formattedConfigs.join(', ') + ' BHK';
    //     return output;
    // } else {
    //     return ''; // Return empty string or handle the non-array case as needed
    // }
    if (project && Array.isArray(project.towers)) {
        const configurationsSet = new Set();
        project.towers.forEach(tower => {
            tower.configurations.forEach(config => {
                const processedName = config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase();
                configurationsSet.add(processedName);
            });
        });
        const formattedConfigs = Array.from(configurationsSet)
            .map(config => config.replace(/\s*(BHK)?$/, ''))
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort();

        const output = formattedConfigs.reduce((acc, config, index, array) => {
            if (index === 0) {
                acc = config; // Start the string with the first configuration
            } else if (index === array.length - 1) {
                // Handle the last configuration based on content
                const digitMatch = config.match(/\d/g); // Get all digit occurrences
                if (digitMatch && digitMatch.length === 1) { // Exactly one digit
                    acc += ', ' + config + ' BHK';
                } else if (digitMatch && digitMatch.length > 1) { // More than one digit
                    acc += ', BHK ' + config;
                } else { // No digits
                    acc += ', ' + config;
                }
            } else {
                acc += ', ' + config; // Add the configuration to the string
            }
            return acc;
        }, '');

        return output;
    } else {
        return '';
    }
};

export const checkFileExtension = (url) => {
    if (url) {
        const allowedImageTypes = ['png', 'jpg', 'jpeg', 'svg', 'webp'];

        // Function to get the file extension from the URL
        const getFileExtension = (url) => {
            return url.split('.').pop().toLowerCase();
        };

        const extension = getFileExtension(url);
        return allowedImageTypes.includes(extension);
    }
    return false;
};

export const getMinMaxPrice = (project) => {
    if (project && Array.isArray(project.towers)) {
        const parseData = project.towers;
        let minPrice = Infinity;
        let maxPrice = -Infinity;

        parseData.forEach(tower => {
            if (tower.configurations) {
                tower.configurations.forEach(config => {
                    const price = Number(config.final_price);
                    if (price < minPrice) {
                        minPrice = price;
                    }
                    if (price > maxPrice) {
                        maxPrice = price;
                    }
                });
            }
        });
        return { minPrice, maxPrice };
    } else {
        return { minPrice: null, maxPrice: null };
    }
};

export const getMinMaxCarpets = (project) => {
    if (project && Array.isArray(project.towers)) {
        const parseData = project.towers;
        let minCarpet = Infinity;
        let maxCarpet = -Infinity;

        parseData.forEach(tower => {
            if (tower.configurations) {
                tower.configurations.forEach(config => {
                    const carpet = Number(config.carpet);
                    if (carpet < minCarpet) {
                        minCarpet = carpet;
                    }
                    if (carpet > maxCarpet) {
                        maxCarpet = carpet;
                    }
                });
            }
        });
        return { minCarpet, maxCarpet };
    } else {
        return { minCarpet: 0, maxCarpet: 0 };
    }
};

export const toCovertYear = (established_time_stamp) => {
    const startDate = new Date(established_time_stamp);
    const today = new Date();
    const timeDiff = today.getTime() - startDate.getTime();
    const yearsDiff = timeDiff / (1000 * 3600 * 24 * 365.25);
    const totalYears = Math.floor(yearsDiff);
    return totalYears;
}

export const convertShortDate = (date) => {
    const longDate = new Date(date);

    // Options for date formatting
    const options = { year: 'numeric', month: 'short' };

    // Convert long date to short format
    return longDate.toLocaleString('en-US', options);
}

export const convertDateTOddmmyyyy = (date) => {
    const longDate = new Date(date);
  
    // Options for date formatting
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
  
    // Convert long date to short format
    return longDate.toLocaleString('en-US', options);
}

export const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// Field Validation functions
export const validateName = (name) => {
    if (!name) return 'This field is required';
    return '';
};

export const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) return 'Invalid email format';
    return '';
};

export const validatePhone = (phone) => {
    const phonePattern = /^\d{10}$/;
    if (!phonePattern.test(phone)) return 'Phone number must be 10 digits';
    return '';
};

export const createGa4Event = (action, category, name) => {
    // gtag('event', action, {
    //     'event_category': category,
    //     'event_label': name
    // });
    name = name.replace(/[^a-zA-Z0-9 ]/g, ' ');
    console.log('Google Analytics Event:', action, category, name);
    TrackGoogleAnalyticsEvent(category, action, name);

};
export const isMobileDevice = () => {
    return window.innerWidth < 768; // Example threshold for mobile
};
export const generateSlug = (text) => {
    return text
        .toString()                   // Convert to string (in case it isn't)
        .normalize('NFD')             // Normalize to decompose accented characters
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
        .toLowerCase()                // Convert to lowercase
        .trim()                       // Trim whitespace
        .replace(/[^a-z0-9 -]/g, '')  // Remove invalid characters
        .replace(/\s+/g, '-')         // Replace spaces with hyphens
        .replace(/-+/g, '-');         // Replace multiple hyphens with a single hyphen
}
export function calculateMonth(status) {
    const timeValue = parseInt(status.match(/\d+/)[0], 10); // Extracts the numerical value
    const timeUnit = status.match(/[a-zA-Z]+/)[0]; // Extracts the unit of time (e.g., "months" or "years")

    if (timeUnit === "months") {
        return timeValue;
    } else if (timeUnit === "years") {
        return timeValue * 12;
    } else {
        throw new Error("Unsupported time unit");
    }
}
export const clearUrlParams = () => {
    const url = window.location.origin + window.location.pathname;
    window.history.replaceState(null, '', url);
};

export const isScreenWidthGreaterThan992 = () => {
    return window.innerWidth > 992;
};

export const isScreenWidthLessThan992 = () => {
    return window.innerWidth <= 992;
};