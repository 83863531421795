import React, { useEffect, useState } from 'react';
import { appAbsPath, executeLaravelFrontAPI, s3url, getSortBHKConf, createGa4Event, formatNumber, getMinMaxPrice, parsedUserData,checkFileExtension } from '../../Admin/Utils';
import { Icon } from '@iconify/react';
import WishListBtn from '../Pages/WishListBtn';
import { Link } from 'react-router-dom';
import defaultGallery from '../../../images/default-corporate-image.jpg';
import ReraImg from '../../../Assets/img/rera.svg';

const handlerelatedProj = (projectSlug) => {
    // window.location.reload();
    window.scrollTo(0, 0);
    // navigate(`/project/${projectSlug}`);
}

const ProjectCard = ({isWishlistActive, project, showWishlist}) => {
    
        return(<>
            <div>
                <div className="top-projects-card">
                    <div className="top-projects-img position-relative">
                        <Link to={`${appAbsPath}/project/${project.slug}`} onClick={() => handlerelatedProj(project.slug)}>
                        {project.gallery && project?.gallery[0]?.url !== undefined && checkFileExtension(project.gallery[0].url) ? (
                            <img src={`${s3url}/project-assets${project.gallery[0].url}`} className="img-fluid" alt={project.name} />
                        ) : (
                            <img src={defaultGallery} className="img-fluid" alt={project.name} />
                        )}
                            
                        </Link>
                        {showWishlist === "yes" &&
                            <WishListBtn isWishlistActives={isWishlistActive} projectId={project.id} color={"#192951"} width={"30px"} height={"30px"} loggeduserdetails={parsedUserData} handleNotLogin />
                        }
                    </div>
                     <Link to={`${appAbsPath}/project/${project.slug}`} className='text-decoration-none' onClick={()=>{createGa4Event('hp_top_projects' , 'hp_top_projects', `${project.name}`)}}>
                    <div className="top-projects-details">
                        <div>
                        <p className='dev-name text-truncate' title={project.developer.name}>{project.developer.name}</p>
                        <h3 className='prj-name col-12'>{project.name}</h3>
                        <p className='conf-desc col-12'><Icon icon="ph:map-pin-bold"  color="#6b6b6b" width="12px" style={{"vertical-align":"baseline"}} /> {getSortBHKConf(project)} Residences in {project?.locality_name}, {project?.address?.city}</p>
                        <p className='rera-desc col-12'><span><img src={ReraImg} alt="map-btn" className='img-fluid' /></span> RERA certificate No. <span className="psn-details">{(project?.rera_registration_no)?.split(',').join(', ').replace(/"/g, '').replace("[", '').replace("]", '')}</span></p>
                        <div className='pricebox'>
                            <p className='text-black'>{getMinMaxPrice(project) && getMinMaxPrice(project).minPrice !== null && formatNumber((getMinMaxPrice(project).minPrice).toFixed(2)) !== 0 
                            ? <span className='project-price'>{`₹${formatNumber((getMinMaxPrice(project).minPrice).toFixed(2))} - ${getMinMaxPrice(project) && getMinMaxPrice(project).maxPrice !== null ? formatNumber((getMinMaxPrice(project).maxPrice).toFixed(2)) : ''}`}</span>
                            : null
                            }
                            </p>
                        <Link to={`${appAbsPath}/project/${project.slug}`} className='explore-btn'><Icon icon="mdi:arrow-right"  /></Link>
                        </div>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
        </>)
}

export default ProjectCard;