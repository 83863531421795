import React, { useState, useEffect, useRef, useContext } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polygon } from 'react-leaflet';
import L from 'leaflet'; // Import Leaflet
// import { Icon } from '@iconify/react';
import 'leaflet/dist/leaflet.css';
// import customMarker from '../../../images/map-pin.svg'; // Import your custom marker image
// import customMarkermobile from '../../../images/map-pin-small.svg'; // Import your custom marker image
import defaultGallery from '../../../images/default-corporate-image.webp';
import { appAbsPath, isMobileDevice, s3url } from './../../Admin/Utils';

const isMobile = isMobileDevice();

const MapContainerLeaflet = ({ projects, latitude, longitude, projectname, address1, address2, address3, city, height, image, locality, setProjectsInBounds }) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState({});
  const [map, setMap] = useState(null);
  console.log("Location: ",locality);
  useEffect(() => {
    // Your useEffect logic here
  }, [projects]); // Make sure to include all dependencies

  const onMarkerClick = (e, location) => {
    setActiveMarker(e.target);
    setSelectedPlace(location);
  };
  const mapRef = React.useRef();
  let latSum = 0;
  let lngSum = 0;
  let count = 0;

  const locations = projects ? projects.map((project) => {
    // Your mapping logic here
    const lat = project.address.latitude ?? '18.5204';
    const lng = project.address.longitude ?? '73.8567';

    latSum += parseFloat(lat);
    lngSum += parseFloat(lng);
    count++;

    return {
      lat,
      lng,
      name: project.name,
      address1: project.address.address1,
      address2: project.address.address2,
      address3: project.address.address3,
      city: project.address.city,
      slug: project.slug,
      image: project?.gallery[0]?.url
    };
  }) : [
    {
      lat: latitude,
      lng: longitude,
      name: projectname,
      address1: address1,
      address2: address2,
      address3: address3,
      city: city,
      image: image
    },
  ];
  let latlong = count > 0 ? [latSum / count, lngSum / count] : [18.49333, 73.85233];
  // const customIcon = L.icon({
  //   iconUrl: isMobile ? customMarkermobile : customMarker,
  //   iconSize: [isMobile ? 25 : 35, isMobile ? 25 : 35], // Set the size of the icon
  //   iconAnchor: [25, 50], // Set the anchor point of the icon
  // });
  const customIcon = L.divIcon({
    className: 'custom-icon',
    html: '<div class="icon-dot"></div>',
    iconSize: [10, 10], // Set the size of the icon
    iconAnchor: [5, 5], // Set the anchor point of the icon
  });
  // Add CSS for the custom icon
  const css = `
  .custom-icon .icon-dot {
    width: 8px;
    height: 8px;
    background-color: #082040;
    border-radius: 50%;
    transition: transform 0.7s ease-in-out;
  }
  .custom-icon:hover .icon-dot {
    // transform: scale(1.2);
    width: 20px;
    height: 20px;
    background-color:rgba(8,32,64,0.5);
    position:relative;
    top:-6px;
    left:-6px;
  }
  .custom-icon:hover .icon-dot:after {
    content:'';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color:rgba(8,32,64,1);
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
  }
  `;
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  head.appendChild(style);
  style.type = 'text/css';
  style.appendChild(document.createTextNode(css));
  
  
  return (
    <MapContainer center={[latitude ? latitude : latlong[0], longitude ? longitude : latlong[1]]} zoom={isMobile ? 13 : latitude ? 10 : 13} style={{ width: '100%', height: height ? height : isMobile ? '100%' : '580px' }} whenCreated={setMap}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {locations.map((location, index) => (
        <Marker
          key={index}
          position={[location.lat, location.lng]}
          icon={customIcon}
          onClick={(e) => onMarkerClick(e, location)}
        >
          <Popup>
            <div className='inner-map-popup' id={location.id}>
            <img 
              src={location.image ? `${s3url}/project-assets${location.image}` : defaultGallery} 
              className='project-img' 
            />
              {/* <div className='lazyload-placeholder project-img' style={{ backgroundImage: `url(${location.image ? location.image : defaultGallery})` }}></div> */}
              <div className='leaflet-popup-content'>
                <h6 className='mb-0'>{location.name}</h6>
                <p className='m-0'>
                  {location.address1}, {location.address2}, {location.address3} {location.city}
                </p>
                {location.slug && <a href={`${appAbsPath}/project/${location.slug}`} className='btn btn-theme btn-sm mt-2'>View Project</a>}
              </div>
            </div>
          </Popup>
           {/* Add polygon overlay for Wakad area */}
        </Marker>
      ))}
    </MapContainer>
  );
}

export default MapContainerLeaflet;
