import React, { useEffect, useState, lazy, Suspense } from 'react';
// import Loader from '../../Loader';
import { appAbsPath, executeLaravelFrontAPI,formatNumber, getMinMaxPrice, getSortBHKConf, s3url, getMinMaxCarpets,toCovertYear, formatNumberThousands, checkFileExtension } from '../../Admin/Utils';
// import ReactPaginate from 'react-paginate';
// import { useLocation } from 'react-router-dom';
import 'rc-slider/assets/index.css';
import { Icon } from '@iconify/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import defaultGallery from '../../../images/default-corporate-image.jpg';
import RelatedProjects from './RecomendedProjects'; 

const ComparisonProjectDetails = ({projectslug }) => {
    
    const [projects, setProject] = useState([]);
    useEffect(() => {
        getProjects(projectslug);
    }, [projectslug]);
    
    const getProjects = async (slugs) => {
        try {
            // console.log(paramsData);   
            // projectslug = {projects:projectslug}
            const result = await executeLaravelFrontAPI('compareprojects', { projects: slugs }, 'GET');
            setProject(result.data);
            console.log(result.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
        }
    }

    const settings = {
        dots: false,
        arrows: projects.length > 3,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow:<Icon icon="ri:arrow-left-s-line"  color="#000" className='prev-arrow' />,
		nextArrow:<Icon icon="ri:arrow-right-s-line"   color="#000" className='next-arrow' />  ,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                arrows:true,
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                infinite: true,
                slidesToShow: 2,
                veriableWidth:true,
                slidesToScroll: 1,
                arrows: projects.length > 2,
              }
            }
          ]        
    };
    
    return (
        <>
            <React.Fragment>
            <main class="comparison pb-3">
                <section class="d-sm-block">
                    <div class="container">
                        <div class="row comparison-left-right">
                            <div class="d-lg-flex flex-column left-details col-3  d-none d-md-block">
                                <div class="d-flex flex-column" style={{ "height": "191px" }}>
                                    {/* <span class="feature-detail">Features</span> */}
                                    <span class="feature-detail my-auto">Property Images</span>
                                </div>
                                <div class="left-desc d-flex flex-column">
                                    <span class="details">Property Name</span>
                                    <span class="details">Price</span>
                                    <span class="details">Avg Price/ Sq.ft</span>
                                    <span class="details">Configuration</span>
                                    <span class="details">Location</span>
                                    <span class="details">Project Size</span>
                                    {/* <span class="details">Number of floors</span> */}
                                    <span class="details">Units</span>
                                    <span class="details">Density</span>
                                    <span class="details">Carpet Area</span>
                                    <span class="details">Amenities</span>
                                    <span class="details">Possession Date</span>
                                    <span class="details">Developer Name</span>
                                    <span class="details">Years of experience</span>
                                </div>
                            </div>
                            <div class="col-12 col-md-9 position-relative">
                                <div class="comparison-slider position-relative">
                                    <Slider {...settings}>
                                    {projects && projects.map((project, index) => (
                                            <div class="comparison-card position-relative" key={index}> 
                                            <div class="right-details">
                                                <div class="position-relative">

                                                    {project.gallery && project.gallery[0]?.url !== undefined && checkFileExtension(project.gallery[0].url) ?
                                                        <img src={`${s3url}/project-assets${project.gallery[0].url}`} className="w-100 comparison-card-img" width="196" height="196" naptha_cursor="text" alt={project.name} />
                                                        :
                                                        <img src={defaultGallery} className="w-100 comparison-card-img" width="196" height="196" naptha_cursor="text" alt={project.name} />
                                                    }
                                                    {/* <img src={project.gallery && project.gallery[0]?.url !== undefined ? `${s3url}/project-assets${project.gallery[0].url}` : defaultGallery} className="w-100 comparison-card-img" width="196" height="196" naptha_cursor="text" alt={project.name} /> */}
                                                    
                                                    <div class="position-absolute green-cross remove-compare-element">
                                                        <img src="/assets/images/green-cross.svg" alt="" />
                                                    </div>
                                                </div>
                                                <div class=" right-desc">
                                                    <h6 class="right-heading m-0" title={project.name}>
                                                        {project.name}
                                                    </h6>
                                                    <div class="text-property details">
                                                        <span class="details d-block d-md-none">Price</span>
                                                        <span class="right-detail">₹ 
                                                        {formatNumber(getMinMaxPrice(project).minPrice)} - ₹ {formatNumber(getMinMaxPrice(project).maxPrice)}
                                                        </span>
                                                    </div>
                                                    <div class="text-property details">
                                                    <span class="details d-block d-md-none">Avg Price/ Sq.ft</span>
                                                        <span class="right-detail">₹ 
                                                        {formatNumber(parseFloat(project.quoted_per_sqft_price).toFixed(0))}
                                                        </span>
                                                    </div>
                                                    
                                                    <div class="text-property details">
                                                    <span class="details d-block d-md-none">Configuration</span>
                                                        <span class="right-detail">
                                                            {getSortBHKConf(project)}
                                                        </span>
                                                    </div>
                                                    <div class="text-property details">
                                                    <span class="details d-block d-md-none">Location</span>
                                                        <span class="right-detail">{project.address.location}</span>
                                                    </div>
                                                    <div class="text-property details">
                                                    <span class="details d-block d-md-none">Project Size</span>
                                                        <span class="right-detail">
                                                            {Number(project.project_size).toFixed(2)} acres
                                                        </span>
                                                    </div>
                                                    {/* <div class="text-property details">
                                                    <span class="details d-block d-md-none">Number of floors</span>
                                                        <span class="right-detail">{project.no_sanctioned_floors}</span>
                                                    </div> */}
                                                    <div class="text-property details">
                                                    <span class="details d-block d-md-none">Units</span>
                                                        <span class="right-detail"> {project.total_units}</span>
                                                    </div>
                                                    <div class="text-property details">
                                                    <span class="details d-block d-md-none">Density</span>
                                                        <span class="right-detail">
                                                            {(project.total_units / project.project_size).toFixed(0)}</span>
                                                    </div>
                                                    <div class="text-property details">
                                                    <span class="details d-block d-md-none">Carpet Area</span>
                                                        <span class="right-detail"> {(getMinMaxCarpets(project).minCarpet).toFixed(0)} - {(getMinMaxCarpets(project).maxCarpet).toFixed(0)} sq.ft.</span>
                                                    </div>
                                                    <div class="text-property details">
                                                    <span class="details d-block d-md-none">Amenities</span>
                                                        <div class="d-flex flex-wrap">
                                                            <p class=" m-0 text-property fs-14">{project.amenities.length}</p>
                                                        </div>
                                                    </div>
                                                    <div class="text-property details">
                                                    <span class="details d-block d-md-none">Possession Date</span>
                                                        <span class="right-detail">{
                                                            project.possession_date_readable === null || project.possession_date_readable === undefined ? 'NA' :
                                                                new Date(project.possession_date_readable).toLocaleString('en-US', { month: 'short', year: 'numeric' })
                                                        }</span>
                                                    </div>
                                                    <div class="text-property details">
                                                    <span class="details d-block d-md-none">Developer Name</span>
                                                        {
                                                            project.developer?.name === null || project.developer?.name === undefined ? 'NA' :
                                                                project.developer?.name
                                                        }
                                                    </div>
                                                    <div class="text-property details">
                                                    <span class="details d-block d-md-none">Years of experience</span>
                                                        {
                                                            project.developer?.established_time_stamp === null || project.developer?.established_time_stamp === undefined ? 'NA' : 
                                                            toCovertYear(project.developer?.established_time_stamp)
                                                        }
                                                    </div>

                                                    
                                                    <div class="pt-4">
                                                        <a class="btn sc w-100" href={`${appAbsPath}/project/${project.slug}`} tabindex="0">VIEW DETAIL</a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                        
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='container'>
                    <div className='row'>
                                      <div className='mt-5'>
                    <RelatedProjects slidesToShow={[3,3,2,2,1]}/>
                </div>  
                    </div>
                </div>
            </main>
            </React.Fragment>
        </>
    )
}
export default ComparisonProjectDetails;