import React, { useEffect, useState, lazy, Suspense} from 'react';
import bwWhite from '../../../../Assets/img/bwwhite.png';
import BannerImg from '../../../../Assets/img/shaniwarwada.png';
import Cloud1 from '../../../../Assets/img/cloud1.png';
import Cloud2 from '../../../../Assets/img/cloud2.png';

const Banner = ({}) => {
    return(<>
        <section className="pt-5">
            <div className='container'>
                <div className='row pune-banner'>
                    <div className='col-5'>
                        <div className='bannercontent'>
                            <img src={bwWhite} className="img-fluid"/>
                            <div className='bnrsubhead'>Explore Localities In Pune</div>
                        </div>
                    </div>
                    <div className='col-7 position-relative'>
                        <img className="img-fluid cloud2" src={Cloud2} alt=""/>
                        <img className="img-fluid cloud1" src={Cloud1} alt=""/>
                        <img src={BannerImg} className="bannerimg"/>
                    </div>
                </div>
            </div>
        </section>
    </>);
};

export default Banner;