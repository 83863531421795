import React, { useEffect, useState, useRef, lazy, useContext, Suspense } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, Polygon } from 'react-leaflet';
import { Carousel, Accordion, Tab, Row, Col, Nav } from 'react-bootstrap';
import 'leaflet/dist/leaflet.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Slider from 'rc-slider';
import { executeLaravelFrontAPI, lazyLoadImages, s3url, formatNumber, parsedUserData, showToastMessage, numberWithCommas, createGa4Event, isMobileDevice, getMinMaxCarpets, dateDiffInMonths, getMinMaxPrice, getSortBHKConf, appAbsPath } from './../../Admin/Utils';
import WishListBtn from './WishListBtn';
import { CompareProjectContext } from '../Layouts/CompareProjectContext';
import defaultGallery from '../../../images/default-corporate-image.webp';
import { Icon } from '@iconify/react';
import NewFilterSidebar from './NewFilterSidebar';
import { debounce } from 'lodash';
import Placeholder from 'react-bootstrap/Placeholder';
import InfiniteScroll from 'react-infinite-scroll-component';
import { param } from 'jquery';

const Loader = lazy(() => import('../../Loader'));

const isMobile = isMobileDevice();
const itemsPerPageOptions = [5, 50, 100, 500, 1000];
let flagPage = false;
let newPage;

// Define coordinates for the polygon (Sus area)
const polygonCoordsSus = [
  [
    18.53869,
    73.74532
  ],
  [
    18.53182,
    73.74546
  ],
  [
    18.53064,
    73.74611
  ],
  [
    18.53097,
    73.74619
  ],
  [
    18.5318,
    73.74629
  ],
  [
    18.53471,
    73.74809
  ],
  [
    18.53491,
    73.74833
  ],
  [
    18.53564,
    73.75066
  ],
  [
    18.53652,
    73.75313
  ],
  [
    18.53744,
    73.75471
  ],
  [
    18.53825,
    73.75595
  ],
  [
    18.53994,
    73.75569
  ],
  [
    18.54142,
    73.75748
  ],
  [
    18.54197,
    73.75903
  ],
  [
    18.54164,
    73.76243
  ],
  [
    18.5414,
    73.76277
  ],
  [
    18.54104,
    73.76314
  ],
  [
    18.54069,
    73.76345
  ],
  [
    18.54094,
    73.7642
  ],
  [
    18.54121,
    73.76489
  ],
  [
    18.54136,
    73.76537
  ],
  [
    18.54145,
    73.76554
  ],
  [
    18.54159,
    73.76575
  ],
  [
    18.54178,
    73.7661
  ],
  [
    18.54242,
    73.76696
  ],
  [
    18.54287,
    73.76749
  ],
  [
    18.54327,
    73.76804
  ],
  [
    18.5439,
    73.76889
  ],
  [
    18.54401,
    73.76905
  ],
  [
    18.54422,
    73.76937
  ],
  [
    18.54426,
    73.7694
  ],
  [
    18.54428,
    73.76941
  ],
  [
    18.54433,
    73.76951
  ],
  [
    18.54446,
    73.76936
  ],
  [
    18.549,
    73.7658
  ],
  [
    18.55365,
    73.76325
  ],
  [
    18.55371,
    73.76305
  ],
  [
    18.55395,
    73.76293
  ],
  [
    18.55427,
    73.76295
  ],
  [
    18.55447,
    73.76307
  ],
  [
    18.55459,
    73.76378
  ],
  [
    18.55506,
    73.76392
  ],
  [
    18.55548,
    73.76399
  ],
  [
    18.55561,
    73.76406
  ],
  [
    18.55575,
    73.76425
  ],
  [
    18.55603,
    73.76436
  ],
  [
    18.55623,
    73.76441
  ],
  [
    18.5571,
    73.76465
  ],
  [
    18.55769,
    73.76454
  ],
  [
    18.55822,
    73.76449
  ],
  [
    18.55854,
    73.76449
  ],
  [
    18.55865,
    73.76449
  ],
  [
    18.55949,
    73.76455
  ],
  [
    18.55996,
    73.76462
  ],
  [
    18.56015,
    73.76462
  ],
  [
    18.5605,
    73.76464
  ],
  [
    18.56103,
    73.76467
  ],
  [
    18.56119,
    73.7637
  ],
  [
    18.5615,
    73.76261
  ],
  [
    18.56172,
    73.7615
  ],
  [
    18.56177,
    73.76129
  ],
  [
    18.56192,
    73.76079
  ],
  [
    18.56199,
    73.76057
  ],
  [
    18.56205,
    73.76023
  ],
  [
    18.56209,
    73.75979
  ],
  [
    18.56212,
    73.75881
  ],
  [
    18.56212,
    73.7584
  ],
  [
    18.56214,
    73.75803
  ],
  [
    18.56235,
    73.75754
  ],
  [
    18.5626,
    73.75692
  ],
  [
    18.56274,
    73.75664
  ],
  [
    18.56303,
    73.75624
  ],
  [
    18.56313,
    73.75582
  ],
  [
    18.56317,
    73.7555
  ],
  [
    18.56314,
    73.75511
  ],
  [
    18.56315,
    73.75464
  ],
  [
    18.56302,
    73.75336
  ],
  [
    18.5636,
    73.73993
  ],
  [
    18.55991,
    73.73706
  ],
  [
    18.55612,
    73.73766
  ],
  [
    18.54889,
    73.73793
  ],
  [
    18.53867,
    73.7453
  ]
];
// Define coordinates for the polygon (Moshi area)
const polygonCoordsMoshi = [
  [
    18.68686,
    73.85851
  ],
  [
    18.68792,
    73.85769
  ],
  [
    18.68999,
    73.85842
  ],
  [
    18.6929,
    73.85883
  ],
  [
    18.69577,
    73.85915
  ],
  [
    18.69622,
    73.85653
  ],
  [
    18.69663,
    73.85378
  ],
  [
    18.69761,
    73.84777
  ],
  [
    18.69383,
    73.84725
  ],
  [
    18.69208,
    73.84631
  ],
  [
    18.68976,
    73.8464
  ],
  [
    18.68952,
    73.84571
  ],
  [
    18.68952,
    73.84434
  ],
  [
    18.68842,
    73.84288
  ],
  [
    18.68834,
    73.8376
  ],
  [
    18.68968,
    73.83357
  ],
  [
    18.67793,
    73.82816
  ],
  [
    18.67671,
    73.83284
  ],
  [
    18.67569,
    73.83258
  ],
  [
    18.67378,
    73.83181
  ],
  [
    18.67163,
    73.83091
  ],
  [
    18.66882,
    73.82992
  ],
  [
    18.6641,
    73.82576
  ],
  [
    18.66348,
    73.83008
  ],
  [
    18.66282,
    73.82954
  ],
  [
    18.66207,
    73.83036
  ],
  [
    18.66096,
    73.83042
  ],
  [
    18.65977,
    73.8299
  ],
  [
    18.65887,
    73.83
  ],
  [
    18.65815,
    73.83061
  ],
  [
    18.658,
    73.83108
  ],
  [
    18.65677,
    73.83312
  ],
  [
    18.65637,
    73.83424
  ],
  [
    18.65766,
    73.83447
  ],
  [
    18.65753,
    73.83474
  ],
  [
    18.657,
    73.8364
  ],
  [
    18.65592,
    73.83846
  ],
  [
    18.65545,
    73.84004
  ],
  [
    18.65281,
    73.84013
  ],
  [
    18.65286,
    73.84322
  ],
  [
    18.6505,
    73.8434
  ],
  [
    18.65064,
    73.84525
  ],
  [
    18.64992,
    73.84542
  ],
  [
    18.64986,
    73.84778
  ],
  [
    18.65054,
    73.8478
  ],
  [
    18.65045,
    73.85017
  ],
  [
    18.64882,
    73.85072
  ],
  [
    18.64775,
    73.85077
  ],
  [
    18.64816,
    73.85538
  ],
  [
    18.64938,
    73.85929
  ],
  [
    18.65088,
    73.86105
  ],
  [
    18.651,
    73.86354
  ],
  [
    18.65035,
    73.86766
  ],
  [
    18.65108,
    73.87204
  ],
  [
    18.65759,
    73.86972
  ],
  [
    18.66576,
    73.87096
  ],
  [
    18.67214,
    73.87075
  ],
  [
    18.67649,
    73.86921
  ],
  [
    18.67743,
    73.8699
  ],
  [
    18.67599,
    73.87524
  ],
  [
    18.67727,
    73.87591
  ],
  [
    18.68195,
    73.87703
  ],
  [
    18.68349,
    73.87499
  ],
  [
    18.68428,
    73.87244
  ],
  [
    18.68389,
    73.87032
  ],
  [
    18.68379,
    73.86944
  ],
  [
    18.68368,
    73.86713
  ],
  [
    18.6821,
    73.8669
  ],
  [
    18.68234,
    73.86334
  ],
  [
    18.68283,
    73.85896
  ],
  [
    18.68669,
    73.85943
  ],
  [
    18.68685,
    73.85853
  ]
];

// Define coordinates for the polygon (NIBM area)
const polygonCoordsNIBM = [
  [
    18.45926,
    73.90726
  ],
  [
    18.46455,
    73.91129
  ],
  [
    18.4687,
    73.91326
  ],
  [
    18.47293,
    73.91395
  ],
  [
    18.47724,
    73.91206
  ],
  [
    18.48147,
    73.90854
  ],
  [
    18.48318,
    73.90433
  ],
  [
    18.4844,
    73.90004
  ],
  [
    18.48277,
    73.89403
  ],
  [
    18.47886,
    73.88905
  ],
  [
    18.473,
    73.88708
  ],
  [
    18.47086,
    73.8874
  ],
  [
    18.46935,
    73.88761
  ],
  [
    18.46899,
    73.89227
  ],
  [
    18.46759,
    73.88784
  ],
  [
    18.46646,
    73.88796
  ],
  [
    18.4642,
    73.8882
  ],
  [
    18.46168,
    73.88974
  ],
  [
    18.45867,
    73.8942
  ],
  [
    18.4572,
    73.90047
  ],
  [
    18.45924,
    73.90725
  ]
];

// Define coordinates for the polygon (Bavdhan area)
const polygonCoordsBavdhan = [
  [
    18.50754,
    73.78353
  ],
  [
    18.50809,
    73.78364
  ],
  [
    18.50901,
    73.78402
  ],
  [
    18.51091,
    73.7852
  ],
  [
    18.51175,
    73.78566
  ],
  [
    18.51376,
    73.78543
  ],
  [
    18.51544,
    73.785
  ],
  [
    18.51696,
    73.78397
  ],
  [
    18.51777,
    73.78355
  ],
  [
    18.51875,
    73.78304
  ],
  [
    18.51971,
    73.783
  ],
  [
    18.52016,
    73.78298
  ],
  [
    18.52222,
    73.78325
  ],
  [
    18.52358,
    73.78358
  ],
  [
    18.52434,
    73.78336
  ],
  [
    18.52573,
    73.78155
  ],
  [
    18.52574,
    73.78156
  ],
  [
    18.52579,
    73.78147
  ],
  [
    18.52606,
    73.78104
  ],
  [
    18.5261,
    73.78098
  ],
  [
    18.52632,
    73.78064
  ],
  [
    18.52745,
    73.77895
  ],
  [
    18.52754,
    73.77882
  ],
  [
    18.52825,
    73.77772
  ],
  [
    18.52824,
    73.77771
  ],
  [
    18.52789,
    73.7773
  ],
  [
    18.52758,
    73.77685
  ],
  [
    18.52729,
    73.77625
  ],
  [
    18.52669,
    73.77466
  ],
  [
    18.52653,
    73.77424
  ],
  [
    18.52637,
    73.77383
  ],
  [
    18.52616,
    73.7734
  ],
  [
    18.52596,
    73.77305
  ],
  [
    18.52576,
    73.77278
  ],
  [
    18.52604,
    73.77155
  ],
  [
    18.52672,
    73.76831
  ],
  [
    18.52694,
    73.76651
  ],
  [
    18.52727,
    73.76055
  ],
  [
    18.52649,
    73.75922
  ],
  [
    18.52387,
    73.75804
  ],
  [
    18.52346,
    73.75967
  ],
  [
    18.52228,
    73.7603
  ],
  [
    18.51995,
    73.76102
  ],
  [
    18.51637,
    73.76189
  ],
  [
    18.51441,
    73.76217
  ],
  [
    18.51422,
    73.76002
  ],
  [
    18.51008,
    73.7582
  ],
  [
    18.50883,
    73.75853
  ],
  [
    18.50685,
    73.76042
  ],
  [
    18.5056,
    73.76159
  ],
  [
    18.50423,
    73.76357
  ],
  [
    18.50316,
    73.76355
  ],
  [
    18.50199,
    73.76339
  ],
  [
    18.5008,
    73.76264
  ],
  [
    18.49979,
    73.76162
  ],
  [
    18.4973,
    73.75943
  ],
  [
    18.49589,
    73.7581
  ],
  [
    18.49497,
    73.75741
  ],
  [
    18.49366,
    73.75612
  ],
  [
    18.49275,
    73.75532
  ],
  [
    18.48102,
    73.77553
  ],
  [
    18.48514,
    73.77804
  ],
  [
    18.48538,
    73.77825
  ],
  [
    18.48569,
    73.77859
  ],
  [
    18.48595,
    73.77882
  ],
  [
    18.48606,
    73.7789
  ],
  [
    18.48613,
    73.77895
  ],
  [
    18.48634,
    73.77903
  ],
  [
    18.48666,
    73.77912
  ],
  [
    18.48674,
    73.77913
  ],
  [
    18.4871,
    73.77915
  ],
  [
    18.48737,
    73.77917
  ],
  [
    18.48767,
    73.77919
  ],
  [
    18.48828,
    73.77927
  ],
  [
    18.48899,
    73.77934
  ],
  [
    18.48994,
    73.77944
  ],
  [
    18.49019,
    73.77948
  ],
  [
    18.49044,
    73.77955
  ],
  [
    18.49066,
    73.77969
  ],
  [
    18.49077,
    73.7798
  ],
  [
    18.49102,
    73.78005
  ],
  [
    18.49105,
    73.78008
  ],
  [
    18.49143,
    73.7805
  ],
  [
    18.49145,
    73.78052
  ],
  [
    18.49181,
    73.78088
  ],
  [
    18.49199,
    73.78102
  ],
  [
    18.4921,
    73.78109
  ],
  [
    18.49225,
    73.7811
  ],
  [
    18.49241,
    73.78109
  ],
  [
    18.49268,
    73.78099
  ],
  [
    18.49294,
    73.78089
  ],
  [
    18.49353,
    73.78064
  ],
  [
    18.49414,
    73.7804
  ],
  [
    18.49434,
    73.78034
  ],
  [
    18.49449,
    73.78032
  ],
  [
    18.49468,
    73.78033
  ],
  [
    18.4948,
    73.78035
  ],
  [
    18.49498,
    73.78046
  ],
  [
    18.49524,
    73.78072
  ],
  [
    18.49552,
    73.78113
  ],
  [
    18.49556,
    73.7812
  ],
  [
    18.49596,
    73.78183
  ],
  [
    18.49662,
    73.78287
  ],
  [
    18.49721,
    73.78379
  ],
  [
    18.49757,
    73.78431
  ],
  [
    18.49768,
    73.78444
  ],
  [
    18.49784,
    73.78457
  ],
  [
    18.49811,
    73.78471
  ],
  [
    18.49845,
    73.78481
  ],
  [
    18.49869,
    73.78485
  ],
  [
    18.49875,
    73.78486
  ],
  [
    18.4993,
    73.78496
  ],
  [
    18.49979,
    73.78505
  ],
  [
    18.50051,
    73.78517
  ],
  [
    18.50097,
    73.78524
  ],
  [
    18.50127,
    73.78534
  ],
  [
    18.50149,
    73.78553
  ],
  [
    18.5016,
    73.78567
  ],
  [
    18.50168,
    73.78583
  ],
  [
    18.50193,
    73.78645
  ],
  [
    18.50211,
    73.78692
  ],
  [
    18.50225,
    73.78725
  ],
  [
    18.50235,
    73.78746
  ],
  [
    18.50241,
    73.78756
  ],
  [
    18.50245,
    73.78763
  ],
  [
    18.50252,
    73.78771
  ],
  [
    18.5026,
    73.78778
  ],
  [
    18.5027,
    73.78787
  ],
  [
    18.50279,
    73.78793
  ],
  [
    18.5028,
    73.78793
  ],
  [
    18.50299,
    73.788
  ],
  [
    18.50357,
    73.78801
  ],
  [
    18.50413,
    73.78796
  ],
  [
    18.50432,
    73.7879
  ],
  [
    18.50441,
    73.78785
  ],
  [
    18.50448,
    73.78779
  ],
  [
    18.50456,
    73.78772
  ],
  [
    18.50466,
    73.78762
  ],
  [
    18.50474,
    73.78748
  ],
  [
    18.50479,
    73.78736
  ],
  [
    18.50485,
    73.78715
  ],
  [
    18.50491,
    73.78685
  ],
  [
    18.50504,
    73.78628
  ],
  [
    18.50508,
    73.78615
  ],
  [
    18.50513,
    73.78594
  ],
  [
    18.50514,
    73.78585
  ],
  [
    18.50514,
    73.78575
  ],
  [
    18.50516,
    73.78565
  ],
  [
    18.50521,
    73.78556
  ],
  [
    18.50526,
    73.78545
  ],
  [
    18.50531,
    73.78536
  ],
  [
    18.50533,
    73.78471
  ],
  [
    18.50598,
    73.78306
  ],
  [
    18.50662,
    73.78249
  ],
  [
    18.50668,
    73.78259
  ],
  [
    18.50678,
    73.7828
  ],
  [
    18.50689,
    73.783
  ],
  [
    18.50691,
    73.78307
  ],
  [
    18.50695,
    73.78322
  ],
  [
    18.50699,
    73.7834
  ],
  [
    18.50708,
    73.78381
  ],
  [
    18.50693,
    73.78402
  ],
  [
    18.50688,
    73.78419
  ],
  [
    18.50682,
    73.78436
  ],
  [
    18.50678,
    73.78448
  ],
  [
    18.50674,
    73.78459
  ],
  [
    18.50672,
    73.78467
  ],
  [
    18.50671,
    73.78474
  ],
  [
    18.50672,
    73.78481
  ],
  [
    18.50675,
    73.7849
  ],
  [
    18.50691,
    73.78522
  ],
  [
    18.50695,
    73.7853
  ],
  [
    18.50698,
    73.78531
  ],
  [
    18.50701,
    73.78531
  ],
  [
    18.50706,
    73.7853
  ],
  [
    18.50711,
    73.78528
  ],
  [
    18.50716,
    73.78524
  ],
  [
    18.50719,
    73.7852
  ],
  [
    18.50722,
    73.78515
  ],
  [
    18.50721,
    73.78486
  ],
  [
    18.50725,
    73.78391
  ],
  [
    18.50726,
    73.78369
  ],
  [
    18.50751,
    73.78352
  ]
];
// Define coordinates for the polygon (Tathawade area)
const polygonCoordsTathawade = [
  [
    18.63331,
    73.7469
  ],
  [
    18.63331,
    73.7469
  ],
  [
    18.63315,
    73.74616
  ],
  [
    18.63168,
    73.74306
  ],
  [
    18.63076,
    73.7422
  ],
  [
    18.62606,
    73.73828
  ],
  [
    18.62429,
    73.73695
  ],
  [
    18.62268,
    73.73568
  ],
  [
    18.6192,
    73.73321
  ],
  [
    18.61187,
    73.72833
  ],
  [
    18.60864,
    73.73374
  ],
  [
    18.60655,
    73.73629
  ],
  [
    18.60485,
    73.73828
  ],
  [
    18.60532,
    73.74301
  ],
  [
    18.60614,
    73.74794
  ],
  [
    18.60656,
    73.7506
  ],
  [
    18.60672,
    73.75174
  ],
  [
    18.6065,
    73.75182
  ],
  [
    18.60544,
    73.75218
  ],
  [
    18.60558,
    73.75233
  ],
  [
    18.60587,
    73.75267
  ],
  [
    18.60615,
    73.75313
  ],
  [
    18.60645,
    73.7539
  ],
  [
    18.60669,
    73.75458
  ],
  [
    18.60699,
    73.7554
  ],
  [
    18.60707,
    73.75561
  ],
  [
    18.6074,
    73.75598
  ],
  [
    18.60787,
    73.7565
  ],
  [
    18.60821,
    73.75683
  ],
  [
    18.60918,
    73.75787
  ],
  [
    18.61013,
    73.75916
  ],
  [
    18.61042,
    73.75956
  ],
  [
    18.61088,
    73.76019
  ],
  [
    18.61129,
    73.76077
  ],
  [
    18.61148,
    73.76101
  ],
  [
    18.61165,
    73.76123
  ],
  [
    18.61179,
    73.76141
  ],
  [
    18.61205,
    73.76176
  ],
  [
    18.61289,
    73.76076
  ],
  [
    18.61691,
    73.75602
  ],
  [
    18.61999,
    73.75779
  ],
  [
    18.62243,
    73.75979
  ],
  [
    18.62626,
    73.76243
  ],
  [
    18.62704,
    73.76077
  ],
  [
    18.62738,
    73.76024
  ],
  [
    18.6281,
    73.75873
  ],
  [
    18.62866,
    73.7576
  ],
  [
    18.62894,
    73.75721
  ],
  [
    18.62928,
    73.75674
  ],
  [
    18.62946,
    73.75649
  ],
  [
    18.62987,
    73.75582
  ],
  [
    18.63032,
    73.75481
  ],
  [
    18.6308,
    73.75415
  ],
  [
    18.6311,
    73.75391
  ],
  [
    18.63203,
    73.75351
  ],
  [
    18.63327,
    73.7536
  ],
  [
    18.63475,
    73.75378
  ],
  [
    18.63494,
    73.7538
  ],
  [
    18.63491,
    73.75167
  ],
  [
    18.6344,
    73.7478
  ],
  [
    18.63425,
    73.74663
  ],
  [
    18.6333,
    73.74688
  ]
];
// Define coordinates for the polygon (Punawale area)
const polygonCoordsPunawale = [
  [
    18.6361,
    73.75391
  ],
  [
    18.63793,
    73.7539
  ],
  [
    18.63844,
    73.7539
  ],
  [
    18.6392,
    73.75388
  ],
  [
    18.63941,
    73.75387
  ],
  [
    18.63997,
    73.75399
  ],
  [
    18.64035,
    73.75396
  ],
  [
    18.64088,
    73.75386
  ],
  [
    18.6412,
    73.7536
  ],
  [
    18.64143,
    73.75322
  ],
  [
    18.64149,
    73.75209
  ],
  [
    18.64136,
    73.75096
  ],
  [
    18.6412,
    73.75006
  ],
  [
    18.64106,
    73.75003
  ],
  [
    18.64067,
    73.74943
  ],
  [
    18.64041,
    73.74912
  ],
  [
    18.63976,
    73.74865
  ],
  [
    18.63964,
    73.74777
  ],
  [
    18.63969,
    73.74759
  ],
  [
    18.63979,
    73.74615
  ],
  [
    18.64005,
    73.74469
  ],
  [
    18.64039,
    73.74376
  ],
  [
    18.6407,
    73.74299
  ],
  [
    18.64077,
    73.74281
  ],
  [
    18.64147,
    73.7418
  ],
  [
    18.64266,
    73.74083
  ],
  [
    18.64278,
    73.74073
  ],
  [
    18.64361,
    73.73987
  ],
  [
    18.64462,
    73.73896
  ],
  [
    18.64481,
    73.73859
  ],
  [
    18.6448,
    73.73792
  ],
  [
    18.6414,
    73.73569
  ],
  [
    18.64186,
    73.73294
  ],
  [
    18.64216,
    73.73228
  ],
  [
    18.6397,
    73.73016
  ],
  [
    18.63697,
    73.72927
  ],
  [
    18.63398,
    73.72786
  ],
  [
    18.62137,
    73.72284
  ],
  [
    18.6197,
    73.72782
  ],
  [
    18.6182,
    73.73155
  ],
  [
    18.62164,
    73.73491
  ],
  [
    18.62298,
    73.73586
  ],
  [
    18.62431,
    73.73694
  ],
  [
    18.62609,
    73.73828
  ],
  [
    18.62789,
    73.73976
  ],
  [
    18.63048,
    73.74193
  ],
  [
    18.63169,
    73.74306
  ],
  [
    18.63316,
    73.74616
  ],
  [
    18.63332,
    73.7469
  ],
  [
    18.63332,
    73.7469
  ],
  [
    18.63427,
    73.74665
  ],
  [
    18.63442,
    73.74782
  ],
  [
    18.63493,
    73.75169
  ],
  [
    18.63496,
    73.75382
  ],
  [
    18.63611,
    73.75392
  ]
];

// Define coordinates for the polygon (Kothrud area)
const polygonCoordsKothrud = [
  [
    18.50639,
    73.82673
  ],
  [
    18.50757,
    73.82621
  ],
  [
    18.50882,
    73.82556
  ],
  [
    18.51083,
    73.82442
  ],
  [
    18.51173,
    73.82391
  ],
  [
    18.51205,
    73.82388
  ],
  [
    18.51343,
    73.82377
  ],
  [
    18.51519,
    73.82407
  ],
  [
    18.51595,
    73.82448
  ],
  [
    18.5164,
    73.82256
  ],
  [
    18.51716,
    73.8223
  ],
  [
    18.51798,
    73.82203
  ],
  [
    18.51835,
    73.82194
  ],
  [
    18.51879,
    73.82188
  ],
  [
    18.51907,
    73.82183
  ],
  [
    18.5194,
    73.82192
  ],
  [
    18.51982,
    73.82222
  ],
  [
    18.5201,
    73.82245
  ],
  [
    18.52029,
    73.82262
  ],
  [
    18.52038,
    73.82251
  ],
  [
    18.52059,
    73.82195
  ],
  [
    18.52087,
    73.82098
  ],
  [
    18.52111,
    73.82025
  ],
  [
    18.52133,
    73.81976
  ],
  [
    18.5216,
    73.81936
  ],
  [
    18.52249,
    73.81814
  ],
  [
    18.52308,
    73.81714
  ],
  [
    18.52314,
    73.81705
  ],
  [
    18.52335,
    73.8167
  ],
  [
    18.52335,
    73.81659
  ],
  [
    18.52345,
    73.81657
  ],
  [
    18.52352,
    73.81652
  ],
  [
    18.52366,
    73.81642
  ],
  [
    18.52376,
    73.81637
  ],
  [
    18.52419,
    73.81619
  ],
  [
    18.52434,
    73.81612
  ],
  [
    18.52441,
    73.81608
  ],
  [
    18.52444,
    73.81605
  ],
  [
    18.52447,
    73.81601
  ],
  [
    18.52451,
    73.81598
  ],
  [
    18.52456,
    73.81595
  ],
  [
    18.52461,
    73.81592
  ],
  [
    18.52464,
    73.81591
  ],
  [
    18.52468,
    73.8159
  ],
  [
    18.52472,
    73.81587
  ],
  [
    18.52476,
    73.81584
  ],
  [
    18.52478,
    73.81583
  ],
  [
    18.52481,
    73.81581
  ],
  [
    18.52484,
    73.81578
  ],
  [
    18.52486,
    73.81577
  ],
  [
    18.52489,
    73.81576
  ],
  [
    18.52492,
    73.81576
  ],
  [
    18.52496,
    73.81574
  ],
  [
    18.52504,
    73.81569
  ],
  [
    18.52521,
    73.81557
  ],
  [
    18.52533,
    73.8155
  ],
  [
    18.52542,
    73.81544
  ],
  [
    18.52514,
    73.81498
  ],
  [
    18.5243,
    73.81354
  ],
  [
    18.52421,
    73.81307
  ],
  [
    18.52392,
    73.81148
  ],
  [
    18.52388,
    73.80934
  ],
  [
    18.5235,
    73.80924
  ],
  [
    18.5229,
    73.80908
  ],
  [
    18.52222,
    73.8084
  ],
  [
    18.52143,
    73.80669
  ],
  [
    18.52137,
    73.8058
  ],
  [
    18.52132,
    73.80504
  ],
  [
    18.52148,
    73.80314
  ],
  [
    18.52088,
    73.80073
  ],
  [
    18.52001,
    73.79997
  ],
  [
    18.51765,
    73.79902
  ],
  [
    18.51507,
    73.79861
  ],
  [
    18.51233,
    73.79858
  ],
  [
    18.51189,
    73.79862
  ],
  [
    18.51124,
    73.79869
  ],
  [
    18.51143,
    73.79822
  ],
  [
    18.51167,
    73.79592
  ],
  [
    18.51251,
    73.79422
  ],
  [
    18.51315,
    73.79252
  ],
  [
    18.51382,
    73.7911
  ],
  [
    18.51446,
    73.78929
  ],
  [
    18.51446,
    73.78752
  ],
  [
    18.51446,
    73.78739
  ],
  [
    18.51398,
    73.78693
  ],
  [
    18.51318,
    73.78644
  ],
  [
    18.51174,
    73.78561
  ],
  [
    18.5109,
    73.78515
  ],
  [
    18.509,
    73.78397
  ],
  [
    18.50808,
    73.78359
  ],
  [
    18.50753,
    73.78348
  ],
  [
    18.50728,
    73.78365
  ],
  [
    18.50727,
    73.78387
  ],
  [
    18.50723,
    73.78482
  ],
  [
    18.50724,
    73.78511
  ],
  [
    18.50721,
    73.78516
  ],
  [
    18.50718,
    73.7852
  ],
  [
    18.50713,
    73.78524
  ],
  [
    18.50708,
    73.78526
  ],
  [
    18.50703,
    73.78527
  ],
  [
    18.507,
    73.78527
  ],
  [
    18.50697,
    73.78526
  ],
  [
    18.50693,
    73.78518
  ],
  [
    18.50677,
    73.78486
  ],
  [
    18.50674,
    73.78477
  ],
  [
    18.50673,
    73.7847
  ],
  [
    18.50674,
    73.78463
  ],
  [
    18.50676,
    73.78455
  ],
  [
    18.5068,
    73.78444
  ],
  [
    18.50684,
    73.78432
  ],
  [
    18.5069,
    73.78415
  ],
  [
    18.50695,
    73.78398
  ],
  [
    18.5071,
    73.78377
  ],
  [
    18.50701,
    73.78336
  ],
  [
    18.50697,
    73.78318
  ],
  [
    18.50693,
    73.78303
  ],
  [
    18.50691,
    73.78296
  ],
  [
    18.5068,
    73.78276
  ],
  [
    18.5067,
    73.78255
  ],
  [
    18.50664,
    73.78245
  ],
  [
    18.506,
    73.78302
  ],
  [
    18.50535,
    73.78467
  ],
  [
    18.50533,
    73.78532
  ],
  [
    18.50528,
    73.78541
  ],
  [
    18.50523,
    73.78552
  ],
  [
    18.50518,
    73.78561
  ],
  [
    18.50516,
    73.78571
  ],
  [
    18.50516,
    73.78581
  ],
  [
    18.50515,
    73.7859
  ],
  [
    18.5051,
    73.78611
  ],
  [
    18.50506,
    73.78624
  ],
  [
    18.50493,
    73.78681
  ],
  [
    18.50487,
    73.78711
  ],
  [
    18.50481,
    73.78732
  ],
  [
    18.50476,
    73.78744
  ],
  [
    18.50468,
    73.78758
  ],
  [
    18.50458,
    73.78768
  ],
  [
    18.5045,
    73.78775
  ],
  [
    18.50443,
    73.78781
  ],
  [
    18.50434,
    73.78786
  ],
  [
    18.50415,
    73.78792
  ],
  [
    18.50359,
    73.78797
  ],
  [
    18.50301,
    73.78796
  ],
  [
    18.50282,
    73.78789
  ],
  [
    18.50281,
    73.78789
  ],
  [
    18.50272,
    73.78783
  ],
  [
    18.50262,
    73.78774
  ],
  [
    18.50254,
    73.78767
  ],
  [
    18.50247,
    73.78759
  ],
  [
    18.50243,
    73.78752
  ],
  [
    18.50237,
    73.78742
  ],
  [
    18.50227,
    73.78721
  ],
  [
    18.50213,
    73.78688
  ],
  [
    18.50195,
    73.78641
  ],
  [
    18.5017,
    73.78579
  ],
  [
    18.50162,
    73.78563
  ],
  [
    18.50151,
    73.78549
  ],
  [
    18.50129,
    73.7853
  ],
  [
    18.50099,
    73.7852
  ],
  [
    18.50053,
    73.78513
  ],
  [
    18.49981,
    73.78501
  ],
  [
    18.49932,
    73.78492
  ],
  [
    18.49877,
    73.78482
  ],
  [
    18.49871,
    73.78481
  ],
  [
    18.49847,
    73.78477
  ],
  [
    18.49813,
    73.78467
  ],
  [
    18.49786,
    73.78453
  ],
  [
    18.49758,
    73.78511
  ],
  [
    18.49682,
    73.78673
  ],
  [
    18.49625,
    73.78785
  ],
  [
    18.49626,
    73.78791
  ],
  [
    18.49643,
    73.78795
  ],
  [
    18.49698,
    73.7882
  ],
  [
    18.49744,
    73.7885
  ],
  [
    18.4979,
    73.78884
  ],
  [
    18.49822,
    73.78916
  ],
  [
    18.49793,
    73.78923
  ],
  [
    18.49738,
    73.78984
  ],
  [
    18.4968,
    73.79028
  ],
  [
    18.49441,
    73.79413
  ],
  [
    18.49297,
    73.79645
  ],
  [
    18.4926,
    73.79697
  ],
  [
    18.4925,
    73.79721
  ],
  [
    18.49237,
    73.79777
  ],
  [
    18.49233,
    73.79798
  ],
  [
    18.49229,
    73.79805
  ],
  [
    18.49227,
    73.79815
  ],
  [
    18.49227,
    73.79825
  ],
  [
    18.49228,
    73.79836
  ],
  [
    18.4923,
    73.79846
  ],
  [
    18.49234,
    73.79861
  ],
  [
    18.49234,
    73.79871
  ],
  [
    18.49234,
    73.79876
  ],
  [
    18.49234,
    73.7988
  ],
  [
    18.49231,
    73.79897
  ],
  [
    18.4923,
    73.79918
  ],
  [
    18.49224,
    73.79955
  ],
  [
    18.49223,
    73.79965
  ],
  [
    18.49221,
    73.79986
  ],
  [
    18.49216,
    73.79997
  ],
  [
    18.49211,
    73.80002
  ],
  [
    18.49204,
    73.80004
  ],
  [
    18.49193,
    73.80004
  ],
  [
    18.49171,
    73.8
  ],
  [
    18.49158,
    73.79997
  ],
  [
    18.49151,
    73.79998
  ],
  [
    18.49149,
    73.80004
  ],
  [
    18.4915,
    73.80011
  ],
  [
    18.49158,
    73.80022
  ],
  [
    18.49165,
    73.80042
  ],
  [
    18.49166,
    73.80051
  ],
  [
    18.49166,
    73.80064
  ],
  [
    18.49165,
    73.80075
  ],
  [
    18.49161,
    73.8009
  ],
  [
    18.49155,
    73.80104
  ],
  [
    18.4915,
    73.8011
  ],
  [
    18.49146,
    73.80116
  ],
  [
    18.4914,
    73.80121
  ],
  [
    18.49135,
    73.80122
  ],
  [
    18.4913,
    73.80122
  ],
  [
    18.49125,
    73.8012
  ],
  [
    18.49117,
    73.80117
  ],
  [
    18.49108,
    73.80114
  ],
  [
    18.49078,
    73.8011
  ],
  [
    18.49062,
    73.80113
  ],
  [
    18.49051,
    73.80122
  ],
  [
    18.49042,
    73.8013
  ],
  [
    18.49037,
    73.80147
  ],
  [
    18.49032,
    73.80157
  ],
  [
    18.49027,
    73.80173
  ],
  [
    18.49016,
    73.8019
  ],
  [
    18.48996,
    73.80212
  ],
  [
    18.48989,
    73.80219
  ],
  [
    18.4898,
    73.80229
  ],
  [
    18.48973,
    73.80246
  ],
  [
    18.48968,
    73.80268
  ],
  [
    18.48958,
    73.803
  ],
  [
    18.48951,
    73.8032
  ],
  [
    18.48943,
    73.80344
  ],
  [
    18.48938,
    73.80364
  ],
  [
    18.48938,
    73.8038
  ],
  [
    18.48938,
    73.80388
  ],
  [
    18.48937,
    73.804
  ],
  [
    18.48934,
    73.80411
  ],
  [
    18.48927,
    73.80433
  ],
  [
    18.48926,
    73.8045
  ],
  [
    18.48927,
    73.80458
  ],
  [
    18.48931,
    73.80472
  ],
  [
    18.48932,
    73.80481
  ],
  [
    18.48932,
    73.80495
  ],
  [
    18.48932,
    73.80507
  ],
  [
    18.48935,
    73.80518
  ],
  [
    18.48939,
    73.80528
  ],
  [
    18.4895,
    73.80542
  ],
  [
    18.48952,
    73.80548
  ],
  [
    18.48953,
    73.80556
  ],
  [
    18.48952,
    73.80561
  ],
  [
    18.48948,
    73.80567
  ],
  [
    18.48943,
    73.80574
  ],
  [
    18.48937,
    73.80583
  ],
  [
    18.48931,
    73.80593
  ],
  [
    18.48931,
    73.80606
  ],
  [
    18.48931,
    73.80624
  ],
  [
    18.48932,
    73.80642
  ],
  [
    18.48933,
    73.80652
  ],
  [
    18.48933,
    73.8066
  ],
  [
    18.48934,
    73.80667
  ],
  [
    18.48934,
    73.80675
  ],
  [
    18.48933,
    73.8068
  ],
  [
    18.4893,
    73.80685
  ],
  [
    18.48928,
    73.80688
  ],
  [
    18.4895,
    73.80691
  ],
  [
    18.49,
    73.80707
  ],
  [
    18.49077,
    73.80744
  ],
  [
    18.4915,
    73.80786
  ],
  [
    18.49214,
    73.80837
  ],
  [
    18.49217,
    73.80839
  ],
  [
    18.49214,
    73.80945
  ],
  [
    18.49238,
    73.81289
  ],
  [
    18.49238,
    73.81293
  ],
  [
    18.49238,
    73.81301
  ],
  [
    18.49235,
    73.81308
  ],
  [
    18.49237,
    73.81331
  ],
  [
    18.4924,
    73.81335
  ],
  [
    18.49246,
    73.81344
  ],
  [
    18.49289,
    73.81464
  ],
  [
    18.493,
    73.81496
  ],
  [
    18.49306,
    73.81513
  ],
  [
    18.49314,
    73.81519
  ],
  [
    18.49318,
    73.81532
  ],
  [
    18.49326,
    73.81559
  ],
  [
    18.49331,
    73.81576
  ],
  [
    18.49345,
    73.81631
  ],
  [
    18.49346,
    73.81636
  ],
  [
    18.49346,
    73.81638
  ],
  [
    18.49361,
    73.817
  ],
  [
    18.4938,
    73.81761
  ],
  [
    18.49383,
    73.81768
  ],
  [
    18.49384,
    73.8177
  ],
  [
    18.49388,
    73.81776
  ],
  [
    18.49394,
    73.8178
  ],
  [
    18.49408,
    73.81784
  ],
  [
    18.49419,
    73.8179
  ],
  [
    18.49425,
    73.81795
  ],
  [
    18.49426,
    73.81797
  ],
  [
    18.49431,
    73.81802
  ],
  [
    18.49436,
    73.81809
  ],
  [
    18.49441,
    73.81816
  ],
  [
    18.49446,
    73.81822
  ],
  [
    18.49451,
    73.81829
  ],
  [
    18.49458,
    73.81836
  ],
  [
    18.49464,
    73.8184
  ],
  [
    18.4947,
    73.81844
  ],
  [
    18.4948,
    73.81848
  ],
  [
    18.49564,
    73.81887
  ],
  [
    18.49601,
    73.81908
  ],
  [
    18.49603,
    73.81911
  ],
  [
    18.49618,
    73.81927
  ],
  [
    18.49665,
    73.81992
  ],
  [
    18.4971,
    73.82048
  ],
  [
    18.49728,
    73.82078
  ],
  [
    18.49752,
    73.82165
  ],
  [
    18.49754,
    73.82201
  ],
  [
    18.49745,
    73.82234
  ],
  [
    18.49749,
    73.82251
  ],
  [
    18.49753,
    73.82265
  ],
  [
    18.49756,
    73.82276
  ],
  [
    18.49758,
    73.82285
  ],
  [
    18.4976,
    73.82296
  ],
  [
    18.49764,
    73.82312
  ],
  [
    18.49767,
    73.82325
  ],
  [
    18.49771,
    73.82334
  ],
  [
    18.49774,
    73.82341
  ],
  [
    18.49778,
    73.82345
  ],
  [
    18.49783,
    73.82347
  ],
  [
    18.4979,
    73.8235
  ],
  [
    18.49795,
    73.8237
  ],
  [
    18.49795,
    73.824
  ],
  [
    18.49792,
    73.82421
  ],
  [
    18.49787,
    73.82452
  ],
  [
    18.49787,
    73.82455
  ],
  [
    18.49784,
    73.82489
  ],
  [
    18.49784,
    73.82494
  ],
  [
    18.49786,
    73.82508
  ],
  [
    18.4979,
    73.82515
  ],
  [
    18.49794,
    73.82523
  ],
  [
    18.49806,
    73.82554
  ],
  [
    18.49809,
    73.82563
  ],
  [
    18.49811,
    73.82575
  ],
  [
    18.49812,
    73.82585
  ],
  [
    18.49813,
    73.82602
  ],
  [
    18.49813,
    73.82605
  ],
  [
    18.49822,
    73.82601
  ],
  [
    18.49888,
    73.82575
  ],
  [
    18.4993,
    73.82543
  ],
  [
    18.49962,
    73.82527
  ],
  [
    18.50023,
    73.82506
  ],
  [
    18.50072,
    73.82496
  ],
  [
    18.50073,
    73.82496
  ],
  [
    18.50124,
    73.82484
  ],
  [
    18.50164,
    73.82465
  ],
  [
    18.50191,
    73.82438
  ],
  [
    18.50224,
    73.82415
  ],
  [
    18.50298,
    73.82377
  ],
  [
    18.50382,
    73.82328
  ],
  [
    18.50434,
    73.82295
  ],
  [
    18.50485,
    73.82247
  ],
  [
    18.50492,
    73.82264
  ],
  [
    18.50499,
    73.8228
  ],
  [
    18.50503,
    73.82289
  ],
  [
    18.50513,
    73.82315
  ],
  [
    18.50524,
    73.82345
  ],
  [
    18.50533,
    73.82377
  ],
  [
    18.5054,
    73.82401
  ],
  [
    18.50546,
    73.82425
  ],
  [
    18.50552,
    73.82442
  ],
  [
    18.50561,
    73.82476
  ],
  [
    18.50569,
    73.82509
  ],
  [
    18.50573,
    73.82519
  ],
  [
    18.50578,
    73.82525
  ],
  [
    18.50583,
    73.82528
  ],
  [
    18.50588,
    73.82529
  ],
  [
    18.50589,
    73.82553
  ],
  [
    18.50593,
    73.82569
  ],
  [
    18.50607,
    73.82609
  ],
  [
    18.50629,
    73.8266
  ],
  [
    18.50636,
    73.82676
  ]
];

// Define coordinates for the polygon (Kharadi area)
const polygonCoordsKharadi = [
  [
    18.53704,
    73.93408
  ],
  [
    18.5371,
    73.93461
  ],
  [
    18.53704,
    73.93508
  ],
  [
    18.53666,
    73.93568
  ],
  [
    18.53643,
    73.93617
  ],
  [
    18.53638,
    73.93664
  ],
  [
    18.53638,
    73.93664
  ],
  [
    18.53632,
    73.93719
  ],
  [
    18.53647,
    73.93846
  ],
  [
    18.53727,
    73.93939
  ],
  [
    18.53873,
    73.9405
  ],
  [
    18.5397,
    73.9411
  ],
  [
    18.54046,
    73.94129
  ],
  [
    18.54172,
    73.9416
  ],
  [
    18.54367,
    73.94286
  ],
  [
    18.54484,
    73.94399
  ],
  [
    18.54548,
    73.94501
  ],
  [
    18.54591,
    73.94569
  ],
  [
    18.5461,
    73.94742
  ],
  [
    18.54614,
    73.94797
  ],
  [
    18.54629,
    73.94999
  ],
  [
    18.5466,
    73.954
  ],
  [
    18.54669,
    73.95461
  ],
  [
    18.54698,
    73.95648
  ],
  [
    18.54726,
    73.95762
  ],
  [
    18.54726,
    73.95924
  ],
  [
    18.54695,
    73.96064
  ],
  [
    18.54752,
    73.96132
  ],
  [
    18.54917,
    73.96138
  ],
  [
    18.54985,
    73.96155
  ],
  [
    18.55451,
    73.9623
  ],
  [
    18.55661,
    73.96252
  ],
  [
    18.55853,
    73.96267
  ],
  [
    18.55926,
    73.96285
  ],
  [
    18.55952,
    73.9606
  ],
  [
    18.56048,
    73.95868
  ],
  [
    18.5623,
    73.95706
  ],
  [
    18.56258,
    73.9561
  ],
  [
    18.56285,
    73.95501
  ],
  [
    18.563,
    73.95444
  ],
  [
    18.56357,
    73.95417
  ],
  [
    18.56404,
    73.95397
  ],
  [
    18.56414,
    73.9539
  ],
  [
    18.56432,
    73.95378
  ],
  [
    18.56482,
    73.95343
  ],
  [
    18.5651,
    73.95322
  ],
  [
    18.56518,
    73.95316
  ],
  [
    18.56527,
    73.95309
  ],
  [
    18.56575,
    73.95272
  ],
  [
    18.56605,
    73.95247
  ],
  [
    18.56656,
    73.95219
  ],
  [
    18.56698,
    73.95197
  ],
  [
    18.56791,
    73.95155
  ],
  [
    18.56834,
    73.95134
  ],
  [
    18.5691,
    73.95097
  ],
  [
    18.56894,
    73.9507
  ],
  [
    18.56861,
    73.95013
  ],
  [
    18.56827,
    73.94958
  ],
  [
    18.56807,
    73.94925
  ],
  [
    18.56787,
    73.94891
  ],
  [
    18.56779,
    73.94878
  ],
  [
    18.56762,
    73.9485
  ],
  [
    18.56736,
    73.94801
  ],
  [
    18.56721,
    73.94768
  ],
  [
    18.56703,
    73.94723
  ],
  [
    18.56692,
    73.94695
  ],
  [
    18.56679,
    73.94658
  ],
  [
    18.56673,
    73.94637
  ],
  [
    18.5667,
    73.94628
  ],
  [
    18.5666,
    73.94596
  ],
  [
    18.56647,
    73.94557
  ],
  [
    18.56636,
    73.94529
  ],
  [
    18.56624,
    73.94505
  ],
  [
    18.566,
    73.94477
  ],
  [
    18.56588,
    73.94466
  ],
  [
    18.56553,
    73.94435
  ],
  [
    18.56504,
    73.94395
  ],
  [
    18.56488,
    73.94379
  ],
  [
    18.56484,
    73.94375
  ],
  [
    18.56469,
    73.9436
  ],
  [
    18.56432,
    73.94324
  ],
  [
    18.56388,
    73.94276
  ],
  [
    18.56379,
    73.94265
  ],
  [
    18.56364,
    73.94245
  ],
  [
    18.56342,
    73.94209
  ],
  [
    18.56311,
    73.94141
  ],
  [
    18.56285,
    73.94081
  ],
  [
    18.5628,
    73.94069
  ],
  [
    18.5627,
    73.9405
  ],
  [
    18.56257,
    73.94014
  ],
  [
    18.56244,
    73.93975
  ],
  [
    18.5624,
    73.93958
  ],
  [
    18.56231,
    73.93928
  ],
  [
    18.56219,
    73.93866
  ],
  [
    18.55937,
    73.9383
  ],
  [
    18.55954,
    73.93775
  ],
  [
    18.55967,
    73.93704
  ],
  [
    18.55974,
    73.93694
  ],
  [
    18.55988,
    73.93679
  ],
  [
    18.56005,
    73.93667
  ],
  [
    18.56042,
    73.93639
  ],
  [
    18.56043,
    73.93555
  ],
  [
    18.56033,
    73.93441
  ],
  [
    18.56037,
    73.93402
  ],
  [
    18.56052,
    73.93337
  ],
  [
    18.56065,
    73.93268
  ],
  [
    18.56097,
    73.93116
  ],
  [
    18.56045,
    73.93104
  ],
  [
    18.55991,
    73.93104
  ],
  [
    18.559,
    73.93131
  ],
  [
    18.55796,
    73.93153
  ],
  [
    18.55794,
    73.92812
  ],
  [
    18.55732,
    73.92814
  ],
  [
    18.55696,
    73.92817
  ],
  [
    18.55657,
    73.9282
  ],
  [
    18.55628,
    73.92825
  ],
  [
    18.55619,
    73.92827
  ],
  [
    18.55576,
    73.92835
  ],
  [
    18.55546,
    73.92839
  ],
  [
    18.5548,
    73.92846
  ],
  [
    18.55455,
    73.92849
  ],
  [
    18.55417,
    73.92851
  ],
  [
    18.5538,
    73.92854
  ],
  [
    18.55379,
    73.92854
  ],
  [
    18.55378,
    73.92854
  ],
  [
    18.55343,
    73.92858
  ],
  [
    18.55306,
    73.92862
  ],
  [
    18.5527,
    73.92869
  ],
  [
    18.5527,
    73.92869
  ],
  [
    18.55241,
    73.92873
  ],
  [
    18.55179,
    73.92882
  ],
  [
    18.55163,
    73.92884
  ],
  [
    18.55116,
    73.92891
  ],
  [
    18.55104,
    73.92893
  ],
  [
    18.55042,
    73.92902
  ],
  [
    18.55011,
    73.92907
  ],
  [
    18.54981,
    73.92918
  ],
  [
    18.54967,
    73.92922
  ],
  [
    18.5495,
    73.92928
  ],
  [
    18.54941,
    73.92931
  ],
  [
    18.54918,
    73.92939
  ],
  [
    18.54824,
    73.92974
  ],
  [
    18.54803,
    73.92982
  ],
  [
    18.54765,
    73.92991
  ],
  [
    18.5471,
    73.93005
  ],
  [
    18.54692,
    73.93009
  ],
  [
    18.54608,
    73.93037
  ],
  [
    18.5458,
    73.93045
  ],
  [
    18.5455,
    73.93054
  ],
  [
    18.54511,
    73.93068
  ],
  [
    18.54505,
    73.9307
  ],
  [
    18.54381,
    73.93102
  ],
  [
    18.54375,
    73.93104
  ],
  [
    18.54307,
    73.93123
  ],
  [
    18.54214,
    73.93141
  ],
  [
    18.54228,
    73.93481
  ],
  [
    18.54229,
    73.93503
  ],
  [
    18.54218,
    73.93501
  ],
  [
    18.54215,
    73.935
  ],
  [
    18.54183,
    73.93494
  ],
  [
    18.54178,
    73.93493
  ],
  [
    18.54118,
    73.93483
  ],
  [
    18.54073,
    73.93472
  ],
  [
    18.54047,
    73.93468
  ],
  [
    18.54036,
    73.93466
  ],
  [
    18.54023,
    73.93463
  ],
  [
    18.53993,
    73.93458
  ],
  [
    18.53982,
    73.93453
  ],
  [
    18.53963,
    73.93449
  ],
  [
    18.5395,
    73.93446
  ],
  [
    18.53931,
    73.93442
  ],
  [
    18.5384,
    73.93423
  ],
  [
    18.53817,
    73.93418
  ],
  [
    18.53792,
    73.93413
  ],
  [
    18.53777,
    73.9341
  ],
  [
    18.53749,
    73.93405
  ],
  [
    18.53703,
    73.93395
  ],
  [
    18.53702,
    73.93403
  ]
];
// Define coordinates for the polygon (Hadapsar area)
const polygonCoordsHadapsar = [
  [
    18.50013,
    73.91714
  ],
  [
    18.49903,
    73.91626
  ],
  [
    18.49878,
    73.91605
  ],
  [
    18.49854,
    73.91585
  ],
  [
    18.49828,
    73.91567
  ],
  [
    18.49818,
    73.9156
  ],
  [
    18.49509,
    73.91321
  ],
  [
    18.49476,
    73.91326
  ],
  [
    18.49417,
    73.91324
  ],
  [
    18.49303,
    73.91363
  ],
  [
    18.492,
    73.91363
  ],
  [
    18.49126,
    73.91385
  ],
  [
    18.49058,
    73.91424
  ],
  [
    18.4901,
    73.91514
  ],
  [
    18.48936,
    73.91606
  ],
  [
    18.48873,
    73.91611
  ],
  [
    18.48814,
    73.91569
  ],
  [
    18.48766,
    73.91462
  ],
  [
    18.4872,
    73.91418
  ],
  [
    18.487,
    73.91383
  ],
  [
    18.4867,
    73.91361
  ],
  [
    18.48629,
    73.91372
  ],
  [
    18.48598,
    73.91383
  ],
  [
    18.48565,
    73.91379
  ],
  [
    18.48525,
    73.91385
  ],
  [
    18.48495,
    73.91411
  ],
  [
    18.48482,
    73.91448
  ],
  [
    18.4844,
    73.91524
  ],
  [
    18.48428,
    73.91555
  ],
  [
    18.48418,
    73.9158
  ],
  [
    18.48399,
    73.91627
  ],
  [
    18.48384,
    73.91739
  ],
  [
    18.48379,
    73.918
  ],
  [
    18.48356,
    73.91811
  ],
  [
    18.4835,
    73.91818
  ],
  [
    18.48324,
    73.91876
  ],
  [
    18.48259,
    73.922
  ],
  [
    18.48238,
    73.92506
  ],
  [
    18.48209,
    73.92527
  ],
  [
    18.48157,
    73.92616
  ],
  [
    18.48041,
    73.92703
  ],
  [
    18.47976,
    73.92754
  ],
  [
    18.47954,
    73.92787
  ],
  [
    18.47914,
    73.92901
  ],
  [
    18.47912,
    73.92907
  ],
  [
    18.47912,
    73.93039
  ],
  [
    18.47912,
    73.93134
  ],
  [
    18.47852,
    73.93137
  ],
  [
    18.47781,
    73.93142
  ],
  [
    18.47777,
    73.93142
  ],
  [
    18.47746,
    73.93145
  ],
  [
    18.47736,
    73.93146
  ],
  [
    18.47694,
    73.93149
  ],
  [
    18.47688,
    73.93149
  ],
  [
    18.47612,
    73.93148
  ],
  [
    18.4761,
    73.93148
  ],
  [
    18.47598,
    73.93149
  ],
  [
    18.47586,
    73.9315
  ],
  [
    18.47502,
    73.93155
  ],
  [
    18.47493,
    73.93156
  ],
  [
    18.47425,
    73.93163
  ],
  [
    18.47383,
    73.93171
  ],
  [
    18.4738,
    73.93172
  ],
  [
    18.4733,
    73.93184
  ],
  [
    18.47323,
    73.93185
  ],
  [
    18.47292,
    73.93194
  ],
  [
    18.4722,
    73.93209
  ],
  [
    18.47184,
    73.93215
  ],
  [
    18.47184,
    73.9329
  ],
  [
    18.47168,
    73.93441
  ],
  [
    18.47172,
    73.93523
  ],
  [
    18.4696,
    73.93516
  ],
  [
    18.46816,
    73.93512
  ],
  [
    18.46815,
    73.93585
  ],
  [
    18.46804,
    73.93761
  ],
  [
    18.4684,
    73.9384
  ],
  [
    18.46866,
    73.93967
  ],
  [
    18.46901,
    73.94053
  ],
  [
    18.46916,
    73.94113
  ],
  [
    18.46799,
    73.94157
  ],
  [
    18.46779,
    73.94202
  ],
  [
    18.46714,
    73.94366
  ],
  [
    18.46985,
    73.94467
  ],
  [
    18.47032,
    73.94703
  ],
  [
    18.47021,
    73.9519
  ],
  [
    18.47027,
    73.95644
  ],
  [
    18.47029,
    73.95797
  ],
  [
    18.4703,
    73.95886
  ],
  [
    18.47023,
    73.95923
  ],
  [
    18.47016,
    73.9595
  ],
  [
    18.47134,
    73.95971
  ],
  [
    18.47215,
    73.95693
  ],
  [
    18.4732,
    73.95411
  ],
  [
    18.47442,
    73.95212
  ],
  [
    18.47608,
    73.94989
  ],
  [
    18.47665,
    73.94914
  ],
  [
    18.47746,
    73.94808
  ],
  [
    18.47813,
    73.94729
  ],
  [
    18.48013,
    73.9444
  ],
  [
    18.48027,
    73.94443
  ],
  [
    18.48043,
    73.94446
  ],
  [
    18.48102,
    73.94467
  ],
  [
    18.48215,
    73.94495
  ],
  [
    18.48281,
    73.94514
  ],
  [
    18.48335,
    73.94533
  ],
  [
    18.48369,
    73.94538
  ],
  [
    18.48404,
    73.94539
  ],
  [
    18.48427,
    73.94538
  ],
  [
    18.48458,
    73.94541
  ],
  [
    18.48504,
    73.94555
  ],
  [
    18.48564,
    73.94571
  ],
  [
    18.48596,
    73.94576
  ],
  [
    18.4866,
    73.94581
  ],
  [
    18.48713,
    73.94584
  ],
  [
    18.48731,
    73.94586
  ],
  [
    18.48776,
    73.94615
  ],
  [
    18.48815,
    73.94643
  ],
  [
    18.48832,
    73.9466
  ],
  [
    18.48807,
    73.94768
  ],
  [
    18.48803,
    73.94801
  ],
  [
    18.48802,
    73.94806
  ],
  [
    18.488,
    73.94816
  ],
  [
    18.488,
    73.94824
  ],
  [
    18.488,
    73.94828
  ],
  [
    18.48804,
    73.94831
  ],
  [
    18.48815,
    73.94834
  ],
  [
    18.48831,
    73.94836
  ],
  [
    18.48847,
    73.94839
  ],
  [
    18.48857,
    73.94842
  ],
  [
    18.48881,
    73.94849
  ],
  [
    18.48905,
    73.94857
  ],
  [
    18.4893,
    73.94865
  ],
  [
    18.48933,
    73.94866
  ],
  [
    18.48951,
    73.94872
  ],
  [
    18.48961,
    73.94875
  ],
  [
    18.48969,
    73.94878
  ],
  [
    18.48999,
    73.94888
  ],
  [
    18.49024,
    73.94896
  ],
  [
    18.49059,
    73.94907
  ],
  [
    18.49087,
    73.94917
  ],
  [
    18.49112,
    73.94927
  ],
  [
    18.49122,
    73.94931
  ],
  [
    18.49074,
    73.94966
  ],
  [
    18.49039,
    73.94993
  ],
  [
    18.4901,
    73.95015
  ],
  [
    18.48987,
    73.95033
  ],
  [
    18.48957,
    73.95055
  ],
  [
    18.48982,
    73.95068
  ],
  [
    18.48991,
    73.95069
  ],
  [
    18.49044,
    73.95086
  ],
  [
    18.49094,
    73.95106
  ],
  [
    18.49246,
    73.95169
  ],
  [
    18.49356,
    73.9521
  ],
  [
    18.49378,
    73.95219
  ],
  [
    18.49378,
    73.95182
  ],
  [
    18.4938,
    73.95136
  ],
  [
    18.49381,
    73.9512
  ],
  [
    18.49382,
    73.95108
  ],
  [
    18.49389,
    73.95079
  ],
  [
    18.4939,
    73.95072
  ],
  [
    18.49682,
    73.95106
  ],
  [
    18.49754,
    73.95115
  ],
  [
    18.49753,
    73.95128
  ],
  [
    18.49734,
    73.95209
  ],
  [
    18.49719,
    73.95258
  ],
  [
    18.49681,
    73.95345
  ],
  [
    18.49666,
    73.95384
  ],
  [
    18.49631,
    73.95475
  ],
  [
    18.49628,
    73.95484
  ],
  [
    18.49618,
    73.95506
  ],
  [
    18.49608,
    73.95532
  ],
  [
    18.49581,
    73.95596
  ],
  [
    18.49559,
    73.95645
  ],
  [
    18.49534,
    73.95708
  ],
  [
    18.4952,
    73.95737
  ],
  [
    18.49506,
    73.95757
  ],
  [
    18.49461,
    73.95807
  ],
  [
    18.49409,
    73.95856
  ],
  [
    18.49367,
    73.95894
  ],
  [
    18.49233,
    73.96022
  ],
  [
    18.49217,
    73.96562
  ],
  [
    18.49701,
    73.9663
  ],
  [
    18.49642,
    73.97068
  ],
  [
    18.49808,
    73.97106
  ],
  [
    18.50291,
    73.97254
  ],
  [
    18.50987,
    73.97182
  ],
  [
    18.51317,
    73.96985
  ],
  [
    18.51479,
    73.96629
  ],
  [
    18.51718,
    73.96101
  ],
  [
    18.51981,
    73.95503
  ],
  [
    18.52014,
    73.95407
  ],
  [
    18.52062,
    73.95274
  ],
  [
    18.52105,
    73.95173
  ],
  [
    18.52107,
    73.95036
  ],
  [
    18.52169,
    73.94907
  ],
  [
    18.52231,
    73.9471
  ],
  [
    18.52387,
    73.94201
  ],
  [
    18.52392,
    73.94183
  ],
  [
    18.5241,
    73.94126
  ],
  [
    18.52425,
    73.94066
  ],
  [
    18.52438,
    73.94015
  ],
  [
    18.52477,
    73.93859
  ],
  [
    18.52495,
    73.93802
  ],
  [
    18.52537,
    73.93672
  ],
  [
    18.52571,
    73.93589
  ],
  [
    18.52615,
    73.93412
  ],
  [
    18.5264,
    73.93228
  ],
  [
    18.52593,
    73.93229
  ],
  [
    18.52542,
    73.93231
  ],
  [
    18.52432,
    73.93229
  ],
  [
    18.52407,
    73.93227
  ],
  [
    18.52364,
    73.93223
  ],
  [
    18.52359,
    73.93223
  ],
  [
    18.5221,
    73.93209
  ],
  [
    18.52151,
    73.93214
  ],
  [
    18.52022,
    73.93256
  ],
  [
    18.51887,
    73.9328
  ],
  [
    18.5169,
    73.93307
  ],
  [
    18.51637,
    73.9331
  ],
  [
    18.51532,
    73.93287
  ],
  [
    18.51427,
    73.93261
  ],
  [
    18.51213,
    73.93257
  ],
  [
    18.51153,
    73.93251
  ],
  [
    18.51117,
    73.93235
  ],
  [
    18.5102,
    73.9314
  ],
  [
    18.50983,
    73.93106
  ],
  [
    18.50935,
    73.93062
  ],
  [
    18.50849,
    73.92989
  ],
  [
    18.50865,
    73.9293
  ],
  [
    18.50907,
    73.92601
  ],
  [
    18.50917,
    73.9249
  ],
  [
    18.5092,
    73.92362
  ],
  [
    18.50908,
    73.92362
  ],
  [
    18.50887,
    73.92355
  ],
  [
    18.50891,
    73.92336
  ],
  [
    18.50902,
    73.92225
  ],
  [
    18.5092,
    73.92181
  ],
  [
    18.5094,
    73.92155
  ],
  [
    18.50957,
    73.92142
  ],
  [
    18.50988,
    73.92125
  ],
  [
    18.5148,
    73.91947
  ],
  [
    18.51671,
    73.91898
  ],
  [
    18.51741,
    73.91878
  ],
  [
    18.51768,
    73.91866
  ],
  [
    18.51792,
    73.91847
  ],
  [
    18.51812,
    73.91825
  ],
  [
    18.51852,
    73.91819
  ],
  [
    18.51993,
    73.91828
  ],
  [
    18.51978,
    73.91651
  ],
  [
    18.5198,
    73.91439
  ],
  [
    18.5198,
    73.91246
  ],
  [
    18.51984,
    73.90994
  ],
  [
    18.51984,
    73.90969
  ],
  [
    18.51972,
    73.90968
  ],
  [
    18.51923,
    73.90964
  ],
  [
    18.51906,
    73.90963
  ],
  [
    18.51897,
    73.90962
  ],
  [
    18.51881,
    73.9096
  ],
  [
    18.51818,
    73.90963
  ],
  [
    18.5173,
    73.90955
  ],
  [
    18.51681,
    73.9095
  ],
  [
    18.51598,
    73.90948
  ],
  [
    18.51553,
    73.9096
  ],
  [
    18.51497,
    73.90961
  ],
  [
    18.51496,
    73.90945
  ],
  [
    18.51503,
    73.90908
  ],
  [
    18.51514,
    73.90876
  ],
  [
    18.51543,
    73.9084
  ],
  [
    18.5156,
    73.90808
  ],
  [
    18.51569,
    73.90769
  ],
  [
    18.51573,
    73.90733
  ],
  [
    18.51573,
    73.90721
  ],
  [
    18.51571,
    73.90721
  ],
  [
    18.51551,
    73.90721
  ],
  [
    18.515,
    73.90718
  ],
  [
    18.5145,
    73.90715
  ],
  [
    18.5142,
    73.90713
  ],
  [
    18.51396,
    73.90732
  ],
  [
    18.51342,
    73.90786
  ],
  [
    18.51301,
    73.90825
  ],
  [
    18.5116,
    73.90951
  ],
  [
    18.51088,
    73.91023
  ],
  [
    18.50979,
    73.91139
  ],
  [
    18.50925,
    73.91191
  ],
  [
    18.50783,
    73.91327
  ],
  [
    18.50574,
    73.91527
  ],
  [
    18.50584,
    73.91493
  ],
  [
    18.50608,
    73.9141
  ],
  [
    18.50614,
    73.91386
  ],
  [
    18.50622,
    73.91352
  ],
  [
    18.50625,
    73.91338
  ],
  [
    18.50631,
    73.91308
  ],
  [
    18.50633,
    73.91297
  ],
  [
    18.50636,
    73.91283
  ],
  [
    18.50635,
    73.91261
  ],
  [
    18.50529,
    73.91259
  ],
  [
    18.50494,
    73.91258
  ],
  [
    18.50461,
    73.91257
  ],
  [
    18.50438,
    73.91256
  ],
  [
    18.5041,
    73.91257
  ],
  [
    18.50304,
    73.9125
  ],
  [
    18.50257,
    73.91248
  ],
  [
    18.50236,
    73.91253
  ],
  [
    18.50198,
    73.91269
  ],
  [
    18.50176,
    73.91292
  ],
  [
    18.50173,
    73.91303
  ],
  [
    18.50174,
    73.91315
  ],
  [
    18.50179,
    73.91336
  ],
  [
    18.50185,
    73.91354
  ],
  [
    18.50204,
    73.91383
  ],
  [
    18.50208,
    73.91407
  ],
  [
    18.5021,
    73.91425
  ],
  [
    18.50216,
    73.91437
  ],
  [
    18.5022,
    73.91444
  ],
  [
    18.50221,
    73.91451
  ],
  [
    18.5022,
    73.91458
  ],
  [
    18.50216,
    73.91465
  ],
  [
    18.50202,
    73.91487
  ],
  [
    18.50189,
    73.91508
  ],
  [
    18.50186,
    73.91517
  ],
  [
    18.50205,
    73.91535
  ],
  [
    18.5022,
    73.91551
  ],
  [
    18.50222,
    73.91566
  ],
  [
    18.50217,
    73.91592
  ],
  [
    18.50204,
    73.91614
  ],
  [
    18.50181,
    73.9164
  ],
  [
    18.50162,
    73.91656
  ],
  [
    18.50126,
    73.91679
  ],
  [
    18.501,
    73.91698
  ],
  [
    18.50059,
    73.91748
  ],
  [
    18.50017,
    73.9172
  ]
];
// Define coordinates for the polygon (Ravet area)
const polygonCoordsRavet = [
  [
    18.65085,
    73.72206
  ],
  [
    18.64883,
    73.72289
  ],
  [
    18.6476,
    73.72339
  ],
  [
    18.64724,
    73.72385
  ],
  [
    18.64676,
    73.7248
  ],
  [
    18.64672,
    73.7255
  ],
  [
    18.64665,
    73.72579
  ],
  [
    18.64651,
    73.72618
  ],
  [
    18.64631,
    73.72712
  ],
  [
    18.646,
    73.72812
  ],
  [
    18.64586,
    73.72864
  ],
  [
    18.64578,
    73.72894
  ],
  [
    18.64509,
    73.73026
  ],
  [
    18.64395,
    73.7315
  ],
  [
    18.64269,
    73.73263
  ],
  [
    18.6424,
    73.73299
  ],
  [
    18.64183,
    73.73367
  ],
  [
    18.64155,
    73.73472
  ],
  [
    18.6415,
    73.73527
  ],
  [
    18.64195,
    73.736
  ],
  [
    18.6428,
    73.73652
  ],
  [
    18.64387,
    73.73697
  ],
  [
    18.64453,
    73.73767
  ],
  [
    18.64471,
    73.73777
  ],
  [
    18.64477,
    73.73793
  ],
  [
    18.64478,
    73.7386
  ],
  [
    18.64459,
    73.73897
  ],
  [
    18.64358,
    73.73988
  ],
  [
    18.64275,
    73.74074
  ],
  [
    18.64263,
    73.74084
  ],
  [
    18.64144,
    73.74181
  ],
  [
    18.64074,
    73.74282
  ],
  [
    18.64067,
    73.743
  ],
  [
    18.64036,
    73.74377
  ],
  [
    18.64002,
    73.7447
  ],
  [
    18.63976,
    73.74616
  ],
  [
    18.63966,
    73.7476
  ],
  [
    18.63961,
    73.74778
  ],
  [
    18.63973,
    73.74866
  ],
  [
    18.64038,
    73.74913
  ],
  [
    18.64064,
    73.74944
  ],
  [
    18.64103,
    73.75004
  ],
  [
    18.64117,
    73.75007
  ],
  [
    18.64124,
    73.7501
  ],
  [
    18.64216,
    73.75014
  ],
  [
    18.64308,
    73.75023
  ],
  [
    18.64303,
    73.75064
  ],
  [
    18.643,
    73.75106
  ],
  [
    18.64302,
    73.75161
  ],
  [
    18.64307,
    73.75247
  ],
  [
    18.64309,
    73.75275
  ],
  [
    18.6431,
    73.75289
  ],
  [
    18.64319,
    73.75313
  ],
  [
    18.64342,
    73.75373
  ],
  [
    18.6435,
    73.7541
  ],
  [
    18.6436,
    73.75462
  ],
  [
    18.64375,
    73.75526
  ],
  [
    18.64378,
    73.7554
  ],
  [
    18.64385,
    73.75571
  ],
  [
    18.64393,
    73.75599
  ],
  [
    18.64404,
    73.75629
  ],
  [
    18.64414,
    73.75652
  ],
  [
    18.64421,
    73.75663
  ],
  [
    18.64426,
    73.75669
  ],
  [
    18.64435,
    73.75679
  ],
  [
    18.64459,
    73.75705
  ],
  [
    18.64506,
    73.75748
  ],
  [
    18.64554,
    73.75796
  ],
  [
    18.64608,
    73.75848
  ],
  [
    18.64633,
    73.75872
  ],
  [
    18.64636,
    73.75876
  ],
  [
    18.64679,
    73.75916
  ],
  [
    18.64717,
    73.75951
  ],
  [
    18.64762,
    73.75993
  ],
  [
    18.64772,
    73.76003
  ],
  [
    18.64791,
    73.76021
  ],
  [
    18.64825,
    73.76052
  ],
  [
    18.64843,
    73.76068
  ],
  [
    18.64866,
    73.7609
  ],
  [
    18.64877,
    73.761
  ],
  [
    18.6489,
    73.76112
  ],
  [
    18.64913,
    73.76134
  ],
  [
    18.64921,
    73.76142
  ],
  [
    18.64929,
    73.7615
  ],
  [
    18.6494,
    73.76161
  ],
  [
    18.64952,
    73.76173
  ],
  [
    18.64959,
    73.7618
  ],
  [
    18.64968,
    73.76189
  ],
  [
    18.65,
    73.76219
  ],
  [
    18.65142,
    73.76059
  ],
  [
    18.65163,
    73.76039
  ],
  [
    18.6522,
    73.75984
  ],
  [
    18.65248,
    73.75961
  ],
  [
    18.65324,
    73.75906
  ],
  [
    18.65342,
    73.75893
  ],
  [
    18.65421,
    73.75843
  ],
  [
    18.65544,
    73.75776
  ],
  [
    18.65584,
    73.75755
  ],
  [
    18.6564,
    73.75726
  ],
  [
    18.65759,
    73.75667
  ],
  [
    18.65865,
    73.75608
  ],
  [
    18.65935,
    73.75567
  ],
  [
    18.65941,
    73.75564
  ],
  [
    18.65989,
    73.75539
  ],
  [
    18.66084,
    73.75451
  ],
  [
    18.66167,
    73.75358
  ],
  [
    18.66185,
    73.75327
  ],
  [
    18.66185,
    73.75327
  ],
  [
    18.66229,
    73.75251
  ],
  [
    18.663,
    73.75105
  ],
  [
    18.66359,
    73.74906
  ],
  [
    18.66403,
    73.74711
  ],
  [
    18.66448,
    73.74607
  ],
  [
    18.66486,
    73.74539
  ],
  [
    18.6654,
    73.74461
  ],
  [
    18.66597,
    73.74396
  ],
  [
    18.66649,
    73.74349
  ],
  [
    18.66708,
    73.74302
  ],
  [
    18.66817,
    73.7424
  ],
  [
    18.66876,
    73.74214
  ],
  [
    18.66986,
    73.74187
  ],
  [
    18.6699,
    73.74174
  ],
  [
    18.66991,
    73.74165
  ],
  [
    18.66988,
    73.74151
  ],
  [
    18.66973,
    73.74136
  ],
  [
    18.66964,
    73.74119
  ],
  [
    18.66958,
    73.74118
  ],
  [
    18.66954,
    73.74109
  ],
  [
    18.6695,
    73.74098
  ],
  [
    18.66949,
    73.74086
  ],
  [
    18.66946,
    73.74069
  ],
  [
    18.66946,
    73.74054
  ],
  [
    18.66508,
    73.73964
  ],
  [
    18.66496,
    73.73988
  ],
  [
    18.66368,
    73.73812
  ],
  [
    18.66,
    73.73051
  ],
  [
    18.65868,
    73.73102
  ],
  [
    18.65841,
    73.73022
  ],
  [
    18.65812,
    73.72912
  ],
  [
    18.65722,
    73.728
  ],
  [
    18.65552,
    73.72636
  ],
  [
    18.65505,
    73.72378
  ],
  [
    18.65236,
    73.72123
  ],
  [
    18.65301,
    73.72061
  ],
  [
    18.65375,
    73.71973
  ],
  [
    18.65439,
    73.71801
  ],
  [
    18.65822,
    73.71934
  ],
  [
    18.65266,
    73.70334
  ],
  [
    18.65323,
    73.70158
  ],
  [
    18.65164,
    73.70145
  ],
  [
    18.65123,
    73.7045
  ],
  [
    18.65115,
    73.70622
  ],
  [
    18.6518,
    73.70819
  ],
  [
    18.6556,
    73.7162
  ],
  [
    18.65184,
    73.72052
  ],
  [
    18.65185,
    73.72162
  ],
  [
    18.65087,
    73.72204
  ]
];
// Define coordinates for the polygon (wagholi area)
const polygonCoordsWagholi = [
  [
    18.58955,
    73.98425
  ],
  [
    18.59402,
    73.98408
  ],
  [
    18.59825,
    73.98357
  ],
  [
    18.59011,
    73.97396
  ],
  [
    18.591,
    73.97345
  ],
  [
    18.59141,
    73.97315
  ],
  [
    18.59152,
    73.97149
  ],
  [
    18.59161,
    73.97018
  ],
  [
    18.59417,
    73.97139
  ],
  [
    18.5997,
    73.96821
  ],
  [
    18.59791,
    73.96049
  ],
  [
    18.58961,
    73.96409
  ],
  [
    18.58228,
    73.95215
  ],
  [
    18.57567,
    73.95154
  ],
  [
    18.57439,
    73.951
  ],
  [
    18.57327,
    73.95072
  ],
  [
    18.57252,
    73.95069
  ],
  [
    18.57099,
    73.95078
  ],
  [
    18.56995,
    73.95084
  ],
  [
    18.56915,
    73.95095
  ],
  [
    18.57161,
    73.9542
  ],
  [
    18.57444,
    73.95951
  ],
  [
    18.57686,
    73.96583
  ],
  [
    18.57732,
    73.96836
  ],
  [
    18.57823,
    73.97122
  ],
  [
    18.58077,
    73.97733
  ],
  [
    18.57696,
    73.97709
  ],
  [
    18.57657,
    73.97739
  ],
  [
    18.57089,
    73.97518
  ],
  [
    18.56937,
    73.97511
  ],
  [
    18.56629,
    73.97457
  ],
  [
    18.56857,
    73.97878
  ],
  [
    18.56774,
    73.99033
  ],
  [
    18.5701,
    74.00275
  ],
  [
    18.57488,
    74.00552
  ],
  [
    18.58159,
    74.00917
  ],
  [
    18.58189,
    74.01227
  ],
  [
    18.58352,
    74.01939
  ],
  [
    18.58409,
    74.02613
  ],
  [
    18.59096,
    74.03008
  ],
  [
    18.59625,
    74.03042
  ],
  [
    18.59804,
    74.02849
  ],
  [
    18.59544,
    74.01845
  ],
  [
    18.59372,
    74.01383
  ],
  [
    18.59333,
    74.01219
  ],
  [
    18.59488,
    74.01165
  ],
  [
    18.59495,
    74.00995
  ],
  [
    18.60214,
    74.00877
  ],
  [
    18.60362,
    74.00921
  ],
  [
    18.60402,
    74.01028
  ],
  [
    18.60839,
    74.00726
  ],
  [
    18.60888,
    74.00797
  ],
  [
    18.61253,
    74.00373
  ],
  [
    18.61056,
    73.99983
  ],
  [
    18.6062,
    73.99181
  ],
  [
    18.5972,
    73.99213
  ],
  [
    18.59258,
    73.99283
  ],
  [
    18.59103,
    73.98998
  ],
  [
    18.5912,
    73.98643
  ],
  [
    18.58968,
    73.98635
  ],
  [
    18.58952,
    73.98425
  ]
];
// Define coordinates for the polygon (Baner area)
const polygonCoordsBaner = [
  [
    18.55112,
    73.80459
  ],
  [
    18.55325,
    73.80299
  ],
  [
    18.55497,
    73.80054
  ],
  [
    18.55605,
    73.79937
  ],
  [
    18.55621,
    73.79931
  ],
  [
    18.55636,
    73.79921
  ],
  [
    18.55669,
    73.79895
  ],
  [
    18.55701,
    73.79873
  ],
  [
    18.55756,
    73.79839
  ],
  [
    18.55783,
    73.79827
  ],
  [
    18.55865,
    73.79781
  ],
  [
    18.55867,
    73.7978
  ],
  [
    18.55895,
    73.79765
  ],
  [
    18.55907,
    73.79763
  ],
  [
    18.5593,
    73.79752
  ],
  [
    18.55952,
    73.79742
  ],
  [
    18.55968,
    73.79732
  ],
  [
    18.55983,
    73.79723
  ],
  [
    18.55979,
    73.797
  ],
  [
    18.55975,
    73.7966
  ],
  [
    18.5597,
    73.79632
  ],
  [
    18.55998,
    73.79576
  ],
  [
    18.56019,
    73.79549
  ],
  [
    18.56045,
    73.79525
  ],
  [
    18.56089,
    73.79489
  ],
  [
    18.56125,
    73.79467
  ],
  [
    18.56163,
    73.79449
  ],
  [
    18.562,
    73.7943
  ],
  [
    18.5631,
    73.79352
  ],
  [
    18.56325,
    73.79317
  ],
  [
    18.5641,
    73.79223
  ],
  [
    18.564,
    73.79145
  ],
  [
    18.56428,
    73.79016
  ],
  [
    18.5644,
    73.78976
  ],
  [
    18.56467,
    73.78887
  ],
  [
    18.56498,
    73.78877
  ],
  [
    18.56529,
    73.78861
  ],
  [
    18.56573,
    73.78845
  ],
  [
    18.56651,
    73.78838
  ],
  [
    18.56683,
    73.78841
  ],
  [
    18.56707,
    73.78856
  ],
  [
    18.56724,
    73.78876
  ],
  [
    18.56748,
    73.78904
  ],
  [
    18.56751,
    73.78907
  ],
  [
    18.56779,
    73.78936
  ],
  [
    18.56862,
    73.78993
  ],
  [
    18.56924,
    73.79014
  ],
  [
    18.56951,
    73.79023
  ],
  [
    18.57016,
    73.79032
  ],
  [
    18.57058,
    73.7903
  ],
  [
    18.57125,
    73.79018
  ],
  [
    18.57171,
    73.79
  ],
  [
    18.57243,
    73.78943
  ],
  [
    18.57276,
    73.78908
  ],
  [
    18.57339,
    73.78818
  ],
  [
    18.5736,
    73.78788
  ],
  [
    18.57561,
    73.78484
  ],
  [
    18.57528,
    73.78408
  ],
  [
    18.57464,
    73.78303
  ],
  [
    18.57389,
    73.7811
  ],
  [
    18.57365,
    73.78087
  ],
  [
    18.57359,
    73.78064
  ],
  [
    18.57365,
    73.77919
  ],
  [
    18.57371,
    73.77852
  ],
  [
    18.57374,
    73.77725
  ],
  [
    18.57372,
    73.77605
  ],
  [
    18.57369,
    73.77476
  ],
  [
    18.57368,
    73.77406
  ],
  [
    18.57369,
    73.77309
  ],
  [
    18.57371,
    73.77294
  ],
  [
    18.57379,
    73.77276
  ],
  [
    18.57419,
    73.77205
  ],
  [
    18.57443,
    73.77158
  ],
  [
    18.5748,
    73.77076
  ],
  [
    18.57501,
    73.77
  ],
  [
    18.57518,
    73.76936
  ],
  [
    18.57521,
    73.76916
  ],
  [
    18.57522,
    73.769
  ],
  [
    18.57517,
    73.76819
  ],
  [
    18.57505,
    73.76631
  ],
  [
    18.57496,
    73.76535
  ],
  [
    18.57491,
    73.76403
  ],
  [
    18.5749,
    73.76356
  ],
  [
    18.57184,
    73.76475
  ],
  [
    18.5692,
    73.76571
  ],
  [
    18.56918,
    73.76572
  ],
  [
    18.56963,
    73.76381
  ],
  [
    18.56125,
    73.76468
  ],
  [
    18.56105,
    73.76468
  ],
  [
    18.56052,
    73.76465
  ],
  [
    18.56017,
    73.76463
  ],
  [
    18.55998,
    73.76463
  ],
  [
    18.55951,
    73.76456
  ],
  [
    18.55867,
    73.7645
  ],
  [
    18.55856,
    73.7645
  ],
  [
    18.55824,
    73.7645
  ],
  [
    18.55771,
    73.76455
  ],
  [
    18.55712,
    73.76466
  ],
  [
    18.55625,
    73.76442
  ],
  [
    18.55605,
    73.76437
  ],
  [
    18.55577,
    73.76426
  ],
  [
    18.55563,
    73.76407
  ],
  [
    18.5555,
    73.764
  ],
  [
    18.55508,
    73.76393
  ],
  [
    18.55461,
    73.76379
  ],
  [
    18.55448,
    73.76307
  ],
  [
    18.55429,
    73.76296
  ],
  [
    18.55397,
    73.76294
  ],
  [
    18.55373,
    73.76305
  ],
  [
    18.55367,
    73.76325
  ],
  [
    18.54902,
    73.7658
  ],
  [
    18.54448,
    73.76936
  ],
  [
    18.54435,
    73.76951
  ],
  [
    18.54504,
    73.77096
  ],
  [
    18.54601,
    73.77286
  ],
  [
    18.54672,
    73.77378
  ],
  [
    18.54674,
    73.77384
  ],
  [
    18.54675,
    73.774
  ],
  [
    18.54671,
    73.77414
  ],
  [
    18.54662,
    73.77429
  ],
  [
    18.54662,
    73.7743
  ],
  [
    18.5467,
    73.77452
  ],
  [
    18.54666,
    73.77498
  ],
  [
    18.54675,
    73.77544
  ],
  [
    18.54694,
    73.77602
  ],
  [
    18.54713,
    73.77662
  ],
  [
    18.54762,
    73.77755
  ],
  [
    18.54765,
    73.77793
  ],
  [
    18.54756,
    73.7785
  ],
  [
    18.54738,
    73.77889
  ],
  [
    18.54714,
    73.77909
  ],
  [
    18.54677,
    73.77943
  ],
  [
    18.5463,
    73.77989
  ],
  [
    18.54608,
    73.78019
  ],
  [
    18.54603,
    73.78043
  ],
  [
    18.54603,
    73.78084
  ],
  [
    18.54606,
    73.7814
  ],
  [
    18.54607,
    73.78181
  ],
  [
    18.54625,
    73.78215
  ],
  [
    18.54678,
    73.78273
  ],
  [
    18.5469,
    73.78293
  ],
  [
    18.54695,
    73.78392
  ],
  [
    18.54695,
    73.78427
  ],
  [
    18.54687,
    73.7845
  ],
  [
    18.54649,
    73.78481
  ],
  [
    18.546,
    73.78519
  ],
  [
    18.54551,
    73.78577
  ],
  [
    18.54534,
    73.78649
  ],
  [
    18.54532,
    73.78686
  ],
  [
    18.54552,
    73.78739
  ],
  [
    18.54578,
    73.78769
  ],
  [
    18.54707,
    73.78829
  ],
  [
    18.54952,
    73.78836
  ],
  [
    18.5512,
    73.78885
  ],
  [
    18.55201,
    73.7898
  ],
  [
    18.5522,
    73.79032
  ],
  [
    18.55208,
    73.79068
  ],
  [
    18.55205,
    73.79176
  ],
  [
    18.55208,
    73.79197
  ],
  [
    18.55208,
    73.79233
  ],
  [
    18.552,
    73.79338
  ],
  [
    18.55192,
    73.79412
  ],
  [
    18.5519,
    73.79428
  ],
  [
    18.55188,
    73.7945
  ],
  [
    18.55187,
    73.79479
  ],
  [
    18.55184,
    73.79529
  ],
  [
    18.55178,
    73.79649
  ],
  [
    18.55176,
    73.79716
  ],
  [
    18.55167,
    73.79785
  ],
  [
    18.55161,
    73.79803
  ],
  [
    18.55157,
    73.79807
  ],
  [
    18.55115,
    73.80454
  ]
];

// Define coordinates for the polygon (Wakad area)
const polygonCoords = [
  [
    18.61181,
    73.76143
  ],
  [
    18.61167,
    73.76125
  ],
  [
    18.6115,
    73.76103
  ],
  [
    18.61131,
    73.76079
  ],
  [
    18.6109,
    73.76021
  ],
  [
    18.61044,
    73.75958
  ],
  [
    18.61015,
    73.75918
  ],
  [
    18.6092,
    73.75789
  ],
  [
    18.6088,
    73.75692
  ],
  [
    18.60819,
    73.75391
  ],
  [
    18.60648,
    73.75392
  ],
  [
    18.60618,
    73.75315
  ],
  [
    18.6059,
    73.75269
  ],
  [
    18.60561,
    73.75235
  ],
  [
    18.60547,
    73.7522
  ],
  [
    18.60653,
    73.75184
  ],
  [
    18.60675,
    73.75176
  ],
  [
    18.60659,
    73.75062
  ],
  [
    18.60617,
    73.74796
  ],
  [
    18.60535,
    73.74303
  ],
  [
    18.60488,
    73.7383
  ],
  [
    18.60395,
    73.73939
  ],
  [
    18.60145,
    73.74231
  ],
  [
    18.60041,
    73.74519
  ],
  [
    18.60027,
    73.74538
  ],
  [
    18.5999,
    73.74587
  ],
  [
    18.59961,
    73.74604
  ],
  [
    18.599,
    73.7464
  ],
  [
    18.59679,
    73.74728
  ],
  [
    18.59671,
    73.74734
  ],
  [
    18.59391,
    73.74939
  ],
  [
    18.59384,
    73.7503
  ],
  [
    18.59357,
    73.75099
  ],
  [
    18.59334,
    73.75181
  ],
  [
    18.59318,
    73.75237
  ],
  [
    18.59306,
    73.75264
  ],
  [
    18.59264,
    73.75312
  ],
  [
    18.59152,
    73.75333
  ],
  [
    18.59165,
    73.75363
  ],
  [
    18.59067,
    73.75404
  ],
  [
    18.58512,
    73.75674
  ],
  [
    18.58526,
    73.75683
  ],
  [
    18.5857,
    73.75726
  ],
  [
    18.58635,
    73.75792
  ],
  [
    18.58635,
    73.75792
  ],
  [
    18.58636,
    73.75794
  ],
  [
    18.58724,
    73.75889
  ],
  [
    18.58721,
    73.7589
  ],
  [
    18.58805,
    73.76023
  ],
  [
    18.58847,
    73.76142
  ],
  [
    18.58858,
    73.76222
  ],
  [
    18.58862,
    73.76321
  ],
  [
    18.58827,
    73.7651
  ],
  [
    18.58769,
    73.76768
  ],
  [
    18.58758,
    73.76816
  ],
  [
    18.58753,
    73.76867
  ],
  [
    18.58752,
    73.76876
  ],
  [
    18.58753,
    73.7693
  ],
  [
    18.5876,
    73.76998
  ],
  [
    18.58784,
    73.77114
  ],
  [
    18.5882,
    73.77222
  ],
  [
    18.58839,
    73.77304
  ],
  [
    18.58847,
    73.77399
  ],
  [
    18.58851,
    73.77528
  ],
  [
    18.58837,
    73.77638
  ],
  [
    18.58889,
    73.77634
  ],
  [
    18.58884,
    73.77717
  ],
  [
    18.58879,
    73.77768
  ],
  [
    18.58863,
    73.77839
  ],
  [
    18.58862,
    73.77853
  ],
  [
    18.58861,
    73.7787
  ],
  [
    18.58862,
    73.77893
  ],
  [
    18.58862,
    73.77964
  ],
  [
    18.58862,
    73.78002
  ],
  [
    18.58859,
    73.78074
  ],
  [
    18.58853,
    73.78165
  ],
  [
    18.58846,
    73.78257
  ],
  [
    18.58844,
    73.78278
  ],
  [
    18.58843,
    73.78296
  ],
  [
    18.5884,
    73.78324
  ],
  [
    18.58839,
    73.7833
  ],
  [
    18.58835,
    73.78362
  ],
  [
    18.58828,
    73.78423
  ],
  [
    18.58827,
    73.78432
  ],
  [
    18.58822,
    73.78462
  ],
  [
    18.58819,
    73.78482
  ],
  [
    18.58811,
    73.78516
  ],
  [
    18.58806,
    73.78538
  ],
  [
    18.58785,
    73.78642
  ],
  [
    18.58741,
    73.78861
  ],
  [
    18.58679,
    73.79079
  ],
  [
    18.58728,
    73.79014
  ],
  [
    18.58794,
    73.7892
  ],
  [
    18.58884,
    73.78826
  ],
  [
    18.59045,
    73.78675
  ],
  [
    18.59156,
    73.78576
  ],
  [
    18.59159,
    73.78574
  ],
  [
    18.59223,
    73.78518
  ],
  [
    18.59235,
    73.7851
  ],
  [
    18.59254,
    73.78498
  ],
  [
    18.59335,
    73.78447
  ],
  [
    18.59369,
    73.78427
  ],
  [
    18.59408,
    73.78403
  ],
  [
    18.59553,
    73.78315
  ],
  [
    18.59651,
    73.78254
  ],
  [
    18.59722,
    73.78212
  ],
  [
    18.59765,
    73.78183
  ],
  [
    18.5981,
    73.78153
  ],
  [
    18.59851,
    73.78125
  ],
  [
    18.59892,
    73.78096
  ],
  [
    18.59925,
    73.78074
  ],
  [
    18.59971,
    73.78044
  ],
  [
    18.59998,
    73.78026
  ],
  [
    18.6009,
    73.7796
  ],
  [
    18.60175,
    73.77905
  ],
  [
    18.60239,
    73.77859
  ],
  [
    18.60305,
    73.77788
  ],
  [
    18.60333,
    73.77755
  ],
  [
    18.60363,
    73.77721
  ],
  [
    18.60398,
    73.77674
  ],
  [
    18.60422,
    73.77641
  ],
  [
    18.60444,
    73.7761
  ],
  [
    18.60463,
    73.77586
  ],
  [
    18.60498,
    73.77542
  ],
  [
    18.6052,
    73.77515
  ],
  [
    18.60543,
    73.77486
  ],
  [
    18.60544,
    73.77484
  ],
  [
    18.60563,
    73.77461
  ],
  [
    18.60575,
    73.77447
  ],
  [
    18.60596,
    73.77422
  ],
  [
    18.60663,
    73.77342
  ],
  [
    18.60684,
    73.77314
  ],
  [
    18.60705,
    73.77286
  ],
  [
    18.60724,
    73.77263
  ],
  [
    18.6077,
    73.77207
  ],
  [
    18.60814,
    73.77153
  ],
  [
    18.60828,
    73.77136
  ],
  [
    18.6085,
    73.77108
  ],
  [
    18.60864,
    73.77094
  ],
  [
    18.60889,
    73.7707
  ],
  [
    18.60963,
    73.77008
  ],
  [
    18.61015,
    73.76965
  ],
  [
    18.61034,
    73.76949
  ],
  [
    18.61052,
    73.76934
  ],
  [
    18.61072,
    73.76917
  ],
  [
    18.611,
    73.76893
  ],
  [
    18.61117,
    73.76878
  ],
  [
    18.6118,
    73.76827
  ],
  [
    18.61405,
    73.76643
  ],
  [
    18.61424,
    73.76628
  ],
  [
    18.61457,
    73.76601
  ],
  [
    18.61539,
    73.76535
  ],
  [
    18.6161,
    73.76475
  ],
  [
    18.61692,
    73.76405
  ],
  [
    18.61741,
    73.76364
  ],
  [
    18.61501,
    73.76229
  ],
  [
    18.61291,
    73.76081
  ],
  [
    18.61207,
    73.76181
  ],
  [
    18.61181,
    73.76146
  ]
];
const NewMapView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const mapRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [noDataFound, setNoDataFound] = useState(false);
  const currentUrl = location.pathname;

  const [currentPage, setCurrentPage] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [projects, setResponseData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [hoveredProject, setHoveredProject] = useState(null);

  const [sortCriteria, setSortCriteria] = useState('recentlyLaunched');

  const [priceRange, setPriceRange] = useState([500000, 50000000]);
  const [builtUpArea, setBuiltRange] = useState([100, 5000]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedPossessionStatus, setSelectedPossessionStatus] = useState([]);
  const [selectedBHK, setSelectedBHK] = useState([]);
  const [selectedConf, setSelectedConf] = useState([]);

  const [getProjectstatusData, getProjectStatus] = useState([]);
  const [getProjectBhkTypeData, getProjectBhkType] = useState([]);
  const [numericParts, getNumericParts] = useState([]);
  const [nonNumericParts, getNonNumericParts] = useState([]);

  const initialDisplayLimit = 20; // Change this number to show more/less items initially
  const [showAllBhk, setShowAllBhk] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [getAllParams1, setAllParams1] = useState([]);
  const locality_name = location.search.split('locality_name=')[1];
  const [popupVisible, setPopupVisible] = useState(false);
  const [currentPopupProject, setCurrentPopupProject] = useState(null);

  const [isLayerOff, setLayerOff] = useState(isMobile ? false : true);
  const [isMapBtn, setMapviewBtn] = useState(true);
  const [isListBtn, setListviewBtn] = useState(false);

  const getAllParams = (location) => {
    const searchParams = new URLSearchParams(location.search);
    const is_new_in_town = searchParams.get('is_new_in_town');
    const is_hot_selling = searchParams.get('is_hot_selling');
    const is_affordable = searchParams.get('is_affordable');
    const is_ready_to_move = searchParams.get('is_ready_to_move');
    const is_great_view = searchParams.get('is_great_view');
    const is_mandate = searchParams.get('is_mandate');
    const allParams = {};

    for (const [key, value] of searchParams.entries()) {
      allParams[key] = value;
    }
    // allParams.city = city;
    if (is_new_in_town === "true") {
      allParams.is_new_in_town = true;
    }
    if (is_hot_selling === "true") {
      allParams.is_hot_selling = true;
    }
    if (is_hot_selling === "true") {
      allParams.is_hot_selling = true;
    }
    if (is_affordable === "true") {
      allParams.is_affordable = true;
    }
    if (is_ready_to_move === "true") {
      allParams.is_ready_to_move = true;
    }
    if (is_great_view === "true") {
      allParams.is_great_view = true;
    }
    if (is_mandate === "true") {
      allParams.is_mandate = true;
    }
    allParams.page = currentPage + 1;
    allParams.per_page = 5;
    return allParams;
  }
  const [params, setParams] = useState(getAllParams(location));
  const boundsCoordinates = [
    [85, -180],  // Top-left corner of the world
    [85, 180],   // Top-right corner of the world
    [-85, 180],  // Bottom-right corner of the world
    [-85, -180]  // Bottom-left corner of the world
  ];
  let localityValue, latlong;
  if (locality_name) {
    localityValue = locality_name.toLowerCase();
    if (localityValue === 'sus') {
      latlong = [18.53869, 73.74532];
    } else if (localityValue === 'moshi') {
      latlong = [18.68686, 73.85851];
    } else if (localityValue === 'nibm') {
      latlong = [18.45926, 73.90726];
    } else if (localityValue === 'bavdhan') {
      latlong = [18.50754, 73.78353];
    } else if (localityValue === 'kharadi') {
      latlong = [18.5449, 73.9397];
    } else if (localityValue === 'baner') {
      latlong = [18.559, 73.7658];
    } else if (localityValue === 'wakad') {
      latlong = [18.591, 73.756];
    } else if (localityValue === 'hinjewadi') {
      latlong = [18.591, 73.756];
    } else if (localityValue === 'pashan') {
      latlong = [18.537, 73.807];
    } else if (localityValue === 'balewadi') {
      latlong = [18.576, 73.779];
    } else if (localityValue === 'aundh') {
      latlong = [18.563, 73.812];
    } else if (localityValue === 'kondhwa') {
      latlong = [18.478, 73.891];
    } else if (localityValue === 'punawale') {
      latlong = [18.632, 73.738];
    } else if (localityValue === 'ravet') {
      latlong = [18.651, 73.735];
    } else if (localityValue === 'tathawade') {
      latlong = [18.625, 73.765];
    } else if (localityValue === 'wagholi') {
      latlong = [18.591, 73.982];
      // }else if (localityValue.toLowerCase() === 'manjri') {
      //   latlong = [ 18.489, 73.941 ];
      // }else if (localityValue.toLowerCase() === 'undri') {
      //   latlong = [ 18.469, 73.917 ];
      //   latlong = [ 18.652, 73.718 ];
      // }else if(localityValue.toLowerCase() === 'kalyani nagar'){
      //   latlong = [ 18.548, 73.902 ];
      // }else if(localityValue.toLowerCase() === 'koregaon park'){
      //   latlong = [ 18.536, 73.893 ];
      // }else if(localityValue.toLowerCase() === 'viman nagar'){
      //   latlong = [ 18.567, 73.914 ];
    } else if (localityValue === 'kondhwa') {
      latlong = [18.478, 73.891];
    } else if (localityValue === 'kothrud') {
      latlong = [18.507, 73.807];
    } else if (localityValue === 'karve nagar') {
      latlong = [18.494, 73.822];
    } else {
      latlong = [18.49333, 73.85233];
    }
  } else {
    // console.log('Locality is undefined');
    latlong = [18.49333, 73.85233];
  }
  React.useEffect(() => {
    const handleScroll = () => {
      const fixedDiv = document.getElementById('sticky-map-rightsidebarwrap');
      const fixedDivMap = document.getElementById('sticky-map-rightsidebar');
      const footer = document.getElementById('footerdiv');

      if (fixedDiv && footer) {
        const footerRect = footer.getBoundingClientRect();
        const fixedDivRect = fixedDiv.getBoundingClientRect();

        if (window.scrollY > 2 && footerRect.top > window.innerHeight) {
          fixedDiv.classList.add('stickySidebar');
          fixedDiv.style.position = 'fixed';
          fixedDiv.style.bottom = 'initial';
          fixedDiv.style.height = 'calc(100vh - 160px)';
          fixedDivMap.classList.add('col-md-5');
          fixedDivMap.classList.remove('col-md-12');
        } else if (footerRect.top <= window.innerHeight) {
          fixedDiv.classList.remove('stickySidebar');
          fixedDiv.style.position = 'relative';
          fixedDiv.style.bottom = '0';
          fixedDiv.style.height = 'auto';
          fixedDivMap.classList.add('col-md-12');
          fixedDivMap.style.top = 'unset';
        } else {
          fixedDiv.classList.remove('stickySidebar');
          fixedDiv.style.position = 'fixed';
          fixedDiv.style.bottom = 'initial';
          fixedDiv.style.right = '0px';
          fixedDiv.style.height = 'calc(100vh - 160px)';
          fixedDivMap.classList.add('col-md-12');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  let newMap;
  useEffect(() => {
    if (!mapRef.current) {
      const zoomLevel = locality_name ? 13 : 10;
      newMap = L.map('sticky-map-rightsidebar').setView([latlong[0], latlong[1]], zoomLevel);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        // attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(newMap);
      newMap.on('moveend', () => {
        // console.log('Moveend event triggered');
        fetchProjectsInBounds(newMap);
      });
      newMap.on('zoomend', () => {
        // console.log('Zoomend event triggered');
        fetchProjectsInBounds(newMap);
      });

      setMap(newMap);
      mapRef.current = newMap;
      newMap.on('click', () => {
        setHoveredProject(null);
      });
      const locationPolygonCoords = {
        'wakad': polygonCoords,
        'baner': polygonCoordsBaner,
        'wagholi': polygonCoordsWagholi,
        'ravet': polygonCoordsRavet,
        'hadapsar': polygonCoordsHadapsar,
        'kharadi': polygonCoordsKharadi,
        'kothrud': polygonCoordsKothrud,
        'punawale': polygonCoordsPunawale,
        'tathawade': polygonCoordsTathawade,
        'bavdhan': polygonCoordsBavdhan,
        'nibm': polygonCoordsNIBM,
        'moshi': polygonCoordsMoshi,
        'sus': polygonCoordsSus,
        // Add more locations here
      };
      if (locality_name in locationPolygonCoords) {
        // Get the polygon coordinates for the current location
        const currentPolygonCoords = locationPolygonCoords[locality_name];

        // Create the polygon
        const overlayPolygon = L.polygon([boundsCoordinates, currentPolygonCoords], {
          color: '#192951',
          weight: 1,
          fillColor: '#192951',
          fillOpacity: 0.3,
          opacity: 0.3
        });

        // Add the polygon to the map
        overlayPolygon.addTo(newMap);
      }

      if (locality_name) {
        // Handle different localities
        switch (locality_name) {
          case 'wakad':
            L.polygon(polygonCoords, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'baner':
            L.polygon(polygonCoordsBaner, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'wagholi':
            L.polygon(polygonCoordsWagholi, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'ravet':
            L.polygon(polygonCoordsRavet, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'hadapsar':
            L.polygon(polygonCoordsHadapsar, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'kharadi':
            L.polygon(polygonCoordsKharadi, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'kothrud':
            L.polygon(polygonCoordsKothrud, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'punawale':
            L.polygon(polygonCoordsPunawale, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'tathawade':
            L.polygon(polygonCoordsTathawade, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'bavdhan':
            L.polygon(polygonCoordsBavdhan, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'nibm':
            L.polygon(polygonCoordsNIBM, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'moshi':
            L.polygon(polygonCoordsMoshi, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
          case 'sus':
            L.polygon(polygonCoordsSus, { color: '#43A66A', weight: 5, fillOpacity: 0 }).addTo(newMap);
            break;
        }
      }
    }
  }, []);
  useEffect(() => {
    setParams(getAllParams(location));
    let params = new URLSearchParams(location.search);
    let configurationValue = params.get('configuration');
    let projectStatusValue = params.get('project_status');
    let possessionStatusValue = params.get('possession_months');
    let minpriceValue = params.get('minprice');
    let maxpriceValue = params.get('maxprice');
    let minBuiltValue = params.get('carpet_min');
    let maxBuiltValue = params.get('carpet_max');
    if (configurationValue) {
      let configurationArray = configurationValue.split(',');
      setSelectedBHK(configurationArray);
    }
    if (projectStatusValue) {
      let projectStatusArray = projectStatusValue.split(',');
      setSelectedStatus(projectStatusArray);
    }
    if (possessionStatusValue) {
      let possessionStatusArray = possessionStatusValue.split(',');
      setSelectedPossessionStatus(possessionStatusArray);
    }
    if (minpriceValue || maxpriceValue) {
      setPriceRange([minpriceValue, maxpriceValue]);
    }
  }, [location.pathname, location.search,]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (selectedStatus.length != 0) {
    params.project_status = selectedStatus;
  }
  if (selectedPossessionStatus.length != 0) {
    params.possession_months = selectedPossessionStatus;
  }
  if (numericParts.length !== 0) {
    params.bhk = numericParts ? numericParts : 0;
    params.type = nonNumericParts;
  }
  if (selectedConf.length != 0) {
    params.configurations = selectedConf.toString().toLowerCase();
    // console.log("Configurations: ", selectedConf);
  }
  if (sortCriteria.length != 0) {
    params.orderby = sortCriteria;
  }
  if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
    let minPrice = Math.floor(priceRange[0]);
    let maxPrice = Math.ceil(priceRange[1]);
    params.minprice = minPrice;
    params.maxprice = maxPrice;
  }
  if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
    let minBuilt = Math.floor(builtUpArea[0]);
    let maxBuilt = Math.ceil(builtUpArea[1]);
    params.carpet_min = minBuilt;
    params.carpet_max = maxBuilt;
  }

  useEffect(() => {
    // This effect only clears the paginated data
    setResponseData([]);
    setPage(1);
    if (map) {
      console.log('Map instance exists, calling fetchProjectsInBounds');
      fetchProjectsInBounds(map);
    }
  }, [location.search, selectedStatus, selectedPossessionStatus, selectedBHK, sortCriteria, priceRange, map, builtUpArea]); // Dependencies that trigger data reset

  useEffect(() => {
    setTimeout(async () => {
      if (projects.length === 0 || map) {
        // getProjectStatusFunc();
        getProjectBhkTypeFunc();
        setAllParams1(params);
        getProjects(params, 0);
      }
    }, 1000);
  }, [params, selectedStatus, selectedPossessionStatus, selectedBHK, sortCriteria, map]);

  useEffect(() => {
    setParams(getAllParams(location));
    let params = new URLSearchParams(location.search);
    let configurationValue = params.get('configuration');
    let projectStatusValue = params.get('project_status');
    let possessionStatusValue = params.get('possession_months');
    let minpriceValue = params.get('minprice');
    let maxpriceValue = params.get('maxprice');
    let minBuiltValue = params.get('carpet_min');
    let maxBuiltValue = params.get('carpet_max');
    if (configurationValue) {
      let configurationArray = configurationValue.split(',');
      setSelectedBHK(configurationArray);
    }
    if (projectStatusValue) {
      let projectStatusArray = projectStatusValue.split(',');
      setSelectedStatus(projectStatusArray);
    }
    if (possessionStatusValue) {
      let possessionStatusArray = possessionStatusValue.split(',');
      setSelectedPossessionStatus(possessionStatusArray);
    }
    if (minpriceValue || maxpriceValue) {
      setPriceRange([minpriceValue, maxpriceValue]);
    }
    if (minBuiltValue || maxBuiltValue) {
      setBuiltRange([minBuiltValue, maxBuiltValue]);
    }
  }, [location.pathname, location.search,]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (selectedStatus.length != 0) {
    params.project_status = selectedStatus;
  }
  if (selectedPossessionStatus.length != 0) {
    params.possession_months = selectedPossessionStatus;
  }
  if (numericParts.length !== 0) {
    params.bhk = numericParts ? numericParts : 0;
    params.type = nonNumericParts;
  }
  if (selectedConf.length != 0) {
    params.configurations = selectedConf.toString().toLowerCase();
    // console.log("Configurations: ", selectedConf);
  }
  if (sortCriteria.length != 0) {
    params.orderby = sortCriteria;
  }
  if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
    let minPrice = Math.floor(priceRange[0]);
    let maxPrice = Math.ceil(priceRange[1]);
    params.minprice = minPrice;
    params.maxprice = maxPrice;
  }
  if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
    let minBuilt = Math.floor(builtUpArea[0]);
    let maxBuilt = Math.ceil(builtUpArea[1]);
    params.carpet_min = minBuilt;
    params.carpet_max = maxBuilt;
  }
  const handleSliderChange = (newRange) => {
    setPriceRange(newRange);
  };
  const handleSliderChangeArea = (newRangeArea) => {
    setBuiltRange(newRangeArea);
  };

  const debouncedHandleSliderChangeComplete = debounce((params) => {
    setAllParams1(params);
    getProjects(params, 0);
  }, 1000);
  const debouncedHandleSliderChangeCompleteArea = debounce((params) => {
    setAllParams1(params);
    getProjects(params, 0);
  }, 1000);

  const handleSliderChangeComplete = (params) => {
    if (locality_name?.length != 0) {
      if (locality_name !== undefined) {
        params.locality_name = locality_name;
      }
      params.searchtype = 'locality_name';
    }
    if (selectedStatus.length != 0) {
      params.project_status = selectedStatus;
    }
    if (selectedPossessionStatus.length != 0) {
      params.possession_months = selectedPossessionStatus;
    }
    if (numericParts.length !== 0) {
      params.bhk = numericParts ? numericParts : 0;
      params.type = nonNumericParts;
    }
    if (selectedConf.length != 0) {
      params.configurations = selectedConf.toString().toLowerCase();
    }
    if (sortCriteria.length != 0) {
      params.orderby = sortCriteria;
    }

    const minPrice = Math.floor(params[0]);
    const maxPrice = Math.ceil(params[1]);
    const priceparams = { ...params, minprice: minPrice, maxprice: maxPrice };
    delete priceparams[0];
    delete priceparams[1];
    let paramsURL = new URLSearchParams(location.search);
    paramsURL.set('minprice', minPrice);
    paramsURL.set('maxprice', maxPrice);
    location.search = paramsURL.toString();
    const newUrl = `${currentUrl}?${location.search}`;
    navigate(newUrl, { replace: true });
    debouncedHandleSliderChangeComplete(priceparams);
  };
  const handleSliderChangeCompleteArea = (params) => {
    if (locality_name?.length != 0) {
      if (locality_name !== undefined) {
        params.locality_name = locality_name;
      }
      params.searchtype = 'locality_name';
    }
    if (selectedStatus.length != 0) {
      params.project_status = selectedStatus;
    }
    if (selectedPossessionStatus.length != 0) {
      params.possession_months = selectedPossessionStatus;
    }
    if (numericParts.length !== 0) {
      params.bhk = numericParts ? numericParts : 0;
      params.type = nonNumericParts;
    }
    if (selectedConf.length != 0) {
      params.configurations = selectedConf.toString().toLowerCase();
    }
    if (sortCriteria.length != 0) {
      params.orderby = sortCriteria;
    }
    if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
      let minPrice = Math.floor(priceRange[0]);
      let maxPrice = Math.ceil(priceRange[1]);
      params.minprice = minPrice;
      params.maxprice = maxPrice;
    }
    const minBuilt = Math.floor(params[0]);
    const maxBuilt = Math.ceil(params[1]);
    const builtparams = { ...params, carpet_min: minBuilt, carpet_max: maxBuilt };
    delete builtparams[0];
    delete builtparams[1];
    let paramsURL = new URLSearchParams(location.search);
    paramsURL.set('carpet_min', minBuilt);
    paramsURL.set('carpet_max', maxBuilt);
    location.search = paramsURL.toString();
    // console.log('New Budget Price :- ', location.search);
    const newUrl = `${currentUrl}?${location.search}`;
    navigate(newUrl, { replace: true });
    debouncedHandleSliderChangeCompleteArea(builtparams);
  };

  useEffect(() => {
    // This effect only clears the paginated data
    setResponseData([]);
    setPage(1);
  }, [location.search, selectedStatus, selectedPossessionStatus, selectedBHK, sortCriteria, priceRange]); // Dependencies that trigger data reset
  useEffect(() => {
    setTimeout(async () => {
      if (projects.length === 0) {
        // getProjectStatusFunc();
        getProjectBhkTypeFunc();
        setAllParams1(params);
        getProjects(params, 0, map);
      }
    }, 10);
  }, [params, selectedStatus, selectedPossessionStatus, selectedBHK, sortCriteria]);

  const getProjects = async (paramsData, scrollpage, newMap) => {
    if (paramsData === undefined || paramsData === "") {
      paramsData = getAllParams1;
    }
    let result = {};
    try {
      if (flagPage === false || scrollpage === 0) {
        scrollpage = 0;
        newPage = 1;
        setPage(newPage);
        paramsData.page = newPage;
      } else {
        scrollpage = 1;
        newPage = page + 1;
        setPage(newPage);
        paramsData.page = newPage;
      }
      const path = location.pathname.split("/");
      const seoparam = path[path.length - 1].split("-");
      const seobedroom = seoparam[0];
      const seocity = seoparam[seoparam.length - 1];
      // SEO URL like: /2-bhk-flats-for-sale-in-wakad-pune
      const seolocality = seoparam[6];
      if (seoparam.length === 8 && seoparam[1] === 'bhk') {
        paramsData.city = seocity;
        paramsData.locality_name = seolocality;
        paramsData.bhk = seobedroom;
        paramsData.type = 'bhk';
        paramsData.searchtype = 'locality_name';
      }
      // SEO URL like: /2-bhk-flats-for-sale-in-pune
      if (seoparam.length === 7 && seoparam[1] === 'bhk') {
        paramsData.city = seocity;
        paramsData.bhk = seobedroom;
        paramsData.type = 'bhk';
      }
      // SEO URL like: /property-in-pune
      if (seoparam.length === 3 && seoparam[0] === 'property') {
        paramsData.city = seocity;
      }
      paramsData.per_page = initialDisplayLimit;
      result = await executeLaravelFrontAPI('projects', { ...paramsData }, 'GET');
      const newItems = result.data.data;
      setResponseData(prevProjects => {
        const addedProjectIds = new Set(prevProjects.map(p => p.id));
        newItems.forEach(project => {
          if (!addedProjectIds.has(project.id)) {
            // Check if map is not null before calling addMarker
            if (map !== null) {
              addMarker(project, map);
            } else {
              console.error('Map object is null, cannot add marker.');
            }
          }
        });
        if (scrollpage === 1) {
          setPage(page + 1);
          return [...prevProjects, ...newItems];
        }
        if (scrollpage === 0) {
          setPage(1);
          return [...newItems];
        }
      });
      setTotalItems(result.data.total);
      setHasMore(newItems.length === initialDisplayLimit);
      flagPage = true;
      // newItems.forEach(project => addMarker(project));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      // setLoading(false);
      if (result.data?.total === 0) {
        setNoDataFound(true);
        setResponseData([]);
        setPage(1);
      }
    }
  };

  const fetchProjectsInBounds = async (newMap) => {
    let result = {};
    try {
      if (!newMap) {
        console.error('Map instance is null or undefined');
        return;
      }
      const bounds = newMap.getBounds();
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();
      const per_page = 10;
      const params = getAllParams(location);
      if (locality_name?.length != 0) {
        if (locality_name !== undefined) {
          params.locality_name = locality_name;
        }
        params.searchtype = 'locality_name';
      }
      if (selectedStatus.length !== 0) {
        params.project_status = selectedStatus;
      }
      if (selectedPossessionStatus.length !== 0) {
        params.possession_months = selectedPossessionStatus;
      }
      if (numericParts.length !== 0) {
        params.bhk = numericParts ? numericParts : 0;
        params.type = nonNumericParts;
      }
      if (selectedConf.length !== 0) {
        params.configurations = selectedConf.toString().toLowerCase();
      }
      if (sortCriteria.length !== 0) {
        params.orderby = sortCriteria;
      }
      if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
        let minPrice = Math.floor(priceRange[0]);
        let maxPrice = Math.ceil(priceRange[1]);
        params.minprice = minPrice;
        params.maxprice = maxPrice;
      }
      if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
        let minBuilt = Math.floor(builtUpArea[0]);
        let maxBuilt = Math.ceil(builtUpArea[1]);
        params.carpet_min = minBuilt;
        params.carpet_max = maxBuilt;
      }
      params.sw_lat = sw.lat;
      params.sw_lng = sw.lng;
      params.ne_lat = ne.lat;
      params.ne_lng = ne.lng;
      params.per_page = initialDisplayLimit;
      // const paramsData = {
      //   ...params,
      //   sw_lat: sw.lat,
      //   sw_lng: sw.lng,
      //   ne_lat: ne.lat,
      //   ne_lng: ne.lng,
      //   per_page: initialDisplayLimit,
      // };
      result = await executeLaravelFrontAPI(`projects`, params, 'GET');
      const newItems = result.data.data;
      mapRef.current.eachLayer(layer => {
        if (layer instanceof L.Marker) {
          mapRef.current.removeLayer(layer);
        }
      });
      setParams(params);
      // // Update the state with new projects
      setResponseData(newItems);

      // // Add new markers to the map
      newItems.forEach(project => {
        addMarker(project, newMap);
      });
      setTotalItems(result.data.total);
      setHasMore(newItems.length === initialDisplayLimit);
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      // setLoading(false);
      if (result.data?.total === 0) {
        setNoDataFound(true);
        setResponseData([]);
        setPage(1);
      }
    }
  };
  {/* Start Project Status Sort Function */ }
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortOrderBHK, setSortOrderBHK] = useState('asc');
  // const [sortOrderConf, setSortOrderConf] = useState('asc');

  const statusOrder = {
    'New Launch': 1,
    'Under Construction': 2,
    'Ready To Move': 3,
    // 'Lunch': 4,
    // 'ongoing': 5,
    // 'prelaunch': 6,
  };
  const toggleStatus = (status) => {
    setSelectedStatus((prevStatus) => {
      const newStatus = prevStatus.includes(status)
        ? prevStatus.filter(item => item !== status) // Remove if already selected
        : [...prevStatus, status]; // Add if not already selected
      // Update params and fetch projects
      const params = getAllParams(location);
      if (locality_name?.length != 0) {
        if (locality_name !== undefined) {
          params.locality_name = locality_name;
        }
        params.searchtype = 'locality_name';
      }
      if (newStatus.length !== 0) {
        params.project_status = newStatus;
      }
      if (numericParts.length !== 0) {
        params.bhk = numericParts ? numericParts : 0;
        params.type = nonNumericParts;
      }
      if (selectedConf.length !== 0) {
        params.configurations = selectedConf.toString().toLowerCase();
      }
      if (sortCriteria.length !== 0) {
        params.orderby = sortCriteria;
      }
      if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
        let minPrice = Math.floor(priceRange[0]);
        let maxPrice = Math.ceil(priceRange[1]);
        params.minprice = minPrice;
        params.maxprice = maxPrice;
      }
      if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
        let minBuilt = Math.floor(builtUpArea[0]);
        let maxBuilt = Math.ceil(builtUpArea[1]);
        params.carpet_min = minBuilt;
        params.carpet_max = maxBuilt;
      }
      let paramsURL = new URLSearchParams(location.search);
      paramsURL.set('project_status', newStatus);
      location.search = paramsURL.toString();
      console.log('New Project Status :- ', location.search);
      const newUrl = `${currentUrl}?${location.search}`;
      navigate(newUrl, { replace: true });
      setCurrentPage(0);
      setPage(1);

      params.page = 1;
      getProjects(params, 0);

      return newStatus; // Return the updated state value
    });
  };

  const togglePossessionStatus = (status) => {
    // console.log("status", status);
    // const newStatus = parseStatus(status);
    // console.log("statuschange", newStatus);
    setSelectedPossessionStatus((prevStatus) => {
      // console.log("prevStatus", prevStatus);
      const newStatus = prevStatus.includes(status)
        ? [] // Remove if already selected, resulting in an empty array
        : [status]; // Set as the only selected value

      // to return old exact array
      // const newStatus = prevStatus.includes(status)
      //     ? prevStatus.filter(item => item !== status) // Remove if already selected
      //     : [...prevStatus, status]; // Add if not already selected
      // Update params and fetch projects
      const params = getAllParams(location);
      if (locality_name?.length != 0) {
        if (locality_name !== undefined) {
          params.locality_name = locality_name;
        }
        params.searchtype = 'locality_name';
      }
      if (newStatus.length !== 0) {
        params.possession_months = newStatus;
      }
      if (numericParts.length !== 0) {
        params.bhk = numericParts ? numericParts : 0;
        params.type = nonNumericParts;
      }
      if (selectedConf.length !== 0) {
        params.configurations = selectedConf.toString().toLowerCase();
      }
      if (sortCriteria.length !== 0) {
        params.orderby = sortCriteria;
      }
      if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
        let minPrice = Math.floor(priceRange[0]);
        let maxPrice = Math.ceil(priceRange[1]);
        params.minprice = minPrice;
        params.maxprice = maxPrice;
      }
      if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
        let minBuilt = Math.floor(builtUpArea[0]);
        let maxBuilt = Math.ceil(builtUpArea[1]);
        params.carpet_min = minBuilt;
        params.carpet_max = maxBuilt;
      }
      let paramsURL = new URLSearchParams(location.search);
      // calculateMonths(newStatus);
      // console.log("adaaarshh--"+newStatus);
      paramsURL.set('possession_months', newStatus);
      if (newStatus.length === 0) {
        paramsURL.delete('possession_months');
      }
      location.search = paramsURL.toString();
      // console.log('New Possession Status :- ', location.search);
      const newUrl = `${currentUrl}?${location.search}`;
      navigate(newUrl, { replace: true });
      setCurrentPage(0);
      setPage(1);

      params.page = 1;
      // getProjects(params, 0);

      return newStatus; // Return the updated state value
    });
  };

  function getSortProjectFilter(sortCriteria) {
    const params = getAllParams(location);
    if (locality_name?.length != 0) {
      if (locality_name !== undefined) {
        params.locality_name = locality_name;
      }
      params.searchtype = 'locality_name';
    }
    if (selectedStatus.length != 0) {
      params.project_status = selectedStatus;
    }
    if (selectedPossessionStatus.length != 0) {
      params.possession_months = selectedPossessionStatus;
    }
    if (numericParts.length !== 0) {
      params.bhk = numericParts ? numericParts : 0;
      params.type = nonNumericParts;
    }
    if (selectedConf.length != 0) {
      params.configurations = selectedConf.toString().toLowerCase();
    }
    if (sortCriteria.length != 0) {
      params.orderby = sortCriteria;
    }
    if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
      let minPrice = Math.floor(priceRange[0]);
      let maxPrice = Math.ceil(priceRange[1]);
      params.minprice = minPrice;
      params.maxprice = maxPrice;
    }
    if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
      let minBuilt = Math.floor(builtUpArea[0]);
      let maxBuilt = Math.ceil(builtUpArea[1]);
      params.carpet_min = minBuilt;
      params.carpet_max = maxBuilt;
    }
    let paramsURL = new URLSearchParams(location.search);
    paramsURL.set('orderby', sortCriteria);
    location.search = paramsURL.toString();
    const newUrl = `${currentUrl}?${location.search}`;
    navigate(newUrl, { replace: true });
    createGa4Event('sort_filter', 'Sort Filter', sortCriteria)
    setSortCriteria(sortCriteria);
    getProjects(params, 0);
  }

  const sortProjectsByBhkType = (status) => {
    const order = sortOrderBHK === 'asc' ? 'desc' : 'asc';
    const sorted = [...projects].sort((a, b) => {
      const orderFactor = order === 'asc' ? 1 : -1;
      return orderFactor * (statusOrder[a.configurations.name] - statusOrder[b.configurations.name]);
    });
    setSortOrderBHK(order);
    setSelectedTab(status);
    setCurrentPage(0);
  };

  function getSortProjectFilter(sortCriteria) {
    const params = getAllParams(location);
    if (locality_name?.length != 0) {
      if (locality_name !== undefined) {
        params.locality_name = locality_name;
      }
      params.searchtype = 'locality_name';
    }
    if (selectedStatus.length != 0) {
      params.project_status = selectedStatus;
    }
    if (selectedPossessionStatus.length != 0) {
      params.possession_months = selectedPossessionStatus;
    }
    if (numericParts.length !== 0) {
      params.bhk = numericParts ? numericParts : 0;
      params.type = nonNumericParts;
    }
    if (selectedConf.length != 0) {
      params.configurations = selectedConf.toString().toLowerCase();
    }
    if (sortCriteria.length != 0) {
      params.orderby = sortCriteria;
    }
    if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
      let minPrice = Math.floor(priceRange[0]);
      let maxPrice = Math.ceil(priceRange[1]);
      params.minprice = minPrice;
      params.maxprice = maxPrice;
    }
    if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
      let minBuilt = Math.floor(builtUpArea[0]);
      let maxBuilt = Math.ceil(builtUpArea[1]);
      params.carpet_min = minBuilt;
      params.carpet_max = maxBuilt;
    }
    let paramsURL = new URLSearchParams(location.search);
    paramsURL.set('orderby', sortCriteria);
    location.search = paramsURL.toString();
    const newUrl = `${currentUrl}?${location.search}`;
    navigate(newUrl, { replace: true });
    createGa4Event('sort_filter', 'Sort Filter', sortCriteria)
    setSortCriteria(sortCriteria);
    getProjects(params, 0);
  }
  const toggleBhkType = (bhkType) => {
    setSelectedBHK((prevBhkType) => {
      const newBhkType = prevBhkType.includes(bhkType)
        ? prevBhkType.filter(item => item !== bhkType) // Remove if already selected
        : [...prevBhkType, bhkType]; // Add if not already selected

      console.log("Selected BHK Types:------", newBhkType);

      // Check if the newBhkType array is empty to display the "Data not found" message
      if (newBhkType.length === 0) {
        console.log("Data not found"); // Show message or handle the scenario
      }

      // Update params and fetch projects
      const params = getAllParams(location);

      if (locality_name?.length != 0) {
        if (locality_name !== undefined) {
          params.locality_name = locality_name;
          console.log(locality_name);
        }
        params.searchtype = 'locality_name';
      }
      if (selectedStatus.length !== 0) {
        params.project_status = selectedStatus;
      }
      if (selectedPossessionStatus.length != 0) {
        params.possession_months = selectedPossessionStatus;
      }
      getNumericParts(newBhkType.map(type => type.match(/^\d*\.?\d*/)[0] === "" ? '0' : type.match(/^\d*\.?\d*/)[0]));
      getNonNumericParts(newBhkType.map(type => type.match(/[a-zA-Z]+$/)[0].toLowerCase()));

      const numericPartsNew = newBhkType.map(type => type.match(/^\d*\.?\d*/)[0] === "" ? '0' : type.match(/^\d*\.?\d*/)[0]);
      const nonNumericPartsNew = newBhkType.map(type => type.match(/[a-zA-Z]+$/)[0].toLowerCase());
      if (numericPartsNew.length !== 0) {
        params.bhk = numericPartsNew ? numericPartsNew : 0;
        params.type = nonNumericPartsNew;
      }
      if (selectedConf.length !== 0) {
        params.configurations = selectedConf.toString().toLowerCase();
      }
      if (sortCriteria.length !== 0) {
        params.orderby = sortCriteria;
      }
      if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
        let minPrice = Math.floor(priceRange[0]);
        let maxPrice = Math.ceil(priceRange[1]);
        params.minprice = minPrice;
        params.maxprice = maxPrice;
      }
      if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
        let minBuilt = Math.floor(builtUpArea[0]);
        let maxBuilt = Math.ceil(builtUpArea[1]);
        params.carpet_min = minBuilt;
        params.carpet_max = maxBuilt;
      }
      let paramsURL = new URLSearchParams(location.search);
      let newBhkTypeArray = Array.isArray(newBhkType) ? newBhkType : newBhkType.split(',');
      paramsURL.set('configuration', newBhkTypeArray);
      let numericPartsNewArray = Array.isArray(numericPartsNew) ? numericPartsNew : numericPartsNew.split(',');
      paramsURL.set('bhk', numericPartsNewArray);
      let nonNumericPartsNewArray = Array.isArray(nonNumericPartsNew) ? nonNumericPartsNew : nonNumericPartsNew.split(',');
      paramsURL.set('type', nonNumericPartsNewArray);
      location.search = paramsURL.toString();
      const newUrl = `${currentUrl}?${location.search}`;
      navigate(newUrl, { replace: true });

      setSelectedTab(bhkType);
      setCurrentPage(0);
      setPage(1);
      getProjects(params, 0);

      return newBhkType;
    });
  };
  const getProjectBhkTypeFunc = async () => {
    try {
      const result = await executeLaravelFrontAPI('bhkconfigurations', "", 'GET');
      getProjectBhkType(result.data);
    } catch (error) {
      console.error('Error parsing JSON:', error);
    } finally {

    }
  };
  const filterByBhkType = (result) => {
    // return result.filter(item => !isNaN(item.name.charAt(0))) || [];
  };

  //const filteredByBhk = filterByBhkType(getProjectBhkTypeData);
  const filteredByBhk = getProjectBhkTypeData;
  const toggleShowAllBhk = () => {
    setShowAllBhk(!showAllBhk);
  };

  const [selectedTab, setSelectedTab] = useState(null);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleFilter = (newRange) => {
    const params = getAllParams(location);
    params.minprice = newRange[0];
    params.maxprice = newRange[1];
    setPage(1);
    getProjects(params);
    createGa4Event('filter_budget', 'Filter Budget', newRange[0] + "-" + newRange[1])
  };
  const handleFilterArea = (newRange) => {
    const params = getAllParams(location);
    params.carpet_min = newRange[0];
    params.carpet_max = newRange[1];
    setPage(1);
    getProjects(params);
    createGa4Event('filter_builtup', 'Built Up Filter', `${newRange[0]} - ${newRange[1]}`);
  };
  const handleShowFilterSidebarBtn = () => {
    const rightSideDiv = document.querySelector('.filter-sidebar');
    rightSideDiv.classList.toggle('active');
  }
  const handleHideFilterSidebarBtn = () => {
    // const filterForm = document.querySelector('.filter-sidebar');
    // filterForm.reset();
    handleShowFilterSidebarBtn();
    window.scrollTo(0, 0);
  }
  const handleShowSortSidebarBtn = () => {
    const sortSideBar = document.getElementById('sort-filter');
    sortSideBar.classList.toggle('active');
  }
  const handleHideSortSidebarBtn = () => {
    const sortSideBar = document.getElementById('sort-filter');
    sortSideBar.classList.remove('active');
  }
  const showMapView = () => {
    setLayerOff(true);
    setMapviewBtn(false);
    setListviewBtn(true);
  }
  const showListView = () => {
    setLayerOff(false);
    setMapviewBtn(true);
    setListviewBtn(false);
  }

  useEffect(() => {
    if (hoveredProject) {
      const projectMarker = markers.find(({ projectName }) => projectName === hoveredProject);
      if (projectMarker) {
        showCustomPopup(projectMarker.marker, projectMarker.project, 'right');
      }
    } else {
      map && map.closePopup();
    }
  }, [hoveredProject, markers, map]);

  const addMarker = (project, map) => {
    // if (!map) {
    //   console.error('Map instance is not defined');
    //   return;
    // }
    const lat = Number(project.address.latitude);
    const lng = Number(project.address.longitude);
    if (isNaN(lat) || isNaN(lng)) {
      console.error('Invalid latitude or longitude:', project);
      return;
    }
    const customIcon = L.divIcon({
      className: 'custom-icon',
      html: '<div class="icon-dot"></div>',
      iconSize: [10, 10],
      iconAnchor: [5, 5],
    });
    const css = `
    .leaflet-marker-icon{
      border-radius: 50%;
    }
    .custom-icon .icon-dot {
      width: 8px;
      height: 8px;
      background-color: #082040;
      border-radius: 50%;
      transition: transform 0.7s ease-in-out;
    }
    .custom-icon:hover .icon-dot {
      width: 20px;
      height: 20px;
      background-color:rgba(8,32,64,0.5);
      position:relative;
      top:-6px;
      left:-6px;
      border-radius: 50%;
    }
  .leaflet-popup-tip-container {
          display: none;
      }
    .custom-icon:hover .icon-dot:after,
    .custom-icon.hover .icon-dot:after {
      content:'';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color:rgba(8,32,64,1);
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
    }`;
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    head.appendChild(style);
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));

    const marker = L.marker([lat, lng], { icon: customIcon }).addTo(map);
    marker.on('mouseover', () => {
      setHoveredProject(project.name);
      setCurrentPopupProject(project);
      setPopupVisible(true);
      marker.getElement().classList.add('hover');
    });
    marker.on('click', () => {
      setHoveredProject(project.name);
      setCurrentPopupProject(project);
      setPopupVisible(true);
      marker.getElement().classList.add('hover');
    });
    marker.on('mouseout', (e) => {
      if (!e.relatedTarget || !e.relatedTarget.closest('.leaflet-popup')) {
        setHoveredProject(null);
        marker.getElement().classList.remove('hover');
      }
    });
    setMarkers((prevMarkers) => [...prevMarkers, { projectName: project.name, marker, project }]);
  };

  const clearMarkers = () => {
    markers.forEach(({ marker }) => {
      map.removeLayer(marker);
    });
    setMarkers([]);
  };

  const handleProjectMouseOver = (projectName) => {
    setHoveredProject(projectName);
  };

  const handleProjectMouseOut = () => {
    setHoveredProject(null);
  };

  const showCustomPopup = (marker, project, position = 'right') => {
    const imageUrl = project.gallery && Array.isArray(project.gallery) && project.gallery.length > 0 ? encodeURI(`${s3url}/project-assets${project.gallery[0].url}`) : defaultGallery;
    const projectUrl = `${appAbsPath}/project/${location.slug}`;
    const minMaxPrice = getMinMaxPrice(project);
    const minPrice = minMaxPrice && minMaxPrice.minPrice !== null ? formatNumber(minMaxPrice.minPrice.toFixed(2)) : null;
    const maxPrice = minMaxPrice && minMaxPrice.maxPrice !== null ? formatNumber(minMaxPrice.maxPrice.toFixed(2)) : '';
    const priceRange = minPrice !== null && minPrice !== 0 ? `₹${minPrice} - ${maxPrice}` : '';

    const minMaxCarpets = getMinMaxCarpets(project);
    const minCarpet = minMaxCarpets.minCarpet.toFixed(0);
    const maxCarpet = minMaxCarpets.maxCarpet.toFixed(0);

    const popupContent = `
      <div class='inner-map-popup' id=${project.id}>
        <a href=${projectUrl} class='text-decoration-none'>
          <img src=${imageUrl} class='project-img' />
          <div class='leaflet-popup-content'>
            <h4 class='mb-2 mt-2 fw-bold'>${priceRange}</h4>
            ${getSortBHKConf(project)}, ${minCarpet} - ${maxCarpet} sq.ft.
            <a href='${appAbsPath}/project/${project.slug}' onClick='createGa4Event("project_view", "Project View", "${project.name}")' class='text-secondary text-capitalize text-decoration-none'>
              <h6 class="m-0">${project.name}</h6>
            </a>
            <p class='mb-0 developer-name'>By <span>${project.developer.name}</span>, ${project?.address?.location}, ${project?.address?.city}</p>
          </div>
        </a>
      </div>
    `;
    const markerLatLng = marker.getLatLng();
    const mapBounds = map.getBounds();
    let offset = [0, 0];

    if (markerLatLng.lat > mapBounds.getCenter().lat) {
      offset[1] = -115;
    } else {
      offset[1] = 115;
    }

    if (markerLatLng.lng > mapBounds.getCenter().lng) {
      offset[0] = -120;
    } else {
      offset[0] = 120;
    }

    const popup = L.popup({
      autoPan: false,
      keepInView: true,
      closeButton: true,
      className: 'custom-popup',
      // offset: offset
    })
      .setLatLng(marker.getLatLng())
      .setContent(popupContent)
      .openOn(map);
  };



  return (
    <main className='plp-page pb-5 pt-lg-5 pt-2 mt-5 mt-md-5 bg-white test'>
      <section className="pt-0 mt-5 pt-md-4">
        <div className="px-4">
          <div className="row filter-sticky-map  d-none d-md-flex">
            <div className='col-8'>
              <NewFilterSidebar
                selectedStatus={selectedStatus} toggleStatus={toggleStatus}
                selectedPossessionStatus={selectedPossessionStatus} togglePossessionStatus={togglePossessionStatus}
                // sortProjectsByStatus={sortProjectsByStatus}
                filteredByBhk={filteredByBhk} initialDisplayLimit={initialDisplayLimit} showAllBhk={showAllBhk} toggleShowAllBhk={toggleShowAllBhk} selectedBHK={selectedBHK}
                toggleBhkType={toggleBhkType} sortProjectsByBhkType={sortProjectsByBhkType} setSelectedTab={setSelectedTab}
                priceRange={priceRange} handleSliderChange={handleSliderChange} handleSliderChangeComplete={handleSliderChangeComplete} builtUpArea={builtUpArea} handleSliderChangeArea={handleSliderChangeArea} handleSliderChangeCompleteArea={handleSliderChangeCompleteArea}
                getProjectstatusData={getProjectstatusData}
              />
            </div>
            <div className='col-4'>
              <div className="d-flex row">
                <div className='col-lg-12 col-12 row'>
                  <div className='col-12 mt-2 text-end d-inline-flex align-items-center justify-content-end pe-0 text-end'>
                    <span className='me-2 d-none d-md-block'>Sort by: </span>
                    <select className="form-select form-select-sm  form-select-sm d-none d-md-block rounded-pill" value={sortCriteria} onChange={(e) => getSortProjectFilter(e.target.value)} style={{ "width": "200px" }}>
                      <option value="recentlyLaunched">Recently Launched</option>
                      <option value="priceLowToHigh">Price: Low to High</option>
                      <option value="priceHighToLow">Price: High to Low</option>
                      <option value="sqftLowToHigh">Sq. Ft.: Low to High</option>
                      <option value="sqftHighToLow">Sq. Ft.: High to Low</option>
                      <option value="possessionDate">Possession Date (Recent First)</option>
                      {/* <option value="updatedat">Latest Updated</option> */}
                    </select>

                    <a href={`${appAbsPath}/projects${location.search}`} className={` ms-2 text-decoration-underline border p-1  rounded-pill`} onClick={() => createGa4Event('On Click', 'List View', 'List View')}>
                      <Icon icon="uim:list-ul" style={{ "color": "rgb(107, 107, 107)", "width": "40px", "height": "25px" }} />
                    </a>
                    <a className={`text-primary ms-2 text-decoration-underline border-primary p-1 rounded-pill`} onClick={() => createGa4Event('On Click', 'Map View', 'Map View')}>
                      <Icon icon="uiw:map" style={{ "color": "", "width": "40px", "height": "25px" }} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div id="projects" className='col-12 col-md-7'>
              <div className='row'>
                <div className='col-lg-12 col-12 pb-3'>
                  {projects ? (
                    projects.length === 0 && loading ? (
                      (noDataFound ? (
                        <></>
                      ) : (
                        <Placeholder animation="wave" >
                          <Placeholder xs={12} className="custom-loading-skeleton" />
                        </Placeholder>
                      ))
                    ) : (
                      <h1 className="fs-6 mt-2 fw-bold"><span className="text-primary">Showing</span> <span id="search-count" className="text-primary">{totalItems} </span>
                        Properties
                      </h1>
                    )
                  ) : (
                    <Placeholder animation="wave" >
                      <Placeholder xs={12} className="custom-loading-skeleton" />
                    </Placeholder>
                  )}
                </div>
                <div className='col-12 col-md-12 mt-0 mt-md-0'>
                  {projects ? (
                    projects.length === 0 && loading ? (
                      (noDataFound ? (
                        <div className="text-center mt-5">
                          <h2>No Projects Found</h2>
                        </div>
                      ) : (
                        <Loader />
                      ))
                    ) : (
                      <div className="">
                        <Suspense fallback={<Loader />}>
                          <InfiniteScroll
                            dataLength={projects.length}
                            scrollableTarget="scrollableDiv"
                            next={() => getProjects(params, page + 1, map)}
                            hasMore={hasMore}
                            loader={<h4><span className="custom-loader m-auto"></span></h4>}
                            endMessage={<p className='text-center'>You've reached the end of the list. <br /> Explore more properties by adjusting your filters or refining your search.</p>}
                          >
                            <div className="row mx-0">
                              {projects.map((project, index) => (

                                <div className="tabcontent rounded-8 mb-3 col-lg-6 col-12 px-0 px-lg-2 new-map-view-card" id={project.id} key={index} onMouseOver={() => handleProjectMouseOver(project.name)}
                                  onMouseOut={() => handleProjectMouseOut(project.name)}>
                                  <div className="px-0 rounded pe-md-0 p-0 plp-card-main">
                                    <div className="image-side position-relative">
                                      {project && project.gallery && Array.isArray(project.gallery) && project.gallery.length > 0 ? (
                                        <Carousel data-bs-theme="light">
                                          {project.gallery.map((image, index) => (
                                            <Carousel.Item key={index}>
                                              <Link to={`${appAbsPath}/project/${project.slug}`} onClick={() => { createGa4Event("project_view", 'Project View', `${project.name}`) }} className='text-decoration-none'>
                                                {/* <div className='lazyload-placeholder project-img' style={{ backgroundImage: `url(${s3url}/project-assets${image.url})` }}></div> */}
                                                <img src={`${s3url}/project-assets${image.url}`} />
                                              </Link>
                                            </Carousel.Item>
                                          ))}
                                        </Carousel>
                                      ) : (
                                        <>
                                          <div className='lazyload-placeholder project-img' style={{ backgroundImage: `url(${defaultGallery})` }}></div>
                                        </>
                                      )}
                                      <WishListBtn projectId={project.id} loggeduserdetails={parsedUserData} color={"#fff"} width={"30px"} height={"30px"} handleNotLogin />
                                      <div className="position-absolute zindex-10 top-0 bottom-0 start-0 m-3 top-picks">
                                        <div className="d-flex flex-column text-center">
                                          <span className="fw-bold"><Icon icon="mingcute:star-fill" className='mb-1' color={"#ffce31"} />


                                            {dateDiffInMonths(project.completion_timestamp, new Date()) <= 3 ? 'Ready To Move' : project.project_status == '' ? dateDiffInMonths(project.completion_timestamp, new Date()) > 3 && dateDiffInMonths(project.completion_timestamp, new Date()) < 24 ? 'Under Construction' : 'New Project' : project.project_status}
                                            {/* {project.project_status} */}
                                          </span>
                                          {/* <span className="ready-since text-white">(Since Jun, 2021)</span> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex-grow-1 flex-basis-0 position-relative pe-0 pe-md-4 prjt-info-wrap">
                                      <div className="pt-2 position-relative p-3 info-box">
                                        <h4 className='mb-2 mt-2 fw-bold'>{getMinMaxPrice(project) && getMinMaxPrice(project).minPrice !== null && formatNumber((getMinMaxPrice(project).minPrice).toFixed(2)) !== 0
                                          ? <span>{`₹${formatNumber((getMinMaxPrice(project).minPrice).toFixed(2))} - ${getMinMaxPrice(project) && getMinMaxPrice(project).maxPrice !== null ? formatNumber((getMinMaxPrice(project).maxPrice).toFixed(2)) : ''}`}</span>
                                          : null
                                        }</h4>
                                        {getSortBHKConf(project)}, {(getMinMaxCarpets(project).minCarpet).toFixed(0)} - {(getMinMaxCarpets(project).maxCarpet).toFixed(0)} sq.ft.
                                        <Link to={`${appAbsPath}/project/${project.slug}`} onClick={() => { createGa4Event("project_view", 'Project View', `${project.name}`) }} className='text-secondary text-capitalize text-decoration-none' >
                                          <h6 className="m-0">{project.name}</h6>
                                        </Link>
                                        <p className='mb-0 developer-name'>By <span>{project.developer.name}</span>, {project?.address?.location}, {project?.address?.city}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              ))}
                            </div>
                          </InfiniteScroll>
                        </Suspense>
                      </div>
                    )
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
            <div className={`col-12 col-md-5 p-0 ${isLayerOff && "active"}`} id="sticky-map-rightsidebarwrap" style={{ height: 'calc(100vh - 160px)' }}>
              <div className='col-12 col-md-5' id="sticky-map-rightsidebar"></div>
            </div>
          </div>
        </div>

      </section>
      {/* Mobile map view and List View */}
      <div className='mobile-map-list-view d-block d-md-none test'>
        {/* {isListBtn &&
          <a className={`text-primary ms-2 text-decoration-underline border p-1 border-primary rounded-pill listbtn`} onClick={() => showListView()}>
            <Icon icon="uim:list-ul" style={{ "color": "#43A66A", "width": "40px", "height": "25px" }} />
          </a>
        }
        {isMapBtn &&
          <a className={`text-primary ms-2 text-decoration-underline border p-1 border-primary rounded-pill mapbtn`} onClick={() => showMapView()}>
            <Icon icon="uiw:map" style={{ "color": "#43A66A", "width": "40px", "height": "25px" }} />
          </a>
        } */}
        <a href={`${appAbsPath}/projects${location.search}`} className={` ms-2 text-decoration-underline border p-1  rounded-pill`} onClick={() => createGa4Event('On Click', 'List View', 'List View')}>
          <Icon icon="uim:list-ul" style={{ "color": "rgb(107, 107, 107)", "width": "40px", "height": "25px" }} />
        </a>
        <a className={`text-primary ms-2 text-decoration-underline border-primary p-1 rounded-pill`} onClick={() => createGa4Event('On Click', 'Map View', 'Map View')}>
          <Icon icon="uiw:map" style={{ "color": "", "width": "40px", "height": "25px" }} />
        </a>
      </div>
      {/* Mobile Filters */}
      <div className='d-block d-md-none mobile-filter-wrap'>
        <a className='filter-sidebar-sort' onClick={() => handleShowSortSidebarBtn()}><Icon icon="bx:sort" /> Sort</a>
        <a className='filter-sidebar-btn' onClick={() => handleShowFilterSidebarBtn()}><Icon icon="mage:filter" /> Filter</a>
      </div>
      <div className='d-block d-md-none mobile-filter-wrap-popup' id="sort-filter">
        <div className='filter-sidebar-sort-popup'>
          <h4>Sort By: <a className='float-end' onClick={() => handleHideSortSidebarBtn()}><Icon icon="codicon:close" style={{ "color": "#6b6b6b" }} /></a></h4>
          <hr />
          <ul>
            {/* <li onClick={() => { getSortProjectFilter("Relevance"); handleHideSortSidebarBtn(); createGa4Event('map_view', 'Map View', 'Map View') }}>
              <div className='d-flex justify-content-between'>
                Relevances
              </div>
            </li> */}
            <li onClick={() => { getSortProjectFilter("recentlyLaunched"); handleHideSortSidebarBtn(); createGa4Event('On Click', 'Filters', 'Recently Launched') }}>
                <div className='d-flex justify-content-between'>
                    Recently Launched
                </div>
            </li>
            <li onClick={() => { getSortProjectFilter("priceLowToHigh"); handleHideSortSidebarBtn() }}>
              <div className='d-flex justify-content-between'>
                Price: Low to High
              </div>
            </li>
            <li onClick={() => { getSortProjectFilter("priceHighToLow"); handleHideSortSidebarBtn() }}>
              <div className='d-flex justify-content-between'>
                Price: High to Low
              </div>
            </li>
            <li onClick={() => { getSortProjectFilter("sqftLowToHigh"); handleHideSortSidebarBtn() }}>
              <div className='d-flex justify-content-between'>
                Sq. Ft.: Low to High
              </div>
            </li>
            <li onClick={() => { getSortProjectFilter("sqftHighToLow"); handleHideSortSidebarBtn() }}>
              <div className='d-flex justify-content-between'>
                Sq. Ft.: High to Low
              </div>
            </li>
            {/* <li onClick={() => { getSortProjectFilter("recentlyLaunched"); handleHideSortSidebarBtn() }}>
              <div className='d-flex justify-content-between'>
                Recently Launched
              </div>
            </li> */}
            <li onClick={() => { getSortProjectFilter("possessionDate"); handleHideSortSidebarBtn() }}>
              <div className='d-flex justify-content-between'>
                Possession Date (Recent First)
              </div>
            </li>
            <li onClick={() => { getSortProjectFilter("updatedat"); handleHideSortSidebarBtn() }}>
              <div className='d-flex justify-content-between'>
                Latest Updated
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className='filter-sidebar d-block d-md-none'>
        <h4 className='mb-0'>Filter: <a className='float-end' onClick={() => handleHideFilterSidebarBtn()}><Icon icon="codicon:close" style={{ "color": "#6b6b6b" }} /></a></h4>
        <hr className='mt-0' />
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row className='align-items-stretch d-flex'>
            <Col sm={4} className='pe-0 align-items-stretch d-flex flex-column left-tab-container'>
              <Nav variant="pills" className="flex-column">
                <Nav.Item className='flex-column d-flex'>
                  <Nav.Link eventKey="first">BHK Type</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Budget</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="three">Project Status</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="five">Possession Status</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="four">Carpet Area (Sq.Ft.)</Nav.Link>
                </Nav.Item>

              </Nav>
            </Col>
            <Col sm={8} className='ps-3'>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  {filteredByBhk.slice(0, showAllBhk ? filteredByBhk.length : initialDisplayLimit).map((BhkType, index) => (
                    BhkType !== "" && (
                      BhkType && BhkType.bedrooms && BhkType.type &&
                      <a key={index} className={`btn btn-outline-secondary me-2 mb-2 ${selectedBHK.includes(BhkType.bedrooms + " " + BhkType.type.toUpperCase()) ? 'active' : ''}`}
                        onClick={() => {
                          toggleBhkType(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                          sortProjectsByBhkType(selectedBHK);
                          setSelectedTab(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                          createGa4Event('filter_configuration_type', 'ConfigurationTypeFilter', BhkType.bedrooms + " " + BhkType.type.toUpperCase())
                        }}>
                        {BhkType.bedrooms} {BhkType.type.toUpperCase()}
                      </a>

                    )
                  ))}
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Slider
                    range
                    min={500000}
                    max={50000000}
                    value={priceRange}
                    onChange={handleSliderChange}
                    onChangeComplete={handleFilter}
                  />
                  <p className='d-flex justify-content-between'>
                    <span>{`₹${formatNumber(priceRange[0])}`}</span>
                    <span className='pe-md-0 pe-3'>{`₹${formatNumber(priceRange[1])}`}+</span>
                  </p>
                </Tab.Pane>
                <Tab.Pane eventKey="three">
                  {/* {getProjectstatusData.map((status, index) => (
                                    (status != "" && 
                                        <a
                                            key={index}
                                            className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes(status) ? 'active' : ''}`}
                                            onClick={() => {
                                            toggleStatus(status);
                                            sortProjectsByStatus(selectedStatus);
                                            }}
                                        >
                                        {status}
                                        </a>
                                    )
                                ))} */}
                  {/* <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("New Launch") ? 'active' : ''}`}
                    onClick={() => {
                      toggleStatus("New Launch");
                      // sortProjectsByStatus(selectedStatus);
                    }}>
                    New Launch
                  </a> */}
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Under Construction") ? 'active' : ''}`}
                    onClick={() => {
                      toggleStatus("Under Construction");
                      // sortProjectsByStatus(selectedStatus);
                    }}>
                    Under Construction
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Ready To Move") ? 'active' : ''}`}
                    onClick={() => {
                      toggleStatus("Ready To Move");
                      // sortProjectsByStatus(selectedStatus);
                    }}>
                    Ready To Move
                  </a>
                </Tab.Pane>

                <Tab.Pane eventKey="five">
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 3 ? 'active' : ''}`}
                    onClick={() => {
                      togglePossessionStatus('3');
                      // sortProjectsByStatus(selectedPossessionStatus);
                      createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 3 months')
                    }}>
                    In 3 months
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 6 ? 'active' : ''}`}
                    onClick={() => {
                      togglePossessionStatus('6');
                      // setProjectStatusFilter("In 6 months");
                      // sortProjectsByStatus(selectedPossessionStatus);
                      createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 6 months')
                    }}>
                    In 6 months
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 12 ? 'active' : ''}`}
                    onClick={() => {
                      togglePossessionStatus('12');
                      // setProjectStatusFilter("In 1 year");
                      // sortProjectsByStatus(selectedPossessionStatus);
                      createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 1 year')
                    }}>
                    In 1 year
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 24 ? 'active' : ''}`}
                    onClick={() => {
                      togglePossessionStatus('24');
                      // setProjectStatusFilter("In 2 years");
                      // sortProjectsByStatus(selectedPossessionStatus);
                      createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 2 years')
                    }}>
                    In 2 years
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 36 ? 'active' : ''}`}
                    onClick={() => {
                      togglePossessionStatus('36');
                      // setProjectStatusFilter("In 3 years");
                      // sortProjectsByStatus(selectedPossessionStatus);
                      createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 3 years')
                    }}>
                    In 3 years
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 60 ? 'active' : ''}`}
                    onClick={() => {
                      togglePossessionStatus('60');
                      // setProjectStatusFilter("In 5 years");
                      // sortProjectsByStatus(selectedPossessionStatus);
                      createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 5 years')
                    }}>
                    In 5 years
                  </a>
                  <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 84 ? 'active' : ''}`}
                    onClick={() => {
                      togglePossessionStatus('84');
                      // setProjectStatusFilter("In 7 years");
                      // sortProjectsByStatus(selectedPossessionStatus);
                      createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 7 years')
                    }}>
                    In 7 years
                  </a>
                </Tab.Pane>
                <Tab.Pane eventKey="four">
                  <Slider
                    range
                    min={100}
                    max={5000}
                    value={builtUpArea}
                    onChange={handleSliderChangeArea}
                    onChangeComplete={handleFilterArea}
                  />
                  <p className='d-flex justify-content-between'>
                    <span>{`${builtUpArea[0]} Sq.Ft.`}</span>
                    <span className='pe-md-0 pe-3'>{`${builtUpArea[1]} Sq.Ft.`} {builtUpArea[1] === 5000 && '+'}</span>
                  </p>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        <button className="btn btn-primary w-100 theme-btn filter-apply-btn" onClick={() => handleHideFilterSidebarBtn()}>Apply</button>
      </div>
    </main>
  );
};

export default NewMapView;
