import React, { useEffect, useState } from 'react';
import { Button, Form, Tabs, Tab, Card } from 'react-bootstrap';
import { showToastMessage, executeLaravelAPI, executeLaravelFrontAPI, userToken } from '../Utils';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { SelectToSearch } from './SelectToSearch';
import TowerComponent from './TowerComponent';
import ConfigurationComponent from './ConfigurationComponent';
import { Icon } from '@iconify/react';
import AsyncSelect from 'react-select/async';

const ProjectCreateForm = ({ onCreateFormSubmit, hideCreateForm }) => {
    const [activeKey, setActiveKey] = useState('basicDetails');
    const [editedItem, setEditedItem] = useState({});
    const [newProject, setNewProject] = useState({
        name: '',
        towers: [
            { configurations: [] }, // Corrected syntax: Object within array
        ],
        amenities: [],
        specifications: [],
        project_type: '',
        project_status: '',
        locality_name: '',
        city: '',
        region: '',
        total_units: '',
        completion_timestamp: '',
        possession_date_readable: '',
        address: {
            latitude: '',
            longitude: ''
        }
    });
    const [getProjectStatusData, setGetProjectStatus] = useState([]);
    const [mstConfig, setMstDetails] = useState([]);

    useEffect(() => {
        document.title = 'Create New Project';
        getProjectStatusFunc();
        getConfig();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('.')) {
            const parts = name.split('.');
            const nestedKey = parts[0];
            const nestedValueKey = parts[1];
            setNewProject(prevState => ({
                ...prevState,
                [nestedKey]: {
                    ...prevState[nestedKey],
                    [nestedValueKey]: value,
                },
            }));
        } else {
            setNewProject(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleSelectChange = (selectedOptions, type) => {
        setNewProject(prevState => ({
            ...prevState,
            [type]: selectedOptions.length > 0 ? selectedOptions.map(option => ({ id: option.value, name: option.label })) : []
        }));
    };

    const getProjectStatusFunc = async () => {
        try {
            const result = await executeLaravelFrontAPI('projectstatus', "", 'GET');
            setGetProjectStatus(result.data);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    };

    const getConfig = async () => {
        try {
            const result = await executeLaravelFrontAPI('mstconfigurations', '', 'GET');
            setMstDetails(result.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const createProject = async (e) => {
        e.preventDefault();
        try {
            console.log(newProject)
            const projectData = await executeLaravelAPI('project/add-project', newProject, 'POST');
            if (projectData.status === 200) {
                showToastMessage('Project Created Successfully', 'top-right', 'success');
                hideCreateForm(true);
            } else {
                showToastMessage('Project not created', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error creating project:', error);
        }
        if (typeof onCreateFormSubmit === 'function') {
            onCreateFormSubmit(newProject);
        }
        // hideCreateForm(true);
    };

    const handleCloseForm = () => {
        // hideCreateForm(true);
    };

    // Rest of the component logic remains similar but adapted for creating a project instead of editing

    const handleSelectChangeForAmenities = (selectedOptions) => {
        // Assuming the state that keeps track of amenities looks something like this
        setNewProject(prevState => ({
            ...prevState,
            amenities: selectedOptions.length > 0 ? selectedOptions.map(option => ({ id: option.value, name: option.label })) : []
        }));
    };

    const handleSelectChangeForSpecifications = (selectedOptions) => {
        setNewProject(prevState => ({
            ...prevState,
            specifications: selectedOptions.length > 0 ? selectedOptions.map(option => ({ id: option.value, name: option.label })) : []
        }));
    };

    const [towers, setTowers] = useState([{ building_name: '', configurations: [{ name: '', agreement_price: '' /* Initialize other configuration fields here */ }] }]);

    const handleTowerChange = (e, towerIndex) => {
        const { name, value } = e.target;
        // Correctly identify the nested path and update the value
        setNewProject(prevState => {
            const updatedTowers = [...prevState.towers];
            if (!updatedTowers[towerIndex]) {
                updatedTowers[towerIndex] = { configurations: [] };
            }
            updatedTowers[towerIndex] = {
                ...updatedTowers[towerIndex],
                [name.replace('towers.', '')]: value, // Remove 'towers.' prefix to match the actual property name
            };
            return { ...prevState, towers: updatedTowers };
        });
    };

    const handleConfigurationChange = (e, towerIndex, configIndex) => {
        const { name, value } = e.target;
        const key = name.split('.')[1]; // Extracts the property name

        setNewProject(prevState => {
            const updatedTowers = prevState.towers.map((tower, tIndex) => {
                if (tIndex === towerIndex) {
                    let updatedConfigurations;
                    if (tower.configurations.length > 0) {
                        // If configurations exist, update the specific configuration or add a new one if necessary
                        updatedConfigurations = tower.configurations.map((config, cIndex) => {
                            if (cIndex === configIndex) {
                                return { ...config, [key]: value };
                            }
                            return config;
                        });

                        // If the configuration to update doesn't exist, add a new one
                        if (configIndex === tower.configurations.length) {
                            updatedConfigurations.push({ [key]: value });
                        }
                    } else {
                        // If no configurations exist, create a new array with the updated configuration
                        updatedConfigurations = [{ [key]: value }];
                    }

                    return { ...tower, configurations: updatedConfigurations };
                }
                return tower;
            });

            return { ...prevState, towers: updatedTowers };
        });
    };

    const addTower = () => {
        setTowers([...towers, { building_name: '', configurations: [{ name: '', agreement_price: '' /* Initialize other configuration fields here */ }] }]);
    };
    const removeTower = (towerIndex) => {
        setTowers(towers.filter((_, index) => index !== towerIndex));
    };


    const addConfiguration = (towerIndex) => {
        const updatedTowers = towers.map((tower, tIndex) => {
            if (tIndex === towerIndex) {
                return { ...tower, configurations: [...tower.configurations, { name: '', agreement_price: '' /* Initialize other configuration fields here */ }] };
            }
            return tower;
        });
        setTowers(updatedTowers);
    };
    const removeConfiguration = (towerIndex, configIndex) => {
        const updatedTowers = towers.map((tower, index) => {
            if (index === towerIndex) {
                return { ...tower, configurations: tower.configurations.filter((_, cIndex) => cIndex !== configIndex) };
            }
            return tower;
        });
        setTowers(updatedTowers);
    };

    const loadOptions = async (inputValue) => {
        if (inputValue.length > 2) {
            try {
                const paramsDev = {
                    name: inputValue,
                    searchtype: `developers`
                };
                // Replace `executeLaravelAPI` with your actual API call function
                const response = await executeLaravelFrontAPI('developersinsearch', paramsDev, 'GET');

                // const resultDevData= await executeLaravelFrontAPI ('developersinsearch', paramsDev, 'GET');
                //  setDevSearchResults(resultDevData.data.data);

                // Transform the data to match the format expected by react-select
                return response.data.data.map(dev => ({
                    label: dev.name, // Adjust this based on your data structure
                    value: dev.id, // Adjust this based on your data structure
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
                return [];
            }
        }
        return [];
    };
    const handleDeveloperChange = (selectedOption) => {
        // Assuming you want to store the developer's ID and name in the `newProject` state
        // Adjust the state structure as per your requirements
        setNewProject(prevState => ({
            ...prevState,
            developer_id: { // Ensure this structure matches your state and intentions
                id: selectedOption.value,
            },
        }));
    };

    return (
        <form onSubmit={createProject} className='projectForm'>
            <div className="row">
                <div className="col-md-12 mb-2">
                    <Card border="" style={{ width: '100%' }}>
                        <Card.Header>Create New Project</Card.Header>
                        <Card.Body>
                            <Tabs id="controlled-tab-example" activeKey={activeKey} onSelect={(k) => setActiveKey(k)} className="mb-3">
                                <Tab eventKey="basicDetails" title="Basic Details">
                                    <div className="row">
                                        {/* Project Name */}
                                        <div className="col-md-6 mb-2">
                                            <FloatingLabel controlId="floatingInputName" label="Project Name">
                                                <Form.Control type="text" placeholder="Project Name" name="name" onChange={handleInputChange} />
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-md-6 mb-2'>

                                            <AsyncSelect
                                                cacheOptions
                                                defaultOptions
                                                loadOptions={loadOptions}
                                                name="developers.name"
                                                onChange={handleDeveloperChange}
                                                className='customSelectStyle'
                                            />
                                        </div>
                                        {/* Project Type */}
                                        <div className="col-md-6 mb-2">
                                            <FloatingLabel controlId="floatingSelectType" label="Project Type">
                                                <Form.Select name="project_type" onChange={handleInputChange}>
                                                    <option value="">Select Type</option>
                                                    <option value="Residential">Residential</option>
                                                    <option value="Commercial">Commercial</option>
                                                    {/* Add more types as needed */}
                                                </Form.Select>
                                            </FloatingLabel>
                                        </div>
                                        {/* Project Status */}
                                        <div className="col-md-6 mb-2">
                                            <FloatingLabel controlId="floatingSelectStatus" label="Project Status">
                                                <Form.Select name="project_status" onChange={handleInputChange}>
                                                    <option value="">Select Status</option>
                                                    {getProjectStatusData.map((status, index) => (
                                                        <option key={index} value={status}>{status}</option>
                                                    ))}
                                                </Form.Select>
                                            </FloatingLabel>
                                        </div>
                                        {/* Locality Name */}
                                        <div className="col-md-6 mb-2">
                                            <FloatingLabel controlId="floatingInputLocality" label="Locality">
                                                <Form.Control type="text" placeholder="Locality" name="locality_name" onChange={handleInputChange} />
                                            </FloatingLabel>
                                        </div>
                                        {/* City */}
                                        <div className="col-md-6 mb-2">
                                            <FloatingLabel controlId="floatingInputCity" label="City">
                                                <Form.Control type="text" placeholder="City" name="city" onChange={handleInputChange} />
                                            </FloatingLabel>
                                        </div>
                                        {/* Total Units */}
                                        <div className="col-md-6 mb-2">
                                            <FloatingLabel controlId="floatingInputUnits" label="Total Units">
                                                <Form.Control type="text" placeholder="Total Units" name="total_units" onChange={handleInputChange} />
                                            </FloatingLabel>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="ammenities" title="Amenities">
                                    <div className="row">
                                        <div className="col-md-12 mb-2">
                                            <Form.Group className="row">
                                                <label className="col-sm-12 col-form-label">Amenities</label>
                                                <div className="col-sm-12">
                                                    <SelectToSearch type={"ammenities"} name="amenities" onChange={(selectedOptions) => handleSelectChangeForAmenities(selectedOptions)} />
                                                </div>
                                            </Form.Group>
                                        </div>

                                    </div>
                                </Tab>
                                <Tab eventKey="specifications" title="Specifications">
                                    <div className="row">
                                        <div className="col-md-12 mb-2">
                                            <Form.Group className="row">
                                                <label className="col-sm-12 col-form-label">Specifications</label>
                                                <div className="col-sm-12">
                                                    <SelectToSearch
                                                        type={"specifications"}

                                                        name="specifications"
                                                        onChange={(selectedOptions) => handleSelectChangeForSpecifications(selectedOptions)}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="towers" title="Towers">
                                    <div className="row">
                                        <table className='table table-responsive mt-0 p-0 mb-0  tower-table'>
                                            <thead>
                                                <tr className='table-secondary'>
                                                    <th>No</th>
                                                    <th>Building Name</th>
                                                    <th>Proposed Completion Date</th>
                                                    <th>No Of Basements</th>
                                                    <th>No Plinth</th>
                                                    <th>No Podium</th>
                                                    <th>No Sanctioned Floors</th>
                                                    <th>No Stilts</th>
                                                    <th>No Open Parkings</th>
                                                    <th>No Closed Parkings</th>

                                                    <th><button type='button' className='btn btn-outline-success rounded-circle add-config' onClick={addTower}>
                                                        <Icon icon="ic:sharp-add" style={{ fontSize: '24px' }} />
                                                    </button></th> {/* This could be for the add or remove buttons */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {towers.map((tower, index) => (
                                                    <TowerComponent
                                                        key={index}
                                                        index={index}
                                                        tower={tower}
                                                        handleTowerChange={handleTowerChange}
                                                        handleConfigurationChange={handleConfigurationChange}
                                                        addConfiguration={() => addConfiguration(index)}
                                                        removeTower={() => removeTower(index)}
                                                        removeConfiguration={removeConfiguration}
                                                        unitConfData={mstConfig}
                                                    />
                                                ))}
                                            </tbody>
                                        </table>


                                    </div>
                                </Tab>
                                <Tab eventKey="location" title="Location">
                                    <div className="row">
                                        <div className="col-md-6 mb-2">
                                            <Form.Group className="row">
                                                <div className="col-sm-12">
                                                    <FloatingLabel controlId="floatingInput" label="Address 1">
                                                        <Form.Control placeholder="Address 1" type="text" name='address.address1' onChange={handleInputChange} />
                                                    </FloatingLabel>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <Form.Group className="row">
                                                <div className="col-sm-12">
                                                    <FloatingLabel controlId="floatingInput" label="Address 2">
                                                        <Form.Control placeholder="Address 2" type="text" name='address.address2' onChange={handleInputChange} />
                                                    </FloatingLabel>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <Form.Group className="row">
                                                <div className="col-sm-12">
                                                    <FloatingLabel controlId="floatingInput" label="Locality">
                                                        <Form.Control placeholder="Locality" type="text" name='address.locality_name' onChange={handleInputChange} />
                                                    </FloatingLabel>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <Form.Group className="row">
                                                <div className="col-sm-12">
                                                    <FloatingLabel controlId="floatingInput" label="City">
                                                        <Form.Control placeholder="City" type="text" name='address.city' onChange={handleInputChange} />
                                                    </FloatingLabel>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <Form.Group className="row">
                                                <div className="col-sm-12">
                                                    <FloatingLabel controlId="floatingInput" label="Region">
                                                        <Form.Control placeholder="Region" type="text" name='address.region' onChange={handleInputChange} />
                                                    </FloatingLabel>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <Form.Group className="row">
                                                <div className="col-sm-12">
                                                    <FloatingLabel controlId="floatingInput" label="Latitude">
                                                        <Form.Control placeholder="Latitude" type="text" name='address.latitude' onChange={handleInputChange} />
                                                    </FloatingLabel>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <Form.Group className="row">
                                                <div className="col-sm-12">
                                                    <FloatingLabel controlId="floatingInput" label="Longitude">
                                                        <Form.Control placeholder="longitude" type="text" name='address.longitude' onChange={handleInputChange} />
                                                    </FloatingLabel>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        {/* <div className="col-md-6 mb-2">
                                            <FloatingLabel controlId="floatingInputLatitude" label="Latitude">
                                                <Form.Control type="text" placeholder="Latitude" name="address.latitude" onChange={handleInputChange} />
                                            </FloatingLabel>
                                        </div>
                                        {/* Longitude * / }
                                        <div className="col-md-6 mb-2">
                                            <FloatingLabel controlId="floatingInputLongitude" label="Longitude">
                                                <Form.Control type="text" placeholder="Longitude" name="address.longitude" onChange={handleInputChange} />
                                            </FloatingLabel>
                                        </div> */}
                                    </div>
                                </Tab>
                                {/* Additional tabs and fields as required */}
                            </Tabs>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="secondary" onClick={handleCloseForm} className="me-2">Cancel</Button>
                            <Button variant="primary" type="submit">Create Project</Button>
                        </Card.Footer>
                    </Card>
                </div>
            </div>
        </form>

    )
};

export default ProjectCreateForm;
