import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { userToken, executeLaravelAPI, executeLaravelFrontAPI, showToastMessage } from '../../Utils';
// import PageHeader from '../Layouts/PageHeader';
// import ModalForm from './ModalForm';
import { Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Icon } from '@iconify/react';
import ProjectForm from '../ProjectForm';
import ProjectEditForm from './ProjectEditForm';
import addNewImg from '../../../../Assets/img/add-new.svg';
// import deleteBtnImg from '../../../../Assets/img/delete-btn.svg';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import ConfirmationModal from '../Confirmation';
const moment = require('moment');
// import { SelectToSearch } from './SelectToSearch';


const itemsPerPageOptions = [20, 50, 100, 500, 1000];

const DisabledProjectList = ({ show, handleClose }) => {
    const location = useLocation();
    const inputRef = useRef(null);
    const pageDetails = { 'pagetitle': 'Projects', 'pageslug': 'projects' };

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
    const [paginatedData, setPaginatedData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [projects, setResponseData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [details, setDetails] = useState([]);
    const [showResult, showSearchResult] = useState(false);
    const [showDefaultResult, showDefaultSearchResult] = useState(false);
    const [sortCriteria, setSortCriteria] = useState('Relevance');
    const [priceRange, setPriceRange] = useState([0, 500000000]);
    const [buttonIcon, setButtonIcon] = useState({});
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [selectedProjectAddressId, setSelectedProjectAddressId] = useState(0);
    const [selectedProjectDeveloperId, setSelectedProjectDeveloperId] = useState(0);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [showOnLocationList, clickOnLocationList] = useState(false);
    const [lastCrawlDate, setLastCrawlDate] = useState('');
    const inputActiveRefs = useRef([]);
    const inputHiddenRefs = useRef([]);
    const inputMandateRefs = useRef([]);
    const inputGreatViewRefs = useRef([]);
    const [projectResponseData, setProjectResponseData] = useState([]);

    // const [removingIndex, setRemovingIndex] = useState(null);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [tempProjectDetails, setTempProjectDetails] = useState({ index: null, isChecked: false, projectId: null });
    const [tempProjectDetailsHp, setTempProjectDetailsHp] = useState({ index: null, isChecked: false, projectId: null });



    const getAllParams = (location) => {
        const searchParams = new URLSearchParams(location.search);
        const allParams = {};
        for (const [key, value] of searchParams.entries()) {
            allParams[key] = value;
        }
        allParams.page = currentPage + 1;
        allParams.per_page = itemsPerPage;
        return allParams;
    }

    useEffect(() => {
        document.title = pageDetails.pagetitle;
        const params = getAllParams(location);
        // params.project_status = selectedStatus;
        getProjects(params);
    }, [currentPage, itemsPerPage]);

    const getProjects = async (paramsData) => {
        try {
            // sqftLowToHigh params.orderby
            setLoading(true);
            // console.log(paramsData);
            if(paramsData.orderby == "" || paramsData.orderby == undefined || paramsData.orderby == null || paramsData.orderby == "Relevance"){
                paramsData.orderby = "priceLowToHigh"
            }
            paramsData.is_active = 'false';
            const result = await executeLaravelFrontAPI('projects', paramsData, 'GET');
            const projectData = result.data.data;
            //console.log(projectData);
            setProjectResponseData(projectData);
            setPaginatedData(projectData);
            setTotalItems(result.data.total);
            inputActiveRefs.current = Array(projectData.length).fill(true).map((_, index) => inputActiveRefs.current[index]);
            inputHiddenRefs.current = Array(projectData.length).fill(true).map((_, index) => inputHiddenRefs.current[index]);
            inputMandateRefs.current = Array(projectData.length).fill(true).map((_, index) => inputMandateRefs.current[index]);
            inputGreatViewRefs.current = Array(projectData.length).fill(true).map((_, index) => inputGreatViewRefs.current[index]);

            // Iterate through towers and configurations
            projectData.forEach(project => {
                let allPrices = [];
                project.towers.forEach(tower => {
                    tower.configurations.forEach(configuration => {
                        const prices = JSON.parse(configuration.all_inclusive_price.replace(/'/g, '"'));
                        allPrices = allPrices.concat(prices.map(priceObj => parseFloat(priceObj.price) || 0));
                    });
                    setLastCrawlDate(project.last_crawl_date);
                });
                // Filter out NaN values
                const validPrices = allPrices.filter(price => !isNaN(price) && isFinite(price));
                if (validPrices.length > 0) {
                    // const maxPrice = Math.max(...validPrices);
                    // const minPrice = Math.min(...validPrices);

                    // console.log(`Project: ${project.name}`);
                    // console.log('Maximum Price:', maxPrice);
                    // console.log('Minimum Price:', minPrice);
                    // console.log('--------------------------');
                } else {
                    // console.log(`No valid prices found for project: ${project.name}`);
                    // console.log('--------------------------');
                }
            });

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }
    const handleOpenModal = () => {
        setShowModal(true);
    };

    // const handleCloseModal = () => {
    //     setShowModal(false);
    // };
    // const [isFormVisible, setIsFormVisible] = useState(true);
    const hideForm = () => {
        setShowModal(false);
    };
    const hideEditForm = () => {
        setShowModal(false);
    };
    {/* Long Amount to convert short Amount like 30.5L or 1.2 Cr.  */ }
    const formatNumber = (num) => {
        try {
            if (num) {
                if (!isNaN(num)) {
                    if (num < 100000) {
                        return num.toString(); // No formatting needed for numbers less than 100,000
                    } else if (num < 10000000) {
                        return (num / 100000).toFixed(2) + ' L';
                    } else {
                        return (num / 10000000).toFixed(2) + ' Cr';
                    }
                }
            }
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }

        return '0';

    };
    // const squareFeet = (num) => {
    //     const sqft = num ? parseFloat(num) * 43560 : '';
    //     return sqft.toFixed(2);
    // };

    // Function to handle input change  
    // const longToShort = (num) => {  
    //     const date = num ? new Date(parseInt(num)).toLocaleDateString('en-GB', {           
    //         day: '2-digit',           
    //         month: '2-digit',           
    //         year: 'numeric',         
    //     }): ''; 
    //     return date;   
    // };

    {/* Search Function */ }
    //  const handleSearchChange = (e) => {
    //     setSearchTerm(e.target.value);
    //     setCurrentPage(0); // Reset to the first page when searching
    // };

    {/* Project Status Function */ }
    const getBadge = (status) => {
        switch (status) {
            case 'completed':
                return 'success'
            case 'On-Going Project':
                return 'secondary'
            case 'ongoing':
                return 'secondary'
            case 'prelaunch':
                return 'warning'
            case 'blocked':
                return 'danger'
            case 'New Project':
                return 'danger'
            default:
                return 'warning'
        }
    }
    const toggleDetails = (index, projectId, projectAddressId, projectDeveloperId) => {
        const position = details.indexOf(index);
        let newDetails = details.slice();
        if (position !== -1) {
            newDetails.splice(position, 1);
        } else {
            newDetails = [...details, index];
        }
        setDetails(newDetails);
        setSelectedProjectId(projectId);
        if (projectAddressId) {
            setSelectedProjectAddressId(projectAddressId);
        }
        if (projectDeveloperId) {
            setSelectedProjectDeveloperId(projectDeveloperId);
        }
        // console.log(selectedProjectDeveloperId);
        setButtonIcon((prevIcon) => ({
            ...prevIcon,
            [index]: !prevIcon[index],
        }));
    };

    {/* Pagination Function */ }
    const handlePageClick = ({ selected }) => {
        // alert(selected);
        setCurrentPage(selected);
        getProjects((selected + 1));
        setLocality(selected + 1);
        setProject(selected + 1);
    };

    const handleItemsPerPageChange = (e) => {
        const selectedValue = parseInt(e.target.value, 10);
        setItemsPerPage(selectedValue);
        setCurrentPage(0); // Reset to the first page when changing items per page
    };
    useEffect(() => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const filteredProjects = paginatedData.filter((project) => {
            const matchName = project.name.toLowerCase().includes(searchTerm.toLowerCase());
            const matchStatus = project.project_status.toLowerCase().includes(searchTerm.toLowerCase());
            const matchRegionName = project.region_name.toLowerCase().includes(searchTerm.toLowerCase());
            // Add similar checks for other parameters (Nearby, Configuration, Price, etc.)
            return matchName || matchRegionName || matchStatus;
        });

        const newPaginatedData = filteredProjects.slice(startIndex, endIndex);
        setPaginatedData(newPaginatedData);
    }, [currentPage, itemsPerPage, projects, searchTerm]);

    // const handleSelectChange = async (e) => {
    //     try {
    //         const filterValue = e.target.value;
    //         const result = await executeLaravelAPI('filterprojects', filterValue, 'POST', userToken);
    //         setResponseData(result.data);
    //         // setSelectedValue(filterValue);
    //     } catch (error) {
    //         console.error('Error fetched project data:', error);
    //     }
    // };

    // Search filter
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchLocationResults, setLocationSearchResults] = useState([]);
    const [searchDevResults, setDevSearchResults] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    // const uniqueLocalitiesRegion = new Set();
    // const uniqueLocalitiesArea = new Set();
    // const uniqueLocalities = new Set();uniqueLocalitiesRegion

    // Out side click hide div
    const searchInputRef = useRef(null);
    const suggestionContainerRef = useRef(null);


    const handleOutsideClick = (e) => {
        if (
            suggestionContainerRef.current &&
            !suggestionContainerRef.current.contains(e.target) &&
            searchInputRef.current &&
            !searchInputRef.current.contains(e.target)
        ) {
            showSearchResult(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []); // Run the effect only once during component mount
    //End Out side click hide div


    const handleFocus = (e) => {
        showSearchResult(true);
        // setDivVisibility(true);
        if (e.target.value === '' || !searchQuery || searchQuery.trim() === '') {
            showDefaultSearchResult(true);
            // setLocality(locality);
        } else {
            showDefaultSearchResult(false);
        }
    };
    const hideLocationList = (e) => {
        clickOnLocationList(true);
        showSearchResult(true);
        inputRef.current.focus();
        setSearchQuery('');
        // console.log("hideLocationList:---- "+showResult);
    };

    const removeLocation = (location) => {
        // const location = e.target.parentElement.innerText;
        const newLocations = selectedLocations.filter((item) => item !== location);
        setSelectedLocations(newLocations);
        setLocality(location);
        showSearchResult(false);
        setSearchQuery('');
    };



    const handleInputChange = async (e) => {
        setSearchQuery(e.target.value);
        try {
            if(e.target.value.startsWith("#") || e.target.value.endsWith("#")){
                const params = {
                    rera: e.target.value.replace("#", ""),
                    // locality_name: e.target.value,
                    page: 1,
                    per_page: 5,
                };
                const resultData = await executeLaravelFrontAPI('projectsinsearch', params, 'GET');
                // console.log(resultData);
                setSearchResults(resultData.data.data);
            }else{
            const paramsLocation = {
                name: e.target.value,
                locality_name: e.target.value,
                page: 1,
                per_page: 5,
            };
            const resultLocalityData = await executeLaravelFrontAPI('localityinsearch', paramsLocation, 'GET');
            setLocationSearchResults(resultLocalityData.data.data);
            // console.log('Location: ',resultLocalityData.data.data);

            const params = {
                name: e.target.value,
                locality_name: e.target.value,
                page: 1,
                per_page: 5,
            };
            const resultData = await executeLaravelFrontAPI('projectsinsearch', params, 'GET');
            // console.log(resultData);
            setSearchResults(resultData.data.data);

            //  if(e.target.value.length > 2){
            const paramsDev = {
                name: e.target.value,
                searchtype: `developers`,
                page: 1,
                per_page: 5,
            };
            const resultDevData = await executeLaravelFrontAPI('developersinsearch', paramsDev, 'GET');
            setDevSearchResults(resultDevData.data.data);
            //  console.log(resultDevData.data.data[0].name);
            //  }
        }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
        }
        showSearchResult(true);
        if (e.target.value.length === 0) {
            showDefaultSearchResult(true);
        } else {
            showDefaultSearchResult(false);
        }
    };

    // // Create a mapping of localities to projects
    // const localityProjectsMap = {};
    // // console.log("search _location: "+searchLocationResults);
    // searchLocationResults.forEach(project => {
    //      const locality = project.locality_name;
    //      console.log("City : "+locality);
    //      if (!localityProjectsMap[locality]) {
    //      localityProjectsMap[locality] = [];
    //      }
    //      localityProjectsMap[locality].push(project);
    //  });
    //  searchResults.forEach(project => {
    //      uniqueLocalitiesRegion.add(project.region); // Store lowercase to ensure case-insensitivity
    //      uniqueLocalitiesArea.add(project.locality_name); // Store lowercase to ensure case-insensitivity
    //  });

    // const handleSearch = async () => {
    //     try {
    //         setLoading(true);
    //         const params = {
    //             // name: searchQuery,
    //             // locality_name: searchQuery ,    
    //             locality_name:  searchQuery ,
    //             // region:  searchQuery ,
    //         };
    //         const resultData= await executeLaravelAPI('projects', params, 'GET', userToken);
    //         // console.log(params);
    //         setSearchResults(resultData.data.data);
    //         // setPaginatedData(result.data);
    //         // setTotalItems(result.data.total);

    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    //Click on locality name
    const setLocality = async (locality, pageNum) => {
        try {
            setLoading(true);
            if (!selectedLocations.includes(locality)) {
                // Update the array with the new location
                const updatedLocations = [...selectedLocations, locality];
                setSelectedLocations([...selectedLocations, locality]);

                const params = {
                    locality_name: updatedLocations,
                    page: pageNum || currentPage + 1,
                    per_page: itemsPerPage,
                    searchtype: 'locality_name'
                };
                // console.log(locality);
                const resultData = await executeLaravelAPI('projects', params, 'GET', userToken);
                // console.log(params);
                // setSearchResults(resultData.data);
                setPaginatedData(resultData.data.data);
                setTotalItems(resultData.data.total);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
        showSearchResult(false);
    };


    //Click on locality name
    const setProject = async (projectName, searchtype) => {
        try {
            //alert(pageNum+"="+currentPage+"="+itemsPerPage);
            setLoading(true);
            setSelectedLocations({});
            const params = {
                name: projectName,
                searchtype: searchtype
            };

            const resultData = await executeLaravelAPI('projects', params, 'GET', userToken);
            // setSearchResults(resultData.data);
            setPaginatedData(resultData.data.data);
            setTotalItems(resultData.data.total);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
        showSearchResult(false);
    };

    // const hideSearchWrap = () => {
    //     showSearchResult(false);
    //     clickOnLocationList(true);
    // };

    //Sorting project --Drop Down
    function getSortProjectFilter(sortCriteria) {
        console.log(sortCriteria)
        const params = getAllParams(location);
        params.orderby = sortCriteria;
        setSortCriteria(sortCriteria);
        getProjects(params);
    }


    const handleSliderChange = (newRange) => {
        setPriceRange(newRange);
        // alert(newRange)
    };

    const handleFilter = (newRange) => {
        const params = getAllParams(location);
        params.minprice = newRange[0];
        params.maxprice = newRange[1];
        console.log("Price min:- " + newRange[0]);
        console.log("Price max:- " + newRange[1]);
        getProjects(params);
    };

    const handleTowerChange = async (event) => {
        const selectedOption = event.target.options[event.target.selectedIndex];

        // let configData = Array();
        // if(projectId != ""){
        //     const result = await executeLaravelAPI(`project/${projectId}`, '', 'GET', userToken);
        //     const dataProjectDetails = result.data;
        //     // console.log(dataProjectDetails.towers);
        //     if (result.data && result.data.towers) {
        //     dataProjectDetails.towers.forEach(tower => {
        //         if(tower.id == towerId){
        //             tower.configurations.forEach(config => {
        //                 configData.push(config)
        //             })
        //         }
        //     })}
        // }



        // get Bhk 
        // const configBhkHTML = event.target.closest('td').nextElementSibling;
        // let configName = new Set();

        // configData.forEach((config, index) => {
        //    configName.add(config.name);
        // });
        // configBhkHTML.innerHTML="";
        // configName.forEach((config, index) => {
        //     configBhkHTML.append(config );
        //     configBhkHTML.append(document.createElement("br"));
        // })

        // // get carpet 
        // const configCarpetHTML = configBhkHTML.nextElementSibling
        // configCarpetHTML.innerHTML="";
        // configData.forEach((config, index) => {
        //     configCarpetHTML.append(parseFloat(config.carpet).toFixed(1) );
        //     configCarpetHTML.append(document.createElement("br"));
        //  });  
    };
    function readableDate(date) {
        const inputDate = date;
        const formattedDate = moment(inputDate, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY');
        return formattedDate;
    }
    const defaultInputActive = []; // default empty array
    const [inputActive, setActive] = useState(
        projectResponseData ? projectResponseData.map(project => project.is_active)
            : defaultInputActive
    );

    const defaultInputActiveHp = []; // default empty array
    const [handPicked, setHandPicked] = useState(
        projectResponseData ? projectResponseData.map(project => project.is_hand_picked)
            : defaultInputActiveHp
    );

    const defaultInputActiveMandate = []; // default empty array
    const [mandate, setMandate] = useState(
        projectResponseData ? projectResponseData.map(project => project.is_mandate)
            : defaultInputActiveMandate
    );

    const defaultInputActiveGreatView = []; // default empty array
    const [inputGreatView, setGreatView] = useState(
        projectResponseData ? projectResponseData.map(project => project.is_great_view)
            : defaultInputActiveGreatView
    );

    const handleActiveChange = async (index, isChecked, projectId) => {
        setTempProjectDetails({ index, isChecked, projectId });
        setShowConfirmModal(true);

    };

    const handleHandPickedStatusChange = async (index, isChecked, projectId) => {
        setTempProjectDetailsHp({ index, isChecked, projectId });
        const newHandPicked = [...handPicked];
        newHandPicked[index] = isChecked;
        setHandPicked(newHandPicked);
        inputHiddenRefs.current[index].checked = isChecked;
        let jsondata = {
            "is_hand_picked": isChecked ? 1 : 0
        };
        const result = await executeLaravelAPI(`project/${projectId}/handpicked`, jsondata, 'PUT', userToken);
        showToastMessage('Project Status updated', 'top-right', 'success');
        const updatedProjects = projectResponseData.map(project => (
            project.id == projectId ? { ...project, is_hand_picked: isChecked } : project
        ));
        setProjectResponseData(updatedProjects)
        setPaginatedData(updatedProjects); // Assuming this s
        // console.log(updatedProjects);
    }

    const handleMandateStatusChange = async (index, isChecked, projectId) => {
        const newMandate = [...mandate];
        newMandate[index] = isChecked;
        setMandate(newMandate);
        inputMandateRefs.current[index].checked = isChecked;
        let jsondata = {
            "is_mandate": isChecked ? 1 : 0
        };
        const result = await executeLaravelAPI(`project/${projectId}/mandate`, jsondata, 'PUT', userToken);
        showToastMessage('Project Status updated', 'top-right', 'success');
        const updatedProjects = projectResponseData.map(project => (
            project.id == projectId ? { ...project, is_mandate: isChecked } : project
        ));
        setProjectResponseData(updatedProjects)
        setPaginatedData(updatedProjects); // Assuming this s
        // console.log(updatedProjects);
    }

    const handleGreatViewStatusChange = async (index, isChecked, projectId) => {
        const newGreatView = [...inputGreatViewRefs.current];
        newGreatView[index] = isChecked;
        setGreatView(newGreatView);
        inputGreatViewRefs.current[index].checked = isChecked;
        let jsondata = {
            "is_great_view": isChecked ? 1 : 0
        };
        const result = await executeLaravelAPI(`project/${projectId}/greatview`, jsondata, 'PUT', userToken);
        showToastMessage('Project Status updated', 'top-right', 'success');
        const updatedProjects = projectResponseData.map(project => (
            project.id == projectId ? { ...project, is_great_view: isChecked } : project
        ));
        setProjectResponseData(updatedProjects)
        setPaginatedData(updatedProjects); // Assuming this s
        // console.log(updatedProjects);
    }


    const handleConfirm = async () => {
        const { index, isChecked, projectId } = tempProjectDetails;
        setShowConfirmModal(false); // Close the modal
        try {
            const newActive = [...inputActive];
            newActive[index] = isChecked;
            setActive(newActive);

            // console.log("before"+isChecked);
            // setRemovingIndex(index);
            let jsondata = {
                "is_active": isChecked ? 1 : 0
            };

            const result = await executeLaravelAPI(`project/${projectId}/activate`, jsondata, 'PUT', userToken);
            inputActiveRefs.current[index].checked = isChecked;


            showToastMessage('Project Status updated', 'top-right', 'success');
            const updatedProjects = projectResponseData.map(project => (
                project.id == projectId ? { ...project, is_active: isChecked } : project
            ));
            setProjectResponseData(updatedProjects)
            setPaginatedData(updatedProjects); // Assuming this s
            // console.log(updatedProjects);

            // if (!isChecked) {
            //     setRemovingIndex(index); // Start the animation

            //     setTimeout(() => {
            //         const newPaginatedData = paginatedData.filter((_, i) => i !== index);
            //         setPaginatedData(newPaginatedData);
            //         setRemovingIndex(null); // Reset removal index
            //     }, 100); 
            //     // Assuming 500ms is your animation duration
            // }
        } catch (error) {
            console.error('Error handling active change:', error);
            // Handle error, show error message, etc.
        }
    };

    const handleCancel = () => {
        setShowConfirmModal(false); // Close the modal without doing anything
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allProjectIds = paginatedData.map(project => project.id);
            setSelectedProjects(allProjectIds);
        } else {
            setSelectedProjects([]);
        }
    };
    const handleProjectSelect = (projectId) => {
        if (selectedProjects.includes(projectId)) {
            setSelectedProjects(selectedProjects.filter(id => id !== projectId));
        } else {
            setSelectedProjects([...selectedProjects, projectId]);
        }
    };
    {/*Table Css */ }
    // const configurationsSize = {
    //     minWidth: '170px',
    // };
    // const correctStyle = {
    //     minWidth: '120px',
    // };


    return (
        <>
            {/* <PageHeader pageDetails={pageDetails}/> */}
            <div className='text-end mb-4'>Last Crawl Date: {`${readableDate(lastCrawlDate)}`}</div>
            <section className="content">
                {/* <div className="row">
                <div className="col-md-12">
                    <select value={selectedValue} onChange={handleSelectChange}>
                        <option value="">--Select--</option>
                        <option value="ongoing">Ongoing</option>
                        <option value="completed" selected>Completed</option>
                    </select>
                </div>
            </div> */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="box">
                            {/* <div className="box-header with-border">
                            <h3 className="box-title">Projects</h3>
                        </div> */}
                            <div className='row'>
                                <div className='col-lg-12'>
                                    {/* <input type="search" placeholder="Search..." value={searchTerm} onChange={handleSearchChange} style={{background: "url('https://api.iconify.design/ion/search-outline.svg') no-repeat 96% center / 24px"}} /> */}
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='position-relative' ref={searchInputRef}>
                                                <div className='position-realtive'>
                                                    <input
                                                        type="search"
                                                        className='form-control search-input shadow-none border-none'
                                                        placeholder="Search for locality, project, or builder"
                                                        value={searchQuery}
                                                        onChange={handleInputChange}
                                                        onFocus={handleFocus}
                                                        ref={inputRef}
                                                        autoComplete='off'
                                                        style={{ background: "url('https://api.iconify.design/ion/search-outline.svg') no-repeat 10px center / 24px" }}
                                                    />
                                                    {/* <button className='theme-btn position-absolute top-0 end-0 search-btn' onClick={handleSearch} disabled={loading}>
                                                    Search
                                                </button> */}
                                                    {!showResult && (
                                                        selectedLocations.length > 0 && (
                                                            <><ul className='selected-location' onClick={hideLocationList}>
                                                                {selectedLocations.map((location, index) => (
                                                                    <li key={index}>
                                                                        {location}
                                                                        <a href="#" key={index} onClick={() => removeLocation(location)} className='position-absolute fs-2 top-0 text-black text-decoration-none' style={{ right: '15px' }}><Icon icon="iconamoon:close-thin" style={{ color: "#fff" }} /></a>
                                                                    </li>
                                                                ))}
                                                            </ul></>
                                                        )
                                                    )}
                                                </div>
                                                {/* {loading && <p>Loading...</p>} */}
                                                <div className={`search-wrap scroll-style position-absolute top-25 start-0 bg-white border pb-4 ${showResult ? ('d-block') : (' d-none')}`} ref={suggestionContainerRef}>
                                                    <div className='row mt-2'>
                                                        {showOnLocationList && (
                                                            selectedLocations.length > 0 && (
                                                                <><ul className='selected-location dropdownLocation-list pb-0 pt-3' onClick={hideLocationList}>
                                                                    {selectedLocations.map((location, index) => (
                                                                        <li key={index}>
                                                                            {location}
                                                                            <a href="#" key={index} onClick={(e) => { e.preventDefault(); removeLocation(location); }} className='position-absolute fs-2 top-0 text-black text-decoration-none' style={{ right: '15px' }}><Icon icon="iconamoon:close-thin" style={{ color: "#fff" }} /></a>
                                                                        </li>
                                                                    ))}
                                                                </ul></>
                                                            )
                                                        )}
                                                        <div className='col-12'>
                                                            <div className='title-wrap'><span>Locality:</span></div>
                                                            <div>
                                                                <ul className='mb-0 result-list'>
                                                                    {searchLocationResults.map(locality => (
                                                                        !showDefaultResult ? (
                                                                            <li key={locality.id}><a href="#" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality(locality.location)}>{locality.location}</a></li>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    ))}
                                                                    {showDefaultResult ?
                                                                        (
                                                                            <><li><a href="#" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality("wakad")}>Wakad</a></li>
                                                                                <li><a href="#" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality("baner")}>Baner</a></li>
                                                                                <li><a href="#" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality("kothrud")}>Kothrud</a></li>
                                                                                <li><a href="#" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality("hadapsar")}>Hadapsar</a></li>
                                                                                <li><a href="#" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality("bavdhan")}>Bavdhan</a></li>
                                                                                <li><a href="#" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality("punewale")}>Punewale</a></li>
                                                                                <li><a href="#" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality("kharadi")}>Kharadi</a></li>
                                                                            </>

                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className='col-12'>
                                                            {!showDefaultResult ? (
                                                                <><div className='title-wrap'><span>Developers:</span></div>
                                                                    <div>
                                                                        <ul className='mb-0 result-list'>
                                                                            {Array.from(searchDevResults).map(developer => (
                                                                                <li key={developer.id}>
                                                                                    <a href="#" className='text-secondary text-capitalize text-decoration-none' onClick={() => setLocality(developer.name)}>{developer.name}</a>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div></>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                        <div className='col-12'>
                                                            {!showDefaultResult ? (
                                                                <><div className='title-wrap'><span>Projects:</span></div>
                                                                    <div>
                                                                        <ul className='mb-0 result-list'>
                                                                            {searchResults.map(project => (
                                                                                <li key={project.id}>
                                                                                    <a href="#" className='text-secondary text-decoration-none text-capitalize' onClick={() => setProject(project.name, 'name')}>{project.name}</a>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div></>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>


                                                        {/* <div className='col-12'>
                                                        <ul className='mb-0 row'>
                                                            <li className='col-12'>
                                                                {searchResults.length === 0 && !loading && <p>No results found.</p>}
                                                            </li>
                                                        </ul>
                                                    </div> */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 mt-3 pe-0 position-relative'>
                                            <label>Price Range:</label>
                                            <Slider
                                                range
                                                min={500000}
                                                max={500000000}
                                                value={priceRange}
                                                onChange={handleSliderChange}
                                                onChangeComplete={handleFilter}
                                            />
                                            <p className='d-flex justify-content-between'>
                                                <span>{`₹${formatNumber(priceRange[0])}`}</span>
                                                <span>{`₹${formatNumber(priceRange[1])}`}</span>
                                            </p>
                                        </div>
                                        <div className='col-lg-6'>
                                            {/* <Button variant="success" className='me-3 editBtn'>
                                            Edit
                                        </Button> */}
                                            <div className='col-lg-12 col-12 pe-0 mt-4'>
                                                {/* <div className='col-6 text-end pe-0'></div> */}
                                                <div className='col-12 pe-0 d-flex justify-content-end'>
                                                    <span className='me-2'>Sort by: </span>
                                                    <select className="form-select form-select-sm" style={{ width: "200px" }}
                                                        value={sortCriteria} onChange={(e) => getSortProjectFilter(e.target.value)}
                                                    >
                                                        <option value="Relevance">Relevance</option>
                                                        <option value="priceLowToHigh">Price: Low to High</option>
                                                        <option value="priceHighToLow">Price: High to Low</option>
                                                        <option value="sqftLowToHigh">Sq. Ft.: Low to High</option>
                                                        <option value="sqftHighToLow">Sq. Ft.: High to Low</option>
                                                        <option value="recentlyLaunched">Recently Launched</option>
                                                        <option value="possessionDate">Possession Date (Recent First)</option>
                                                        {/* <option value="updatedat">Latest Updated</option> */}
                                                        <option value="orderByNameAsc">Name: A-Z</option>
                                                        <option value="orderByNameDesc">Name: Z-A</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-12 pe-0 d-flex justify-content-end'>
                                                <a href="#" className='mt-4 me-3' onClick={handleOpenModal}>
                                                    <img src={addNewImg} />
                                                </a>
                                                {/* <a href="#" className='px-0 mt-4'>
                                                    <img src={deleteBtnImg} />
                                                </a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div show={show} onHide={handleClose} className={`animated-box mt-2 ${showModal ? ( 'd-block' ) : ('d-none')}`}>
                            <ProjectForm hideForm={hideForm}/>
                        </div> */}

                            <div show={show} className={`animated-box mt-2 ${showModal ? ('d-block') : ('d-none')}`}>
                                <ProjectForm hideForm={hideForm} />
                            </div>
                            <div className="col-md-12">
                                {/* <select className='fform-select form-select-sm'>
                        <option value="">--Select--</option>
                        <option value="Status">Status</option>
                        <option value="Delete">Delete</option>
                    </select> */}
                            </div>

                            <div className="box-body table-container mt-4 hr-scroll-style">
                                <table className="table table-resposive table-hover dashboard-table shadow-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th width="40px" style={{ minWidth: '40px' }} className='sticky-column'>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="selectAll"
                                                    onChange={handleSelectAll}
                                                    checked={selectedProjects.length === paginatedData.length && paginatedData.length > 0}
                                                />
                                            </th>
                                            <th width="200px" style={{ 'minWidth': '200px' }} className='sticky-column column-snd'> Project Name</th>
                                            <th>Location</th>
                                            {/* <th>Tower</th> */}
                                            <th width="190px">Price</th>
                                            <th>Project Status</th>
                                            <th>Possession Date</th>
                                            <th>Status</th>
                                            <th>Hand Picked</th>
                                            <th>Is Mandate</th>
                                            <th>Is Great View</th>
                                            <th className='sticky-column'>Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr><td colSpan={7}><div className='loading'><span className="loadingspan">Loading...</span><span className="custom-loader"></span></div></td></tr>
                                        ) : (
                                            paginatedData.map((project, index) => (
                                                <React.Fragment key={`fragment-${project.id}-${index}`} >
                                                    {/* ${removingIndex === index ? 'fadeOutRight' : ''} */}
                                                    <tr key={project.id} className={`${(project.minimum_total_price == 0 || project.maximum_total_price == 0) ? 'table-danger' : 'table-success'} `}>
                                                        <td>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`select-${project.id}`}
                                                                onChange={() => handleProjectSelect(project.id)}
                                                                checked={selectedProjects.includes(project.id)}
                                                            />
                                                        </td>
                                                        <td className='sticky-column column-snd'>{project.name} - {project.id}</td>
                                                        <td>{project.locality_name}</td>
                                                        {/* <td>
                                                            <div className="row form-group">
                                                                <div className="col-sm-12">
                                                                    <select name="tower" className="form-select" onChange={(event) => handleTowerChange(event)} data-project-id={project.id} disabled={project.towers.length === 1} data-tower-id="">
                                                                        {project.towers.map((tower, index) => (
                                                                            <option key={tower.id} data-tower-id={tower.id} value={tower.building_name}>{tower.building_name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td> */}
                                                        <td>Min: ₹ {formatNumber(project.minimum_total_price)} <br /> Max: ₹ {formatNumber(project.maximum_total_price)}</td>
                                                        <td className="text-capitalize">
                                                            <span className={`badge rounded-pill bg-${getBadge(project.project_status)}`}>{project.project_status}</span>
                                                        </td>
                                                        <td>{project.possession_date_readable}</td>
                                                        <td className='sticky-column'>
                                                            <Form.Check // prettier-ignore
                                                                type="switch"
                                                                id={`custom-switch-${index}`}
                                                                key={index}
                                                                ref={el => inputActiveRefs.current[index] = el}
                                                                name={`active${index}`}
                                                                data-project-id={project.id}
                                                                checked={project.is_active}
                                                                onChange={(e) => handleActiveChange(index, e.target.checked, e.target.getAttribute('data-project-id'))} />
                                                        </td>
                                                        <td className='sticky-column'>
                                                            {/* {console.log(project.is_hand_picked)} */}
                                                            <Form.Check // prettier-ignore
                                                                type="switch"
                                                                id={`custom-switch-hp-${index}`}
                                                                key={index}
                                                                ref={el => inputHiddenRefs.current[index] = el}
                                                                name={`active${index}`}
                                                                data-project-id={project.id}
                                                                checked={project.is_hand_picked}
                                                                onChange={(e) => handleHandPickedStatusChange(index, e.target.checked, e.target.getAttribute('data-project-id'))} />
                                                        </td>

                                                        <td className='sticky-column'>
                                                            {/* {console.log(project.is_mandate)} */}
                                                            <Form.Check // prettier-ignore
                                                                type="switch"
                                                                id={`custom-switch-hp-${index}`}
                                                                key={index}
                                                                ref={el => inputMandateRefs.current[index] = el}
                                                                name={`active${index}`}
                                                                data-project-id={project.id}
                                                                checked={project.is_mandate}
                                                                onChange={(e) => handleMandateStatusChange(index, e.target.checked, e.target.getAttribute('data-project-id'))} />
                                                        </td>

                                                        <td className='sticky-column'>
                                                            {/* {console.log(project.is_great_view)} */}
                                                            <Form.Check // prettier-ignore
                                                                type="switch"
                                                                id={`custom-switch-hp-${index}`}
                                                                key={index}
                                                                ref={el => inputGreatViewRefs.current[index] = el}
                                                                name={`active${index}`}
                                                                data-project-id={project.id}
                                                                checked={project.is_great_view}
                                                                onChange={(e) => handleGreatViewStatusChange(index, e.target.checked, e.target.getAttribute('data-project-id'))} />
                                                        </td>
                                                        <td className='sticky-column'>
                                                            <a href="javascript:;" onClick={() => toggleDetails(index, project.id, project.address.id, project.developer.id)} data-project-id={project.id} data-index={index + 1}>
                                                                {buttonIcon[(index)] ? <Icon icon='ion:close-outline' style={{ fontSize: '30px', color: '#192951' }} /> : <Icon icon='fe:edit' style={{ fontSize: '24px', color: '#192951' }} />}
                                                            </a>
                                                        </td>
                                                        
                                                    </tr>
                                                    {details.includes(index) && (
                                                        <tr data-id={index + 1}>
                                                            <td colSpan={10}>
                                                                <ProjectEditForm selectedProjectId={selectedProjectId} selectedProjectAddressId={selectedProjectAddressId} selectedProjectDeveloperId={selectedProjectDeveloperId} hideEditForm={hideEditForm} />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            ))
                                        )
                                        }
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-5 align-items-center'>
                    <div className='col-lg-4'>
                        {/* Display "Showing x-y of z items" */}
                        <p className='graylight mb-0'>Showing {currentPage * itemsPerPage + 1}-{Math.min((currentPage + 1) * itemsPerPage, totalItems)} of {totalItems} items</p>
                    </div>
                    <div className='col-lg-8 d-flex justify-content-end align-items-center'>
                        {/* Render pagination component */}
                        <div className='me-3'>
                            <span className='graylight'>Results per page </span>
                            <select className='showPerPage' value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                {itemsPerPageOptions.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <ReactPaginate
                            pageCount={Math.ceil(totalItems / itemsPerPage)}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination justify-content-end mb-0'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            activeClassName={'active'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            disableInitialCallback={true}
                        />
                    </div>
                </div>

                <ConfirmationModal
                    show={showConfirmModal}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                    modelBody={"Are you sure you want to change the status?"}
                />
            </section>
            {/*  */}
            {/* <Modal show={showModal} onHide={handleCloseModal} className="modal-xl">
            
            <Modal.Header closeButton  className="px-5">
                <Modal.Title>Add New</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5">
                <ProjectForm/> 
            </Modal.Body>
         
         </Modal> */}

        </>
    )
};
// const Loader = () => {
//     return <tr><td colSpan="7">Loading...</td></tr>;
// };
export default DisabledProjectList;