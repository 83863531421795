import React, { useEffect, useState } from 'react';
import { showToastMessage, userToken, executeLaravelAPI } from '../../Utils';
import { Form } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import SubmitButton from '../../shared/SubmitButton';

const ProjectEditDeveloper = ({selectedProjectDeveloperId}) => {
    const [projectDeveloper, setEditedItem] = useState({
        name:'',
        total_completed_projects:'',
        total_ongoing_projects:'',
        rating:'',
        established_time_stamp:'',
        website:'',
        description:''
    });
    
    useEffect(() => {
        const getDeveloper = async () => {
            try {
                const result = await executeLaravelAPI(`developer/${selectedProjectDeveloperId}`, '', 'GET', userToken);
                const dataAddressDetails = result.data;
                console.log(dataAddressDetails);
                setEditedItem(dataAddressDetails);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (selectedProjectDeveloperId) {
            getDeveloper();
        }
    }, [selectedProjectDeveloperId]);
    // console.log(selectedProjectDeveloperId);
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let parsedValue = value;
        if(name === 'established_time_stamp'){
            // parsedValue = new Date(value).getTime(); 
            let date = new Date(value);
            date.setDate(date.getDate() + 1); // Add one day
            parsedValue = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        }
        if(name === 'total_completed_projects'){
            parsedValue = parseInt(value);
        }
        if(name === 'total_ongoing_projects'){
            parsedValue = parseInt(value);
        }
        if(name === 'rating'){
            parsedValue = parseInt(value);
        }
        // Check if the name attribute corresponds to a nested property
        if (name.includes('.')) {
            const parts = name.split('.');
            // Assuming only one level of nesting for simplicity
            const nestedKey = parts[0]; // e.g., 'address'
            const nestedValueKey = parts[1]; // e.g., 'latitude' or 'longitude'
            setEditedItem(prevState => ({
                ...prevState,
                [nestedKey]: {
                    ...prevState[nestedKey],
                    [nestedValueKey]: parsedValue,
                },
            }));
        } else {
            // Handle top-level properties
            setEditedItem(prevState => ({
                ...prevState,
                [name]: parsedValue,
            }));
        }
    };

    const updateProjectDeveloper = async (e) => {
        e.preventDefault();
        try {
            //console.log(projectBasicData);return false;
            const updatedProjectLocationData = await executeLaravelAPI(`developer/edit/${selectedProjectDeveloperId}`, projectDeveloper, 'PUT', userToken);
            console.log(projectDeveloper);
            if (updatedProjectLocationData.status === 200) {
                showToastMessage('Developer details have been updated successfully', 'top-right', 'success');
            } else {
                showToastMessage('Developer details have not been updated.', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error logging in:', error);
        }
    }

    return (
        <form onSubmit={updateProjectDeveloper} className='projectForm'>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Developer Name">
                                <Form.Control placeholder="Name" type="text" name='name' value={projectDeveloper?.name || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Total Completed Projects">
                                <Form.Control placeholder="Total Completed Projects" type="number" name='total_completed_projects' value={projectDeveloper?.total_completed_projects || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Total Ongoing Projects">
                                <Form.Control placeholder="Total Ongoing Projects" type="number" name='total_ongoing_projects' value={projectDeveloper?.total_ongoing_projects || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Rating">
                                <Form.Control placeholder="Rating" type="number" name='rating' value={projectDeveloper?.rating || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="No of Experience">
                                {/* <Form.Control placeholder="No of Experience" type="number" name='established_time_stamp' value={projectDeveloper?.established_time_stamp || ''} onChange={handleInputChange} /> */}
                                <Form.Control 
                                    placeholder="Established Date" 
                                    type="date" 
                                    name='established_time_stamp' 
                                    value={projectDeveloper?.established_time_stamp ? new Date(projectDeveloper.established_time_stamp).toISOString().split('T')[0] : ''} 
                                    onChange={handleInputChange} 
                                />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Website">
                                <Form.Control placeholder="Website" type="text" name='website' value={projectDeveloper?.website || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-12 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Description">
                                <Form.Control placeholder="Description" type="textarea" name='description' value={projectDeveloper?.description || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
            </div>    
            <div className='col-12'>
                <hr></hr>
            </div>
            <div className='text-end mt-3'>
                {/* <Button variant="secondary" onClick={handleCloseForm} className="theme-btn secondary me-2">Cancel</Button> */}
                <SubmitButton variant="success" type="submit" className="theme-btn" label="Update Developer" />   
            </div>           
        </form>
    )
}
export default ProjectEditDeveloper;
