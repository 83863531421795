import CompareBanner from '../../../../Assets/img/compare-banner.png';
import bwWhiteLogo from '../../../../Assets/img/bwwhite.png';
import cobject from '../../../../Assets/img/cobject.png';
import cobject1 from '../../../../Assets/img/cobject1.png';
import cobject2 from '../../../../Assets/img/cobject2.png';
import cobject3 from '../../../../Assets/img/cobject3.png';
import cobject4 from '../../../../Assets/img/cobject4.png';
import cobject5 from '../../../../Assets/img/cobject5.png';
import hand from '../../../../Assets/img/hand.png';
import hand1 from '../../../../Assets/img/hand1.png';

const Banner = ({}) => {
    return(<>
        <section>
            <div className="container">
                <div className="row compare-banner">
                    <div className='col-xl-5 col-12'>
                        <div className='bannercontent'>
                            <img src={bwWhiteLogo} className="img-fluid bwlogo"/>
                            <div className='bnrsubhead'>Property Comparison, Simplified!</div>
                        </div>
                    </div>
                    <div className='col-xl-7 col-12 position-relative'>
                        <div className='bannerimg-div'>
                            <img src={CompareBanner} className="bannerimg"/>
                        </div>
                        <img src={cobject} className="cobject"/>
                        <img src={cobject1} className="cobject1"/>
                        <img src={cobject2} className="cobject2"/>
                        <img src={cobject4} className="cobject4"/>
                        <img src={cobject5} className="cobject5"/>
                        <img src={hand} className="hand"/>
                        <img src={hand1} className="hand1"/>
                    </div>
                </div>
            </div>
        </section>
    </>);
};

export default Banner;