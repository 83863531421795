import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { appAbsPath, parsedUserData, createGa4Event } from '../../../Admin/Utils';
import { ReactComponent as HomeIcon } from '../../../../Assets/img/home.svg';
import { ReactComponent as ExploreIcon } from '../../../../Assets/img/explore.svg';
import { ReactComponent as LikedIcon } from '../../../../Assets/img/like.svg';
import { ReactComponent as ProfileIcon } from '../../../../Assets/img/profile.svg';

const BottomNavBar = ({ isLoggedIn, onLogin }) => {
  const [visible, setVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isLoginPopupVisible, setLoginPopupVisible] = useState(false);
  const [redirectPath, setRedirectPath] = useState(null);
  const navigate = useNavigate();

  const handleScroll = () => {
    if (window.scrollY > lastScrollY) {
      // Scrolling down
      setVisible(false);
    } else {
      // Scrolling up
      setVisible(true);
    }
    setLastScrollY(window.scrollY);
  };

  const handleNavItemClick = () => {
    document.getElementById('overlay').style.display = 'block';
    sessionStorage.setItem('popupShown', 'true');
  };

  const handleLoginSuccess = () => {
    // setLoginPopupVisible(false);
    // if (redirectPath) {
    //   navigate(redirectPath);
    // }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <>
      <div className={`bottom-nav-bar ${visible ? 'slide-up' : 'slide-down'}`}>
        <Link to={`${appAbsPath}/irfs`} className="nav-item">
          <HomeIcon />
          <span>Home</span>
        </Link>
        <Link to={`${appAbsPath}/irfs/projects`} className="nav-item">
          <ExploreIcon />
          <span>Explore</span>
        </Link>
        <Link
          to={parsedUserData === null ? '#' : `${appAbsPath}/profile`}
          className="nav-item"
          onClick={parsedUserData === null ? () => handleNavItemClick() : null}
        >
          <LikedIcon />
          <span>Liked</span>
        </Link>
        <Link
          to={parsedUserData === null ? '#' : `${appAbsPath}/profile`}
          className="nav-item"
          onClick={parsedUserData === null ? () => handleNavItemClick() : () => createGa4Event('header_login', 'HeaderLogIN', 'Log in')}
        >
          <ProfileIcon />
          <span>{parsedUserData === null ? 'Log In' : 'Profile'}</span>
        </Link>

      </div>
    </>
  );
};

export default BottomNavBar;
