import React, { useEffect, useState } from "react";
import { json, useParams } from "react-router-dom"
import { lazyLoadImages } from "../../Admin/Utils";
import Banner from "../../../Assets/img/blogbg.png";
import BlogIcon from "../../../Assets/img/house.png";
import Sideimg from "../../../Assets/img/sideimg.png";
import Right from "../../../Assets/img/gr-right.png";
import BRight from "../../../Assets/img/blue-right.png";
import Recom1 from "../../../Assets/img/r1.png";
import Recom2 from "../../../Assets/img/r2.png";
import { Helmet } from 'react-helmet-async';

const BlogDetail = ({ }) => {
  const [showLink, setShowLink] = useState(false);

  const { slug } = useParams();
  const [blog, setBlogData] = useState(null);

  useEffect(() => {
    lazyLoadImages();
    getBlog(slug);
  }, [slug]);

  const getBlog = async (slug) => {
    try {
      const apiUrl =
        "https://corporate.beyondwalls.com/?portalblogsdetails=1&portalblogid=" +slug;

      
      const response = await fetch(apiUrl, {
        method: "GET",
      });
      if (response.status === 200) {
        const data = await response.json();
        data.content = modifyImageSrc(data.content); // Modify the content before setting the state
        setBlogData(data);
        console.log("asdasd ", data)
      } else {
        return "";
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const modifyImageSrc = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const images = doc.getElementsByTagName("img");

    for (let img of images) {
      if (img.hasAttribute("data-src")) {
        const originalSrc = img.getAttribute("data-src");

        // Example: Use regex to handle more complex replacements if needed
        const modifiedSrc = originalSrc.replace(
          /www.beyondwalls\.com/g,
          "corporate.beyondwalls.com"
        ); // Adjust as needed

        img.setAttribute("src", modifiedSrc);
        img.removeAttribute("data-src");
      }
    }

    return doc.body.innerHTML;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      lazyLoadImages();
      setShowLink(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  function extractJsonLd(schemaCode) {
    const scriptTags = schemaCode.match(/<script[^>]*>([\s\S]*?)<\/script>/gi);
    if (!scriptTags) return [];

    return scriptTags.map(scriptTag => {
      const jsonLd = scriptTag.replace(/<\/?script[^>]*>/gi, '').trim();

      let jsondata = JSON.parse(jsonLd);
      jsondata.image = jsondata?.image?.replace(/www.beyondwalls\.com/g, "corporate.beyondwalls.com")
      if (jsondata?.publisher?.logo) {
        jsondata.publisher.logo.url = jsondata.publisher.logo.url.replace(/www.beyondwalls\.com/g, "corporate.beyondwalls.com");
      }
      if (jsondata?.url) {
        jsondata.url = jsondata.url.replace(/www.beyondwalls\.com/g, "corporate.beyondwalls.com");
      }
      return jsondata;
    });
  }
  const schemaObjects = blog?.schema_code ? extractJsonLd(blog.schema_code) : [];

  return (
    <>
      <Helmet>
        <title>{blog?.meta_title}</title>
        <meta name="description" content={blog?.meta_description} />
        {schemaObjects.map((schemaObject, index) => (
          <script key={index} type="application/ld+json">
            {JSON.stringify(schemaObject)}
          </script>
        ))}
        <link rel="canonical" href={`https://www.beyondwalls.com/blog/${slug ? slug : ''}`} />
      </Helmet>
      <main className="plp-page pb-5 bg-white">
        <section className="blog-banner mt-5 pt-5">
          <div className="container position-relative">
            <div className="row">
              <img src={blog?.thumbnail} alt="Blog Banner" className="img-fluid blog-detail-img" />
              <div className="icon-circle">
                <img src={BlogIcon} alt="Blog Banner" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>

        <section className="details-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8">
                <h1 className="blog-title">{blog?.title}</h1>
                {blog?.content && (
                  <article className="blog-content"
                    dangerouslySetInnerHTML={{ __html: blog.content }}
                  ></article>
                )}

              </div>
              <div className="col-12 col-md-4">
                <div className="blog-sidebar">
                  <div className="sidebox1">
                    <img
                      data-src={Sideimg}
                      alt="Blog Banner"
                      className="img-fluid lazy"
                    />
                    <div className="sidecontent">
                      <h2 className="side-title">
                        Know it alls with beyondwalls
                      </h2>
                      <img
                        src={Right}
                        alt="Blog Banner"
                        className="img-fluid gr-right"
                      />
                    </div>
                  </div>
                  <div className="sidebox2">
                    <h2 className="side-title">
                      Analyse the Locations With High Livability Quotient?
                    </h2>
                    <img
                      src={BRight}
                      alt="Blog Banner"
                      className="img-fluid b-right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="">
          <div className="container recomend-blogs">
            <div className="row">
              <div className="title-flex">
                <h2 className="recom-title">Recommended blogs</h2>
                <a href="#">View all</a>
              </div>
              <div className="blogs-box">
                <div className="blog-card">
                  <img
                    data-src={Recom1}
                    alt="Blog Banner"
                    className="img-fluid lazy"
                  />
                  <div className="blog-card-content">
                    <h3 className="blog-card-title">
                      Know it alls with beyondwalls Lorem
                    </h3>
                    <p className="blog-card-desc">
                      Lorem Ipsum is simply dummy{" "}
                    </p>
                  </div>
                </div>
                <div className="blog-card">
                  <img
                    data-src={Recom2}
                    alt="Blog Banner"
                    className="img-fluid lazy"
                  />
                  <div className="blog-card-content">
                    <h3 className="blog-card-title">
                      Know it alls with beyondwalls Lorem
                    </h3>
                    <p className="blog-card-desc">
                      Lorem Ipsum is simply dummy{" "}
                    </p>
                  </div>
                </div>
                <div className="blog-card">
                  <img
                    data-src={Recom1}
                    alt="Blog Banner"
                    className="img-fluid lazy"
                  />
                  <div className="blog-card-content">
                    <h3 className="blog-card-title">
                      Know it alls with beyondwalls Lorem
                    </h3>
                    <p className="blog-card-desc">
                      Lorem Ipsum is simply dummy{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </main>
    </>
  );
};

export default BlogDetail;
