import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { executeLaravelFrontAPI } from './../../Admin/Utils'; // Ensure this is the correct path or adapt as necessary
import { Icon } from '@iconify/react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProjectCard from '../Layouts/ProjectCard';

const RelatedProjects = ({
    locationName,
    slidesToShow = [4, 3, 2, 1],
    isWishlistActive
}) => {
    const [relatedProjects, setRelatedProjects] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRelatedProjects = async () => {
            try {
                const paramsData = {
                    locality_name: locationName ?? '',
                    searchtype: 'locality_name',
                    per_page: 10,
                };
                const result = await executeLaravelFrontAPI('projects', paramsData, 'GET');
                setRelatedProjects(result.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchRelatedProjects();
    }, [locationName]);

    const handlerelatedProj = (projectSlug) => {
        window.scrollTo(0, 0);
        navigate(`/project/${projectSlug}`);
    };

    const [slidesToShowDesk, slidesToShowLarge, slidesToShowIpro, slidesToShowIpad, slidesToShowMob] = slidesToShow;

    const settingsForRelatedProject = {
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: true,
        speed: 800,
        slidesToShow: slidesToShowDesk || 3,
        slidesToScroll: 1,
        centerMode: false,
        variableWidth: false,
        prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className="prev-arrow" />,
        nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className="next-arrow" />,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: slidesToShowLarge || 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: slidesToShowIpro || 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 993,
                settings: {
                    slidesToShow: slidesToShowIpad || 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 641,
                settings: {
                    slidesToShow: slidesToShowMob || 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="similer-project">
            <h2 className="fw-bold mb-1">You may also like</h2>
            <Slider {...settingsForRelatedProject} className="top-projects-slider">
                {relatedProjects.map((project, index) => (
                    <ProjectCard key={index} project={project} isWishlistActive={isWishlistActive} showWishlist="yes" />
                ))}
            </Slider>
        </section>
    );
};

export default RelatedProjects;
