import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { executeLaravelAPI, userToken, convertDateTOddmmyyyy, parsedUserData } from '../../../Admin/Utils';
import { Button } from 'react-bootstrap';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import DownloadIcon from '../../../../images/download-icon.svg';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import $ from 'jquery';
import Breadcrumbs from './Breadcrumbs';

const CreateButtonComponent = (props) => {
    return (
        <button className='btn-download' variant="primary" onClick={() => console.log(props.data)}>
            <img src={DownloadIcon} alt="Download" />
            <span>Download</span>
        </button>
    );
}

const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: false,
    autoplay: false,
    autoplaySpeed: 3000,
    prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className='prev-arrow' />,
    nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className='next-arrow' />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                arrows: true,
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                variableWidth: false,
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }
    ]
};

const UserCouponListing = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [rowData, setRowData] = useState([]);
    const gridRef = useRef();

    const userType = parsedUserData.userrole;
    const userId = parsedUserData.id;

    useEffect(() => {
        const fetchProjects = async () => {
            setLoading(true);

            if (userType === 'developer') {
                const jsonData = { searchtype: 'developers', developer: parsedUserData.developer_id };
                const response = await executeLaravelAPI('projects', jsonData, 'GET', userToken);

                if (response.status === 200 && response.success && response.data && Array.isArray(response.data.data)) {
                    setProjects(response.data.data);
                    if (response.data.data.length > 0) {
                        callCouponAPI('developer', response.data.data[0].id, parsedUserData.developer_id);
                    }
                } else {
                    setProjects([]);
                }
            } else if (userType === 'user') {
                callCouponAPI('user', userId);
            }

            setLoading(false);
        };

        fetchProjects();
    }, [userType, userId]);

    const projectList = useMemo(() => {
        if (loading || userType === 'user') return <div></div>;

        if (Array.isArray(projects) && projects.length > 0) {
            return projects.map((project, index) => (
                <li key={index}>
                    <button className="project-button" onClick={(event) => projectChange(project.id, event)}>{project.name}</button>
                </li>
            ));
        }

        return <li>No projects available</li>;
    }, [loading, projects, userType]);

    const defaultColDef = {
        flex: 1,
    };

    const projectChange = (pid, event) => {
        $('.project-button').removeClass('active');
        $(event.target).addClass('active');
        callCouponAPI('developer', pid, parsedUserData.developer_id);
    }

    const callCouponAPI = async (type, pid = null, did = null) => {
        let jsonData;
        let endpoint;

        if (type === 'developer') {
            jsonData = { pid: pid, did: did };
            endpoint = 'getcouponbydeveloper';
        } else if (type === 'user') {
            jsonData = { user_id: userId };
            endpoint = 'getcouponsbyuser';
        }

        const response = await executeLaravelAPI(endpoint, jsonData, 'GET', userToken);
        if (response.status === 200) {
            let newArray = [];
            if (type === 'developer') {
                newArray = response.data.map((item, index) => ({
                    'Sr No': index + 1,
                    'Name': item.user.name,
                    'Phone': item.user.phone,
                    'Coupon': item.coupon_code,
                    'Created At': convertDateTOddmmyyyy(item.created_at),
                    'actions': item
                }));
            } else if (type === 'user') {
                newArray = response.data.map((item, index) => ({
                    'Sr No': index + 1,
                    'Name': `${item.project_name}`,
                    'Developer': `${item.developer_name}`,
                    'Coupon': item.coupon_code,
                    'Created At': convertDateTOddmmyyyy(item.created_at),
                    'actions': item
                }));
            }
            setRowData(newArray);
        }
    }

    const onGridReady = useCallback((params) => { }, []);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setGridOption(
            "quickFilterText",
            document.getElementById("filter-text-box").value,
        );
    }, []);

    const onGridSizeChanged = useCallback((params) => {
        if (params && params.columnApi) {
            const gridWidth = document.querySelector(".ag-body-viewport").clientWidth;
            const columnsToShow = [];
            const columnsToHide = [];
            let totalColsWidth = 0;
            const allColumns = params.columnApi.getAllColumns();

            if (allColumns && allColumns.length > 0) {
                allColumns.forEach(column => {
                    totalColsWidth += column.getMinWidth();
                    if (totalColsWidth > gridWidth) {
                        columnsToHide.push(column.getColId());
                    } else {
                        columnsToShow.push(column.getColId());
                    }
                });

                params.columnApi.setColumnsVisible(columnsToShow, true);
                params.columnApi.setColumnsVisible(columnsToHide, false);

                window.setTimeout(() => {
                    params.api.sizeColumnsToFit();
                }, 10);
            }
        }
    }, []);

     // Dynamically generate the column definitions based on userType
     const columnDefs = useMemo(() => {
        let baseColumns = [
            { field: 'Sr No', maxWidth: 100 },
            { field: 'Name', cellClass: 'name-column' },
            { field: 'Created At' },
            {
                field: 'actions',
                headerName: 'Actions',
                cellRenderer: CreateButtonComponent,
                cellRendererParams: (params) => (
                    <CreateButtonComponent value="Action" data={params.data} />
                ),
                flex: 1,
            },
        ];

        // Conditionally add the Phone or Coupon column based on userType
        if (userType === 'developer') {
            baseColumns.splice(2, 0, { field: 'Phone' });
            baseColumns.splice(3, 0, { field: 'Coupon' });
        } else {
            baseColumns.splice(2, 0, { field: 'Coupon' });
            baseColumns.splice(3, 0, { field: 'Developer' });
        }

        return baseColumns;
    }, [userType]);

    const onFirstDataRendered = useCallback((params) => {
        params.api.sizeColumnsToFit();
    }, []);

    return (
        <main className="project-detail-main pb-5 pt-5 mt-5">
            <Breadcrumbs />
            <div className='container'>
                <div className='row'>
                    {userType === 'developer' && (
                        <div className='col-md-6'>
                            <div className="dev-project-wrapper overflow-hidden">
                                <div className="dev-project-list">
                                    <ul><Slider {...settings}>{projectList}</Slider></ul>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={`${userType === 'user' ? 'col-md-12' : 'col-md-6  '}`}>
                        <div className={`dev-header-search-wrap`}>
                            <input
                                type="text"
                                id="filter-text-box"
                                placeholder="Search..."
                                onInput={onFilterTextBoxChanged}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", height: "600px" }}>
                    <div id="grid-wrapper" style={{ width: "100%", height: "100%", minHeight: "600px" }}>
                        <div
                            style={{ width: "100%", height: "600px" }}
                            className="ag-theme-quartz"
                        >
                            <AgGridReact
                                style={{ minHeight: "600px" }}
                                ref={gridRef}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                onGridReady={onGridReady}
                                onGridSizeChanged={onGridSizeChanged}
                                onFirstDataRendered={onFirstDataRendered}
                                rowHeight={80}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default UserCouponListing;
