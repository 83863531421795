import React, { useState } from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';
import { userToken, executeLaravelAPI, showToastMessage, userSrd, createGa4Event} from '../../Admin/Utils';
import{CompletePurchase} from '../../Admin/GoogleAnalytics';

const FormEnquiry = ({projectId, developerId, projectName}) => {
    const [formSubmitted, setformSubmited] = useState(false);
    const [errorMsg, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const [formData, setFormData] = useState({
        enquiry_name: '',
        enquiry_email: '',
        enquiry_phone: '',
        enquiry_message: '',
        project_id: '',
        developer_id: '',
        agreetnc: false
    });

    const handleUserAddSubmit = async (e) => {
        // e.preventDefault();
        try {
            /// Basic form validation

            const { enquiry_name, enquiry_email, enquiry_phone, enquiry_message, agreetnc } = formData;
            formData.project_id = projectId;
            formData.developer_id = developerId;
            const messages = [];

            if (!enquiry_name.trim()) {
                messages.enquiry_name = ['Name is required'];
            } else if (!/^[a-z A-Z\s]+$/.test(enquiry_name)) {
                messages.enquiry_name = ['Name should only contain alphabetic characters'];
            }

            if (!enquiry_email.trim()) {
                messages.enquiry_email = ['Email is required'];
            } else if (!/^\S+@\S+\.\S+$/.test(enquiry_email)) {
                messages.enquiry_email = ['Invalid email address'];
            }

            if (!enquiry_phone.trim()) {
                messages.enquiry_phone = ['Phone no is required'];
            } else if (enquiry_phone.length < 6) {
                messages.enquiry_phone = ['Phone no must be at least 6 characters long'];
            }else if (!/^[6-9]\d{9}$/.test(enquiry_phone)) {
                messages.enquiry_phone = ['Invalid phone number format'];
            }

            if (!agreetnc) {
                messages.agreetnc = 'You must agree to the terms and conditions';
            }

            if (Object.keys(messages).length > 0) {
                setErrors(messages);
                return;
            }

            

            if(userSrd!==null || userSrd!==undefined || userSrd!==''){
                formData.srd = userSrd;
            }

            // console.log("formadata:  " ,formData);
            // return;
            // Assuming userToken is defined elsewhere
            let result = await executeLaravelAPI('add-enquiry', formData, 'POST', userToken);
            // console.table(result.message);
            if (result.status === 409 || result.status === 400) {
                setErrors(result.message);
                // console.log(result.message.email);
            } else if (result.status === 200) {
                showToastMessage('Your request has been submitted successfully.', 'top-right', 'success');
                CompletePurchase(formData.enquiry_phone, window.location.pathname)
                createGa4Event('selldo_form_submitted', 'Selldo Form Submitted Detail page', `${formData.enquiry_phone}`);
                // setTimeout(() => window.location.reload(), 3000);
                setformSubmited(true);
            } else {
                showToastMessage('Failed to submit request', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error:', error);
            showToastMessage('Failed to submit request', 'top-right', 'error');
        }
    }

    return (
        <div className="left-form mt-5 bg-white d-flex flex-column gap-4 realestate-form">
        <div className="d-flex align-items-center gap-2 justify-content-center form-heading">
            {/* <div className="circle">
                <div className="letter fw-bold">R</div>
            </div> */}
            <span className="text-dark fs-4 fw-700"><b>Get a call back from our expert</b></span>
        </div>
        {!formSubmitted ? (
        <><form id="enquiry-form-pdp" className="enquiry-form">
            <div className="d-flex flex-column gap-3">
                <Form.Group className="row mb-2">
                    <div className="col-sm-12 position-relative">
                        <FloatingLabel controlId="floatingName1" label="Name">
                            <Form.Control placeholder="Name" type="text" name='enquiry_name' onChange={handleChange} />
                        </FloatingLabel>
                        {errorMsg && errorMsg.enquiry_name && <div className="text-danger error-msg">{errorMsg.enquiry_name[0]}</div>}
                    </div>
                </Form.Group>
                <Form.Group className="row mb-2">
                    <div className="col-sm-12 position-relative">
                        <FloatingLabel controlId="floatingEmail1" label="Email">
                            <Form.Control placeholder="Email" type="text" name='enquiry_email' onChange={handleChange} />
                        </FloatingLabel>
                        {errorMsg && errorMsg.enquiry_email && <div className="text-danger error-msg">{errorMsg.enquiry_email[0]}</div>}
                    </div>
                </Form.Group>
                <Form.Group className="row mb-2">
                    <div className="col-sm-12 position-relative">
                        <FloatingLabel controlId="floatingPhone1" label="Phone Number">
                            <Form.Control placeholder="Phone Number" type="text" name='enquiry_phone' pattern="[0-9]{10}" maxLength="10" minLength="10" onChange={handleChange} />
                        </FloatingLabel>
                        {errorMsg && errorMsg.enquiry_phone && <div className="text-danger error-msg">{errorMsg.enquiry_phone[0]}</div>}
                    </div>
                </Form.Group>
                <Form.Group className="row mb-2">
                    <div className="col-sm-12 position-relative">
                        <FloatingLabel controlId="floatingMsg" label="Message">
                            <Form.Control placeholder="Message" type="text" name='enquiry_message' onChange={handleChange} />
                        </FloatingLabel>
                        {errorMsg.message && <div className="text-danger error-msg">{errorMsg.message[0]}</div>}
                    </div>
                </Form.Group>
                <div className="form-check c-pointer position-relative">
                    <input className="form-check-input agreetnc" name="agreetnc" type="checkbox" id="flexCheckChecked" checked={formData.agreetnc} onChange={handleChange} />
                    <label className="form-check-label fs-6" htmlFor="flexCheckChecked">
                    I agree to the <a href="https://corporate.beyondwalls.com/terms-and-conditions" ><span className="text-primary  text-decoration-underline"> terms and
                    conditions</span></a>
                    </label>
                    {errorMsg.agreetnc && <div className="text-danger error-msg lasterror">{errorMsg.agreetnc}</div>}
                </div>
                <a onClick={() => [handleUserAddSubmit(), createGa4Event("project_enquiry", 'Project Enquiry Form', `${projectName}`)]} id="register" className="btn sc register-btn text-center">
                    <span className="d-block signin text-uppercase">ENQUIRE NOW</span>
                </a>
                {/* <input type="submit" className="btn btn-primary sc enquire_now_btn-pdp" value="ENQUIRE NOW" /> */}
            </div>
        </form></>
        ):(
            <p>Thank you for your request. Our sales team will get back to you soon.</p>
        )}
    </div>
    );
};

export default FormEnquiry;