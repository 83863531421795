import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import axios from 'axios';
import { lazyLoadImages, appAbsPath } from '../../Admin/Utils';
import ListingBanner from '../../../Assets/img/listing-banner.png';
// import BlogIcon from '../../../Assets/img/blogicon.png';
import Sideimg from '../../../Assets/img/sideimg.png';
import Right from '../../../Assets/img/gr-right.png';
import BRight from '../../../Assets/img/blue-right.png';
// import Recom1 from '../../../Assets/img/r1.png';
// import Recom2 from '../../../Assets/img/r2.png';
import BWwhite from '../../../Assets/img/bwwhite.png';
import EMI from '../../../Assets/img/emi.png';
import Costs from '../../../Assets/img/costs.png';
import Loans from '../../../Assets/img/loans.png';
import Payments from '../../../Assets/img/payments.png';
import Insights from '../../../Assets/img/listingimg1.png';
// import Prevbtn from '../../../Assets/img/prevbtnwh.png';
import Fleximg from '../../../Assets/img/lacalitybg.png';
// import Listimg from '../../../Assets/img/listimg.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Icon } from '@iconify/react';
import { Helmet } from 'react-helmet-async';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import BlogListCard from './Blogs/BlogListCard';

// Line 82:49:  'propertyDetailsRef' is not defined   no-undef
// Line 83:44:  'highlightsRef' is not defined        no-undef
// Line 84:43:  'amenitiesRef' is not defined         no-undef
// Line 85:48:  'specificationRef' is not defined     no-undef
// Line 86:42:  'locationRef' is not defined          no-undef
// Line 87:44:  'developerRef' is not defined         no-undef
// Line 91:27:  'projOverviewHeadRef' is not defined  no-undef
// Line 92:17:  'projOverviewHeadRef' is not defined  no-undef
// Line 94:17:  'projOverviewHeadRef' is not defined  no-undef
// Line 99:21:  'setActiveSection' is not defined     no-undef

const BlogsListing = ({ }) => {
    const [showLink, setShowLink] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [blogs, setBlogs] = useState([]);
    const layoutPlanRef = useRef(null);
    const propertyDetailsRef = useRef(null);
    const highlightsRef = useRef(null);
    const amenitiesRef = useRef(null);
    const specificationRef = useRef(null);
    const locationRef = useRef(null);
    const developerRef = useRef(null);
    const projOverviewHeadRef = useRef(null);
    const [activeSection, setActiveSection] = useState('layout');

    useEffect(() => {
        // axios.get('https://corporate.beyondwalls.com/?portalblogs=1')
        axios.get(`https://corporate.beyondwalls.com/?portalblogbycategory=1&portalblogcategoryname=blog`)
            .then((response) => {
                setBlogs(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const panels = [
        {
            id: 0,
            backgroundImage: `url(${Fleximg})`,
            title: 'Explore The World',
        },
        {
            id: 1,
            backgroundImage: `url(${Fleximg})`,
            title: 'Wild Forest',
        },
        {
            id: 2,
            backgroundImage: `url(${Fleximg})`,
            title: 'Sunny Beach',
        },
        {
            id: 3,
            backgroundImage: `url(${Fleximg})`,
            title: 'City on Winter',
        },
    ];

    useEffect(() => {
        const timer = setTimeout(() => {
            lazyLoadImages();
            setShowLink(true);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        const handleScroll = () => {
            const yOffset = window.pageYOffset + 80; // Add 100px offset
            // const sections = [
            //     { name: 'layout', ref: layoutPlanRef },
            //     { name: 'propertyDetails', ref: propertyDetailsRef },
            //     { name: 'highlights', ref: highlightsRef },
            //     { name: 'amenities', ref: amenitiesRef },
            //     { name: 'specifications', ref: specificationRef },
            //     { name: 'location', ref: locationRef },
            //     { name: 'developers', ref: developerRef }
            //     // Add other sections...
            // ];

            if (yOffset > projOverviewHeadRef.current.offsetTop) {
                projOverviewHeadRef.current.classList.add('sticky');
            } else {
                projOverviewHeadRef.current.classList.remove('sticky');
            }
            // for (let section of sections) {
            //     // console.log(section.ref.current, yOffset)
            //     if (section.ref.current && yOffset >= section.ref.current.offsetTop - 80) {
            //         setActiveSection(section.name);
            //     }
            // }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        centerMode: false,
        fade: true,
        prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className='prev-arrow' />,
        nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className='next-arrow' />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (<>
        <Helmet>
            <title>Latest Real Estate Industry News, Articles and Property Insights</title>
            <meta name="description" content="Read Indian real estate news, industry insights, guides and tips from on BeyondWalls.com. Get all information on property buying in Pune." />

        </Helmet>
        <main className='plp-page pb-5 mt-5 bg-white'>
            <section className="mt-5 pt-5">
                <div className="container position-relative bloglisting-banner">
                    <div className="row align-items-center">
                        <div className='col-12 col-md-6'>
                            <div className='banner-content'>
                                <img src={BWwhite} alt="Blog Banner" className="img-fluid" />
                                <h1 className='banner-title'>Know it alls with <br></br>beyondwalls</h1>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 position-relative' >
                            <div className='banner-img'>
                                <img src={EMI} alt="Blog Banner" className="img-fluid emiimg" />
                                <img src={Loans} alt="Blog Banner" className="img-fluid loansimg" />
                                <img src={Costs} alt="Blog Banner" className="img-fluid costsimg" />
                                <img src={Payments} alt="Blog Banner" className="img-fluid paymentimg" />
                                <img src={ListingBanner} alt="Blog Banner" className="img-fluid bannerimg" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='details-sec'>
                <div className="blog-listing-head py-2 mb-5" ref={projOverviewHeadRef}>
                    <div className={`mx-auto menu-scroll-container border-bottom-plp layout-mobile-slider`}>
                        
                        <Navbar expand="lg" className="">
                            <Container>
                                
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="">
                                        {/* <Nav.Link href="#home">Home</Nav.Link>
                                        // <Nav.Link href="#link">Link</Nav.Link> */}
                                        <Nav.Link>Articles</Nav.Link>
                                        {/* <NavDropdown title="Insights" id="basic-nav-dropdown1">
                                            <NavDropdown.Item href="#guides">
                                            Guides
                                            </NavDropdown.Item>
                                            <NavDropdown.Item href="#trends">Trends</NavDropdown.Item>
                                          
                                            <NavDropdown.Item href="#market-analysis">
                                            Market Analysis
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                        <NavDropdown title="Home & Lifestyle" id="basic-nav-dropdown2">
                                            <NavDropdown.Item href="#home-improvement">
                                            Home Improvement
                                            </NavDropdown.Item>
                                            <NavDropdown.Item href="#architecture-design">Architecture & Design</NavDropdown.Item>                                          
                                            <NavDropdown.Item href="#eco-living">
                                            Eco Living
                                            </NavDropdown.Item>
                                            <NavDropdown.Item href="#leisure">
                                            Leisure
                                            </NavDropdown.Item>
                                            <NavDropdown.Item href="#vaastu">
                                            Vaastu
                                            </NavDropdown.Item>
                                        </NavDropdown> */}
                                        
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>

                    </div>
                </div>


               
            <section className="">
                <div className='bloglisting-card'>
                    <div className="container">
                        <div className="row">
                           
                        </div>
                        <div className='blogs-box'>
                            {blogs.map((blog, index) => (
                               
                                <BlogListCard blog={blog} />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            
                {/* <div className='container'>
                    <div className='row tabs-sec'>
                        <div className='col-12 col-md-8'>
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Insights</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Home & Lifestyle</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Finance & Legal</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className='insightslider1'>
                                        <Slider {...settings}>
                                            <div className='slide1'>
                                                <div className='slide-flex'>
                                                    <div className='sbox1'>
                                                        <h3 className='sbox-title'>Know it alls with beyondwalls</h3>
                                                        <a className='know-more'>Know More</a>
                                                    </div>
                                                    <div className='sbox2'>
                                                        <img src={Insights} alt="Blog Banner" className="img-fluid sbox2img" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='slide1'>
                                                <div className='slide-flex'>
                                                    <div className='sbox1'>
                                                        <h3 className='sbox-title'>Know it alls with beyondwalls</h3>
                                                        <a className='know-more'>Know More</a>
                                                    </div>
                                                    <div className='sbox2'>
                                                        <img src={Insights} alt="Blog Banner" className="img-fluid sbox2img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">...</div>
                                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
                            </div>
                            <div className='blogs-box'>
                                {blogs.slice(0, 2).map((blogs, index) => (
                                    // <a className='blog-card' href={`${appAbsPath}/blog/${blogs.slug}`} target="_blank">
                                    //     <div className='cardimg'>
                                    //         <img data-src={blogs.image.url} alt="Blog Banner" className="img-fluid lazy" />
                                    //     </div>
                                    //     <div className='blog-card-content'>
                                    //         <h2 className='blog-card-title'>{blogs.title}</h2>
                                    //         <p className='blog-card-desc'>{blogs.content}</p>
                                    //     </div>
                                    // </a>
                                    <BlogListCard blog={blogs} />
                                ))}
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div className='blog-sidebar'>
                                <div className='sidebox1'>
                                    <img data-src={Sideimg} alt="Blog Banner" className="img-fluid lazy" />
                                    <div className='sidecontent'>
                                        <h3 className='side-title'>Know it alls with beyondwalls</h3>
                                        <img src={Right} alt="Blog Banner" className="img-fluid gr-right" />
                                    </div>
                                </div>
                                <div className='sidebox2'>
                                    <h3 className='side-title'>Analyse the Locations With High Livability Quotient?</h3>
                                    <img src={BRight} alt="Blog Banner" className="img-fluid b-right" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>

            {/* <section className='expand-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className="exp-container">
                            {panels.map((panel, index) => (
                                <div
                                    key={panel.id}
                                    className={`panel ${activeIndex === index ? 'active' : ''}`}
                                    style={{ backgroundImage: panel.backgroundImage }}
                                    onClick={() => setActiveIndex(index)}
                                >
                                    <div className='exp-content'>
                                        <h3 className='exp-title'>Click here to get to know your Locality</h3>
                                        <img src={Right} alt="Blog Banner" className="img-fluid exp-arrow" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <section className='listing-sec'>
                <div className='container list-container'>
                    <div className='row'>
                        <div className='col-12 col-md-8'>
                            <h2 className='section-title'>From information and beyond</h2>
                            {blogs.slice(0, 3).map((blogs, index) => (
                                <div className='blog-listing'>
                                    <div className='imgdiv'>
                                        <img src={blogs.image.url} alt="Blog Banner" className="img-fluid b-right" />
                                    </div>
                                    <div className='blog-content'>
                                        <h2 className='blogtitle'>{blogs.title}</h2>
                                        <p className='blogdesc'>{blogs.content}</p>
                                        <a className='readmore' href={blogs.blogurl} target="_blank">Read More</a>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='col-12 col-md-4'>
                            <div className='blog-sidebar'>
                                <div className='sidebox1'>
                                    <img data-src={Sideimg} alt="Blog Banner" className="img-fluid lazy" />
                                    <div className='sidecontent'>
                                        <h3 className='side-title'>Know it alls with beyondwalls</h3>
                                        <img src={Right} alt="Blog Banner" className="img-fluid gr-right" />
                                    </div>
                                </div>
                                <div className='sidebox2'>
                                    <h3 className='side-title'>Analyse the Locations With High Livability Quotient?</h3>
                                    <img src={BRight} alt="Blog Banner" className="img-fluid b-right" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='expert-sec'>
                <div className='container recomend-blogs'>
                    <div className='row'>
                        <div className='title-flex'>
                            <h2 className='recom-title'>Tips from experts</h2>
                        </div>
                        <div className='blogs-box'>
                            {blogs.slice(0, 3).map((blogs, index) => (
                                <a className='blog-card' href={blogs.blogurl} target="_blank">
                                    <div className='cardimg'>
                                        <img data-src={blogs.image.url} alt="Blog Banner" className="img-fluid lazy" />
                                    </div>
                                    <div className='blog-card-content'>
                                        <h2 className='blog-card-title'>{blogs.title}</h2>
                                        <p className='blog-card-desc'>{blogs.content}</p>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </section> */}
        </main>
    </>);
};

export default BlogsListing;