import React, { useEffect, useState, useRef } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { appAbsPath, userToken, executeLaravelAPI} from '../../Utils';
import { Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Icon } from '@iconify/react';
import addNewImg from '../../../../Assets/img/add-new.svg';
import deleteBtnImg from '../../../../Assets/img/delete-btn.svg';

const moment = require('moment');
const itemsPerPageOptions = [20, 50, 100, 500, 1000];

const LocationsList = () => {
    const location = useLocation();
    const inputRef = useRef(null);
    const pageDetails={'pagetitle':'Locations','pageslug':'locations'};

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
    const [paginatedData, setPaginatedData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);

    const inputActiveRefs = useRef([]);
    
    useEffect(() => {
        document.title = pageDetails.pagetitle;
        getLocations();
    },[currentPage, itemsPerPage]);

    const getLocations = async (paramsData) => {
        try {
            // sqftLowToHigh params.orderby
            setLoading(true);
            const result=await executeLaravelAPI('locations', paramsData, 'GET',userToken);
            const locationData=result.data.data;
            setPaginatedData(locationData);
            setTotalItems(result.data.total);
            
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }
    
    
    {/* Pagination Function */}
    const handlePageClick = ({ selected }) => {
        // alert(selected);
        setCurrentPage(selected);
        getLocations((selected+1));
       
    };

    const handleItemsPerPageChange = (e) => {
        const selectedValue = parseInt(e.target.value, 10);
        setItemsPerPage(selectedValue);
        setCurrentPage(0); // Reset to the first page when changing items per page
    };
    
    // Search filter
    const [searchQuery, setSearchQuery] = useState('');
    
     // Out side click hide div
     const searchInputRef = useRef(null);
     const suggestionContainerRef = useRef(null);
   

    return (
        <>
        <section className="content"> 
            <div className="row">
                <div className="col-md-12">
                    <div className="box">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='row'> 
                                    <div className='col-lg-12'>
                                        <div className='position-relative' ref={searchInputRef}>
                                            <div className='position-realtive'>
                                                <input
                                                    type="search"
                                                    className='form-control search-input shadow-none border-none'
                                                    placeholder="Search for locality, location, or builder"
                                                    value={searchQuery}
                                                    
                                                    ref={inputRef}
                                                    autoComplete='off'
                                                    style={{background: "url('https://api.iconify.design/ion/search-outline.svg') no-repeat 10px center / 24px"}}
                                                />
                                                
                                            </div>

                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className='col-lg-12 col-12 pe-0 d-flex justify-content-end'>
                            <NavLink className="nav-link" to={`${appAbsPath}/portaladmin/add/location`}>
                                <img src={addNewImg} />
                            </NavLink>
                        </div>

                        
                        <div className="box-body table-container mt-4 hr-scroll-style">
                            <table className="table table-resposive table-hover dashboard-table shadow-sm mb-0">
                                <thead>
                                    <tr>
                                        <th width="40px" style={{ minWidth: '40px' }} className='sticky-column'>
                                           No.
                                        </th>
                                        <th width="200px" style={{'minWidth': '200px'}} className='sticky-column column-snd'> Location Name</th>
                                        <th>Status</th>
                                        <th className='sticky-column'>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {loading ? (
                                    <tr><td colSpan={7}><div className='loading'><span className="loadingspan">Loading...</span><span className="custom-loader"></span></div></td></tr>
                                    ) : (
                                        paginatedData.map((location, index) => (
                                            <React.Fragment key={`fragment-${location.id}-${index}`} >
                                                <tr key={location.id}>
                                                    <td>
                                                        {index + 1}
                                                    </td>
                                                    <td className='sticky-column column-snd'>{location.name}</td>
                                                    <td className='sticky-column'>
                                                        <Form.Check // prettier-ignore
                                                        type="switch"
                                                        id={`custom-switch-${index}`}
                                                        key={index}
                                                        ref={el => inputActiveRefs.current[index] = el}
                                                        name={`active${index}`}
                                                        data-location-id={location.id}
                                                        checked={location.is_active}       
                                                        />
                                                    </td>
                                                    <td className='sticky-column'>
                                                        <NavLink className='btn btn-primary btn-sm' to={`${appAbsPath}/portaladmin/location/edit/${location.id}`} >
                                                            Edit
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                                
                                            </React.Fragment>
                                        ))
                                    )
                                }
                                </tbody>
                                <tfoot></tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-5 align-items-center'>
                <div className='col-lg-4'>
                    {/* Display "Showing x-y of z items" */}
                    <p className='graylight mb-0'>Showing {currentPage * itemsPerPage + 1}-{Math.min((currentPage + 1) * itemsPerPage, totalItems)} of {totalItems} items</p>
                </div>
                <div className='col-lg-8 d-flex justify-content-end align-items-center'>
                    {/* Render pagination component */}
                    <div className='me-3'>
                        <span className='graylight'>Results per page </span>
                        <select className='showPerPage' value={itemsPerPage} onChange={handleItemsPerPageChange}>
                            {itemsPerPageOptions.map((option) => (
                                <option key={option} value={option}>
                                {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <ReactPaginate
                        pageCount={Math.ceil(totalItems / itemsPerPage)}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-end mb-0'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        activeClassName={'active'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        disableInitialCallback={true}     
                    />              
                </div>
            </div>
           
        </section>
       
        </>
    )
};
const Loader = () => {
    return <tr><td colSpan="7">Loading...</td></tr>;
};
export default LocationsList;