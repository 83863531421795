import React, { useEffect, useState, lazy, Suspense, useMemo, useContext } from 'react';
import Loader from './../../../Components/Loader';
import { Carousel, Accordion, Tab, Row, Col, Nav } from 'react-bootstrap';
import { appAbsPath, executeLaravelFrontAPI, parsedUserData, createGa4Event, formatNumber, calculateMonth } from './../../Admin/Utils';
// import ReactPaginate from 'react-paginate';
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Icon } from '@iconify/react';
// import ProjectCard from './ProjectCard';
import Placeholder from 'react-bootstrap/Placeholder';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CompareProjectContext, CompareProjectProvider } from '../Layouts/CompareProjectContext';
import FilterSidebar from './FilterSidebar';
import { Helmet } from 'react-helmet-async';
import { debounce } from 'lodash';
import RelatedProjects from './RecomendedProjects';
const ProjectCardListing = lazy(() => import('./ProjectCardListing'));


const itemsPerPageOptions = [5, 50, 100, 500, 1000];
let flagPage = false;
let newPage;
const ProjectList = ({ }) => {
    // let currentUrl = getCurrentUrl();
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [isPdp, setPdp] = useState(false);
    const [noDataFound, setNoDataFound] = useState(false);
    const pageDetails = { 'pagetitle': 'Projects', 'pageslug': 'projects' };
    const isLandingPage = location.pathname === '/';
    const currentUrl = location.pathname;

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
    const [paginatedData, setPaginatedData] = useState([]);
    const [projects, setResponseData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);

    const [sortCriteria, setSortCriteria] = useState('recentlyLaunched');

    const [priceRange, setPriceRange] = useState([500000, 50000000]);
    const [builtUpArea, setBuiltRange] = useState([100, 5000]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedPossessionStatus, setSelectedPossessionStatus] = useState([]);
    const [selectedBHK, setSelectedBHK] = useState([]);
    const [selectedConf, setSelectedConf] = useState([]);

    const [getProjectstatusData, getProjectStatus] = useState([]);
    const [getProjectBhkTypeData, getProjectBhkType] = useState([]);
    const [numericParts, getNumericParts] = useState([]);
    const [nonNumericParts, getNonNumericParts] = useState([]);
    const [showRecomendedProejctComponent, setRecomendedProejctComponent] = useState(false);

    const initialDisplayLimit = 10; // Change this number to show more/less items initially
    const [showAllBhk, setShowAllBhk] = useState(false);
    // const [showAllConf, setShowAllConf] = useState(false);
    const [userdetails, setUserResponseData] = useState(null);
    const [isWishList, isWishlistActive] = useState(false);
    const [getAllParams1, setAllParams1] = useState([]);

    const [seoTitle, setSeoTitle] = useState('');
    const [seoDescription, setSeoDiscription] = useState('');

    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const locality_name = location.search.split('locality_name=')[1];
    const [hasParams, setHasParams] = useState(false);
    const [activeSort, setActiveSort] = useState('');
    // const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        setHasParams(urlParams.toString().length > 0);
    }, [location.search]); // Effect will run whenever location.search changes

    const getAllParams = (location) => {
        const searchParams = new URLSearchParams(location.search);
        const is_new_in_town = searchParams.get('is_new_in_town');
        const is_hot_selling = searchParams.get('is_hot_selling');
        const is_affordable = searchParams.get('is_affordable');
        const is_ready_to_move = searchParams.get('is_ready_to_move');
        const is_great_view = searchParams.get('is_great_view');
        const is_mandate = searchParams.get('is_mandate');
        const is_live_in_sky = searchParams.get('is_live_in_sky');
        const allParams = {};

        for (const [key, value] of searchParams.entries()) {
            allParams[key] = value;
        }
        // allParams.city = city;
        if (is_new_in_town === "true") {
            allParams.is_new_in_town = true;
        }
        if (is_hot_selling === "true") {
            allParams.is_hot_selling = true;
        }
        if (is_affordable === "true") {
            allParams.is_affordable = true;
        }
        if (is_ready_to_move === "true") {
            allParams.is_ready_to_move = true;
        }
        if (is_great_view === "true") {
            allParams.is_great_view = true;
        }
        if (is_mandate === "true") {
            allParams.is_mandate = true;
        }
        if (is_live_in_sky === "true") {
            allParams.is_live_in_sky = true
        }
        allParams.page = currentPage + 1;
        allParams.per_page = 5;
        return allParams;
    }
    const [params, setParams] = useState(getAllParams(location));

    useEffect(() => {
        if (!hasMore) {
            setRecomendedProejctComponent(true);
        }
    }, [hasMore]);

    useEffect(() => {
        setParams(getAllParams(location));
        let params = new URLSearchParams(location.search);
        let configurationValue = params.get('configuration');
        let projectStatusValue = params.get('project_status');
        let possessionStatusValue = params.get('possession_months');
        let minpriceValue = params.get('minprice');
        let maxpriceValue = params.get('maxprice');
        let minBuiltValue = params.get('carpet_min');
        let maxBuiltValue = params.get('carpet_max');
        if (configurationValue) {
            let configurationArray = configurationValue.split(',');
            setSelectedBHK(configurationArray);
        }
        if (projectStatusValue) {
            let projectStatusArray = projectStatusValue.split(',');
            setSelectedStatus(projectStatusArray);
        }
        if (possessionStatusValue) {
            let possessionStatusArray = possessionStatusValue.split(',');
            // console.log("possessionStatusArray", possessionStatusArray);
            setSelectedPossessionStatus(possessionStatusArray);
        }
        if (minpriceValue || maxpriceValue) {
            setPriceRange([minpriceValue, maxpriceValue]);
        }
        if (minBuiltValue || maxBuiltValue) {
            setBuiltRange([minBuiltValue, maxBuiltValue]);
        }
    }, [location.pathname, location.search,]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (selectedStatus.length != 0) {
        params.project_status = selectedStatus;
    }
    if (selectedPossessionStatus.length != 0) {
        // console.log("selectedPossessionStatus111111", selectedPossessionStatus);
        params.possession_months = selectedPossessionStatus;
    }
    if (numericParts.length !== 0) {
        params.bhk = numericParts ? numericParts : 0;
        params.type = nonNumericParts;
    }
    if (selectedConf.length != 0) {
        params.configurations = selectedConf.toString().toLowerCase();
        // console.log("Configurations: ", selectedConf);
    }
    if (sortCriteria.length != 0) {
        params.orderby = sortCriteria;
    }
    if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
        let minPrice = Math.floor(priceRange[0]);
        let maxPrice = Math.ceil(priceRange[1]);
        params.minprice = minPrice;
        params.maxprice = maxPrice;
    }
    if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
        let minBuilt = Math.floor(builtUpArea[0]);
        let maxBuilt = Math.ceil(builtUpArea[1]);
        params.carpet_min = minBuilt;
        params.carpet_max = maxBuilt;
    }
    const handleSliderChange = (newRange) => {
        setPriceRange(newRange);
    };
    const handleSliderChangeArea = (newRangeArea) => {
        setBuiltRange(newRangeArea);
    };

    const debouncedHandleSliderChangeComplete = debounce((params) => {
        setAllParams1(params);
        getProjects(params, 0);
    }, 1000);
    const debouncedHandleSliderChangeCompleteArea = debounce((params) => {
        setAllParams1(params);
        getProjects(params, 0);
    }, 1000);
    if (currentUrl === '/') {
        currentUrl = '/projects';
    }
    const handleSliderChangeComplete = (params) => {
        if (locality_name?.length != 0) {
            if (locality_name !== undefined) {
                params.locality_name = locality_name;
            }
            params.searchtype = 'locality_name';
        }
        if (selectedStatus.length != 0) {
            params.project_status = selectedStatus;
        }
        if (selectedPossessionStatus.length != 0) {
            params.possession_months = selectedPossessionStatus;
        }
        if (numericParts.length !== 0) {
            params.bhk = numericParts ? numericParts : 0;
            params.type = nonNumericParts;
        }
        if (selectedConf.length != 0) {
            params.configurations = selectedConf.toString().toLowerCase();
        }
        if (sortCriteria.length != 0) {
            params.orderby = sortCriteria;
        }
        if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
            let minBuilt = Math.floor(builtUpArea[0]);
            let maxBuilt = Math.ceil(builtUpArea[1]);
            params.carpet_min = minBuilt;
            params.carpet_max = maxBuilt;
        }

        const minPrice = Math.floor(params[0]);
        const maxPrice = Math.ceil(params[1]);
        const priceparams = { ...params, minprice: minPrice, maxprice: maxPrice };
        delete priceparams[0];
        delete priceparams[1];
        let paramsURL = new URLSearchParams(location.search);
        paramsURL.set('minprice', minPrice);
        paramsURL.set('maxprice', maxPrice);
        location.search = paramsURL.toString();
        console.log('New Budget Price :- ', location.search);
        const newUrl = `${currentUrl}?${location.search}`;
        navigate(newUrl, { replace: true });
        debouncedHandleSliderChangeComplete(priceparams);
    };
    const handleSliderChangeCompleteArea = (params) => {
        if (locality_name?.length != 0) {
            if (locality_name !== undefined) {
                params.locality_name = locality_name;
            }
            params.searchtype = 'locality_name';
        }
        if (selectedStatus.length != 0) {
            params.project_status = selectedStatus;
        }
        if (selectedPossessionStatus.length != 0) {
            params.possession_months = selectedPossessionStatus;
        }
        if (numericParts.length !== 0) {
            params.bhk = numericParts ? numericParts : 0;
            params.type = nonNumericParts;
        }
        if (selectedConf.length != 0) {
            params.configurations = selectedConf.toString().toLowerCase();
        }
        if (sortCriteria.length != 0) {
            params.orderby = sortCriteria;
        }
        if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
            let minPrice = Math.floor(priceRange[0]);
            let maxPrice = Math.ceil(priceRange[1]);
            params.minprice = minPrice;
            params.maxprice = maxPrice;
        }
        const minBuilt = Math.floor(params[0]);
        const maxBuilt = Math.ceil(params[1]);
        const builtparams = { ...params, carpet_min: minBuilt, carpet_max: maxBuilt };
        delete builtparams[0];
        delete builtparams[1];
        let paramsURL = new URLSearchParams(location.search);
        paramsURL.set('carpet_min', minBuilt);
        paramsURL.set('carpet_max', maxBuilt);
        location.search = paramsURL.toString();
        // console.log('New Budget Price :- ', location.search);
        const newUrl = `${currentUrl}?${location.search}`;
        navigate(newUrl, { replace: true });
        debouncedHandleSliderChangeCompleteArea(builtparams);
    };

    // useEffect(() => {
    //     // This effect only clears the paginated data
    //     setPaginatedData([]);
    //     setPage(1);
    // }, [location.search, currentPage, itemsPerPage, selectedStatus, selectedBHK, sortCriteria, priceRange]); // Dependencies that trigger data reset

    useEffect(() => {
        // This effect only clears the paginated data
        setPaginatedData([]);
        setPage(1);
    }, [location.search, itemsPerPage, selectedStatus, selectedBHK, sortCriteria, priceRange, builtUpArea]); // Dependencies that trigger data reset

    useEffect(() => {
        setTimeout(async () => {
            if (paginatedData.length === 0) {
                // getProjectStatusFunc();
                getProjectBhkTypeFunc();
                setAllParams1(params);
                getProjects(params, 0);
                // console.log("Callingggggggggg-----------3333333333")
            }
        }, 1000);
    }, [params, selectedStatus, selectedBHK, sortCriteria]);

    const getProjects = async (paramsData, scrollpage) => {
        // setLoading(true);
        // setNoDataFound(false);
        if (paramsData === undefined || paramsData === "") {
            paramsData = getAllParams1;
        }
        let result = {};
        try {
            if (flagPage === false || scrollpage === 0) {
                scrollpage = 0;
                newPage = 1;
                setPage(newPage);
                paramsData.page = newPage;
            } else {
                scrollpage = 1;
                newPage = page + 1;
                setPage(newPage);
                paramsData.page = newPage;
            }
            const path = location.pathname.split("/");
            const seoparam = path[path.length - 1].split("-");
            const seobedroom = seoparam[0];
            const seocity = seoparam[seoparam.length - 1];
            // SEO URL like: /2-bhk-flats-for-sale-in-wakad-pune
            const seolocality = seoparam[6];
            if (seoparam.length === 8 && seoparam[1] === 'bhk') {
                paramsData.city = seocity;
                paramsData.locality_name = seolocality;
                paramsData.bhk = seobedroom;
                paramsData.type = 'bhk';
                paramsData.searchtype = 'locality_name';
                setSeoTitle(`${seoparam[0]} ${seoparam[1]} Flats, Apartments for Sale in ${seolocality} ${seocity} - ${totalItems}+ Properties`)
                setSeoDiscription(`Find & Explore ${seoparam[0]} ${seoparam[1]}Flats for Sale in ${seolocality}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)

            }
            // SEO URL like: /2-bhk-flats-for-sale-in-pune
            if (seoparam.length === 7 && seoparam[1] === 'bhk') {
                paramsData.city = seocity;
                paramsData.bhk = seobedroom;
                paramsData.type = 'bhk';
            }
            // SEO URL like: /property-in-pune
            if (seoparam.length === 3 && seoparam[0] === 'property') {
                paramsData.city = seocity;
            }


            //SEO URL like: /ready-to-move-in-wakad-pune
            if (seoparam.length === 6 && seoparam[0] === 'ready' && seoparam[1] === 'to' && seoparam[2] === 'move') {
                paramsData.is_ready_to_move = true;
                paramsData.locality_name = seoparam[4];
                paramsData.searchtype = 'locality_name';
                setSeoTitle(`Buy Ready to Move Flats / Apartments in  ${seoparam[4]} ${seocity} - ${totalItems}+ Flats`)
                setSeoDiscription(`Find & Explore Ready to Move Flats & Apartments for Sale in ${seoparam[4]}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)
            }

            //SEO URL like: /what-a-view-in-wakad-pune
            if (seoparam.length === 6 && seoparam[0] === 'what' && seoparam[1] === 'a' && seoparam[2] === 'view') {
                paramsData.is_great_view = true;
                paramsData.locality_name = seoparam[4];
                paramsData.searchtype = 'locality_name';
                setSeoTitle(`Buy Flats / Apartments with a Great View in  ${seoparam[4]} ${seocity} - ${totalItems}+ Flats`)
                setSeoDiscription(`Find & Explore Flats & Apartments with a Great View for Sale in ${seoparam[4]}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)
            }

            //SEO URL like: /new-in-wakad-pune
            if (seoparam.length === 4 && seoparam[0] === 'new' && seoparam[1] === 'in') {
                paramsData.is_hot_selling = true;
                paramsData.locality_name = seoparam[2];
                paramsData.searchtype = 'locality_name';
                setSeoTitle(`Explore New Launch Flats / Apartments in  ${seoparam[2]} ${seocity} - ${totalItems}+ Flats`)
                setSeoDiscription(`Find & Explore New Launch Flats & Apartments for Sale in ${seoparam[2]}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)
            }

            //SEO URL like: /hot-selling-in-wakad-pune
            if (seoparam.length === 5 && seoparam[0] === 'hot' && seoparam[1] === 'selling') {
                paramsData.is_new_in_town = true;
                paramsData.locality_name = seoparam[3];
                paramsData.searchtype = 'locality_name';
                setSeoTitle(`Explore Hot Selling Flats / Apartments in  ${seoparam[3]} ${seocity} - ${totalItems}+ Flats`)
                setSeoDiscription(`Find & Explore Hot Selling Flats & Apartments for Sale in ${seoparam[3]}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)
            }

            //SEO URL like: affordable-homes-in-wakad-pune
            if (seoparam.length === 5 && seoparam[0] === 'affordable' && seoparam[1] === 'homes') {
                paramsData.is_affordable = true;
                paramsData.locality_name = seoparam[3];
                paramsData.searchtype = 'locality_name';
                setSeoTitle(`Affordable Housing Projects | Affordable Flats in  ${seoparam[3]} ${seocity}`)
                setSeoDiscription(`Find low budget affordable flats or apartments in  ${seoparam[3]}, ${seocity} for sale. Get verified property details for all listed affordable housing projects at BeyondWalls.`)
            }

            //SEO URL like: live-in-sky-in-wakad-pune
            if (seoparam.length === 5 && seoparam[0] === 'live' && seoparam[2] === 'sky') {
                paramsData.is_live_in_sky = true;
                paramsData.locality_name = seoparam[4];
                paramsData.searchtype = 'locality_name';
                setSeoTitle(`Explore High-Rise Building Flats / Apartments in  ${seoparam[4]} ${seocity} - ${totalItems}+ Flats`)
                setSeoDiscription(`Find & Explore High-Rise Building Flats & Apartments for Sale in ${seoparam[4]}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)
            }

            if (window.location.pathname.includes('irfs') ) {
                paramsData.is_irfs = true;
            }
            

            // console.log("collingggggg----------2222222222")
            result = await executeLaravelFrontAPI('projects', { ...paramsData }, 'GET');
            const newItems = result.data.data;
            const dataProjectDetails = result.data;
            if (scrollpage === 1) {
                setPage(page + 1);
                setPaginatedData([...paginatedData, ...newItems]);
            }
            if (scrollpage === 0) {
                setPage(1);
                setPaginatedData([...newItems]);
            }
            setTotalItems(result.data.total);
            isWishlistActive(false);
            if(window.location.pathname.includes('irfs') ) {
                setPdp(true);
            }

            
            setHasMore(newItems.length === 5);
            flagPage = true;
        } catch (error) {
            console.error('Error fetching data:', error);
            setNoDataFound(true);
        } finally {
            setLoading(false);
            if (result.data?.total === 0) {
                setNoDataFound(true);
                setPaginatedData([]);
                setPage(1);
            }
        }
    }
    function getSortProjectFilter(sortCriteria) {
        setActiveSort(sortCriteria);
        const params = getAllParams(location);
        if (locality_name?.length != 0) {
            if (locality_name !== undefined) {
                params.locality_name = locality_name;
            }
            params.searchtype = 'locality_name';
        }
        if (selectedStatus.length != 0) {
            params.project_status = selectedStatus;
        }
        if (selectedPossessionStatus.length != 0) {
            params.possession_months = selectedPossessionStatus;
        }
        if (numericParts.length !== 0) {
            params.bhk = numericParts ? numericParts : 0;
            params.type = nonNumericParts;
        }
        if (selectedConf.length != 0) {
            params.configurations = selectedConf.toString().toLowerCase();
        }
        if (sortCriteria.length != 0) {
            params.orderby = sortCriteria;
            //console.log(sortCriteria);
        }
        if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
            let minPrice = Math.floor(priceRange[0]);
            let maxPrice = Math.ceil(priceRange[1]);
            params.minprice = minPrice;
            params.maxprice = maxPrice;
        }
        if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
            let minBuilt = Math.floor(builtUpArea[0]);
            let maxBuilt = Math.ceil(builtUpArea[1]);
            params.carpet_min = minBuilt;
            params.carpet_max = maxBuilt;
        }
        let paramsURL = new URLSearchParams(location.search);
        paramsURL.set('orderby', sortCriteria);
        location.search = paramsURL.toString();
        const newUrl = `${currentUrl}?${location.search}`;
        navigate(newUrl, { replace: true });
        setSortCriteria(sortCriteria);
        //console.log("Callingggggg-----------444444444");
        getProjects(params, 0);
    }

    // const getProjectStatusFunc = async () => {
    //     try {
    //     const result= await executeLaravelFrontAPI('projectstatus', "", 'GET');
    //         getProjectStatus(result.data);
    //         // console.log(result.data)
    //     } catch (error) {
    //         console.error('Error parsing JSON:', error);
    //     } finally {

    //     }
    // };
    const getProjectBhkTypeFunc = async () => {
        try {
            const result = await executeLaravelFrontAPI('bhkconfigurations', "", 'GET');
            getProjectBhkType(result.data);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        } finally {

        }
    };
    const filterByBhkType = (result) => {
        // return result.filter(item => !isNaN(item.name.charAt(0))) || [];
    };

    //const filteredByBhk = filterByBhkType(getProjectBhkTypeData);
    const filteredByBhk = getProjectBhkTypeData;

    {/* Configuration Tab Function */ }
    const [selectedTab, setSelectedTab] = useState(null);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const handleFilter = (newRange) => {
        const params = getAllParams(location);
        params.minprice = newRange[0];
        params.maxprice = newRange[1];
        setPage(1);
        getProjects(params);
        createGa4Event('filter_budget', 'Budget Filter', `${newRange[0]} - ${newRange[1]}`);
    };
    const handleFilterArea = (newRange) => {
        const params = getAllParams(location);
        params.carpet_min = newRange[0];
        params.carpet_max = newRange[1];
        setPage(1);
        getProjects(params);
        createGa4Event('filter_builtup', 'Built Up Filter', `${newRange[0]} - ${newRange[1]}`);
    };

    const getMinMaxCarpet = (project, configurationName) => {
        const carpetValues = [];

        project.towers.forEach((tower) => {
            const matchingConfigurations = tower.configurations.filter((config) => config.name === configurationName);

            if (matchingConfigurations.length > 0) {
                carpetValues.push(parseFloat(matchingConfigurations[0].carpet));
            }
        });

        const minCarpet = Math.min(...carpetValues);
        const maxCarpet = Math.max(...carpetValues);

        return { minCarpet, maxCarpet };
    };

    {/* Start Project Status Sort Function */ }
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortOrderBHK, setSortOrderBHK] = useState('asc');
    // const [sortOrderConf, setSortOrderConf] = useState('asc');

    const statusOrder = {
        'New Project': 1,
        'Under Construction': 2,
        'Ready To Move': 3,
        // 'Lunch': 4,
        // 'ongoing': 5,
        // 'prelaunch': 6,
    };

    const toggleStatus = (status) => {
        setSelectedStatus((prevStatus) => {
            const newStatus = prevStatus.includes(status)
                ? prevStatus.filter(item => item !== status) // Remove if already selected
                : [...prevStatus, status]; // Add if not already selected
            // Update params and fetch projects
            const params = getAllParams(location);
            if (locality_name?.length != 0) {
                if (locality_name !== undefined) {
                    params.locality_name = locality_name;
                }
                params.searchtype = 'locality_name';
            }
            if (newStatus.length !== 0) {
                params.project_status = newStatus;
            }
            if (numericParts.length !== 0) {
                params.bhk = numericParts ? numericParts : 0;
                params.type = nonNumericParts;
            }
            if (selectedConf.length !== 0) {
                params.configurations = selectedConf.toString().toLowerCase();
            }
            if (sortCriteria.length !== 0) {
                params.orderby = sortCriteria;
            }
            if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
                let minPrice = Math.floor(priceRange[0]);
                let maxPrice = Math.ceil(priceRange[1]);
                params.minprice = minPrice;
                params.maxprice = maxPrice;
            }
            if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
                let minBuilt = Math.floor(builtUpArea[0]);
                let maxBuilt = Math.ceil(builtUpArea[1]);
                params.carpet_min = minBuilt;
                params.carpet_max = maxBuilt;
            }
            let paramsURL = new URLSearchParams(location.search);
            paramsURL.set('project_status', newStatus);
            if (newStatus.length === 0) {
                paramsURL.delete('project_status');
            }
            location.search = paramsURL.toString();
            // console.log('New Project Status :- ', location.search);
            const newUrl = `${currentUrl}?${location.search}`;
            navigate(newUrl, { replace: true });
            setCurrentPage(0);
            setPage(1);

            // params.page = 1;
            // console.log("Callinggggggggggggggggggggggg-----------------1111111111111111")
            // getProjects(params, 0);

            return newStatus; // Return the updated state value
        });
    };



    const togglePossessionStatus = (status) => {
        // console.log("status", status);
        // const newStatus = parseStatus(status);
        // console.log("statuschange", newStatus);
        setSelectedPossessionStatus((prevStatus) => {
            // console.log("prevStatus", prevStatus);
            const newStatus = prevStatus.includes(status)
                ? [] // Remove if already selected, resulting in an empty array
                : [status]; // Set as the only selected value

            // to return old exact array
            // const newStatus = prevStatus.includes(status)
            //     ? prevStatus.filter(item => item !== status) // Remove if already selected
            //     : [...prevStatus, status]; // Add if not already selected
            // Update params and fetch projects
            const params = getAllParams(location);
            if (locality_name?.length != 0) {
                if (locality_name !== undefined) {
                    params.locality_name = locality_name;
                }
                params.searchtype = 'locality_name';
            }
            if (newStatus.length !== 0) {
                params.possession_months = newStatus;
            }
            if (numericParts.length !== 0) {
                params.bhk = numericParts ? numericParts : 0;
                params.type = nonNumericParts;
            }
            if (selectedConf.length !== 0) {
                params.configurations = selectedConf.toString().toLowerCase();
            }
            if (sortCriteria.length !== 0) {
                params.orderby = sortCriteria;
            }
            if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
                let minPrice = Math.floor(priceRange[0]);
                let maxPrice = Math.ceil(priceRange[1]);
                params.minprice = minPrice;
                params.maxprice = maxPrice;
            }
            if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
                let minBuilt = Math.floor(builtUpArea[0]);
                let maxBuilt = Math.ceil(builtUpArea[1]);
                params.carpet_min = minBuilt;
                params.carpet_max = maxBuilt;
            }
            let paramsURL = new URLSearchParams(location.search);
            // calculateMonths(newStatus);
            // console.log("adaaarshh--"+newStatus);
            paramsURL.set('possession_months', newStatus);
            if (newStatus.length === 0) {
                paramsURL.delete('possession_months');
            }
            location.search = paramsURL.toString();
            // console.log('New Possession Status :- ', location.search);
            const newUrl = `${currentUrl}?${location.search}`;
            navigate(newUrl, { replace: true });
            setCurrentPage(0);
            setPage(1);

            params.page = 1;
            // getProjects(params, 0);

            return newStatus; // Return the updated state value
        });
    };


    {/* End Project Status Sort Function */ }

    {/* Start Project BHK Type Sort Function */ }
    const sortProjectsByBhkType = (status) => {
        const order = sortOrderBHK === 'asc' ? 'desc' : 'asc';
        const sorted = [...projects].sort((a, b) => {
            const orderFactor = order === 'asc' ? 1 : -1;
            return orderFactor * (statusOrder[a.configurations.name] - statusOrder[b.configurations.name]);
        });
        setSortOrderBHK(order);
        setSelectedTab(status);
        setCurrentPage(0);
    };


    const toggleBhkType = (bhkType) => {
        setSelectedBHK((prevBhkType) => {
            const newBhkType = prevBhkType.includes(bhkType)
                ? prevBhkType.filter(item => item !== bhkType) // Remove if already selected
                : [...prevBhkType, bhkType]; // Add if not already selected

            // console.log("Selected BHK Types:------", newBhkType);

            // Check if the newBhkType array is empty to display the "Data not found" message
            if (newBhkType.length === 0) {
                console.log("Data not found"); // Show message or handle the scenario
            }

            // Update params and fetch projects
            const params = getAllParams(location);

            if (locality_name?.length != 0) {
                if (locality_name !== undefined) {
                    params.locality_name = locality_name;
                    console.log(locality_name);
                }
                params.searchtype = 'locality_name';
            }
            if (selectedStatus.length !== 0) {
                params.project_status = selectedStatus;
            }
            if (selectedPossessionStatus.length != 0) {
                params.possession_months = selectedPossessionStatus;
            }
            getNumericParts(newBhkType.map(type => type.match(/^\d*\.?\d*/)[0] === "" ? '0' : type.match(/^\d*\.?\d*/)[0]));
            getNonNumericParts(newBhkType.map(type => type.match(/[a-zA-Z]+$/)[0].toLowerCase()));

            const numericPartsNew = newBhkType.map(type => type.match(/^\d*\.?\d*/)[0] === "" ? '0' : type.match(/^\d*\.?\d*/)[0]);
            const nonNumericPartsNew = newBhkType.map(type => type.match(/[a-zA-Z]+$/)[0].toLowerCase());
            if (numericPartsNew.length !== 0) {
                params.bhk = numericPartsNew ? numericPartsNew : 0;
                params.type = nonNumericPartsNew;
            }
            if (selectedConf.length !== 0) {
                params.configurations = selectedConf.toString().toLowerCase();
            }
            if (sortCriteria.length !== 0) {
                params.orderby = sortCriteria;
            }
            if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
                let minPrice = Math.floor(priceRange[0]);
                let maxPrice = Math.ceil(priceRange[1]);
                params.minprice = minPrice;
                params.maxprice = maxPrice;
            }
            if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
                let minBuilt = Math.floor(builtUpArea[0]);
                let maxBuilt = Math.ceil(builtUpArea[1]);
                params.carpet_min = minBuilt;
                params.carpet_max = maxBuilt;
            }
            let paramsURL = new URLSearchParams(location.search);
            let newBhkTypeArray = Array.isArray(newBhkType) ? newBhkType : newBhkType.split(',');
            paramsURL.set('configuration', newBhkTypeArray);
            let numericPartsNewArray = Array.isArray(numericPartsNew) ? numericPartsNew : numericPartsNew.split(',');
            paramsURL.set('bhk', numericPartsNewArray);
            let nonNumericPartsNewArray = Array.isArray(nonNumericPartsNew) ? nonNumericPartsNew : nonNumericPartsNew.split(',');
            paramsURL.set('type', nonNumericPartsNewArray);
            if (newBhkType.length === 0) {
                paramsURL.delete('configuration');
                paramsURL.delete('bhk');
                paramsURL.delete('type');
            }
            location.search = paramsURL.toString();
            const newUrl = `${currentUrl}?${location.search}`;
            navigate(newUrl, { replace: true });

            setSelectedTab(bhkType);
            setCurrentPage(0);
            setPage(1);
            // console.log("Callingggggg-----------55555555555");
            // getProjects(params, 0);

            return newBhkType;
        });
    };




    const toggleShowAllBhk = () => {
        setShowAllBhk(!showAllBhk);

    };


    const clearUrlParams = (state) => {
        if (state == "true") {
            const params = new URLSearchParams(location.search);
            localStorage.removeItem('selectedValues');
            params.delete('configuration');
            params.delete('bhk');
            params.delete('type')

            getNumericParts([])
            setSelectedPossessionStatus([]);
            setSelectedStatus([]);
            setSelectedBHK([]);
            setSelectedConf([]);
            setSortCriteria('');
            setPriceRange([500000, 50000000]);
            setBuiltRange([100, 5000]);
            setAllParams1({});
            setSelectedBHK([]);
            getProjectBhkType([])
            setShowAllBhk(false);
            setParams([])
            setSelectedTab(null)
            setPaginatedData([])
            setCurrentPage(0);
            setPage(1);
            const newUrl = `${currentUrl}`;
            navigate(newUrl, { replace: true });
        }
    }

    // const toggleShowAllConf = () => {
    //     setShowAllConf(!showAllConf);
    // };

    const convertToJSON = (data) => {
        return JSON.stringify(data, (key, value) => {
            if (Array.isArray(value)) {
                return value.map((item) => convertToJSON(item));
            } else if (typeof value === 'object') {
                return convertToJSON(value);
            }
            return value;
        });
    };

    const handleShowFilterSidebarBtn = () => {
        const rightSideDiv = document.querySelector('.filter-sidebar');
        rightSideDiv.classList.toggle('active');
    }
    const handleHideFilterSidebarBtn = () => {
        // const filterForm = document.querySelector('.filter-sidebar');
        // filterForm.reset();
        handleShowFilterSidebarBtn();
        window.scrollTo(0, 0);
    }
    const handleShowSortSidebarBtn = () => {
        const sortSideBar = document.getElementById('sort-filter');
        sortSideBar.classList.toggle('active');
    }
    const handleHideSortSidebarBtn = () => {
        const sortSideBar = document.getElementById('sort-filter');
        sortSideBar.classList.remove('active');
    }


    return (
        <>
            <Helmet>
                <title>{seoTitle}</title>
                <meta name="description" content={seoDescription} />
                {/* <link rel="canonical" href={`${appUrl}/${currentUrl}`} /> */}
            </Helmet>
            {/* <Header onCityChange={handleCityChange}/> */}
            <React.Fragment key={pageDetails.pageslug}>
            <div className={isPdp ? 'irfslist-class' : ''}>
                <main className='plp-page pb-5 pt-lg-5 pt-0 mt-5'>
                    <section className="pt-5 pt-lg-4">
                        <div className="px-2 px-sm-4">
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <div className="d-flex row">
                                        <div className='col-lg-6 col-md-4 col-8'>
                                            {paginatedData ? (
                                                paginatedData.length === 0 && loading ? (
                                                    (noDataFound ? (
                                                        <></>
                                                    ) : (
                                                        <Placeholder animation="wave" >
                                                            <Placeholder xs={12} className="custom-loading-skeleton" />
                                                        </Placeholder>
                                                    ))

                                                ) : (
                                                    <h1 className="fs-6 mt-2 fw-bold"><span className="text-primary text-orange">Showing</span> <span id="search-count" className="text-primary text-orange">{totalItems} </span>
                                                        Properties
                                                    </h1>
                                                )
                                            ) : (
                                                <Placeholder animation="wave" >
                                                    <Placeholder xs={12} className="custom-loading-skeleton" />
                                                </Placeholder>
                                            )}
                                        </div>
                                        <div className='col-lg-6 col-md-8 col-4 row pe-0'>
                                            <div className='col-12 text-end d-inline-flex align-items-center justify-content-end pe-0 text-end'>

                                                <span className='me-2 hidden-ipro'>Sort by: </span>
                                                <select className="sort-filter form-select form-select-sm hidden-ipro rounded-pill" value={sortCriteria} onChange={(e) => [getSortProjectFilter(e.target.value), createGa4Event('On Chnage', 'Configuration Type Filter', e.target.value)]} style={{ "width": "200px" }}>
                                                    <option value="recentlyLaunched">Recently Launched</option>
                                                    <option value="priceLowToHigh">Price: Low to High</option>
                                                    <option value="priceHighToLow">Price: High to Low</option>
                                                    <option value="sqftLowToHigh">Sq. Ft.: Low to High</option>
                                                    <option value="sqftHighToLow">Sq. Ft.: High to Low</option>
                                                    <option value="possessionDate">Possession Date (Recent First)</option>
                                                    {/* <option value="updatedat">Latest Updated</option> */}
                                                </select>
                                                <a className={`text-primary ms-2 text-decoration-underline border p-1 border-primary border-orange rounded-pill`} onClick={() => createGa4Event('On Click', 'List View', 'List View')}>
                                                    <Icon className='i-icon' icon="uim:list-ul" style={{ "color": "#43A66A", "width": "40px", "height": "25px" }} />
                                                </a>
                                                <a href={`${appAbsPath}/projects/newmap${location.search}`} className={`text-primary ms-2 text-decoration-underline border p-1 rounded-pill`} onClick={() => createGa4Event('On Click', 'Map View', 'Map View')}>
                                                    <Icon className='' icon="uiw:map" style={{ "color": "#6b6b6b", "width": "40px", "height": "25px" }} />
                                                </a>
                                            </div>
                                        </div>

                                        <section className="map-sec">
                                            <div id="image" style={{ "display": "none" }}>
                                                <img src={require("../../../Assets/img//mapview-img.webp")} className="d-block rounded w-100 object-fit-cover h-100 images-corousal" />
                                            </div>
                                        </section>

                                    </div>
                                </div>
                            </div>
                            <div className="row flex-column-reverse flex-md-row">
                                <div className="d-flex flex-column gap-4 rounded project-listing-main col-12 col-xl-9">

                                    <div className='min-height-100vh'>
                                        {paginatedData ? (
                                            paginatedData.length === 0 && loading ? (
                                                (noDataFound ? (
                                                    <div className="text-center mt-5">
                                                        <h2>No Projects Found</h2>
                                                    </div>
                                                ) : (
                                                    <Loader />
                                                ))
                                            ) : (
                                                <div className="row">
                                                    <Suspense fallback={<Loader />}>
                                                        <InfiniteScroll
                                                            dataLength={paginatedData.length}
                                                            next={getProjects}
                                                            hasMore={hasMore}
                                                            loader={<h4><span className="custom-loader m-auto"></span></h4>}
                                                            endMessage={<><p className='text-center'>You've reached the end of the list. <br /> Explore more properties by adjusting your filters or refining your search.</p>
                                                                <div className='m-2 w-95'>
                                                                    <RelatedProjects slidesToShow={[3, 2, 2, 2, 1]} />
                                                                </div>
                                                            </>
                                                            }
                                                        >
                                                            {paginatedData.map((project, index) => (

                                                                <ProjectCardListing
                                                                    key={index}
                                                                    project={project}
                                                                    selectedTab={selectedBHK || selectedConf}
                                                                    handleTabClick={handleTabClick}
                                                                    getMinMaxCarpet={getMinMaxCarpet}
                                                                    appAbsPath={appAbsPath}
                                                                    userdetails={userdetails}
                                                                    isWishlistActive={isWishList}
                                                                    loggeduserdetails={parsedUserData}
                                                                    locality_name={locality_name}
                                                                />
                                                            ))}
                                                        </InfiniteScroll>
                                                    </Suspense>
                                                </div>
                                            )

                                        ) : (
                                            <Loader />
                                        )}

                                    </div>
                                </div>


                                <div className="col-12 col-md-3 hidden-ipro">
                                    <FilterSidebar
                                        selectedStatus={selectedStatus} toggleStatus={toggleStatus}
                                        togglePossessionStatus={togglePossessionStatus} selectedPossessionStatus={selectedPossessionStatus}
                                        // sortProjectsByStatus={sortProjectsByStatus}
                                        filteredByBhk={filteredByBhk} initialDisplayLimit={initialDisplayLimit} showAllBhk={showAllBhk} toggleShowAllBhk={toggleShowAllBhk} selectedBHK={selectedBHK}
                                        toggleBhkType={toggleBhkType} sortProjectsByBhkType={sortProjectsByBhkType} setSelectedTab={setSelectedTab}
                                        priceRange={priceRange} handleSliderChange={handleSliderChange} handleSliderChangeComplete={handleSliderChangeComplete}
                                        builtUpArea={builtUpArea} handleSliderChangeArea={handleSliderChangeArea} handleSliderChangeCompleteArea={handleSliderChangeCompleteArea}
                                        getProjectstatusData={getProjectstatusData} clearUrlParams={clearUrlParams}
                                    />
                                </div>
                            </div >
                        </div >

                    </section >




                    <div className='visible-ipro mobile-filter-wrap'>
                        <a className='filter-sidebar-sort' onClick={() => handleShowSortSidebarBtn()}>
                            <Icon icon="bx:sort" />
                            <span className='relativeWrapper'>
                                Sort
                                {/* {activeSort !== "" && <sup className='activeDot'>&nbsp;</sup>} */}
                            </span>
                        </a>
                        <a className='filter-sidebar-btn' onClick={() => handleShowFilterSidebarBtn()}>
                            <Icon icon="mage:filter" />
                            <span className='relativeWrapper'> 
                                &nbsp;Filters
                                {hasParams && <sup className='activeDot'>&nbsp;</sup>}
                            </span>
                        </a>
                    </div>
                    <div className='visible-ipro mobile-filter-wrap-popup' id="sort-filter">
                        <div className='filter-sidebar-sort-popup'>
                            <h4>Sort By: <a className='float-end' onClick={() => handleHideSortSidebarBtn()}><Icon icon="codicon:close" style={{ "color": "#6b6b6b" }} /></a></h4>
                            <hr />
                            <ul>
                                <li
                                    onClick={() => {
                                        getSortProjectFilter("recentlyLaunched");
                                        handleHideSortSidebarBtn();
                                        createGa4Event('On Click', 'Filters', 'Recently Launched')
                                    }}
                                    className={activeSort === "recentlyLaunched" ? 'active' : ''}
                                >
                                    <div className='d-flex justify-content-between'>
                                        Recently Launched
                                    </div>
                                </li>
                                <li
                                    onClick={() => {
                                        getSortProjectFilter("priceLowToHigh");
                                        handleHideSortSidebarBtn();
                                        createGa4Event('On Click', 'Filters', 'Low to High')
                                    }}
                                    className={activeSort === "priceLowToHigh" ? 'active' : ''}
                                >
                                    <div className='d-flex justify-content-between'>
                                        Price: Low to High
                                    </div>
                                </li>
                                <li
                                    onClick={() => {
                                        getSortProjectFilter("priceHighToLow");
                                        handleHideSortSidebarBtn();
                                        createGa4Event('On Click', 'Filters', 'High to Low');
                                    }}
                                    className={activeSort === "priceHighToLow" ? 'active' : ''}
                                >
                                    <div className='d-flex justify-content-between'>
                                        Price: High to Low
                                    </div>
                                </li>
                                <li
                                    onClick={() => {
                                        getSortProjectFilter("sqftLowToHigh");
                                        handleHideSortSidebarBtn();
                                        createGa4Event('On Click', 'Filters', 'Low to High');
                                    }}
                                    className={activeSort === "sqftLowToHigh" ? 'active' : ''}
                                >
                                    <div className='d-flex justify-content-between'>
                                        Sq. Ft.: Low to High
                                    </div>
                                </li>
                                <li
                                    onClick={() => {
                                        getSortProjectFilter("sqftHighToLow");
                                        handleHideSortSidebarBtn();
                                        createGa4Event('On Click', 'Filters', 'Sq. Ft.: High to Low');
                                    }}
                                    className={activeSort === "sqftHighToLow" ? 'active' : ''}
                                >
                                    <div className='d-flex justify-content-between'>
                                        Sq. Ft.: High to Low
                                    </div>
                                </li>
                                <li
                                    onClick={() => {
                                        getSortProjectFilter("possessionDate");
                                        handleHideSortSidebarBtn();
                                        createGa4Event('On Click', 'Filters', 'Possession Date (Recent First)');
                                    }}
                                    className={activeSort === "possessionDate" ? 'active' : ''}
                                >
                                    <div className='d-flex justify-content-between'>
                                        Possession Date (Recent First)
                                    </div>
                                </li>
                                {/* <li onClick={() => {getSortProjectFilter("sqftHighToLow"); handleHideSortSidebarBtn()}}>
                                <div className='d-flex justify-content-between'>
                                    Sq. Ft.: High to Low
                                </div>
                            </li> */}
                                {/* <li onClick={() => { getSortProjectFilter("updatedat"); handleHideSortSidebarBtn(); createGa4Event('On Click', 'Filters', 'Latest Updated') }}>
                                    <div className='d-flex justify-content-between'>
                                        Latest Updated
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className='filter-sidebar visible-ipro'>
                        <div className="headBtns">
                            <a className='' onClick={() => handleHideFilterSidebarBtn()}>
                                <svg width="19" height="16" viewBox="0 0 19 16" xmlns="http://www.w3.org/2000/svg"><path d="M17.556 7.847H1M7.45 1L1 7.877l6.45 6.817" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none"></path></svg>
                            </a>
                            <h4 className='mb-0'>Filters:</h4>
                            {hasParams && (
                                <button
                                    className='btn btn-primary sc py-1 h-auto'
                                    onClick={() => clearUrlParams("true")}
                                >
                                    Clear All
                                </button>
                            )}
                        </div>
                        <hr className='mt-0' />
                        <Tab.Container id="left-tabs-example" defaultActiveKey="three">
                            <Row className='align-items-stretch d-flex'>
                                <Col sm={4} className='pe-0 align-items-stretch d-flex flex-column left-tab-container'>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="three">Project Status</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='flex-column d-flex'>
                                            <Nav.Link eventKey="first">Configuration Type</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="five">Possession Status</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Budget</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="four">Carpet Area (Sq.Ft.)</Nav.Link>
                                        </Nav.Item>

                                    </Nav>
                                </Col>
                                <Col sm={8} className='ps-3'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            {filteredByBhk && filteredByBhk.length > 0 && filteredByBhk.slice(0, showAllBhk ? filteredByBhk.length : initialDisplayLimit).map((BhkType, index) => (
                                                BhkType !== "" && (
                                                    BhkType && BhkType.bedrooms && BhkType.type &&
                                                    <a key={index} className={`btn btn-outline-secondary me-2 mb-2 ${selectedBHK.includes(BhkType.bedrooms + " " + BhkType.type.toUpperCase()) ? 'active' : ''}`}
                                                        onClick={() => {
                                                            toggleBhkType(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                                                            sortProjectsByBhkType(selectedBHK);
                                                            setSelectedTab(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                                                            createGa4Event('Click', 'Configuration Type Filter', BhkType.bedrooms + " " + BhkType.type.toUpperCase())
                                                        }}>
                                                        {BhkType.bedrooms} {BhkType.type.toUpperCase()}
                                                    </a>
                                                )
                                            ))}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <Slider
                                                range
                                                min={500000}
                                                max={50000000}
                                                value={priceRange}
                                                onChange={handleSliderChange}
                                                onChangeComplete={handleFilter}
                                            />
                                            <p className='d-flex justify-content-between'>
                                                <span>{`₹${formatNumber(priceRange[0])}`}</span>
                                                <span className='pe-md-0 pe-3'>{`₹${formatNumber(priceRange[1])}`}+</span>
                                            </p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="three">
                                            {/* {getProjectstatusData.map((status, index) => (
                                    (status != "" && 
                                        <a
                                            key={index}
                                            className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes(status) ? 'active' : ''}`}
                                            onClick={() => {
                                            toggleStatus(status);
                                            sortProjectsByStatus(selectedStatus);
                                            }}
                                        >
                                        {status}
                                        </a>
                                    )
                                ))} */}
                                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("New Project") ? 'active' : ''}`}
                                                onClick={() => {
                                                    toggleStatus("New Project");
                                                    // sortProjectsByStatus(selectedStatus);
                                                }}>
                                                New Project
                                            </a>
                                            {/* <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("New Launch") ? 'active' : ''}`}
                                                onClick={() => {
                                                    toggleStatus("New Launch");
                                                    // sortProjectsByStatus(selectedStatus);
                                                }}>
                                                New Launch
                                            </a> */}
                                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Under Construction") ? 'active' : ''}`}
                                                onClick={() => {
                                                    toggleStatus("Under Construction");
                                                    // sortProjectsByStatus(selectedStatus);
                                                }}>
                                                Under Construction
                                            </a>
                                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Ready To Move") ? 'active' : ''}`}
                                                onClick={() => {
                                                    toggleStatus("Ready To Move");
                                                    // sortProjectsByStatus(selectedStatus);
                                                }}>
                                                Ready To Move
                                            </a>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="four">
                                            <Slider
                                                range
                                                min={100}
                                                max={5000}
                                                value={builtUpArea}
                                                onChange={handleSliderChangeArea}
                                                onChangeComplete={handleFilterArea}
                                            />
                                            <p className='d-flex justify-content-between'>
                                                <span>{`${builtUpArea[0]} Sq.Ft.`}</span>
                                                <span className='pe-md-0 pe-3'>{`${builtUpArea[1]} Sq.Ft.`} {builtUpArea[1] === 5000 && '+'}</span>
                                            </p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="five">
                                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 3 ? 'active' : ''}`}
                                                onClick={() => {
                                                    togglePossessionStatus('3');
                                                    // sortProjectsByStatus(selectedPossessionStatus);
                                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 3 months')
                                                }}>
                                                In 3 months
                                            </a>
                                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 6 ? 'active' : ''}`}
                                                onClick={() => {
                                                    togglePossessionStatus('6');
                                                    // setProjectStatusFilter("In 6 months");
                                                    // sortProjectsByStatus(selectedPossessionStatus);
                                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 6 months')
                                                }}>
                                                In 6 months
                                            </a>
                                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 12 ? 'active' : ''}`}
                                                onClick={() => {
                                                    togglePossessionStatus('12');
                                                    // setProjectStatusFilter("In 1 year");
                                                    // sortProjectsByStatus(selectedPossessionStatus);
                                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 1 year')
                                                }}>
                                                In 1 year
                                            </a>
                                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 24 ? 'active' : ''}`}
                                                onClick={() => {
                                                    togglePossessionStatus('24');
                                                    // setProjectStatusFilter("In 2 years");
                                                    // sortProjectsByStatus(selectedPossessionStatus);
                                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 2 years')
                                                }}>
                                                In 2 years
                                            </a>
                                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 36 ? 'active' : ''}`}
                                                onClick={() => {
                                                    togglePossessionStatus('36');
                                                    // setProjectStatusFilter("In 3 years");
                                                    // sortProjectsByStatus(selectedPossessionStatus);
                                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 3 years')
                                                }}>
                                                In 3 years
                                            </a>
                                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 60 ? 'active' : ''}`}
                                                onClick={() => {
                                                    togglePossessionStatus('60');
                                                    // setProjectStatusFilter("In 5 years");
                                                    // sortProjectsByStatus(selectedPossessionStatus);
                                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 5 years')
                                                }}>
                                                In 5 years
                                            </a>
                                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 84 ? 'active' : ''}`}
                                                onClick={() => {
                                                    togglePossessionStatus('84');
                                                    // setProjectStatusFilter("In 7 years");
                                                    // sortProjectsByStatus(selectedPossessionStatus);
                                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 7 years')
                                                }}>
                                                In 7 years
                                            </a>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                        <button className="btn btn-primary w-100 theme-btn filter-apply-btn" onClick={() => handleHideFilterSidebarBtn()}>Apply</button>
                    </div>

                </main>     
            </div>
            </React.Fragment>
        </>
    )
}
export default ProjectList;