
import React from "react";
import { Container, Row } from "react-bootstrap";
import defaultGallery from '../../../Assets/img/banner404.png';


const Page404 = ({}) => {

    const handleRedirect = () => {
        window.location.href = '/';
    }
    return(<>
        <main className='plp-page pb-5 mt-5 bg-white'>
        <section className="banner404 pt-5">
            <Container>
                <Row>
                    <div className="content404">
                        <h1 className="head404">404</h1>
                        <div className="subhead404">Oops!</div>
                        <div className="desc404">Property not found. Let's discover your dream home together!</div>
                        <button class="btn btn-darkblue bg-theme btn-sm p-2 px-3 mt-3 btn-bk-home" onClick={()=>[handleRedirect()]}>Back to home</button>
                        <img src={defaultGallery} className="img-fluid banner404"/>
                    </div>
                </Row>
            </Container>
        </section>
        </main>
        </>)
};

export default Page404;